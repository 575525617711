import React, { Component } from 'react';
import { Link } from "react-router-dom";
import logo1 from './logo-mxt.png';
//import logo2 from './logo-cmtk.png';
import logo2 from './logo-associazione-tokalon.png';
import logo3 from './logo-ixt.png';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import HomeButton from './HomeButton';
import SocialButtonsInHeader from './SocialButtonsInHeader';
import Subheader from './Subheader';
import {Helmet} from "react-helmet";
import Logo from './Logo';
import TokalonButton from './TokalonButton';

class InvalidUrl extends Component {

    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            invalidurl: false,
            reset_password_url_wp: ""
        };
    }

    componentDidMount() {
        this._isMounted = true;
        var config = require('./config.json');
        var env_wp = config.env_wp;
        this.setState({reset_password_url_wp: config.reset_password_url_wp[env_wp]});        
    }

    render() {

        var reset_password_url_wp = this.state.reset_password_url_wp;

        return (
            <>
            <Helmet htmlAttributes={{lang: 'it-IT'}}></Helmet>
            <div className="App">
                <header className="App-header">
                    <AppBar position="static" className="WelcomeBar">
                        <Toolbar>
                            <table className="WelcomeMenu">
                            <tbody>
                                    <tr>
                                        <td className="LogoMenuColumn">
                                            <Logo />
                                        </td>
                                        <td className="HomeMenuColumn">
                                            <HomeButton />
                                            |
                                            <TokalonButton />
                                        </td>
                                        <td className="AccountMenuButton">
                                            <>
                                                {/* <SocialButtonsInHeader /> */}
                                            </>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Toolbar>
                    </AppBar>
                    {/* <Subheader></Subheader> */}
                    {/* <div className="Logo-Bar">
                        <div className="Logo-Box"><img src={logo1} alt="logo1" height="60"></img></div>
                        <div className="Logo-Box"><img src={logo2} alt="logo2" height="60"></img></div>
                        <div className="Logo-Box"><img src={logo3} alt="logo3" height="60"></img></div>
                    </div> */}
                    <div className="Home-description">
                        <p>Il link è scaduto. Reimposta la password per verificare l'email.</p>
                    </div>
                    <div className="Form-control">
                        <a href={reset_password_url_wp}>
                            <Button 
                                size="large" 
                                className="submit" 
                                variant="contained" 
                                color="secondary">
                                    REIMPOSTA LA PASSWORD
                            </Button>
                        </a>
                    </div>
                </header>
            </div>
            </>
        );
    }
}

export default InvalidUrl;