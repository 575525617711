import React, { Component, Fragment } from 'react';
import Cookies from 'js-cookie';
import { Redirect } from "react-router-dom";
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Link } from "react-router-dom";
import Subheader from './Subheader';
import HomeButton from './HomeButton';
import SocialButtonsInHeader from './SocialButtonsInHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import {Helmet} from "react-helmet";
import Logo from './Logo';
import TokalonButton from './TokalonButton';

class SupporterOrdered extends Component {

    _isMounted = false;

    constructor(props) {

        super(props);
        this.state = {
            authorization: Cookies.get('authorization') || '',
            wp_user_id: Cookies.get('wp_user_id') || 0,
            user_email: Cookies.get('user_email') || '',
            nome: Cookies.get('nome') || '',
            cognome: Cookies.get('cognome') || '',
            anchorElUserMenu: null
        }
        this.handleLogoutClick = this.handleLogoutClick.bind(this);
        this.handleUserMenuClick = this.handleUserMenuClick.bind(this);
        this.handleCloseUserMenu = this.handleCloseUserMenu.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleLogoutClick(event) {
        event.preventDefault();
        this.setState({ authorization: ''});
        Cookies.remove('authorization');
        Cookies.remove('user_email');
        Cookies.remove('nome');
        Cookies.remove('cognome');
        Cookies.remove('wp_user_id');
        Cookies.remove('newuser');
    }

    handleUserMenuClick(event){
        //console.log(event.currentTarget);
        this.setState({ anchorElUserMenu: event.currentTarget});
    }

    handleCloseUserMenu(){
        this.setState({ anchorElUserMenu: null});
    }

    render() {

        const autenticato = this.state.authorization !== '';

        const nome = this.state.nome;
        const cognome = this.state.cognome;
        
        const anchorElUserMenu = this.state.anchorElUserMenu;
        const open = Boolean(anchorElUserMenu);

        return (
            <div>
            { autenticato ? ( 
            <Fragment>
            <Helmet htmlAttributes={{lang: 'it-IT'}}></Helmet>
            <div className="App">
                <header className="App-header-home">
                    <AppBar position="static" className="WelcomeBar">
                        <Toolbar>
                            <table className="WelcomeMenu">
                                <tbody>
                                    <tr>
                                        <td className="LogoMenuColumn">
                                            <Logo />
                                        </td>
                                        <td className="HomeMenuColumn">
                                            <HomeButton />
                                            |
                                            <TokalonButton />
                                        </td>
                                        <td className="AccountMenuButton">
                                            { (nome === "" || cognome === "") ? (
                                                <>
                                                    {/* <SocialButtonsInHeader /> */}
                                                    <Link to="/profile">
                                                        <Button
                                                            className="AccountName"
                                                            size="large"
                                                            id="user-menu-button"
                                                            aria-label="account of current user"
                                                            aria-controls="user-menu"
                                                            //aria-haspopup="true"
                                                            //aria-expanded={Boolean(anchorElUserMenu) ? 'true' : undefined}
                                                            //onClick={this.handleUserMenuClick}
                                                            color="inherit"
                                                        >
                                                            {/* <FontAwesomeIcon icon={faUser} /> */}
                                                            <span className="WelcomeUser">{/* Bentornato */}</span>
                                                        </Button>
                                                    </Link>
                                                    |
                                                    <Button
                                                        className=""
                                                        size="large"
                                                        color="inherit"
                                                        id="logout-button"
                                                        onClick={this.handleLogoutClick}
                                                    >
                                                        Esci
                                                    </Button>
                                                </>
                                            ): (
                                                <>
                                                    {/* <SocialButtonsInHeader /> */}
                                                    <Link to="/profile">
                                                        <Button
                                                            className=""
                                                            size="large"
                                                            id="user-menu-button"
                                                            aria-label="account of current user"
                                                            aria-controls="user-menu"
                                                            //aria-haspopup="true"
                                                            //aria-expanded={Boolean(anchorElUserMenu) ? 'true' : undefined}
                                                            //onClick={this.handleUserMenuClick}
                                                            color="inherit" 
                                                        >
                                                            {/* <FontAwesomeIcon icon={faUser} /> */}
                                                            <span className="WelcomeUser">{/* Bentornato */}</span> { nome + " " + cognome }
                                                        </Button>
                                                    </Link>
                                                    |
                                                    <Button
                                                        className=""
                                                        size="large"
                                                        color="inherit"
                                                        id="logout-button"
                                                        onClick={this.handleLogoutClick}
                                                    >
                                                        Esci
                                                    </Button>
                                                </>
                                            )}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <Menu
                                id="user-menu"
                                anchorEl={anchorElUserMenu}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={open}
                                onClose={this.handleCloseUserMenu}
                                MenuListProps={{
                                    'aria-labelledby': 'user-menu-button',
                                }}
                            >
                                <MenuItem onClick={this.handleCloseUserMenu}>Profilo</MenuItem>
                                <MenuItem onClick={this.handleLogoutClick}>Esci</MenuItem>
                            </Menu>
                        </Toolbar>
                    </AppBar>
                    {/* <Subheader></Subheader> */}
                </header>
                <div className="Box-fullpage">
                    <div className="Home-description">
                        <p>Domanda di iscrizione inoltrata con successo! Riceverai un'email non appena sarà confermata.</p>
                        <p>Possiedi già i giochi relativi alle competizioni a cui hai iscritto le tue classi?</p>
                        <p><a href="https://www.associazionetokalon.com/giochi-didattica/" target="_blank">Puoi acquistarli <strong>QUI</strong></a></p>
                        <div className="Form-control" style={{textAlign: "center"}}>
                            <Link to="/">
                                <Button size="large" className="submit" variant="contained" color="secondary">Torna alla home</Button>
                            </Link>
                        </div>
                    </div>
                </div> 
                <AppBar position="static" className="Footer">
                    <div>© Copyright {new Date().getFullYear()} – Associazione ToKalon - <Link to="/privacy">Privacy Policy</Link> - Realizzato da <a href="https://www.creab.it/" target="_blank" rel="noreferrer">Creabit.</a></div>
                </AppBar>              
            </div>
            </Fragment>
            ) : (
                <Redirect to="/login" />
            )}
            </div>
        );
    }
}

export default SupporterOrdered;