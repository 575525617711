import React, { Component } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';
import { Redirect } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import purify from "dompurify";
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import Box from '@mui/material/Box';
import { Link } from "react-router-dom";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import { withRouter } from "react-router";
import Divider from '@mui/material/Divider';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import {Helmet} from "react-helmet";
import Subheader from './Subheader';
import HomeButton from './HomeButton';
import SocialButtonsInHeader from './SocialButtonsInHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import Logo from './Logo';
import TokalonButton from './TokalonButton';

class SignupClass extends Component {

    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            authorization: Cookies.get('authorization') || '',
            wp_user_id: Cookies.get('wp_user_id') || 0,
            user_email: Cookies.get('user_email') || '',
            nome: Cookies.get('nome') || '',
            cognome: Cookies.get('cognome') || '',

            anchorElUserMenu: null,

            nome_progetto: "",
            tipo_progetto: null,
            anno_progetto: "",
            descrizione_progetto: "",
            docente_sped_nome: "",
            docente_sped_cognome: "",
            docente_sped_indirizzo: "",
            docente_sped_citta: "",
            docente_sped_provincia: "XX",
            docente_sped_cap: "",
            docente_sped_cellulare: "",
            docente_sped_note_consegna: "",
            docente_fatt_nome: "",
            docente_fatt_cognome: "",
            docente_fatt_cf_piva: "",
            docente_fatt_indirizzo: "",
            docente_fatt_citta: "",
            docente_fatt_provincia: "XX",
            docente_fatt_cap: "",
            scuola_sped_docente_ref: null,
            scuola_sped_nome_docente_ref: "",
            scuola_sped_cognome_docente_ref: "",
            scuola_sped_grado_docente_ref: "",
            scuola_sped_nome: "",
            scuola_sped_indirizzo:"",
            scuola_sped_citta: "",
            scuola_sped_provincia: "XX",
            scuola_sped_cap: "",
            scuola_sped_cellulare: "",
            scuola_sped_note_consegna: "",
            
            //id_progetto: (props.match.params.id == 2 || props.match.params.id == 3) ? parseInt(props.match.params.id) : 2,
            id_progetto: props.match.params.id,
            
            classi: [],
            ordini_precedenti: 0,
            classi_gia_iscritte: [],
            email_gia_iscritti: [],

            id_metodo_pagamento: 0,
            id_scuola: null,
            nome_scuola: "",
            sped_nome: "",
            sped_cognome: "",
            sped_nome_scuola: "",
            sped_codice_scuola: "",
            sped_indirizzo: "",
            sped_citta: "",
            sped_provincia: "XX",
            sped_cap: "",
            sped_cellulare: "",
            note_consegna: "",
            fatt_nome: "",
            fatt_cognome: "",
            fatt_nome_scuola: "",
            fatt_cf_piva: "",
            fatt_indirizzo: "",
            fatt_citta: "",
            fatt_provincia: "XX",
            fatt_cap: "",
            importo_netto: 0,
            sconto: 0,
            bollo: 0,
            importo_totale: 0,
           

            error: false,
            errorcode: '',
            errormessage: '',
            isloading: false,
            
            orderenabled: false,
            addclassenabled: false,
            confirmclassenabled: false,
            editclassenabled: false,
            proceed: false,
            
            sped_nome_error: false,
            sped_cognome_error: false,
            sped_nome_scuola_error: false,
            sped_indirizzo_error: false,
            sped_citta_error: false,
            sped_provincia_error: false,
            sped_cap_error: false,
            sped_cellulare_error: false,
            note_consegna_error: false,
            fatt_nome_error: false,
            fatt_cognome_error: false,
            fatt_nome_scuola_error: false,
            fatt_cf_piva_error: false,
            fatt_indirizzo_error: false,
            fatt_citta_error: false,
            fatt_provincia_error: false,
            fatt_cap_error: false,
            ordercompleted: false,

            opendeletemodal: false,
            class_to_delete: null,
            class_to_edit: null,
            openimagemodal: false,
            image_to_show: null,
            image_hd_width: 0,

            //Stato della sottopagina aggiungi classe
            
            sub_classe: 1,
            sub_sezione: "A",
            sub_tipo_iscrizione: "docente",
            sub_id_docente_referente1: null,
            sub_id_docente_referente2: null,
            sub_nome_referente2: "",
            sub_cognome_referente2: "",
            sub_email_referente2: "",
            sub_id_prodotto_prim_selezionato: null,
            sub_nome_prodotto_prim_selezionato: null,
            sub_immagine_prodotto_prim_selezionato: "",
            sub_descrizione_prodotto_prim_selezionato: "",
            sub_importo_prodotto_prim_selezionato: 0,

            sub_prodotti_primari: [],
            sub_prodotti_secondari: [],
            sub_prodotti_regalo: [],
            sub_importo_totale: 0,

            sub_error: false,
            sub_errorcode: '',
            sub_errormessage: '',
            sub_isloading: false,

            sub_addclassenabled: true,
            sub_classe_error: false,
            sub_sezione_error: false,
            sub_nome_error: false,
            sub_cognome_error: false,
            sub_email_error: false,
            sub_email_exist_error: false,
            sub_addclasscompleted: false,
            sub_editclasscompleted: false,
            sub_addclasscanceled: false,
            sub_nome_referente2_error: false,
            sub_cognome_referente2_error: false,
            sub_email_referente2_error: false,
            sub_editclassenabled: true
            
        };
        this.handleChangeSpedNome = this.handleChangeSpedNome.bind(this);
        this.handleChangeSpedCognome = this.handleChangeSpedCognome.bind(this);
        this.handleChangeSpedNomeScuola = this.handleChangeSpedNomeScuola.bind(this);
        this.handleChangeSpedIndirizzo = this.handleChangeSpedIndirizzo.bind(this);
        this.handleChangeSpedCitta = this.handleChangeSpedCitta.bind(this);
        this.handleChangeSpedProvincia = this.handleChangeSpedProvincia.bind(this);
        this.handleChangeSpedCap = this.handleChangeSpedCap.bind(this);
        this.handleChangeSpedCellulare = this.handleChangeSpedCellulare.bind(this);
        this.handleChangeNoteConsegna = this.handleChangeNoteConsegna.bind(this);
        this.handleChangeFattNome = this.handleChangeFattNome.bind(this);
        this.handleChangeFattCognome = this.handleChangeFattCognome.bind(this);
        this.handleChangeFattNomeScuola = this.handleChangeFattNomeScuola.bind(this);
        this.handleChangeFattCfPiva = this.handleChangeFattCfPiva.bind(this);
        this.handleChangeFattIndirizzo = this.handleChangeFattIndirizzo.bind(this);
        this.handleChangeFattCitta = this.handleChangeFattCitta.bind(this);
        this.handleChangeFattProvincia = this.handleChangeFattProvincia.bind(this);
        this.handleChangeFattCap = this.handleChangeFattCap.bind(this);
        this.handleChangeMetodoPagamento = this.handleChangeMetodoPagamento.bind(this);
        
        this.handleLogoutClick = this.handleLogoutClick.bind(this);
        this.handleUserMenuClick = this.handleUserMenuClick.bind(this);
        this.handleCloseUserMenu = this.handleCloseUserMenu.bind(this);
        this.handleAddClassClick = this.handleAddClassClick.bind(this);
        this.handleOrderClick = this.handleOrderClick.bind(this);

        this.handleCancelClick = this.handleCancelClick.bind(this);
        this.handleCancelEditClick = this.handleCancelEditClick.bind(this);
        this.handleClassFormFilled = this.handleClassFormFilled(this);

        this.handleOpenDeleteModal = this.handleOpenDeleteModal.bind(this);
        this.handleCloseDeleteModal = this.handleCloseDeleteModal.bind(this);
        this.handleDeleteClassClick = this.handleDeleteClassClick.bind(this);
        this.handleEditClassClick = this.handleEditClassClick.bind(this);
        this.handleProceedClick = this.handleProceedClick.bind(this);

        //Bind eventi sottopagina aggiungi classe

        this.sub_handleChangeClasse = this.sub_handleChangeClasse.bind(this);
        this.sub_handleChangeSezione = this.sub_handleChangeSezione.bind(this);
        this.sub_handleChangeTipoIscrizione = this.sub_handleChangeTipoIscrizione.bind(this);
        this.sub_handleChangeNomeReferente2 = this.sub_handleChangeNomeReferente2.bind(this);
        this.sub_handleChangeCognomeReferente2 = this.sub_handleChangeCognomeReferente2.bind(this);
        this.sub_handleChangeEmailReferente2 = this.sub_handleChangeEmailReferente2.bind(this);
        this.sub_handleChangeProdottoPrimario = this.sub_handleChangeProdottoPrimario.bind(this);
        this.sub_handleChangeQuantitaProdottoSecondario = this.sub_handleChangeQuantitaProdottoSecondario.bind(this);

        this.sub_handleAddClassClick = this.sub_handleAddClassClick.bind(this);
        this.sub_handleOpenImageModal = this.sub_handleOpenImageModal.bind(this);
        this.sub_handleCloseImageModal = this.sub_handleCloseImageModal.bind(this);
        this.sub_handleEditClassClick = this.sub_handleEditClassClick.bind(this);
        this.getImageHdWidth = this.getImageHdWidth.bind(this);
        
    }

    componentDidMount() {

        this._isMounted = true;

        if (this._isMounted) {
            this.setState({ isloading: true });
        }

        //Verifica se c'è un'azione in pending e se c'è vai alla pagina di ordine completato
        var pending = Cookies.get('signup_pending');
        
        if (pending == 1 || pending =="1"){
            this.setState({ ordercompleted: true});
            Cookies.remove('signup_pending');
        }        

        var ImageHdWidth = this.getImageHdWidth();
        this.setState({ image_hd_width: ImageHdWidth });

        var config = require('./config.json');
        var env_backend = config.env_backend;
        var api_url_backend = config.api_url_backend[env_backend];
        const apiUrl = api_url_backend + 'classes/getclassdata';
        var authorization = config.api_auth_backend[env_backend];
        var email = this.state.user_email;

        const settings = {
            headers: { Authorization: `Bearer ${authorization}` }
        };

        axios.post(apiUrl, { 
            "id_progetto" : this.state.id_progetto,
            "email" : email
        }, settings).then(res => {
            //console.log(res);

            if (this._isMounted) {
                this.setState({ ordini_precedenti: res.data.data.ordini_precedenti.conteggio_ordini });
                this.setState({ classi_gia_iscritte: res.data.data.ordini_precedenti.classi_gia_iscritte });
                this.setState({ email_gia_iscritti: res.data.data.ordini_precedenti.email_gia_iscritti });
                this.setState({ id_progetto: res.data.data.dati_progetto.id });
                this.setState({ id_utente: res.data.data.dati_spedizione_docente.id_utente});
                this.setState({ grado_utente: res.data.data.dati_spedizione_docente.grado_utente});
                this.setState({ is_supporter: res.data.data.dati_spedizione_docente.is_supporter});
                this.setState({ sconto_attivo: res.data.data.dati_spedizione_docente.discount});
                this.setState({ id_scuola: res.data.data.dati_spedizione_docente.id_scuola});
                this.setState({ nome_scuola: res.data.data.dati_spedizione_docente.nome_scuola});
                //
                this.setState({ codice_meccanografico_scuola: res.data.data.dati_spedizione_docente.codice_scuola});
                //
                this.setState({ nome_progetto: res.data.data.dati_progetto.nome});
                this.setState({ tipo_progetto: res.data.data.dati_progetto.tipo});
                this.setState({ anno_progetto: res.data.data.dati_progetto.anno});
                this.setState({ descrizione_progetto: res.data.data.dati_progetto.descrizione});
                this.setState({ docente_sped_nome: res.data.data.dati_spedizione_docente.nome});
                this.setState({ docente_sped_cognome: res.data.data.dati_spedizione_docente.cognome});
                this.setState({ docente_sped_indirizzo: res.data.data.dati_spedizione_docente.indirizzo});
                this.setState({ docente_sped_citta: res.data.data.dati_spedizione_docente.citta});
                this.setState({ docente_sped_provincia: res.data.data.dati_spedizione_docente.provincia});
                this.setState({ docente_sped_cap: res.data.data.dati_spedizione_docente.cap});
                this.setState({ docente_sped_cellulare: res.data.data.dati_spedizione_docente.cellulare});
                this.setState({ docente_sped_note_consegna: res.data.data.dati_spedizione_docente.note_consegna});
                this.setState({ docente_fatt_nome: res.data.data.dati_fatturazione_docente.nome});
                this.setState({ docente_fatt_cognome: res.data.data.dati_fatturazione_docente.cognome});
                this.setState({ docente_fatt_cf_piva: res.data.data.dati_fatturazione_docente.cf_piva});
                this.setState({ docente_fatt_indirizzo: res.data.data.dati_fatturazione_docente.indirizzo});
                this.setState({ docente_fatt_citta: res.data.data.dati_fatturazione_docente.citta});
                this.setState({ docente_fatt_provincia: res.data.data.dati_fatturazione_docente.provincia});
                this.setState({ docente_fatt_cap: res.data.data.dati_fatturazione_docente.cap});
                this.setState({ scuola_sped_docente_ref: res.data.data.dati_spedizione_scuola.docente_referente});
                this.setState({ scuola_sped_nome_docente_ref: res.data.data.dati_spedizione_scuola.nome_docente_referente});
                this.setState({ scuola_sped_cognome_docente_ref: res.data.data.dati_spedizione_scuola.cognome_docente_referente});
                this.setState({ scuola_sped_grado_docente_ref: res.data.data.dati_spedizione_scuola.grado_docente_referente});
                this.setState({ scuola_sped_nome: res.data.data.dati_spedizione_scuola.nome_scuola});
                this.setState({ scuola_sped_indirizzo: res.data.data.dati_spedizione_scuola.indirizzo});
                this.setState({ scuola_sped_citta: res.data.data.dati_spedizione_scuola.citta});
                this.setState({ scuola_sped_provincia: res.data.data.dati_spedizione_scuola.provincia});
                this.setState({ scuola_sped_cap: res.data.data.dati_spedizione_scuola.cap});
                this.setState({ scuola_sped_cellulare: res.data.data.dati_spedizione_scuola.cellulare});
                this.setState({ scuola_sped_note_consegna: res.data.data.dati_spedizione_scuola.note_consegna});               
                this.setState({ pagamento_carta_docente: res.data.data.carta_docente_attiva});

                if (res.data.data.dati_spedizione_scuola.docente_referente) {
                    this.setState({ sped_codice_scuola: res.data.data.dati_spedizione_scuola.codice_scuola});
                    this.setState({ spedizione_attiva: res.data.data.spedizione_attiva});
                    var spedizione_attiva = res.data.data.spedizione_attiva;
                    if (spedizione_attiva){
                        this.setState({ sped_nome: res.data.data.dati_spedizione_scuola.nome_docente_referente});
                        this.setState({ sped_cognome: res.data.data.dati_spedizione_scuola.cognome_docente_referente});
                        this.setState({ sped_nome_scuola: res.data.data.dati_spedizione_scuola.nome_scuola});
                        this.setState({ sped_indirizzo: res.data.data.dati_spedizione_scuola.indirizzo});
                        this.setState({ sped_citta: res.data.data.dati_spedizione_scuola.citta});
                        this.setState({ sped_provincia: res.data.data.dati_spedizione_scuola.provincia});
                        this.setState({ sped_cap: res.data.data.dati_spedizione_scuola.cap});
                        this.setState({ sped_cellulare: res.data.data.dati_spedizione_scuola.cellulare});
                        this.setState({ note_consegna: res.data.data.dati_spedizione_scuola.note_consegna});
                    } else {
                        this.setState({ sped_nome: "-"});
                        this.setState({ sped_cognome: "-"});
                        this.setState({ sped_nome_scuola: "-"});
                        this.setState({ sped_indirizzo: "-"});
                        this.setState({ sped_citta: "-"});
                        this.setState({ sped_provincia: "XX"});
                        this.setState({ sped_cap: "00000"});
                        this.setState({ sped_cellulare: "-"});
                        this.setState({ note_consegna: "-"});
                    }
                } else {
                    this.setState({ sped_codice_scuola: res.data.data.dati_spedizione_docente.codice_scuola});
                    this.setState({ spedizione_attiva: res.data.data.spedizione_attiva});
                    var spedizione_attiva = res.data.data.spedizione_attiva;
                    if (spedizione_attiva){
                        this.setState({ sped_nome: res.data.data.dati_spedizione_docente.nome});
                        this.setState({ sped_cognome: res.data.data.dati_spedizione_docente.cognome});
                        this.setState({ sped_nome_scuola: res.data.data.dati_spedizione_docente.nome_scuola});
                        this.setState({ sped_indirizzo: res.data.data.dati_spedizione_docente.indirizzo});
                        this.setState({ sped_citta: res.data.data.dati_spedizione_docente.citta});
                        this.setState({ sped_provincia: res.data.data.dati_spedizione_docente.provincia});
                        this.setState({ sped_cap: res.data.data.dati_spedizione_docente.cap});
                        this.setState({ sped_cellulare: res.data.data.dati_spedizione_docente.cellulare});
                        this.setState({ note_consegna: ""});
                    } else {
                        this.setState({ sped_nome: "-"});
                        this.setState({ sped_cognome: "-"});
                        this.setState({ sped_nome_scuola: "-"});
                        this.setState({ sped_indirizzo: "-"});
                        this.setState({ sped_citta: "-"});
                        this.setState({ sped_provincia: "XX"});
                        this.setState({ sped_cap: "00000"});
                        this.setState({ sped_cellulare: "-"});
                        this.setState({ note_consegna: "-"});
                    }
                }

                this.setState({ fatt_nome: res.data.data.dati_fatturazione_docente.nome});
                this.setState({ fatt_cognome: res.data.data.dati_fatturazione_docente.cognome});
                this.setState({ fatt_nome_scuola: res.data.data.dati_fatturazione_docente.nome_scuola});
                this.setState({ fatt_cf_piva: res.data.data.dati_fatturazione_docente.cf_piva});
                this.setState({ fatt_indirizzo: res.data.data.dati_fatturazione_docente.indirizzo});
                this.setState({ fatt_citta: res.data.data.dati_fatturazione_docente.citta});
                this.setState({ fatt_provincia: res.data.data.dati_fatturazione_docente.provincia});
                this.setState({ fatt_cap: res.data.data.dati_fatturazione_docente.cap});

                //sezione sottopagina aggiungi classe

                this.setState({ sub_classe: parseInt(Object.keys(this.sub_getListaClassi())[0]) });
                this.setState({ sub_prodotti_primari: res.data.data.prodotti_primari});
                this.setState({ sub_prodotti_secondari: res.data.data.prodotti_secondari});
                this.setState({ sub_prodotti_regalo: res.data.data.prodotti_regalo});
                this.setState({ sub_id_prodotto_prim_selezionato: res.data.data.prodotti_primari[0].id});
                this.setState({ sub_nome_prodotto_prim_selezionato: res.data.data.prodotti_primari[0].titolo});
                this.setState({ sub_immagine_prodotto_prim_selezionato: res.data.data.prodotti_primari[0].immagine});
                this.setState({ sub_descrizione_prodotto_prim_selezionato: res.data.data.prodotti_primari[0].descrizione});
                this.setState({ sub_importo_prodotto_prim_selezionato: res.data.data.prodotti_primari[0].importo});

                let totale = parseInt(this.state.sub_importo_prodotto_prim_selezionato);

                //console.log("totale => " + totale);

                this.state.sub_prodotti_secondari.forEach((element) => {
                    if (typeof element.quantita !== 'undefined' && element.quantita !== null && element.quantita !== 0 && element.importo !== null) {
                        totale = totale + (parseInt(element.quantita) * parseInt(element.importo));

                        //console.log("qta => " + element.quantita);
                        //console.log("importo => " + element.importo);
                    }
                });

                this.setState({ sub_importo_totale: totale});

                var classi = [];
                
                this.setState({ isloading: false }, function(){
                    let required_field_are_filled = this.requiredFieldsAreFilled();
                    let fields_are_valid = this.fieldsAreValid();
                    //console.log("required field are filled => " + required_field_are_filled);
                    //console.log("fields are valid => " + fields_are_valid);
                    if (required_field_are_filled && fields_are_valid && classi.length > 0) {
                        this.setState({ orderenabled: true });
                    } else {
                        this.setState({ orderenabled: false });
                    }                    
                });
            }

        }).catch (error => {
            //console.log(error);
            this.setState({ isloading: false});
        });

    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getImageHdWidth(){
        let screenWidth = window.innerWidth;
        let screenHeight = window.innerHeight;
        let imageWidth = Math.min(screenWidth, screenHeight);
        return imageWidth;
    }

    handleDeleteClassClick(event){
        event.preventDefault();
        this.setState({ opendeletemodal: false });
        let class_to_delete = this.state.class_to_delete;
        let vecchio_importo_netto = this.state.importo_netto;
        let importo_da_sottrarre = this.state.classi[class_to_delete].importo_netto;
        let nuovo_importo_netto = vecchio_importo_netto - importo_da_sottrarre;

        let classi = this.state.classi;
        classi.splice(class_to_delete, 1);

        if (this.state.is_supporter == true && this.state.sconto_attivo == true){
            var config = require('./config.json');
            var sconto_percentuale = Boolean(config.sconto_percentuale); 
            var sconto = 0;
            if (sconto_percentuale){
                //sconto = (nuovo_importo_netto * parseInt(config.sconto_sostenitore) / 100).toFixed(2);
                if (classi.length > 0){
                    sconto = (classi[0].importo_netto * parseInt(config.sconto_sostenitore) / 100).toFixed(2);
                } else {
                    sconto = 0;
                }
            } else {
                sconto = parseInt(config.sconto_sostenitore);
            }
        } else {
            sconto = 0;
        }
        
        let nuovo_importo_totale = nuovo_importo_netto - sconto;

        if (nuovo_importo_totale > 77.47 && this.state.id_metodo_pagamento === 1){
            var config = require('./config.json');
            var bollo = parseInt(config.bollo); 
        } else {
            var bollo = 0;
        }

        nuovo_importo_totale = nuovo_importo_totale + bollo;

        this.setState({ importo_netto: nuovo_importo_netto });
        this.setState({ importo_totale: nuovo_importo_totale });
        this.setState({ sconto: sconto });
        this.setState({ bollo: bollo });
        this.setState({ classi: classi });
    }

    handleProceedClick(){
        this.setState({ proceed: true});
    }

    handleEditClassClick(event){
        event.preventDefault();
        let class_to_edit = parseInt(event.currentTarget.id.split("_")[1]);
        this.setState({ class_to_edit: class_to_edit}, function(){

            let dati_classe = this.state.classi[class_to_edit];
            let classi = this.sub_getListaClassi();
            let sezioni = this.sub_getListaSezioni();

            let classe = Object.keys(classi).find(key => classi[key] === dati_classe.classe);
            let sezione = Object.keys(sezioni).find(key => sezioni[key] === dati_classe.sezione);
            
            let totale = parseInt(dati_classe.prodotti[0].importo);
            let prodottisecondari_classe = [...dati_classe.prodotti];
            let prodottisecondari = [...this.state.sub_prodotti_secondari];
            prodottisecondari_classe.forEach((element, index) => {
                if (index > 0){
                    let prod_id = element.id_prodotto;
                    let prod_sec = Object.keys(this.state.sub_prodotti_secondari).find(key => this.state.sub_prodotti_secondari[key].id === prod_id);
                    if (typeof prod_sec !== 'undefined'){
                        prodottisecondari[prod_sec].quantita = element.quantita;  
                        totale = totale + (parseInt(element.quantita) * parseInt(element.importo)); 
                    }
                }
            });   
            //leggere dati utente


            this.setState({ 
                sub_classe: classe,
                sub_sezione: sezione,
                sub_tipo_iscrizione: "docente",
                sub_id_docente_referente1: dati_classe.id_docente_referente1,
                sub_id_docente_referente2: dati_classe.id_docente_referente2,
                sub_nome_referente2: dati_classe.first_name_docente_referente2,
                sub_cognome_referente2: dati_classe.last_name_docente_referente2,
                sub_email_referente2: dati_classe.email_docente_referente2,
                sub_id_prodotto_prim_selezionato: dati_classe.prodotti[0].id_prodotto,
                sub_nome_prodotto_prim_selezionato: dati_classe.prodotti[0].nome_prodotto,
                sub_importo_prodotto_prim_selezionato: dati_classe.prodotti[0].importo,
                sub_prodotti_secondari: prodottisecondari,
                sub_importo_totale: totale,
                editclassenabled: true
            }, function(){
                if (this.sub_validateClasse(classe)) {
                    this.setState({ sub_classe_error: false });
                    this.setState({ sub_sezione_error: false });
                } else {
                    this.setState({ sub_classe_error: true });
                    this.setState({ sub_sezione_error: true });
                }
                let required_field_are_filled = this.sub_requiredFieldsAreFilled();
                let fields_are_valid = this.sub_fieldsAreValid();

                if (required_field_are_filled && fields_are_valid) {
                    this.setState({ sub_editclassenabled: true });
                } else {
                    this.setState({ sub_editclassenabled: false });
                }
            });
        });
    }

    handleOpenDeleteModal(event){
        event.preventDefault();
        this.setState({ class_to_delete: event.currentTarget.id}, function(){
            this.setState({ opendeletemodal: true });
        });
    }

    handleCloseDeleteModal(event){
        event.preventDefault();
        this.setState({ opendeletemodal: false });
    }

    sub_handleOpenImageModal(event){
        event.preventDefault();
        let image_to_show = parseInt(event.currentTarget.id.split("_")[1]);
        var url_image_to_show = "";
        this.state.sub_prodotti_primari.forEach(element => {
            if (element.id === image_to_show){
                url_image_to_show = element.immagine_hd;
            }
        });
        this.state.sub_prodotti_secondari.forEach(element => {
            if (element.id === image_to_show){
                url_image_to_show = element.immagine_hd;
            }
        });
        this.state.sub_prodotti_regalo.forEach(element => {
            if (element.id === image_to_show){
                url_image_to_show = element.immagine_hd;
            }
        });

        this.setState({ image_to_show: url_image_to_show}, function(){
            this.setState({ openimagemodal: true });
        });
    }

    sub_handleCloseImageModal(event){
        event.preventDefault();
        this.setState({ openimagemodal: false });
    }


    handleLogoutClick(event) {
        event.preventDefault();
        this.setState({ authorization: ''});
        Cookies.remove('authorization');
        Cookies.remove('user_email');
        Cookies.remove('nome');
        Cookies.remove('cognome');
        Cookies.remove('wp_user_id');
        Cookies.remove('newuser');
    }

    handleUserMenuClick(event){
        //console.log(event.currentTarget);
        this.setState({ anchorElUserMenu: event.currentTarget});
    }

    handleCloseUserMenu(){
        this.setState({ anchorElUserMenu: null});
    }

    handleCancelClick(){
        this.setState({ addclassenabled: false}, function(){

            this.setState({ sub_classe: parseInt(Object.keys(this.sub_getListaClassi())[0]) });
            this.setState({ sub_sezione: "A" });
            this.setState({ sub_tipo_iscrizione: "docente" });
            this.setState({ sub_id_docente_referente1: null });
            this.setState({ sub_id_docente_referente2: null });
            this.setState({ sub_nome_referente2: "" });
            this.setState({ sub_cognome_referente2: "" });
            this.setState({ sub_email_referente2: "" });
            this.setState({ sub_id_prodotto_prim_selezionato: this.state.sub_prodotti_primari[0].id });
            this.setState({ sub_nome_prodotto_prim_selezionato: this.state.sub_prodotti_primari[0].titolo });
            this.setState({ sub_immagine_prodotto_prim_selezionato: this.state.sub_prodotti_primari[0].immagine });
            this.setState({ sub_descrizione_prodotto_prim_selezionato: this.state.sub_prodotti_primari[0].descrizione });
            this.setState({ sub_importo_prodotto_prim_selezionato: this.state.sub_prodotti_primari[0].importo });
            let prodottisecondari = [...this.state.sub_prodotti_secondari];
            prodottisecondari.forEach((element, index) => {
                prodottisecondari[index].quantita = 0;
            });   
            this.setState({ sub_prodotti_secondari: prodottisecondari });
            this.setState({ sub_importo_totale: this.state.sub_prodotti_primari[0].importo });
            this.setState({ sub_email_exist_error: false});
        });
    }

    handleCancelEditClick(){
        this.setState({ editclassenabled: false}, function(){
            this.setState({ sub_classe: parseInt(Object.keys(this.sub_getListaClassi())[0]) });
            this.setState({ sub_sezione: "A" });
            this.setState({ sub_tipo_iscrizione: "docente" });
            this.setState({ sub_id_docente_referente1: null });
            this.setState({ sub_id_docente_referente2: null });
            this.setState({ sub_nome_referente2: "" });
            this.setState({ sub_cognome_referente2: "" });
            this.setState({ sub_email_referente2: "" });
            this.setState({ sub_id_prodotto_prim_selezionato: this.state.sub_prodotti_primari[0].id });
            this.setState({ sub_nome_prodotto_prim_selezionato: this.state.sub_prodotti_primari[0].titolo });
            this.setState({ sub_immagine_prodotto_prim_selezionato: this.state.sub_prodotti_primari[0].immagine });
            this.setState({ sub_descrizione_prodotto_prim_selezionato: this.state.sub_prodotti_primari[0].descrizione });
            this.setState({ sub_importo_prodotto_prim_selezionato: this.state.sub_prodotti_primari[0].importo });
            let prodottisecondari = [...this.state.sub_prodotti_secondari];
            prodottisecondari.forEach((element, index) => {
                prodottisecondari[index].quantita = 0;
            });   
            this.setState({ sub_prodotti_secondari: prodottisecondari });
            this.setState({ sub_importo_totale: this.state.sub_prodotti_primari[0].importo });
            this.setState({ sub_email_exist_error: false});         
        });
    }

    requiredFieldsAreFilled() {

        var required_fields;
        var config = require('./config.json');
        var spedizione_attiva = this.state.spedizione_attiva;
        
        if (this.state.id_metodo_pagamento === 2 || this.state.id_metodo_pagamento === 3) {
        
            if (spedizione_attiva){
                required_fields = [
                    'sped_nome',
                    'sped_cognome',
                    'sped_nome_scuola',
                    'sped_indirizzo',
                    'sped_citta',
                    'sped_cap',
                    'sped_cellulare',
                    'fatt_nome',
                    'fatt_cognome',
                    //'fatt_nome_scuola',
                    'fatt_cf_piva',
                    'fatt_indirizzo',
                    'fatt_citta',
                    'fatt_cap'
                ];
            } else {
                required_fields = [
                    'fatt_nome',
                    'fatt_cognome',
                    //'fatt_nome_scuola',
                    'fatt_cf_piva',
                    'fatt_indirizzo',
                    'fatt_citta',
                    'fatt_cap'
                ];
            }

        } else {

            if (spedizione_attiva){
                required_fields = [
                    'sped_nome',
                    'sped_cognome',
                    'sped_nome_scuola',
                    'sped_indirizzo',
                    'sped_citta',
                    'sped_cap',
                    'sped_cellulare'
                ];
            } else {
                required_fields = [];
            }

        }

        let filled = 0;
        required_fields.forEach((element) => {
            if (this.state[element] !== null) {
                if ( this.state[element].length > 0) {
                    filled = filled + 1;
                }
            }
        });

        if (filled === required_fields.length){
            return true;
        } else {
            return false;
        }

    }

    sub_requiredFieldsAreFilled() {

        var required_fields;

        if (this.state.editclassenabled){

            if (this.state.sub_id_docente_referente2 !== null && typeof this.state.sub_id_docente_referente2 !== 'undefined') {

                required_fields = [
                    //'sub_nome_referente2',
                    //'sub_cognome_referente2',
                    //'sub_email_referente2'
                ];
    
            } else {
    
                required_fields = [];
    
            }
    
            let filled = 0;

            //console.log("required field" + required_fields);
    
            if (this.state.sub_id_docente_referente2 !== null && typeof this.state.sub_id_docente_referente2 !== 'undefined') {
                required_fields.forEach((element) => {
                    if (this.state[element] !== null) {
                        //console.log(">>>" + this.state[element]);
                        if ( this.state[element].length > 0) {
                            filled = filled + 1;
                        }
                    }
                });
            }
    
            if (filled === required_fields.length){
                return true;
            } else {
                return false;
            }

        } else {

            if (this.state.classi.length > 0 || (this.state.classi.length === 0 && this.state.ordini_precedenti > 0)){

                required_fields = [
                    //'sub_nome_referente2',
                    //'sub_cognome_referente2',
                    //'sub_email_referente2'
                ];

            } else {

                required_fields = [];

            }

            let filled = 0;

            if (this.state.classi.length > 0 || (this.state.classi.length === 0 && this.state.ordini_precedenti > 0)){
                required_fields.forEach((element) => {
                    if (this.state[element] !== null) {
                        if ( this.state[element].length > 0) {
                            filled = filled + 1;
                        }
                    }
                });
            }

            if (filled === required_fields.length){
                return true;
            } else {
                return false;
            }

        }

    }

    fieldsAreValid() {

        var config = require('./config.json');
        var spedizione_attiva = this.state.spedizione_attiva;
        
        if (spedizione_attiva){
            if (this.validateSpedNome(this.state.sped_nome) === false) {
                //console.log("nome spedizione invalid");
                return false;
            }
            if (this.validateSpedCognome(this.state.sped_cognome) === false) {
                //console.log("cognome spedizione invalid");
                return false;
            }
            if (this.validateSpedNomeScuola(this.state.sped_nome_scuola) === false) {
                //console.log("nome scuola spedizione invalid");
                return false;
            }
            if (this.validateSpedIndirizzo(this.state.sped_indirizzo) === false) {
                //console.log("indirizzo spedizione invalid");
                return false;
            }
            if (this.validateSpedProvincia(this.state.sped_provincia) === false) {
                //console.log("provincia spedizione invalid");
                return false;
            }
            if (this.validateSpedCitta(this.state.sped_citta) === false) {
                //console.log("citta spedizione invalid");
                return false;
            }
            if (this.validateSpedCap(this.state.sped_cap) === false) {
                //console.log("cap spedizione invalid");
                return false;
            }
            if (this.validateSpedCellulare(this.state.sped_cellulare) === false) {
                //console.log("cellulare spedizione invalid");
                return false;
            }
        }
        
        if (this.state.id_metodo_pagamento === 2 || this.state.id_metodo_pagamento === 3) {
            if (this.validateFattNome(this.state.fatt_nome) === false) {
                //console.log("nome fatturazione invalid");
                return false;
            }
            if (this.validateFattCognome(this.state.fatt_cognome) === false) {
                //console.log("cognome fatturazione invalid");
                return false;
            }
            if (this.validateFattCfPiva(this.state.fatt_cf_piva) === false) {
                //console.log("cf piva fatturazione invalid");
                return false;
            }
            if (this.validateFattIndirizzo(this.state.fatt_indirizzo) === false) {
                //console.log("indirizzo fatturazione invalid");
                return false;
            }
            if (this.validateFattCitta(this.state.fatt_citta) === false) {
                //console.log("citta fatturazione invalid");
                return false;
            }
            if (this.validateFattProvincia(this.state.fatt_provincia) === false) {
                //console.log("provincia fatturazione invalid");
                return false;
            }
            if (this.validateFattCap(this.state.fatt_cap) === false) {
                //console.log("cap fatturazione invalid");
                return false;
            }
        }
        return true;

    }

    sub_fieldsAreValid() {

        //if (this.sub_validateClasse(this.state.sub_classe) === false){
            //console.log("classe invalid");
            //return false;
        //}

        //if (this.state.classi.length > 0 || (this.state.classi.length === 0 && this.state.ordini_precedenti > 0)){

            if (this.sub_validateNomeReferente2(this.state.sub_nome_referente2) === false) {
                //console.log("nome referente2 invalid");
                return false;
            }
            if (this.sub_validateCognomeReferente2(this.state.sub_cognome_referente2) === false) {
                //console.log("cognome referente2 invalid");
                return false;
            }
            if (this.sub_validateEmailReferente2(this.state.sub_email_referente2) === false) {
                //console.log("email referente2 invalid");
                return false;
            }
            if (this.sub_validateEmailExist(this.state.sub_email_referente2) === false) {
                //console.log("email referente2 exist");
                return false;
            }
        //}
        return true;
        
    }

    getListaProvincie() {
        let provincie = {
            XX : '', 
            AG : 'Agrigento',
            AL : 'Alessandria',
            AN : 'Ancona',
            AO : 'Aosta',
            AQ : 'L\'Aquila',
            AR : 'Arezzo',
            AP : 'Ascoli-Piceno',
            AT : 'Asti',
            AV : 'Avellino',
            BA : 'Bari',
            BT : 'Barletta-Andria-Trani',
            BL : 'Belluno',
            BN : 'Benevento',
            BG : 'Bergamo',
            BI : 'Biella',
            BO : 'Bologna',
            BZ : 'Bolzano',
            BS : 'Brescia',
            BR : 'Brindisi',
            CA : 'Cagliari',
            CL : 'Caltanissetta',
            CB : 'Campobasso',
            CI : 'Carbonia Iglesias',
            CE : 'Caserta',
            CT : 'Catania',
            CZ : 'Catanzaro',
            CH : 'Chieti',
            CO : 'Como',
            CS : 'Cosenza',
            CR : 'Cremona',
            KR : 'Crotone',
            CN : 'Cuneo',
            EN : 'Enna',
            FM : 'Fermo',
            FE : 'Ferrara',
            FI : 'Firenze',
            FG : 'Foggia',
            FC : 'Forli-Cesena',
            FR : 'Frosinone',
            GE : 'Genova',
            GO : 'Gorizia',
            GR : 'Grosseto',
            IM : 'Imperia',
            IS : 'Isernia',
            SP : 'La-Spezia',
            LT : 'Latina',
            LE : 'Lecce',
            LC : 'Lecco',
            LI : 'Livorno',
            LO : 'Lodi',
            LU : 'Lucca',
            MC : 'Macerata',
            MN : 'Mantova',
            MS : 'Massa-Carrara',
            MT : 'Matera',
            VS : 'Medio Campidano',
            ME : 'Messina',
            MI : 'Milano',
            MO : 'Modena',
            MB : 'Monza-Brianza',
            NA : 'Napoli',
            NO : 'Novara',
            NU : 'Nuoro',
            OG : 'Ogliastra',
            OT : 'Olbia Tempio',
            OR : 'Oristano',
            PD : 'Padova',
            PA : 'Palermo',
            PR : 'Parma',
            PV : 'Pavia',
            PG : 'Perugia',
            PU : 'Pesaro-Urbino',
            PE : 'Pescara',
            PC : 'Piacenza',
            PI : 'Pisa',
            PT : 'Pistoia',
            PN : 'Pordenone',
            PZ : 'Potenza',
            PO : 'Prato',
            RG : 'Ragusa',
            RA : 'Ravenna',
            RC : 'Reggio-Calabria',
            RE : 'Reggio-Emilia',
            RI : 'Rieti',
            RN : 'Rimini',
            RM : 'Roma',
            RO : 'Rovigo',
            SA : 'Salerno',
            SS : 'Sassari',
            SU : 'Sud Sardegna',
            SV : 'Savona',
            SI : 'Siena',
            SR : 'Siracusa',
            SO : 'Sondrio',
            TA : 'Taranto',
            TE : 'Teramo',
            TR : 'Terni',
            TO : 'Torino',
            TP : 'Trapani',
            TN : 'Trento',
            TV : 'Treviso',
            TS : 'Trieste',
            UD : 'Udine',
            VA : 'Varese',
            VE : 'Venezia',
            VB : 'Verbania',
            VC : 'Vercelli',
            VR : 'Verona',
            VV : 'Vibo-Valentia',
            VI : 'Vicenza',
            VT : 'Viterbo',
        };
        return provincie;
    }

    sub_getListaClassi(){

        let grado_utente = this.state.grado_utente;
        let tipo_progetto = this.state.tipo_progetto;
        var classi = null;
        
        if (this.state.editclassenabled){

            /* if (this.state.sub_id_docente_referente2 !== null && typeof this.state.sub_id_docente_referente2 !== 'undefined') {

                classi = {
                    1 : "1° infanzia",
                    2 : "2° infanzia",
                    3 : "3° infanzia",
                    4 : "1° primaria",
                    5 : "2° primaria",
                    6 : "3° primaria",
                    7 : "4° primaria",
                    8 : "5° primaria",
                    9 : "1° secondaria di primo grado",
                    10 : "2° secondaria di primo grado",
                    11 : "3° secondaria di primo grado",
                    12 : "1° secondaria di secondo grado",
                    13 : "2° secondaria di secondo grado",
                    14 : "3° secondaria di secondo grado",
                    15 : "4° secondaria di secondo grado",
                    16 : "5° secondaria di secondo grado",
                };  

            } else { */

                switch (grado_utente) {
                    case "infanzia":
                        classi = {
                            1 : "1° infanzia",
                            2 : "2° infanzia",
                            3 : "3° infanzia",
                        };  
                        break;
    
                    case "primaria":
                        if (tipo_progetto == 1){
                            classi = {
                                /* 1 : "1° primaria", */
                                2 : "2° primaria",
                                3 : "3° primaria",
                                4 : "4° primaria",
                                5 : "5° primaria",
                            };
                        } else {
                            classi = {
                                1 : "1° primaria",
                                2 : "2° primaria",
                                3 : "3° primaria",
                                4 : "4° primaria",
                                5 : "5° primaria",
                            };
                        }
                        break;
    
                    case "secondaria":
                        classi = {
                            1 : "1° secondaria di primo grado",
                            2 : "2° secondaria di primo grado",
                            3 : "3° secondaria di primo grado",
                        };                    
                        break;
    
                    case "secondaria2":
                        classi = {
                            1 : "1° secondaria di secondo grado",
                            2 : "2° secondaria di secondo grado",
                            //3 : "3° secondaria di secondo grado",
                            //4 : "4° secondaria di secondo grado",
                            //5 : "5° secondaria di secondo grado",
                        };                    
                        break;    
                
                    default:
                        break;
                }

            /* } */

        } else {

            /* if (this.state.classi.length > 0){

                classi = {
                    1 : "1° infanzia",
                    2 : "2° infanzia",
                    3 : "3° infanzia",
                    4 : "1° primaria",
                    5 : "2° primaria",
                    6 : "3° primaria",
                    7 : "4° primaria",
                    8 : "5° primaria",
                    9 : "1° secondaria di primo grado",
                    10 : "2° secondaria di primo grado",
                    11 : "3° secondaria di primo grado",
                    12 : "1° secondaria di secondo grado",
                    13 : "2° secondaria di secondo grado",
                    14 : "3° secondaria di secondo grado",
                    15 : "4° secondaria di secondo grado",
                    16 : "5° secondaria di secondo grado",
                };  

            } else { */

                switch (grado_utente) {
                    case "infanzia":
                        classi = {
                            1 : "1° infanzia",
                            2 : "2° infanzia",
                            3 : "3° infanzia",
                        };  
                        break;
    
                    case "primaria":
                        if (tipo_progetto == 1){
                            classi = {
                                /* 1 : "1° primaria", */
                                2 : "2° primaria",
                                3 : "3° primaria",
                                4 : "4° primaria",
                                5 : "5° primaria",
                            };
                        } else {
                            classi = {
                                1 : "1° primaria",
                                2 : "2° primaria",
                                3 : "3° primaria",
                                4 : "4° primaria",
                                5 : "5° primaria",
                            };
                        }
                        break;
    
                    case "secondaria":
                        classi = {
                            1 : "1° secondaria di primo grado",
                            2 : "2° secondaria di primo grado",
                            3 : "3° secondaria di primo grado",
                        };                    
                        break;
    
                    case "secondaria2":
                        classi = {
                            1 : "1° secondaria di secondo grado",
                            2 : "2° secondaria di secondo grado",
                            //3 : "3° secondaria di secondo grado",
                            //4 : "4° secondaria di secondo grado",
                            //5 : "5° secondaria di secondo grado",
                        };                    
                        break;    
                
                    default:
                        break;
                }

            /* } */
        }

              
        return classi;
    }

    sub_getListaSezioni(){
        let sezioni = {
            A : "A",
            B : "B",
            C : "C",
            D : "D",
            E : "E",
            F : "F",
            G : "G",
            H : "H",
            I : "I",
            J : "J",
            K : "K",
            L : "L",
            M : "M",
            N : "N",
            O : "O",
            P : "P",
            Q : "Q",
            R : "R",
            S : "S",
            T : "T",
            U : "U",
            V : "V",
            W : "W",
            X : "X",
            Y : "Y",
            Z : "Z"
        };  
        return sezioni;
    }

    handleAddClassClick(event){
        event.preventDefault();
        this.setState({ addclassenabled: true }, function(){
            if (this.sub_validateClasse(this.state.sub_classe)) {
                this.setState({ sub_classe_error: false });
                this.setState({ sub_sezione_error: false });
            } else {
                this.setState({ sub_classe_error: true });
                this.setState({ sub_sezione_error: true });
            }
    
            let required_field_are_filled = this.sub_requiredFieldsAreFilled();
            let fields_are_valid = this.sub_fieldsAreValid();

            //console.log("required > " + required_field_are_filled );
            //console.log("fields > " + fields_are_valid );
    
            if (required_field_are_filled && fields_are_valid) {
                this.setState({ sub_addclassenabled: true });
            } else {
                this.setState({ sub_addclassenabled: false });
            } 
        });
        
    }

    handleOrderClick(event){
        event.preventDefault();

        if (this._isMounted) {
            this.setState({ error: false });
            this.setState({ isloading: true });
        }

        var config = require('./config.json');
        var env_backend = config.env_backend;
        var api_url_backend = config.api_url_backend[env_backend];
        const apiUrl = api_url_backend + 'orders/sendorder';
        var authorization = config.api_auth_backend[env_backend];

        let id_progetto = this.state.id_progetto;
        let id_utente = this.state.id_utente;
        let id_metodo_pagamento;
        if (this.state.id_metodo_pagamento == 0){
            id_metodo_pagamento = null;
        } else {
            id_metodo_pagamento = this.state.id_metodo_pagamento;
        }
        let id_scuola = this.state.id_scuola;
        let sped_nome = this.state.sped_nome;
        let sped_cognome = this.state.sped_cognome;
        let sped_nome_scuola = this.state.sped_nome_scuola;
        let sped_indirizzo = this.state.sped_indirizzo;
        let sped_citta = this.state.sped_citta;
        let sped_provincia = this.state.sped_provincia;
        let sped_cap = this.state.sped_cap;
        let sped_cellulare = this.state.sped_cellulare;
        let note_consegna = this.state.note_consegna;
        let fatt_nome = this.state.fatt_nome;
        let fatt_cognome = this.state.fatt_cognome;
        let fatt_nome_scuola = this.state.fatt_nome_scuola;
        let fatt_cf_piva = this.state.fatt_cf_piva;
        let fatt_indirizzo = this.state.fatt_indirizzo;
        let fatt_citta = this.state.fatt_citta;
        let fatt_provincia = this.state.fatt_provincia;
        let fatt_cap = this.state.fatt_cap;
        let importo_netto = this.state.importo_netto;
        let sconto = this.state.sconto;
        let bollo = this.state.bollo;
        let importo_totale = this.state.importo_totale;
        let classi = this.state.classi;
        
        const settings = {
            headers: { Authorization: `Bearer ${authorization}` }
        };

        //Prima della chiamata API memorizzare nella Sessione un valore
        //temporaneo per catturare il refresh pagina dell'utente
        Cookies.set('signup_pending', 1);

        axios.post(apiUrl, { 
            "id_progetto": id_progetto,
            "id_utente": id_utente,
            "id_metodo_pagamento": id_metodo_pagamento,
            "id_scuola": id_scuola,
            "sped_nome": sped_nome,
            "sped_cognome": sped_cognome,
            "sped_nome_scuola": sped_nome_scuola,
            "sped_indirizzo": sped_indirizzo,
            "sped_citta": sped_citta,
            "sped_provincia": sped_provincia,
            "sped_cap": sped_cap,
            "sped_cellulare": sped_cellulare,
            "note_consegna": note_consegna,
            "fatt_nome": fatt_nome,
            "fatt_cognome": fatt_cognome,
            "fatt_nome_scuola": fatt_nome_scuola,
            "fatt_cf_piva": fatt_cf_piva,
            "fatt_indirizzo": fatt_indirizzo,
            "fatt_citta": fatt_citta,
            "fatt_provincia": fatt_provincia,
            "fatt_cap": fatt_cap,
            "importo_netto": importo_netto,
            "sconto": sconto,
            "bollo": bollo,
            "importo_totale": importo_totale,
            "classi": classi

        }, settings).then(res => {

            Cookies.remove('signup_pending');

            this.setState({ isloading: false }, function(){
                //console.log(res.data.data); 

            });
         
            if (res.data.success){

                this.setState({ id_progetto: null});
                this.setState({ id_metodo_pagamento: 0});
                this.setState({ id_scuola: null});
                this.setState({ sped_nome: ""});
                this.setState({ sped_cognome: ""});
                this.setState({ sped_nome_scuola: ""});
                this.setState({ sped_codice_scuola: ""});
                this.setState({ sped_indirizzo: ""});
                this.setState({ sped_citta: ""});
                this.setState({ sped_provincia: "XX"});
                this.setState({ sped_cap: ""});
                this.setState({ sped_cellulare: ""});
                this.setState({ note_consegna: ""});
                this.setState({ fatt_nome: ""});
                this.setState({ fatt_cognome: ""});
                this.setState({ fatt_nome_scuola: ""});
                this.setState({ fatt_cf_piva: ""});
                this.setState({ fatt_indirizzo: ""});
                this.setState({ fatt_citta: ""});
                this.setState({ fatt_provincia: "XX"});
                this.setState({ fatt_cap: ""});
                this.setState({ importo_netto: 0});
                this.setState({ sconto: 0});
                this.setState({ bollo: 0});
                this.setState({ importo_totale: 0});
                this.setState({ classi: []});
                this.setState({ ordercompleted: true });

            } else {

                if (this._isMounted) {

                    this.setState({ error: true});
                    this.setState({ errorcode: res.data.data.code });
                    this.setState({ errormessage: res.data.data.message });
                    this.setState({ isloading: false });

                }

            }
            

        }).catch (error => {

            Cookies.remove('signup_pending');

            if (this._isMounted) {
                
                //console.log(error);
                //this.setState({ error: true});
                //this.setState({ errorcode: error.response.data.data.code });
                //this.setState({ errormessage: error.response.data.data.message });
                this.setState({ isloading: false });

            }

        });

    }

    validateSpedNome(value){
        let nome = value;
        const re = /^[a-zàòùèéì ,.'-]+$/i;
        return re.test(nome) || nome.length === 0;
    }

    validateSpedCognome(value){
        let cognome = value;
        const re = /^[a-zA-Zàòùèéì ,.'-]+$/i;
        return re.test(cognome) || cognome.length === 0;
    }

    validateSpedNomeScuola(value){
        let nomescuola = value;
        const re = /^[0-9a-zA-Z/àòùèéì&?^*+@°_='() ,.:"'-]+$/i;
        return re.test(nomescuola) || nomescuola.length === 0;
    }

    validateSpedIndirizzo(value){
        let spedindirizzo = value;
        const re = /^[0-9a-zA-Z/òàùèéì'\s,.-]+$/;
        return re.test(spedindirizzo) || spedindirizzo.length === 0;
    }

    validateSpedCitta(value){
        let citta = value;
        const re = /^[0-9a-zA-Zàòùèéì'\s,-]+$/;
        return re.test(citta) || citta.length === 0;
    }

    validateSpedProvincia(value){
        let provincia = value;
        return provincia !== "XX" || provincia === 0;
    }

    validateSpedCap(value){
        let cap = value;
        const re = /^[0-9]{5}/;
        return re.test(cap) || cap.length === 0;
    }

    validateSpedCellulare(value){
        let cellulare = value;
        const re = /^[0-9\s]{8,20}$/;
        return re.test(cellulare) || cellulare.length === 0;
    }

    validateNoteConsegna(value){
        let note = value;
        const re = /^[0-9a-zA-Zàòùèéì'\s,-]+$/;
        return re.test(note) || note.length === 0; 
    }

    validateFattNome(value){
        let nome = value;
        const re = /^[a-zA-Zàòùèéì ,.'-]+$/i;
        return re.test(nome) || nome.length === 0;
    }

    validateFattCognome(value){
        let cognome = value;
        const re = /^[a-zA-Zàòùèéì ,.'-]+$/i;
        return re.test(cognome) || cognome.length === 0;
    }

    validateFattNomeScuola(value){
        let nomescuola = value;
        const re = /^[a-zA-Z/àòùèéì&?^*+@°_='() ,.:"'-]+$/i;
        return re.test(nomescuola) || nomescuola.length === 0;
    }

    validateFattCfPiva(value){
        let cfpiva = value;
        const re = /^[A-Z0-9]{11,16}/;
        //console.log(re.test(cfpiva));
        return re.test(cfpiva);
    }

    validateFattIndirizzo(value){
        let fattindirizzo = value;
        const re = /^[0-9a-zA-Z/òàùèéì'\s,.-]+$/;
        return re.test(fattindirizzo) || fattindirizzo.length === 0;
    }

    validateFattCitta(value){
        let citta = value;
        const re = /^[0-9a-zA-Zàòùèéì'\s,-]+$/;
        return re.test(citta) || citta.length === 0;
    }

    validateFattProvincia(value){
        let provincia = value;
        return provincia !== "XX" || provincia === 0;
    }

    validateFattCap(value){
        let cap = value;
        const re = /^[0-9]{5}/;
        return re.test(cap) || cap.length === 0;
    }

    //funzioni validazione sottopagina Aggiungi classe

    sub_validateClasse(value){
        let classe = value;
        let lista_classi = this.sub_getListaClassi();
        let nome_classe = lista_classi[classe];
        let sezione = this.state.sub_sezione;
        let classi_gia_iscritte = this.state.classi_gia_iscritte;
        let isValid = true;
        classi_gia_iscritte.forEach(element => {
            if (element.classe === nome_classe && element.sezione === sezione){
                isValid = false;
            } 
        });
        
        let classi = this.state.classi;
        
        classi.forEach((element, index) => {
            if (this.state.editclassenabled){
                if (element.classe === nome_classe && element.sezione === sezione){
                    if (index !== this.state.class_to_edit){
                        isValid = false;
                    }
                }  
            } else {
                if (element.classe === nome_classe && element.sezione === sezione){
                    isValid = false;
                }             
            }
        });
        return isValid;
    }

    sub_validateSezione(value){
        let sezione = value;
        let classe = this.state.sub_classe;
        let lista_classi = this.sub_getListaClassi();
        let nome_classe = lista_classi[classe];
        let classi_gia_iscritte = this.state.classi_gia_iscritte;
        let isValid = true;
        classi_gia_iscritte.forEach(element => {
            if (element.classe === nome_classe && element.sezione === sezione){
                isValid = false;
            } 
        });
        let classi = this.state.classi;
        classi.forEach((element, index) => {
            if (this.state.editclassenabled){
                if (element.classe === nome_classe && element.sezione === sezione){
                    if (index !== this.state.class_to_edit){
                        isValid = false;
                    }
                }
            } else {
                if (element.classe === nome_classe && element.sezione === sezione){
                    isValid = false;
                }
            }    
        });
        return isValid;
    }


    sub_validateNomeReferente2(value){
        let nome = value;
        const re = /^[a-zàòùèéì ,.'-]+$/i;
        return re.test(nome) || nome.length === 0;
    }

    sub_validateCognomeReferente2(value){
        let cognome = value;
        const re = /^[a-zA-Zàòùèéì ,.'-]+$/i;
        return re.test(cognome) || cognome.length === 0;
    }

    sub_validateEmailReferente2(value){
        let email = value;
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email) || email.length === 0;
    }

    sub_validateEmailExist(value){
        let email = value;
        let email_gia_iscritti = this.state.email_gia_iscritti;
        let index = email_gia_iscritti.indexOf(email);
        let isValid = true;

        //console.log("email> "+ email);
        //console.log("index> "+ index);
        //console.log("user_email> "+ this.state.user_email);
       
        if (index !== -1) {
            isValid = false;
        }

        if (email === this.state.user_email){
            isValid = false;
        }

        let classi = this.state.classi;

        classi.forEach((element, index) => {
            if (this.state.editclassenabled){
                if (element.email_docente_referente2 === email){
                    if (index !== this.state.class_to_edit){
                        isValid = false;
                    }
                }  
            } else {
                if (element.email_docente_referente2 === email){
                    isValid = false;
                }             
            }
        });
        //Imposto il valore isValid a true per disabilitare il controllo dell'esistenza dell'email
        isValid = true;
        //
        return isValid;
    }

    handleClassFormFilled(status){
        //console.log(status);
        this.setState({ confirmclassenabled: status });
    }

    handleChangeSpedNome(event) {
        this.setState({ sped_nome: event.target.value }, function(){
            if (this.validateSpedNome(event.target.value)) {
                this.setState({ sped_nome_error: false });
            } else {
                this.setState({ sped_nome_error: true });
            }
            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();
            let classi = this.state.classi;
            if (required_field_are_filled && fields_are_valid && classi.length > 0) {
                this.setState({ orderenabled: true });
            } else {
                this.setState({ orderenabled: false });
            }
        });
    }

    handleChangeSpedCognome(event) {
        this.setState({ sped_cognome: event.target.value }, function(){
            if (this.validateSpedCognome(event.target.value)) {
                this.setState({ sped_cognome_error: false });
            } else {
                this.setState({ sped_cognome_error: true });
            }
            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();
            let classi = this.state.classi;
            if (required_field_are_filled && fields_are_valid && classi.length > 0) {
                this.setState({ orderenabled: true });
            } else {
                this.setState({ orderenabled: false });
            }
        });
    }

    handleChangeSpedNomeScuola(event){
        this.setState({ sped_nome_scuola: event.target.value }, function(){
            if (this.validateSpedNomeScuola(event.target.value)) {
                this.setState({ sped_nome_scuola_error: false });
            } else {
                this.setState({ sped_nome_scuola_error: true });
            }
            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();
            let classi = this.state.classi;
            if (required_field_are_filled && fields_are_valid && classi.length > 0) {
                this.setState({ orderenabled: true });
            } else {
                this.setState({ orderenabled: false });
            }
        });
    }

    handleChangeSpedIndirizzo(event){
        this.setState({ sped_indirizzo: event.target.value }, function(){
            if (this.validateSpedIndirizzo(event.target.value)) {
                this.setState({ sped_indirizzo_error: false });
            } else {
                this.setState({ sped_indirizzo_error: true });
            }
            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();
            let classi = this.state.classi;
            if (required_field_are_filled && fields_are_valid && classi.length > 0) {
                this.setState({ orderenabled: true });
            } else {
                this.setState({ orderenabled: false });
            }
        });
    }

    handleChangeSpedCitta(event){
        this.setState({ sped_citta: event.target.value }, function(){
            if (this.validateSpedCitta(event.target.value)) {
                this.setState({ sped_citta_error: false });
            } else {
                this.setState({ sped_citta_error: true });
            }
            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();
            let classi = this.state.classi;
            if (required_field_are_filled && fields_are_valid && classi.length > 0) {
                this.setState({ orderenabled: true });
            } else {
                this.setState({ orderenabled: false });
            }
        });
    }

    handleChangeSpedProvincia(event){
        this.setState({ sped_provincia: event.target.value },function(){
            if (this.validateSpedProvincia(event.target.value)) {
                this.setState({ sped_provincia_error: false });
            } else {
                this.setState({ sped_provincia_error: true });
            }
            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();
            let classi = this.state.classi;
            if (required_field_are_filled && fields_are_valid && classi.length > 0) {
                this.setState({ orderenabled: true });
            } else {
                this.setState({ orderenabled: false });
            }
        });
    }

    handleChangeSpedCap(event){
        this.setState({ sped_cap: event.target.value }, function(){
            if (this.validateSpedCap(event.target.value)) {
                this.setState({ sped_cap_error: false });
            } else {
                this.setState({ sped_cap_error: true });
            }
            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();
            let classi = this.state.classi;
            if (required_field_are_filled && fields_are_valid && classi.length > 0) {
                this.setState({ orderenabled: true });
            } else {
                this.setState({ orderenabled: false });
            }
        });
    }

    handleChangeSpedCellulare(event){
        this.setState({ sped_cellulare: event.target.value }, function(){
            if (this.validateSpedCellulare(event.target.value)) {
                this.setState({ sped_cellulare_error: false });
            } else {
                this.setState({ sped_cellulare_error: true });
            }
            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();
            let classi = this.state.classi;
            if (required_field_are_filled && fields_are_valid && classi.length > 0) {
                this.setState({ orderenabled: true });
            } else {
                this.setState({ orderenabled: false });
            }
        });
    }

    handleChangeNoteConsegna(event){
        this.setState({ note_consegna: event.target.value }, function(){
            if (this.validateNoteConsegna(event.target.value)) {
                this.setState({ note_consegna_error: false });
            } else {
                this.setState({ note_consegna_error: true });
            }
            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();
            let classi = this.state.classi;
            if (required_field_are_filled && fields_are_valid && classi.length > 0) {
                this.setState({ orderenabled: true });
            } else {
                this.setState({ orderenabled: false });
            }
        });
    }

    handleChangeFattNome(event){
        this.setState({ fatt_nome: event.target.value }, function(){
            if (this.validateFattNome(event.target.value)) {
                this.setState({ fatt_nome_error: false });
            } else {
                this.setState({ fatt_nome_error: true });
            }
            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();
            let classi = this.state.classi;
            if (required_field_are_filled && fields_are_valid && classi.length > 0) {
                this.setState({ orderenabled: true });
            } else {
                this.setState({ orderenabled: false });
            }
        });
    }

    handleChangeFattCognome(event){
        this.setState({ fatt_cognome: event.target.value }, function(){
            if (this.validateFattCognome(event.target.value)) {
                this.setState({ fatt_cognome_error: false });
            } else {
                this.setState({ fatt_cognome_error: true });
            }
            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();
            let classi = this.state.classi;
            if (required_field_are_filled && fields_are_valid && classi.length > 0) {
                this.setState({ orderenabled: true });
            } else {
                this.setState({ orderenabled: false });
            }
        });
    }

    handleChangeFattNomeScuola(event){
        this.setState({ fatt_nome_scuola: event.target.value }, function(){
            if (this.validateFattNomeScuola(event.target.value)) {
                this.setState({ fatt_nome_scuola_error: false });
            } else {
                this.setState({ fatt_nome_scuola_error: true });
            }
            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();
            let classi = this.state.classi;
            if (required_field_are_filled && fields_are_valid && classi.length > 0) {
                this.setState({ orderenabled: true });
            } else {
                this.setState({ orderenabled: false });
            }
        });
    }

    handleChangeFattCfPiva(event){
        this.setState({ fatt_cf_piva: event.target.value }, function(){
            if (this.validateFattCfPiva(event.target.value)) {
                this.setState({ fatt_cf_piva_error: false });
            } else {
                this.setState({ fatt_cf_piva_error: true });
            }
            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();
            let classi = this.state.classi;
            if (required_field_are_filled && fields_are_valid && classi.length > 0) {
                this.setState({ orderenabled: true });
            } else {
                this.setState({ orderenabled: false });
            }
        });
    }

    handleChangeFattIndirizzo(event){
        this.setState({ fatt_indirizzo: event.target.value }, function(){
            if (this.validateFattIndirizzo(event.target.value)) {
                this.setState({ fatt_indirizzo_error: false });
            } else {
                this.setState({ fatt_indirizzo_error: true });
            }
            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();
            let classi = this.state.classi;
            if (required_field_are_filled && fields_are_valid && classi.length > 0) {
                this.setState({ orderenabled: true });
            } else {
                this.setState({ orderenabled: false });
            }
        });
    }

    handleChangeFattCitta(event){
        this.setState({ fatt_citta: event.target.value }, function(){
            if (this.validateFattCitta(event.target.value)) {
                this.setState({ fatt_citta_error: false });
            } else {
                this.setState({ fatt_citta_error: true });
            }
            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();
            let classi = this.state.classi;
            if (required_field_are_filled && fields_are_valid && classi.length > 0) {
                this.setState({ orderenabled: true });
            } else {
                this.setState({ orderenabled: false });
            }
        });
    }

    handleChangeFattProvincia(event){
        this.setState({ fatt_provincia: event.target.value },function(){
            if (this.validateFattProvincia(event.target.value)) {
                this.setState({ fatt_provincia_error: false });
            } else {
                this.setState({ fatt_provincia_error: true });
            }
            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();
            let classi = this.state.classi;
            if (required_field_are_filled && fields_are_valid && classi.length > 0) {
                this.setState({ orderenabled: true });
            } else {
                this.setState({ orderenabled: false });
            }
        });
    }

    handleChangeFattCap(event){
        this.setState({ fatt_cap: event.target.value }, function(){
            if (this.validateFattCap(event.target.value)) {
                this.setState({ fatt_cap_error: false });
            } else {
                this.setState({ fatt_cap_error: true });
            }
            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();
            let classi = this.state.classi;
            if (required_field_are_filled && fields_are_valid && classi.length > 0) {
                this.setState({ orderenabled: true });
            } else {
                this.setState({ orderenabled: false });
            }
        });
    }

    handleChangeMetodoPagamento(event){
        this.setState({ id_metodo_pagamento: parseInt(event.target.value) }, function(){

            if (parseInt(event.target.value) === 1 && this.state.importo_totale > 77.47){
                var config = require('./config.json');
                var bollo = parseInt(config.bollo); 
                var importo_totale = this.state.importo_netto - this.state.sconto + bollo;
                this.setState({ bollo: bollo });
                this.setState({ importo_totale: importo_totale });
            } else {
                var bollo = 0;
                var importo_totale = this.state.importo_netto - this.state.sconto + bollo;
                this.setState({ bollo: bollo });
                this.setState({ importo_totale: importo_totale });
            }

            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();
            let classi = this.state.classi;
            if (required_field_are_filled && fields_are_valid && classi.length > 0) {
                this.setState({ orderenabled: true });
            } else {
                this.setState({ orderenabled: false });
            }
        });

        if (event.target.value > 1){
            //Pagamento Docente

        } else {
            //pagamento Scuola
            
        }
    }

    //Funzioni handlechange sottopagina aggiungi classe
    sub_handleChangeClasse(event){
        this.setState({ sub_classe: parseInt(event.target.value) },function(){
            if (this.sub_validateClasse(event.target.value)) {
                this.setState({ sub_classe_error: false });
                this.setState({ sub_sezione_error: false });
            } else {
                this.setState({ sub_classe_error: true });
                this.setState({ sub_sezione_error: true });
            }
            let required_field_are_filled = this.sub_requiredFieldsAreFilled();
            let fields_are_valid = this.sub_fieldsAreValid();
            if (required_field_are_filled && fields_are_valid) {
                if (this.state.editclassenabled){
                    this.setState({ sub_editclassenabled: true });
                } else {
                    this.setState({ sub_addclassenabled: true });
                }
                
            } else {
                if (this.state.editclassenabled){
                    this.setState({ sub_editclassenabled: false });
                } else {
                    this.setState({ sub_addclassenabled: false });
                }
            }
        });
    }

    sub_handleChangeSezione(event){
        this.setState({ sub_sezione: event.target.value },function(){
            if (this.sub_validateSezione(event.target.value)) {
                this.setState({ sub_classe_error: false });
                this.setState({ sub_sezione_error: false });
            } else {
                this.setState({ sub_classe_error: true });
                this.setState({ sub_sezione_error: true });
            }
            let required_field_are_filled = this.sub_requiredFieldsAreFilled();
            let fields_are_valid = this.sub_fieldsAreValid();
            if (required_field_are_filled && fields_are_valid) {
                if (this.state.editclassenabled){
                    this.setState({ sub_editclassenabled: true });
                } else {
                    this.setState({ sub_addclassenabled: true });
                }
            } else {
                if (this.state.editclassenabled){
                    this.setState({ sub_editclassenabled: false });
                } else {
                    this.setState({ sub_addclassenabled: false });
                }
           
            }
        });
    }

    sub_handleChangeTipoIscrizione(event){
        this.setState({ sub_tipo_iscrizione: event.target.value },function(){
            if (this.sub_requiredFieldsAreFilled()) {
                if (this.state.editclassenabled){
                    this.setState({ sub_editclassenabled: true });
                } else {
                    this.setState({ sub_addclassenabled: true });
                }

            } else {
                if (this.state.editclassenabled){
                    this.setState({ sub_editclassenabled: false });
                } else {
                    this.setState({ sub_addclassenabled: false });
                }
            }
        });
    }

    sub_handleChangeNomeReferente2(event){
        this.setState({ sub_nome_referente2: event.target.value }, function(){
            if (this.sub_validateNomeReferente2(event.target.value)) {
                this.setState({ sub_nome_error: false });
            } else {
                this.setState({ sub_nome_error: true });
            }
            let required_field_are_filled = this.sub_requiredFieldsAreFilled();
            let fields_are_valid = this.sub_fieldsAreValid();
            if (required_field_are_filled && fields_are_valid) {
                if (this.state.editclassenabled){
                    this.setState({ sub_editclassenabled: true });
                } else {
                    this.setState({ sub_addclassenabled: true });
                }
            } else {
                if (this.state.editclassenabled){
                    this.setState({ sub_editclassenabled: false });
                } else {
                    this.setState({ sub_addclassenabled: false });                    
                }
            }
        });
    }

    sub_handleChangeCognomeReferente2(event){
        this.setState({ sub_cognome_referente2: event.target.value }, function(){
            if (this.sub_validateCognomeReferente2(event.target.value)) {
                this.setState({ sub_cognome_error: false });
            } else {
                this.setState({ sub_cognome_error: true });
            }
            let required_field_are_filled = this.sub_requiredFieldsAreFilled();
            let fields_are_valid = this.sub_fieldsAreValid();
            if (required_field_are_filled && fields_are_valid) {
                if (this.state.editclassenabled){
                    this.setState({ sub_editclassenabled: true });
                } else {
                    this.setState({ sub_addclassenabled: true });
                }
            } else {
                if (this.state.editclassenabled){
                    this.setState({ sub_editclassenabled: false });
                } else {
                    this.setState({ sub_addclassenabled: false });
                }
            }
        });
    }

    sub_handleChangeEmailReferente2(event){
        this.setState({ sub_email_referente2: event.target.value }, function(){
            if (this.sub_validateEmailReferente2(event.target.value)) {
                this.setState({ sub_email_error: false });
            } else {
                this.setState({ sub_email_error: true });
            }
            if (this.sub_validateEmailExist(event.target.value)) {
                this.setState({ sub_email_exist_error: false });
            } else {
                this.setState({ sub_email_exist_error: true });
            }
            let required_field_are_filled = this.sub_requiredFieldsAreFilled();
            let fields_are_valid = this.sub_fieldsAreValid();
            if (required_field_are_filled && fields_are_valid) {
                if (this.state.editclassenabled){
                    this.setState({ sub_editclassenabled: true });
                } else {
                    this.setState({ sub_addclassenabled: true });
                }
            } else {
                if (this.state.editclassenabled){
                    this.setState({ sub_editclassenabled: false });
                } else {
                    this.setState({ sub_addclassenabled: false });
                }
            }
        });
    }

    sub_handleChangeProdottoPrimario(event){
        this.setState({ sub_id_prodotto_prim_selezionato: parseInt(event.target.value) },function(){
           
            let titolo = event.target.dataset.titolo;
            //console.log(event.target.dataset.importo);
            let importo = event.target.dataset.importo;
            //let immagine = this.state.sub_prodotti_primari[event.target.selectedIndex].immagine;
            //let descrizione =  this.state.sub_prodotti_primari[event.target.selectedIndex].descrizione;

            //console.log(event.target.selectedIndex);
            //console.log(immagine);
            //console.log(descrizione);

            this.setState({ sub_nome_prodotto_prim_selezionato: titolo});
            this.setState({ sub_importo_prodotto_prim_selezionato: parseInt(importo)});
            //this.setState({ sub_immagine_prodotto_prim_selezionato: immagine});
            //this.setState({ sub_descrizione_prodotto_prim_selezionato: descrizione});

            let totale = parseInt(importo);

            //console.log("totale => " + totale);

            this.state.sub_prodotti_secondari.forEach((element) => {
                if (typeof element.quantita !== 'undefined' && element.quantita !== null && element.quantita !== 0 && element.importo !== null) {
                    totale = totale + (parseInt(element.quantita) * parseInt(element.importo));

                    //console.log("qta => " + element.quantita);
                    //console.log("importo => " + element.importo);
                }
            });

            this.setState({ sub_importo_totale: totale});

            let required_field_are_filled = this.sub_requiredFieldsAreFilled();
            let fields_are_valid = this.sub_fieldsAreValid();

            if (required_field_are_filled && fields_are_valid) {
                if (this.state.editclassenabled){
                    this.setState({ sub_editclassenabled: true });
                } else {
                    this.setState({ sub_addclassenabled: true });
                }

            } else {
                if (this.state.editclassenabled){
                    this.setState({ sub_editclassenabled: false });
               } else {
                    this.setState({ sub_addclassenabled: false });
                }
            }
        });
    }

    sub_handleChangeQuantitaProdottoSecondario(event){

        let prodottisecondari = [...this.state.sub_prodotti_secondari];

        //console.log(prodottisecondari);

        let prodotto = {...prodottisecondari[event.target.dataset.product]};
        prodotto.quantita = parseInt(event.target.value);
        prodottisecondari[event.target.dataset.product] = prodotto;

        //console.log(prodottisecondari);

        this.setState({ sub_prodotti_secondari : prodottisecondari },function(){

            let totale = parseInt(this.state.sub_importo_prodotto_prim_selezionato);

            //console.log("totale => " + totale);

            this.state.sub_prodotti_secondari.forEach((element) => {
                if (typeof element.quantita !== 'undefined' && element.quantita !== null && element.quantita !== 0 && element.importo !== null) {
                    totale = totale + (parseInt(element.quantita) * parseInt(element.importo));

                    //console.log("qta => " + element.quantita);
                    //console.log("importo => " + element.importo);
                }
            });

            this.setState({ sub_importo_totale: totale});

            let required_field_are_filled = this.sub_requiredFieldsAreFilled();
            let fields_are_valid = this.sub_fieldsAreValid();

            if (required_field_are_filled && fields_are_valid) {
                if (this.state.editclassenabled){
                    this.setState({ sub_editclassenabled: true });
                } else {
                    this.setState({ sub_addclassenabled: true });
                }

            } else {
                if (this.state.editclassenabled){
                    this.setState({ sub_editclassenabled: false });
                } else {
                    this.setState({ sub_addclassenabled: false });
                }
            }
        });
    }

    sub_handleEditClassClick(event){
        event.preventDefault();

        //Controlla esistenza utente docente referente 2
        if (this._isMounted) {
            this.setState({ isloading: true });
        }

        if (this.state.sub_id_docente_referente2 !== null && typeof this.state.sub_id_docente_referente2 !== 'undefined'){

            var config = require('./config.json');
            var env_wp = config.env_wp;
            var api_url_wp = config.api_url_wp[env_wp];
            var api_auth_wp = config.api_auth_wp[env_wp];
            var env_backend = config.env_backend;
            var api_url_backend = config.api_url_backend[env_backend];
            const apiUrl = api_url_backend + 'users/exist';
            var authorization = config.api_auth_backend[env_backend];
            var email = this.state.sub_email_referente2;
            var id_docente_referente2;

            const settings = {
                headers: { Authorization: `Bearer ${authorization}` }
            };

            axios.post(apiUrl, { 
                "email" : email
            }, settings).then(res => {
                console.log(res);

                if (this._isMounted) {

                    if (res.data.success == "true"){

                        id_docente_referente2 = res.data.data.id;

                        this.setState({ sub_id_docente_referente2: id_docente_referente2}, function(){

                            let prodotti = [];

                            prodotti.push({
                                id_prodotto: this.state.sub_id_prodotto_prim_selezionato,
                                importo: this.state.sub_importo_prodotto_prim_selezionato,
                                nome_prodotto: this.state.sub_nome_prodotto_prim_selezionato,
                                quantita: 1,
                            });

                            this.state.sub_prodotti_secondari.forEach(function(value, index){

                                if (typeof(value.quantita) !== 'undefined' && value.quantita !== 0){
                                    prodotti.push({
                                        id_prodotto: value.id,
                                        importo: value.importo,
                                        nome_prodotto: value.titolo,
                                        quantita: value.quantita,
                                    });
                                }
                            });

                            if (this.state.sub_id_prodotto_prim_selezionato != 2){
                                this.state.sub_prodotti_regalo.forEach(function(value, index){
                                
                                    prodotti.push({
                                        id_prodotto: value.id,
                                        importo: value.importo,
                                        nome_prodotto: value.titolo,
                                        quantita: 1,
                                    });
                                    
                                });
                            }

                            let importoprodotti = 0;

                            prodotti.forEach(function(value, index){
                                //console.log("importo_netto > " + value.importo);
                                importoprodotti = importoprodotti + (parseInt(value.importo) * parseInt(value.quantita));
                            });

                            let classemodificata = {
                                id: this.state.sub_classe,
                                classe: this.sub_getListaClassi()[this.state.sub_classe],
                                id_docente_referente1: this.state.id_utente,
                                id_docente_referente2: this.state.sub_id_docente_referente2,
                                importo_netto: this.state.sub_importo_totale,
                                nome_docente_referente1: this.state.nome + " " + this.state.cognome,
                                first_name_docente_referente2: this.state.sub_nome_referente2,
                                last_name_docente_referente2: this.state.sub_cognome_referente2,
                                email_docente_referente2: this.state.sub_email_referente2,
                                nome_docente_referente2: this.state.sub_nome_referente2 + " " + this.state.sub_cognome_referente2,
                                nome_scuola: this.state.nome_scuola,
                                prodotti: prodotti,
                                sezione: this.state.sub_sezione
                            };

                            let importo_da_sottrarre = this.state.classi[this.state.class_to_edit].importo_netto;
                            let classi = this.state.classi;
                            classi[this.state.class_to_edit] = classemodificata;

                            let importo_netto = this.state.importo_netto - importo_da_sottrarre + importoprodotti;
                            var sconto = 0;
                            var sconto_percentuale = false;

                            if (this.state.is_supporter == true && this.state.sconto_attivo == true){
                                var config = require('./config.json');
                                sconto_percentuale = Boolean(config.sconto_percentuale); 
                                if (sconto_percentuale){
                                    //sconto = (importo_netto * parseInt(config.sconto_sostenitore) / 100).toFixed(2);
                                    if (classi.length > 0){
                                        sconto = (classi[0].importo_netto * parseInt(config.sconto_sostenitore) / 100).toFixed(2);
                                    } else {
                                        sconto = 0;
                                    }
                                } else {
                                    sconto = parseInt(config.sconto_sostenitore);
                                }
                            } else {
                                sconto = 0;
                            }

                            let importo_totale = importo_netto - sconto;

                            if (importo_totale > 77.47 && this.state.id_metodo_pagamento === 1){
                                var config = require('./config.json');
                                var bollo = parseInt(config.bollo); 
                            } else {
                                var bollo = 0;
                            }

                            importo_totale = importo_totale + bollo;

                            this.setState({ importo_netto: importo_netto});
                            this.setState({ sconto: sconto});
                            this.setState({ bollo: bollo });
                            this.setState({ importo_totale: importo_totale});

                            this.setState({ classi: classi });

                            this.setState({ sub_classe: parseInt(Object.keys(this.sub_getListaClassi())[0]) });
                            this.setState({ sub_sezione: "A" });
                            this.setState({ sub_tipo_iscrizione: "docente" });
                            this.setState({ sub_id_docente_referente1: null });
                            this.setState({ sub_id_docente_referente2: null });
                            this.setState({ sub_nome_referente2: "" });
                            this.setState({ sub_cognome_referente2: "" });
                            this.setState({ sub_email_referente2: "" });
                            this.setState({ sub_id_prodotto_prim_selezionato: this.state.sub_prodotti_primari[0].id });
                            this.setState({ sub_nome_prodotto_prim_selezionato: this.state.sub_prodotti_primari[0].titolo });
                            this.setState({ sub_immagine_prodotto_prim_selezionato: this.state.sub_prodotti_primari[0].immagine });
                            this.setState({ sub_descrizione_prodotto_prim_selezionato: this.state.sub_prodotti_primari[0].descrizione });
                            this.setState({ sub_importo_prodotto_prim_selezionato: this.state.sub_prodotti_primari[0].importo });
                            let prodottisecondari = [...this.state.sub_prodotti_secondari];
                            prodottisecondari.forEach((element, index) => {
                                prodottisecondari[index].quantita = 0;
                            });   
                            this.setState({ sub_prodotti_secondari: prodottisecondari });
                            this.setState({ sub_importo_totale: this.state.sub_prodotti_primari[0].importo });

                            this.setState({ sub_editclasscompleted: true });
                            this.setState({ editclassenabled: false });

                            let required_field_are_filled = this.requiredFieldsAreFilled();
                            let fields_are_valid = this.fieldsAreValid();
                            if (required_field_are_filled && fields_are_valid && classi.length > 0) {
                                this.setState({ orderenabled: true });
                            } else {
                                this.setState({ orderenabled: false });
                            }   

                        });
                    } else {

                        //Registra docente referente 2 non presente nel database

                        const apiUrl_wp = api_url_wp + 'register';
                        const apiUrl2 = api_url_backend + 'users/register';

                        //Registrare utente in WP
                        axios.post(apiUrl_wp, {
                            "authorization" : api_auth_wp,
                            "username" : this.state.sub_email_referente2,
                            "password" : "Temp#1234",
                            "email" : this.state.sub_email_referente2,
                            "first_name" : this.state.sub_nome_referente2,
                            "last_name" : this.state.sub_cognome_referente2,
                            //
                            "cellulare" : "",
                            "grado" : this.state.grado_utente,
                            "codice_meccanografico_scuola" : this.state.codice_meccanografico_scuola,
                            "scuola" : this.state.nome_scuola + " - " + this.state.docente_sped_citta
                            //
                        }).then(res_wp => {
                            var user_id_wp = res_wp.data.data.user_id;

                            //Salvare i dati nell'utente Laravel      

                            axios.post(apiUrl2, { 
                                "ruolo": 1,
                                "nome": this.state.sub_nome_referente2,
                                "cognome": this.state.sub_cognome_referente2,
                                "name": this.state.sub_nome_referente2 + " " + this.state.sub_cognome_referente2,
                                "email": this.state.sub_email_referente2,
                                "id_scuola": this.state.id_scuola,
                                "password": "Temp#1234",
                                "user_id_wp": user_id_wp,
                                "privacy": true,
                                "newsletter": false,
                                "from_other": true
                            }, settings).then(res2 => {
    
                                id_docente_referente2 = res2.data.data.id;
    
                                this.setState({ sub_id_docente_referente2: id_docente_referente2}, function(){
    
                                    //Aggiungi i dati di iscrizione di una classe
    
                                    let prodotti = [];
    
                                    prodotti.push({
                                        id_prodotto: this.state.sub_id_prodotto_prim_selezionato,
                                        importo: this.state.sub_importo_prodotto_prim_selezionato,
                                        nome_prodotto: this.state.sub_nome_prodotto_prim_selezionato,
                                        quantita: 1,
                                    });
    
                                    this.state.sub_prodotti_secondari.forEach(function(value, index){
    
                                        if (typeof(value.quantita) !== 'undefined' && value.quantita !== 0){
                                            prodotti.push({
                                                id_prodotto: value.id,
                                                importo: value.importo,
                                                nome_prodotto: value.titolo,
                                                quantita: value.quantita,
                                            });
                                        }
    
                                    });
    
                                    this.state.sub_prodotti_regalo.forEach(function(value, index){
    
                                            prodotti.push({
                                                id_prodotto: value.id,
                                                importo: value.importo,
                                                nome_prodotto: value.titolo,
                                                quantita: 1,
                                            });
    
                                    });
    
                                    let importoprodotti = 0;
    
                                    prodotti.forEach(function(value, index){
                                        //console.log("importo_netto > " + value.importo);
                                        importoprodotti = importoprodotti + (parseInt(value.importo) * parseInt(value.quantita));
                                    });
    
                                    let classemodificata = {
                                        id: this.state.sub_classe,
                                        classe: this.sub_getListaClassi()[this.state.sub_classe],
                                        id_docente_referente1: this.state.id_utente,
                                        id_docente_referente2: this.state.sub_id_docente_referente2,
                                        importo_netto: this.state.sub_importo_totale,
                                        nome_docente_referente1: this.state.nome + " " + this.state.cognome,
                                        nome_docente_referente2: this.state.sub_nome_referente2 + " " + this.state.sub_cognome_referente2,
                                        nome_scuola: this.state.nome_scuola,
                                        prodotti: prodotti,
                                        sezione: this.state.sub_sezione
                                    };
                                    let importo_da_sottrarre = this.state.classi[this.state.class_to_edit].importo_netto;
                                    let classi = this.state.classi;
                                    classi[this.state.class_to_edit] = classemodificata;
    
                                    let importo_netto = this.state.importo_netto - importo_da_sottrarre + importoprodotti;
                                    var sconto = 0;
    
                                    if (this.state.is_supporter == true && this.state.sconto_attivo == true){
                                        var config = require('./config.json');
                                        var sconto_percentuale = Boolean(config.sconto_percentuale); 
                                        if (sconto_percentuale){
                                            //sconto = (importo_netto * parseInt(config.sconto_sostenitore) / 100).toFixed(2);
                                            if (classi.length > 0){
                                                sconto = (classi[0].importo_netto * parseInt(config.sconto_sostenitore) / 100).toFixed(2);
                                            } else {
                                                sconto = 0;
                                            }
                                        } else {
                                            sconto = parseInt(config.sconto_sostenitore);
                                        }
                                    } else {
                                        sconto = 0;
                                    }
    
                                    let importo_totale = importo_netto - sconto;
    
                                    if (importo_totale > 77.47 && this.state.id_metodo_pagamento === 1){
                                        var config = require('./config.json');
                                        var bollo = parseInt(config.bollo); 
                                    } else {
                                        var bollo = 0;
                                    }
    
                                    importo_totale = importo_totale + bollo;
    
                                    this.setState({ importo_netto: importo_netto});
                                    this.setState({ sconto: sconto});
                                    this.setState({ bollo: bollo});
                                    this.setState({ importo_totale: importo_totale});
    
                                    this.setState({ classi: classi });
    
                                    this.setState({ sub_classe: parseInt(Object.keys(this.sub_getListaClassi())[0]) });
                                    this.setState({ sub_sezione: "A" });
                                    this.setState({ sub_tipo_iscrizione: "docente" });
                                    this.setState({ sub_id_docente_referente1: null });
                                    this.setState({ sub_id_docente_referente2: null });
                                    this.setState({ sub_nome_referente2: "" });
                                    this.setState({ sub_cognome_referente2: "" });
                                    this.setState({ sub_email_referente2: "" });
                                    this.setState({ sub_id_prodotto_prim_selezionato: this.state.sub_prodotti_primari[0].id });
                                    this.setState({ sub_nome_prodotto_prim_selezionato: this.state.sub_prodotti_primari[0].titolo });
                                    this.setState({ sub_immagine_prodotto_prim_selezionato: this.state.sub_prodotti_primari[0].immagine });
                                    this.setState({ sub_descrizione_prodotto_prim_selezionato: this.state.sub_prodotti_primari[0].descrizione });
                                    this.setState({ sub_importo_prodotto_prim_selezionato: this.state.sub_prodotti_primari[0].importo });
                                    let prodottisecondari = [...this.state.sub_prodotti_secondari];
                                    prodottisecondari.forEach((element, index) => {
                                        prodottisecondari[index].quantita = 0;
                                    });   
    
                                    this.setState({ sub_prodotti_secondari: prodottisecondari });
                                    this.setState({ sub_importo_totale: this.state.sub_prodotti_primari[0].importo });
    
                                    this.setState({ sub_editclasscompleted: true });
                                    this.setState({ editclassenabled: false });
    
                                    let required_field_are_filled = this.requiredFieldsAreFilled();
                                    let fields_are_valid = this.fieldsAreValid();
                                    
                                    if (required_field_are_filled && fields_are_valid && classi.length > 0) {
                                        this.setState({ orderenabled: true });
                                    } else {
                                        this.setState({ orderenabled: false });
                                    }    
    
                                });
                            }).catch (error => {
                                this.setState({ isloading: false});
                            });
                        }).catch (error_wp => {
                            if (this._isMounted) {
                                this.setState({ error: true});
                                if (error_wp.response.data.data.code === "email_error"){
                                    this.setState({ emailerror: true }); 
                                }
                                this.setState({ errorcode: error_wp.response.data.data.code });
                                this.setState({ errormessage: error_wp.response.data.data.message });
                                this.setState({ isloading: false });
                            }
                        });
                               
                    }
                }

                this.setState({ isloading: false});  
            }).catch (error => {
                console.log(error);
                this.setState({ isloading: false});
            });
        } else {

            //Aggiungi i dati di iscrizione di una classe

            let prodotti = [];

            prodotti.push({
                id_prodotto: this.state.sub_id_prodotto_prim_selezionato,
                importo: this.state.sub_importo_prodotto_prim_selezionato,
                nome_prodotto: this.state.sub_nome_prodotto_prim_selezionato,
                quantita: 1,
            });

            this.state.sub_prodotti_secondari.forEach(function(value, index){

                if (typeof(value.quantita) !== 'undefined' && value.quantita !== 0){
                    prodotti.push({
                        id_prodotto: value.id,
                        importo: value.importo,
                        nome_prodotto: value.titolo,
                        quantita: value.quantita,
                    });
                }

            });

            this.state.sub_prodotti_regalo.forEach(function(value, index){

                    prodotti.push({
                        id_prodotto: value.id,
                        importo: value.importo,
                        nome_prodotto: value.titolo,
                        quantita: 1,
                    });
            
            });

            let importoprodotti = 0;

            prodotti.forEach(function(value, index){
                //console.log("importo_netto > " + value.importo);
                importoprodotti = importoprodotti + (parseInt(value.importo) * parseInt(value.quantita));
            });

            let classemodificata = {
                id: this.state.sub_classe,
                classe: this.sub_getListaClassi()[this.state.sub_classe],
                id_docente_referente1: this.state.id_utente,
                importo_netto: this.state.sub_importo_totale,
                nome_docente_referente1: this.state.nome + " " + this.state.cognome,
                nome_scuola: this.state.nome_scuola,
                prodotti: prodotti,
                sezione: this.state.sub_sezione
            };

            let importo_da_sottrarre = this.state.classi[this.state.class_to_edit].importo_netto;
            let classi = this.state.classi;
            classi[this.state.class_to_edit] = classemodificata;

            let importo_netto = this.state.importo_netto - importo_da_sottrarre + importoprodotti;
            var sconto = 0;

            if (this.state.is_supporter == true && this.state.sconto_attivo == true){
                var config = require('./config.json');
                var sconto_percentuale = Boolean(config.sconto_percentuale); 
                if (sconto_percentuale){
                    //sconto = (importo_netto * parseInt(config.sconto_sostenitore) / 100).toFixed(2);
                    if (classi.length > 0){
                        sconto = (classi[0].importo_netto * parseInt(config.sconto_sostenitore) / 100).toFixed(2);
                    } else {
                        sconto = 0;
                    }
                } else {
                    sconto = parseInt(config.sconto_sostenitore);
                }
            } else {
                sconto = 0;
            }

            let importo_totale = importo_netto - sconto;

            if (importo_totale > 77.47 && this.state.id_metodo_pagamento === 1){
                var config = require('./config.json');
                var bollo = parseInt(config.bollo); 
            } else {
                var bollo = 0;
            }

            importo_totale = importo_totale + bollo;

            this.setState({ importo_netto: importo_netto});
            this.setState({ sconto: sconto});
            this.setState({ bollo: bollo});
            this.setState({ importo_totale: importo_totale});

            this.setState({ classi: classi });

            this.setState({ sub_classe: parseInt(Object.keys(this.sub_getListaClassi())[0]) });
            this.setState({ sub_sezione: "A" });
            this.setState({ sub_tipo_iscrizione: "docente" });
            this.setState({ sub_id_docente_referente1: null });
            this.setState({ sub_id_docente_referente2: null });
            this.setState({ sub_nome_referente2: "" });
            this.setState({ sub_cognome_referente2: "" });
            this.setState({ sub_email_referente2: "" });
            this.setState({ sub_id_prodotto_prim_selezionato: this.state.sub_prodotti_primari[0].id });
            this.setState({ sub_nome_prodotto_prim_selezionato: this.state.sub_prodotti_primari[0].titolo });
            this.setState({ sub_immagine_prodotto_prim_selezionato: this.state.sub_prodotti_primari[0].immagine });
            this.setState({ sub_descrizione_prodotto_prim_selezionato: this.state.sub_prodotti_primari[0].descrizione });
            this.setState({ sub_importo_prodotto_prim_selezionato: this.state.sub_prodotti_primari[0].importo });
            let prodottisecondari = [...this.state.sub_prodotti_secondari];
            prodottisecondari.forEach((element, index) => {
                prodottisecondari[index].quantita = 0;
            });   
            this.setState({ sub_prodotti_secondari: prodottisecondari });
            this.setState({ sub_importo_totale: this.state.sub_prodotti_primari[0].importo });

            this.setState({ sub_editclasscompleted: true });
            this.setState({ editclassenabled: false });

            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();
            
            if (required_field_are_filled && fields_are_valid && classi.length > 0) {
                this.setState({ orderenabled: true });
            } else {
                this.setState({ orderenabled: false });
            }    

            window.scrollTo(0, 0);

            this.setState({ isloading: false});
        }
    }

    sub_handleAddClassClick(event){
        event.preventDefault();

        //Controlla esistenza utente docente referente 2
        if (this._isMounted) {
            this.setState({ isloading: true });
        }

        //console.log(">>" + this.state.sub_email_referente2);

        if (this.state.sub_email_referente2 !== "" && typeof this.state.sub_email_referente2 !== 'undefined'/*this.state.classi.length > 0*/){

            var config = require('./config.json');
            var env_wp = config.env_wp;
            var api_url_wp = config.api_url_wp[env_wp];
            var api_auth_wp = config.api_auth_wp[env_wp];
            var env_backend = config.env_backend;
            var api_url_backend = config.api_url_backend[env_backend];
            const apiUrl = api_url_backend + 'users/exist';
            var authorization = config.api_auth_backend[env_backend];
            var email = this.state.sub_email_referente2;
            var id_docente_referente2;

            const settings = {
                headers: { Authorization: `Bearer ${authorization}` }
            };

            axios.post(apiUrl, { 
                "email" : email
            }, settings).then(res => {
                //console.log(res);

                if (this._isMounted) {

                    if (res.data.success == "true"){
                        id_docente_referente2 = res.data.data.id;

                        this.setState({ sub_id_docente_referente2: id_docente_referente2}, function(){

                            //Aggiungi i dati di iscrizione di una classe
        
                            //console.log("3>>>> " + id_docente_referente2);
        
                            let prodotti = [];
        
                            prodotti.push({
                                id_prodotto: this.state.sub_id_prodotto_prim_selezionato,
                                importo: this.state.sub_importo_prodotto_prim_selezionato,
                                nome_prodotto: this.state.sub_nome_prodotto_prim_selezionato,
                                quantita: 1,
                            });
        
                            this.state.sub_prodotti_secondari.forEach(function(value, index){
        
                                if (typeof(value.quantita) !== 'undefined' && value.quantita !== 0){
                                    prodotti.push({
                                        id_prodotto: value.id,
                                        importo: value.importo,
                                        nome_prodotto: value.titolo,
                                        quantita: value.quantita,
                                    });
                                }
                            
                            });   

                            if (this.state.sub_id_prodotto_prim_selezionato != 2){
                                this.state.sub_prodotti_regalo.forEach(function(value, index){
            
                                        prodotti.push({
                                            id_prodotto: value.id,
                                            importo: value.importo,
                                            nome_prodotto: value.titolo,
                                            quantita: 1,
                                        });
                                    
                                }); 
                            }  
        
                            let importoprodotti = 0;
                            
                            prodotti.forEach(function(value, index){
                                //console.log("importo_netto > " + value.importo);
                                importoprodotti = importoprodotti + (parseInt(value.importo) * parseInt(value.quantita));
                            });
        
                            //console.log("importo prodotti > " + importoprodotti);
        
                            let nuovaclasse = {
                                id: this.state.sub_classe,
                                classe: this.sub_getListaClassi()[this.state.sub_classe],
                                id_docente_referente1: this.state.id_utente,
                                id_docente_referente2: this.state.sub_id_docente_referente2,
                                importo_netto: this.state.sub_importo_totale,
                                nome_docente_referente1: this.state.nome + " " + this.state.cognome,
                                first_name_docente_referente2: this.state.sub_nome_referente2,
                                last_name_docente_referente2: this.state.sub_cognome_referente2,
                                email_docente_referente2: this.state.sub_email_referente2,
                                nome_docente_referente2: this.state.sub_nome_referente2 + " " + this.state.sub_cognome_referente2,
                                nome_scuola: this.state.nome_scuola,
                                prodotti: prodotti,
                                sezione: this.state.sub_sezione
                            };
        
                            let classi = this.state.classi;
                            classi.push(nuovaclasse);
        
                            let importo_netto = this.state.importo_netto + importoprodotti;

                            //console.log("importo netto > " + importoprodotti);

                            //console.log("is_supporter > " + this.state.is_supporter);
                            //console.log("discount > " + this.state.sconto_attivo);

                            if (this.state.is_supporter == true && this.state.sconto_attivo == true){
                                var config = require('./config.json');
                                var sconto_percentuale = Boolean(config.sconto_percentuale); 
                                if (sconto_percentuale){
                                    //sconto = (importo_netto * parseInt(config.sconto_sostenitore) / 100).toFixed(2);
                                    if (classi.length > 0){
                                        sconto = (classi[0].importo_netto * parseInt(config.sconto_sostenitore) / 100).toFixed(2);
                                    } else {
                                        sconto = 0;
                                    }
                                } else {
                                    sconto = parseInt(config.sconto_sostenitore);
                                }
                            } else {
                                var sconto = 0;
                            }

                            let importo_totale = importo_netto - sconto;

                            if (importo_totale > 77.47 && this.state.id_metodo_pagamento === 1){
                                var config = require('./config.json');
                                var bollo = parseInt(config.bollo); 
                            } else {
                                var bollo = 0;
                            }

                            importo_totale = importo_totale + bollo;

                            //console.log("importo totale > " + importo_totale);
        
                            this.setState({ importo_netto: importo_netto});
                            this.setState({ sconto: sconto});
                            this.setState({ bollo: bollo});
                            this.setState({ importo_totale: importo_totale});
        
                            this.setState({ classi: classi });

                            this.setState({ sub_classe: parseInt(Object.keys(this.sub_getListaClassi())[0]) });
                            this.setState({ sub_sezione: "A" });
                            this.setState({ sub_tipo_iscrizione: "docente" });
                            this.setState({ sub_id_docente_referente1: null });
                            this.setState({ sub_id_docente_referente2: null });
                            this.setState({ sub_nome_referente2: "" });
                            this.setState({ sub_cognome_referente2: "" });
                            this.setState({ sub_email_referente2: "" });
                            this.setState({ sub_id_prodotto_prim_selezionato: this.state.sub_prodotti_primari[0].id });
                            this.setState({ sub_nome_prodotto_prim_selezionato: this.state.sub_prodotti_primari[0].titolo });
                            this.setState({ sub_immagine_prodotto_prim_selezionato: this.state.sub_prodotti_primari[0].immagine });
                            this.setState({ sub_descrizione_prodotto_prim_selezionato: this.state.sub_prodotti_primari[0].descrizione });
                            this.setState({ sub_importo_prodotto_prim_selezionato: this.state.sub_prodotti_primari[0].importo });
                            let prodottisecondari = [...this.state.sub_prodotti_secondari];
                            prodottisecondari.forEach((element, index) => {
                                prodottisecondari[index].quantita = 0;
                            });   
                            this.setState({ sub_prodotti_secondari: prodottisecondari });
                            this.setState({ sub_importo_totale: this.state.sub_prodotti_primari[0].importo });

                            this.setState({ sub_addclasscompleted: true });
                            this.setState({ addclassenabled: false });
        
                            let required_field_are_filled = this.requiredFieldsAreFilled();
                            let fields_are_valid = this.fieldsAreValid();
                            if (required_field_are_filled && fields_are_valid && classi.length > 0) {
                                this.setState({ orderenabled: true });
                            } else {
                                this.setState({ orderenabled: false });
                            }    
                            this.setState({ isloading: false});   
                        }); 

                    } else {

                        //Registra docente referente 2 non presente nel database

                        const apiUrl_wp = api_url_wp + 'register';
                        const apiUrl2 = api_url_backend + 'users/register';

                        //Registrare utente in WP
                        axios.post(apiUrl_wp, {
                            "authorization" : api_auth_wp,
                            "username" : this.state.sub_email_referente2,
                            "password" : "Temp#1234",
                            "email" : this.state.sub_email_referente2,
                            "first_name" : this.state.sub_nome_referente2,
                            "last_name" : this.state.sub_cognome_referente2,
                            //
                            "cellulare" : "",
                            "grado" : this.state.grado_utente,
                            "codice_meccanografico_scuola" : this.state.codice_meccanografico_scuola,
                            "scuola" : this.state.nome_scuola + " - " + this.state.docente_sped_citta
                            //
                        }).then(res_wp => {
                            var user_id_wp = res_wp.data.data.user_id;

                            //Salvare i dati nell'utente Laravel  
                            axios.post(apiUrl2, { 
                                "ruolo": 1,
                                "grado": this.state.grado_utente,
                                "nome": this.state.sub_nome_referente2,
                                "cognome": this.state.sub_cognome_referente2,
                                "name": this.state.sub_nome_referente2 + " " + this.state.sub_cognome_referente2,
                                "email": this.state.sub_email_referente2,
                                "id_scuola": this.state.id_scuola,
                                "password": "Temp#1234",
                                "user_id_wp": user_id_wp,
                                "privacy": true,
                                "newsletter": false,
                                "from_other": true
                            }, settings).then(res2 => {
    
                                //console.log(res2);
    
                                id_docente_referente2 = res2.data.data.id;
    
                                //console.log("2>>>> " + id_docente_referente2);
    
                                this.setState({ sub_id_docente_referente2: id_docente_referente2}, function(){
    
                                    //Aggiungi i dati di iscrizione di una classe
                
                                    //console.log("3>>>> " + id_docente_referente2);
                
                                    let prodotti = [];
                
                                    prodotti.push({
                                        id_prodotto: this.state.sub_id_prodotto_prim_selezionato,
                                        importo: this.state.sub_importo_prodotto_prim_selezionato,
                                        nome_prodotto: this.state.sub_nome_prodotto_prim_selezionato,
                                        quantita: 1,
                                    });
                
                                    this.state.sub_prodotti_secondari.forEach(function(value, index){
                
                                        if (typeof(value.quantita) !== 'undefined' && value.quantita !== 0){
                                            prodotti.push({
                                                id_prodotto: value.id,
                                                importo: value.importo,
                                                nome_prodotto: value.titolo,
                                                quantita: value.quantita,
                                            });
                                        }
                                    
                                    });   
    
    
                                    if (this.state.sub_id_prodotto_prim_selezionato != 2){
                                        this.state.sub_prodotti_regalo.forEach(function(value, index){
                    
                                                prodotti.push({
                                                    id_prodotto: value.id,
                                                    importo: value.importo,
                                                    nome_prodotto: value.titolo,
                                                    quantita: 1,
                                                });
                                                                            
                                        });   
                                    }
                
                                    let importoprodotti = 0;
                                    
                                    prodotti.forEach(function(value, index){
                                        //console.log("importo_netto > " + value.importo);
                                        importoprodotti = importoprodotti + (parseInt(value.importo) * parseInt(value.quantita));
                                    });
                
                                    //console.log("importo prodotti > " + importoprodotti);
                
                                    let nuovaclasse = {
                                        id: this.state.sub_classe,
                                        classe: this.sub_getListaClassi()[this.state.sub_classe],
                                        id_docente_referente1: this.state.id_utente,
                                        id_docente_referente2: this.state.sub_id_docente_referente2,
                                        importo_netto: this.state.sub_importo_totale,
                                        nome_docente_referente1: this.state.nome + " " + this.state.cognome,
                                        first_name_docente_referente2: this.state.sub_nome_referente2,
                                        last_name_docente_referente2: this.state.sub_cognome_referente2,
                                        email_docente_referente2: this.state.sub_email_referente2,
                                        nome_docente_referente2: this.state.sub_nome_referente2 + " " + this.state.sub_cognome_referente2,
                                        nome_scuola: this.state.nome_scuola,
                                        prodotti: prodotti,
                                        sezione: this.state.sub_sezione
                                    };
                
                                    let classi = this.state.classi;
                                    classi.push(nuovaclasse);
                
                                    let importo_netto = this.state.importo_netto + importoprodotti;
    
                                    //console.log("importo netto > " + importoprodotti);
    
                                    //console.log("is_supporter > " + this.state.is_supporter);
                                    //console.log("discount > " + this.state.sconto_attivo);
    
                                    if (this.state.is_supporter == true && this.state.sconto_attivo == true){
                                        var config = require('./config.json');
                                        var sconto = 0;
                                        var sconto_percentuale = Boolean(config.sconto_percentuale); 
                                        if (sconto_percentuale){
                                            //sconto = (importo_netto * parseInt(config.sconto_sostenitore) / 100).toFixed(2);
                                            if (classi.length > 0){
                                                sconto = (classi[0].importo_netto * parseInt(config.sconto_sostenitore) / 100).toFixed(2);
                                            } else {
                                                sconto = 0;
                                            }
                                        } else {
                                            sconto = parseInt(config.sconto_sostenitore);
                                        }
                                    } else {
                                        var sconto = 0;
                                    }
    
                                    let importo_totale = importo_netto - sconto;
    
                                    if (importo_totale > 77.47 && this.state.id_metodo_pagamento === 1){
                                        var config = require('./config.json');
                                        var bollo = parseInt(config.bollo); 
                                    } else {
                                        var bollo = 0;
                                    }
    
                                    importo_totale = importo_totale + bollo;
    
                                    //console.log("importo totale > " + importo_totale);
                
                                    this.setState({ importo_netto: importo_netto});
                                    this.setState({ sconto: sconto});
                                    this.setState({ bollo: bollo});
                                    this.setState({ importo_totale: importo_totale});
                
                                    this.setState({ classi: classi });
    
                                    this.setState({ sub_classe: parseInt(Object.keys(this.sub_getListaClassi())[0]) });
                                    this.setState({ sub_sezione: "A" });
                                    this.setState({ sub_tipo_iscrizione: "docente" });
                                    this.setState({ sub_id_docente_referente1: null });
                                    this.setState({ sub_id_docente_referente2: null });
                                    this.setState({ sub_nome_referente2: "" });
                                    this.setState({ sub_cognome_referente2: "" });
                                    this.setState({ sub_email_referente2: "" });
                                    this.setState({ sub_id_prodotto_prim_selezionato: this.state.sub_prodotti_primari[0].id });
                                    this.setState({ sub_nome_prodotto_prim_selezionato: this.state.sub_prodotti_primari[0].titolo });
                                    this.setState({ sub_immagine_prodotto_prim_selezionato: this.state.sub_prodotti_primari[0].immagine });
                                    this.setState({ sub_descrizione_prodotto_prim_selezionato: this.state.sub_prodotti_primari[0].descrizione });
                                    this.setState({ sub_importo_prodotto_prim_selezionato: this.state.sub_prodotti_primari[0].importo });
                                    let prodottisecondari = [...this.state.sub_prodotti_secondari];
                                    prodottisecondari.forEach((element, index) => {
                                        prodottisecondari[index].quantita = 0;
                                    });   
                                    this.setState({ sub_prodotti_secondari: prodottisecondari });
                                    this.setState({ sub_importo_totale: this.state.sub_prodotti_primari[0].importo });
    
                                    this.setState({ sub_addclasscompleted: true }, function(){
                                        this.setState({ addclassenabled: false });
                
                                        let required_field_are_filled = this.requiredFieldsAreFilled();
                                        let fields_are_valid = this.fieldsAreValid();
                                        
                                        if (required_field_are_filled && fields_are_valid && classi.length > 0) {
                                            this.setState({ orderenabled: true });
                                        } else {
                                            this.setState({ orderenabled: false });
                                        }    
                                        this.setState({ isloading: false});     
                                    });                                    
    
                                }); 
    
                            }).catch (error => {
                                //console.log(error);
                                this.setState({ isloading: false});
                            });

                        }).catch (error_wp => {
                            if (this._isMounted) {
                                this.setState({ error: true});
                                if (error_wp.response.data.data.code === "email_error"){
                                    this.setState({ emailerror: true }); 
                                }
                                this.setState({ errorcode: error_wp.response.data.data.code });
                                this.setState({ errormessage: error_wp.response.data.data.message });
                                this.setState({ isloading: false });
                            }
                        });                        

                    }

                }

                //this.setState({ isloading: false});     

            }).catch (error => {
                //console.log(error);
                this.setState({ isloading: false});
            });

        } else {

            //Aggiungi i dati di iscrizione di una classe
        
            let prodotti = [];

            prodotti.push({
                id_prodotto: this.state.sub_id_prodotto_prim_selezionato,
                importo: this.state.sub_importo_prodotto_prim_selezionato,
                nome_prodotto: this.state.sub_nome_prodotto_prim_selezionato,
                quantita: 1,
            });

            this.state.sub_prodotti_secondari.forEach(function(value, index){

                if (typeof(value.quantita) !== 'undefined' && value.quantita !== 0){
                    prodotti.push({
                        id_prodotto: value.id,
                        importo: value.importo,
                        nome_prodotto: value.titolo,
                        quantita: value.quantita,
                    });
                }
            
            });   

            if (this.state.sub_id_prodotto_prim_selezionato != 2){
                this.state.sub_prodotti_regalo.forEach(function(value, index){

                        prodotti.push({
                            id_prodotto: value.id,
                            importo: value.importo,
                            nome_prodotto: value.titolo,
                            quantita: 1,
                        });
                                
                });   
            }

            let importoprodotti = 0;
            
            prodotti.forEach(function(value, index){
                //console.log("importo_netto > " + value.importo);
                importoprodotti = importoprodotti + (parseInt(value.importo) * parseInt(value.quantita));
            });

            //console.log("importo prodotti > " + importoprodotti);

            let nuovaclasse = {
                id: this.state.sub_classe,
                classe: this.sub_getListaClassi()[this.state.sub_classe],
                id_docente_referente1: this.state.id_utente,
                importo_netto: this.state.sub_importo_totale,
                nome_docente_referente1: this.state.nome + " " + this.state.cognome,
                nome_scuola: this.state.nome_scuola,
                prodotti: prodotti,
                sezione: this.state.sub_sezione
            };

            let classi = this.state.classi;
            classi.push(nuovaclasse);

            let importo_netto = this.state.importo_netto + importoprodotti;

            //console.log("importo_netto > " + importo_netto);

            //console.log("is_supporter > " + this.state.is_supporter);
            //console.log("discount > " + this.state.sconto_attivo);
            
                                
            if (this.state.is_supporter == true && this.state.sconto_attivo == true){
                var config = require('./config.json');
                var sconto = 0;
                var sconto_percentuale = Boolean(config.sconto_percentuale); 
                if (sconto_percentuale){
                    //sconto = (importo_netto * parseInt(config.sconto_sostenitore) / 100).toFixed(2);
                    if (classi.length > 0){
                        sconto = (classi[0].importo_netto * parseInt(config.sconto_sostenitore) / 100).toFixed(2);
                    } else {
                        sconto = 0;
                    }
                } else {
                    sconto = parseInt(config.sconto_sostenitore);
                }
            } else {
                var sconto = 0;
            }

            let importo_totale = importo_netto - sconto;

            if (importo_totale > 77.47 && this.state.id_metodo_pagamento === 1){
                var config = require('./config.json');
                var bollo = parseInt(config.bollo); 
            } else {
                var bollo = 0;
            }

            importo_totale = importo_totale + bollo;

            //console.log("importo_totale > " + importo_totale);

            this.setState({ importo_netto: importo_netto});
            this.setState({ sconto: sconto});
            this.setState({ bollo: bollo});
            this.setState({ importo_totale: importo_totale});

            this.setState({ classi: classi });

            this.setState({ sub_classe: parseInt(Object.keys(this.sub_getListaClassi())[0]) });
            this.setState({ sub_sezione: "A" });
            this.setState({ sub_tipo_iscrizione: "docente" });
            this.setState({ sub_id_docente_referente1: null });
            this.setState({ sub_id_docente_referente2: null });
            this.setState({ sub_nome_referente2: "" });
            this.setState({ sub_cognome_referente2: "" });
            this.setState({ sub_email_referente2: "" });
            this.setState({ sub_id_prodotto_prim_selezionato: this.state.sub_prodotti_primari[0].id });
            this.setState({ sub_nome_prodotto_prim_selezionato: this.state.sub_prodotti_primari[0].titolo });
            this.setState({ sub_immagine_prodotto_prim_selezionato: this.state.sub_prodotti_primari[0].immagine });
            this.setState({ sub_descrizione_prodotto_prim_selezionato: this.state.sub_prodotti_primari[0].descrizione });
            this.setState({ sub_importo_prodotto_prim_selezionato: this.state.sub_prodotti_primari[0].importo });
            let prodottisecondari = [...this.state.sub_prodotti_secondari];
            prodottisecondari.forEach((element, index) => {
                prodottisecondari[index].quantita = 0;
            });   
            this.setState({ sub_prodotti_secondari: prodottisecondari });
            this.setState({ sub_importo_totale: this.state.sub_prodotti_primari[0].importo });

            this.setState({ sub_addclasscompleted: true });
            this.setState({ addclassenabled: false });

            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();
            
            if (required_field_are_filled && fields_are_valid && classi.length > 0) {
                this.setState({ orderenabled: true });
            } else {
                this.setState({ orderenabled: false });
            }    

            window.scrollTo(0, 0);

            this.setState({ isloading: false});   

        }

    }

    render() {

        var config = require('./config.json');

        const autenticato = this.state.authorization !== '';
        const error = this.state.error;
        const errormessage = this.state.errormessage;
        const isloading = this.state.isloading;

        const nome = this.state.nome;
        const cognome = this.state.cognome;

        const anchorElUserMenu = this.state.anchorElUserMenu;
        const open = Boolean(anchorElUserMenu);

        const sped_codice_scuola = this.state.sped_codice_scuola;

        const pagamento_carta_docente = this.state.pagamento_carta_docente;
        
        const sped_nome = this.state.sped_nome;
        const sped_cognome = this.state.sped_cognome;
        const sped_nome_scuola = this.state.sped_nome_scuola;
        const sped_indirizzo = this.state.sped_indirizzo;
        const sped_citta = this.state.sped_citta;
        const sped_provincia = this.state.sped_provincia;
        const sped_cap = this.state.sped_cap;
        const sped_cellulare = this.state.sped_cellulare;
        const note_consegna = this.state.note_consegna;
        
        const fatt_nome = this.state.fatt_nome;
        const fatt_cognome = this.state.fatt_cognome;
        const fatt_cf_piva = this.state.fatt_cf_piva;
        const fatt_indirizzo = this.state.fatt_indirizzo;
        const fatt_citta = this.state.fatt_citta;
        const fatt_provincia = this.state.fatt_provincia;
        const fatt_cap = this.state.fatt_cap;

        const sped_nome_error = this.state.sped_nome_error;
        const sped_cognome_error = this.state.sped_cognome_error;
        const sped_nome_scuola_error = this.state.sped_nome_scuola_error;
        const sped_indirizzo_error = this.state.sped_indirizzo_error;
        const sped_citta_error = this.state.sped_citta_error;
        const sped_provincia_error = this.state.sped_provincia_error;
        const sped_cap_error = this.state.sped_cap_error;
        const sped_cellulare_error = this.state.sped_cellulare_error;
        const note_consegna_error = this.state.note_consegna_error;
        const fatt_nome_error = this.state.fatt_nome_error;
        const fatt_cognome_error = this.state.fatt_cognome_error;
        const fatt_cf_piva_error = this.state.fatt_cf_piva_error;
        const fatt_indirizzo_error = this.state.fatt_indirizzo_error;
        const fatt_citta_error = this.state.fatt_citta_error;
        const fatt_provincia_error = this.state.fatt_provincia_error;
        const fatt_cap_error = this.state.fatt_cap_error;

        const orderenabled = this.state.orderenabled;
        const ordercompleted = this.state.ordercompleted;
        const nome_progetto = this.state.nome_progetto;
        const tipo_progetto = this.state.tipo_progetto;
        const anno_progetto = this.state.anno_progetto;
        const descrizione_progetto = this.state.descrizione_progetto;
        const id_metodo_pagamento  = this.state.id_metodo_pagamento;
        const addclassenabled = this.state.addclassenabled;
        const editclassenabled = this.state.editclassenabled;
        const proceed = this.state.proceed;

        const is_supporter = this.state.is_supporter;
        
        const sconto_sostenitore = parseInt(config.sconto_sostenitore);
        const sconto_percentuale = Boolean(config.sconto_percentuale);
        const sconto_sostenitore_enabled = Boolean(config.sconto_sostenitore_enabled);
        
        const classi = this.state.classi;
        const importo_netto = this.state.importo_netto;
        const sconto = this.state.sconto;
        const bollo = this.state.bollo;
        const importo_totale = this.state.importo_totale;
        const docente_referente = this.state.scuola_sped_docente_ref;
        const nome_docente_referente = this.state.scuola_sped_nome_docente_ref;
        const cognome_docente_referente = this.state.scuola_sped_cognome_docente_ref;
        const grado_docente_referente = this.state.scuola_sped_grado_docente_ref;
        
        //Costanti per la sottopagina aggiungi classe
        const sub_classe = this.state.sub_classe;
        const sub_sezione = this.state.sub_sezione;
        const sub_nome_referente2 = this.state.sub_nome_referente2;
        const sub_cognome_referente2 = this.state.sub_cognome_referente2;
        const sub_email_referente2 = this.state.sub_email_referente2;
        const sub_classe_error = this.state.sub_classe_error;
        const sub_sezione_error = this.state.sub_sezione_error;
        const sub_nome_referente2_error = this.state.sub_nome_referente2_error;
        const sub_cognome_referente2_error = this.state.sub_cognome_referente2_error;
        const sub_nome_error = this.state.sub_nome_error;
        const sub_cognome_error = this.state.sub_cognome_error;
        const sub_email_error = this.state.sub_email_error;
        const sub_email_exist_error = this.state.sub_email_exist_error;

        const sub_id_prodotto_prim_selezionato = this.state.sub_id_prodotto_prim_selezionato;
        
        const sub_prodotti_primari = this.state.sub_prodotti_primari;
        const sub_prodotti_secondari = this.state.sub_prodotti_secondari;
        const sub_prodotti_regalo = this.state.sub_prodotti_regalo;

        const sub_importo_totale = this.state.sub_importo_totale;

        const sub_addclassenabled = this.state.sub_addclassenabled;
        const sub_editclassenabled = this.state.sub_editclassenabled;

        const opendeletemodal = this.state.opendeletemodal;
        const openimagemodal = this.state.openimagemodal;
        const image_to_show = this.state.image_to_show;
        const image_hd_width = this.state.image_hd_width;

        const sub_id_docente_referente2 = this.state.sub_id_docente_referente2;
        const ordini_precedenti = this.state.ordini_precedenti;

        const spedizione_attiva = this.state.spedizione_attiva;        

        const metadesc = "Compila questo modulo per iscrivere le tue classi alla IV edizione di " + nome_progetto + "."

        return(
            <div>
                <Helmet htmlAttributes={{lang: 'it-IT'}}>
                    <title>Concorsi ToKalon - Iscrivi una o più classi a {nome_progetto}</title>
                    <meta name="description" content={metadesc}  data-react-helmet="true"/>
                </Helmet> 
                { ordercompleted && (
                    <Redirect to="/supporterordered" />
                )}
                { (autenticato && addclassenabled === false && editclassenabled === false) && ( 
                    <div className="App">
                        {( isloading === false) ? (
                        <div>    
                            <header className="App-header">
                                <AppBar position="static" className="WelcomeBar">
                                    <Toolbar>
                                        <table className="WelcomeMenu">
                                            <tbody>
                                                <tr>
                                                    <td className="LogoMenuColumn">
                                                        <Logo />
                                                    </td>
                                                    <td className="HomeMenuColumn">
                                                        <HomeButton />
                                                        |
                                                        <TokalonButton />
                                                    </td>
                                                    <td className="AccountMenuButton">
                                                        { (nome === "" || cognome === "") ? (
                                                            <>
                                                                {/* <SocialButtonsInHeader /> */}
                                                                <Link to="/profile">
                                                                    <Button
                                                                        className="AccountName"
                                                                        size="large"
                                                                        id="user-menu-button"
                                                                        aria-label="account of current user"
                                                                        aria-controls="user-menu"
                                                                        //aria-haspopup="true"
                                                                        //aria-expanded={Boolean(anchorElUserMenu) ? 'true' : undefined}
                                                                        //onClick={this.handleUserMenuClick}
                                                                        color="inherit"
                                                                    >
                                                                        {/* <FontAwesomeIcon icon={faUser} /> */}
                                                                        <span className="WelcomeUser">{/* Bentornato */}</span>
                                                                    </Button>
                                                                </Link>
                                                                |
                                                                <Button
                                                                    className=""
                                                                    size="large"
                                                                    color="inherit"
                                                                    id="logout-button"
                                                                    onClick={this.handleLogoutClick}
                                                                >
                                                                    Esci
                                                                </Button>
                                                            </>
                                                        ): (
                                                            <>
                                                                {/* <SocialButtonsInHeader /> */}
                                                                <Link to="/profile">
                                                                    <Button
                                                                        className=""
                                                                        size="large"
                                                                        id="user-menu-button"
                                                                        aria-label="account of current user"
                                                                        aria-controls="user-menu"
                                                                        //aria-haspopup="true"
                                                                        //aria-expanded={Boolean(anchorElUserMenu) ? 'true' : undefined}
                                                                        //onClick={this.handleUserMenuClick}
                                                                        color="inherit" 
                                                                    >
                                                                        {/* <FontAwesomeIcon icon={faUser} /> */}
                                                                        <span className="WelcomeUser">{/* Bentornato */}</span> { nome + " " + cognome }
                                                                    </Button>
                                                                </Link>
                                                                |
                                                                <Button
                                                                    className=""
                                                                    size="large"
                                                                    color="inherit"
                                                                    id="logout-button"
                                                                    onClick={this.handleLogoutClick}
                                                                >
                                                                    Esci
                                                                </Button>
                                                            </>
                                                        )}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <Menu
                                            id="user-menu"
                                            anchorEl={anchorElUserMenu}
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            keepMounted
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            open={open}
                                            onClose={this.handleCloseUserMenu}
                                            MenuListProps={{
                                                'aria-labelledby': 'user-menu-button',
                                            }}
                                        >
                                            <MenuItem><Link to="/profile">Profilo</Link></MenuItem>
                                            <MenuItem onClick={this.handleLogoutClick}>Esci</MenuItem>
                                        </Menu>
                                    </Toolbar>
                                </AppBar>
                                {/* <Subheader></Subheader> */}

                                {/* <div className="separator"></div> */}
                                <div className="Page-title Orange">Iscrivi una o più classi al Con-corso {nome_progetto} - Edizione {anno_progetto}</div>
                                <div className="Page-subtitle-top" dangerouslySetInnerHTML={{__html:purify.sanitize(descrizione_progetto)}}></div>
                                {/* <div className="Page-subtitle-top" style={{padding: "0 20px 20px 60px"}}>
                                    <ul className="Project-description" style={{maxWidth: "740px", padding: "0 40px"}}>
                                        <li>Puoi inserire più classi, aggiungendo una classe alla volta</li>
                                        <li>per tutte le classi inserite dovrai utilizzare lo stesso metodo di pagamento</li>
                                        <li>se vuoi scegliere metodi di pagamento diversi per le diverse classi (es. alcune con bonifico e altre con Carta del Docente) allora iscrivi prima quella/e con un metodo di pagamento (es. bonifico) e poi quella/e con l’altro (es. Carta del Docente)</li>
                                    </ul>
                                </div> */}
                                <div className="Page-title Orange">1. Riepilogo delle classi iscritte</div>
                                { classi.length === 0 ? (
                                    <div className="Page-subtitle-top">Non hai ancora inserito nessuna classe in questa iscrizione :(</div>
                                ) : (
                                    <TableContainer className="TableContainer">
                                        <Table className="TabellaClassi" sx={{ minWidth: 700 }} aria-label="customized table">
                                            <TableHead className="HeadTabellaClassi">
                                                <TableRow>
                                                    <TableCell>Edizione</TableCell>
                                                    <TableCell align="right">Scuola</TableCell>
                                                    <TableCell align="right">Docenti</TableCell>
                                                    <TableCell align="right">Classe</TableCell>
                                                    <TableCell align="right">Sezione</TableCell>
                                                    <TableCell align="right">Acquisti</TableCell>
                                                    <TableCell align="right">Costo</TableCell>
                                                    <TableCell align="right"></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody className="BodyTabellaClassi">
                                                {classi.map((classe, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell component="td" scope="row"
                                                            data-title="N° Progetto"
                                                        >
                                                            {nome_progetto} {anno_progetto}
                                                        </TableCell>
                                                        <TableCell align="center"
                                                            data-title="Scuola"
                                                        >{classe.nome_scuola}</TableCell>
                                                        <TableCell align="center"
                                                            data-title="Docenti"
                                                        >{classe.nome_docente_referente1}<br />{classe.nome_docente_referente2}</TableCell>
                                                        <TableCell align="center"
                                                            data-title="Classe"
                                                        >{classe.classe}</TableCell>
                                                        <TableCell align="center"
                                                            data-title="Sezione"
                                                        >{classe.sezione}</TableCell>
                                                        <TableCell
                                                            data-title="Acquisti"
                                                        >
                                                            <Table>
                                                                <TableBody>
                                                                    {classe.prodotti.map((prodotto) => (
                                                                        <TableRow key={prodotto.id_prodotto}>
                                                                            <TableCell>{prodotto.quantita} x {prodotto.nome_prodotto} {prodotto.importo_totale}</TableCell>
                                                                        </TableRow>
                                                                    ))}
                                                                </TableBody>
                                                            </Table>
                                                        </TableCell>
                                                        <TableCell align="right"
                                                            data-title="Costo"
                                                        >{classe.importo_netto} €</TableCell>
                                                        <TableCell align="center" style={{minWidth: "90px"}}>
                                                            <IconButton id={"edit_" + index} color="primary" onClick={this.handleEditClassClick} variant="contained" aria-label="edit class">
                                                                <EditIcon></EditIcon>
                                                            </IconButton>
                                                            <IconButton id={index} color="secondary" onClick={this.handleOpenDeleteModal} variant="contained" aria-label="delete class">
                                                                <DeleteIcon></DeleteIcon>
                                                            </IconButton>
                                                            <Modal
                                                                open={opendeletemodal}
                                                                onClose={this.handleCloseDeleteModal}
                                                                aria-labelledby="modal-modal-title"
                                                                aria-describedby="modal-modal-description"
                                                            >
                                                                <Box className="Modal">
                                                                    <Typography id="modal-modal-title" variant="h6" component="h2">
                                                                        Elimina classe
                                                                    </Typography>
                                                                    <Typography id="modal-modal-description">
                                                                        Sei sicuro di voler eliminare questa classe?
                                                                    </Typography>
                                                                    <Button variant="contained" onClick={this.handleCloseDeleteModal}>Annulla</Button>
                                                                    <Button color="secondary" variant="contained" onClick={this.handleDeleteClassClick}>Elimina</Button>
                                                                </Box>
                                                            </Modal>    
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                            <TableFooter>
                                                <TableRow key={9999991} className="first">
                                                    <TableCell rowSpan={4} colSpan={5} className="MobileHide"/>
                                                    <TableCell>Totale classi:</TableCell>
                                                    <TableCell align="right">{importo_netto}€</TableCell>
                                                </TableRow> 
                                                { sconto_sostenitore_enabled && (
                                                <TableRow key={9999992} className="second">
                                                    <TableCell>Sconto sostenitore:</TableCell>
                                                    <TableCell align="right">{sconto > 0 ? - sconto : sconto}€</TableCell>
                                                </TableRow> 
                                                )}
                                                { bollo > 0 && (                            
                                                <TableRow key={9999993} className="first">
                                                    <TableCell>Marca da bollo:</TableCell>
                                                    <TableCell align="right">{bollo}€</TableCell>
                                                </TableRow> 
                                                )}
                                                <TableRow key={9999994} className="third">
                                                    <TableCell>Totale iscrizione:</TableCell>
                                                    <TableCell align="right">{importo_totale}€</TableCell>
                                                </TableRow> 
                                            </TableFooter>
                                        </Table>
                                        {(sconto_sostenitore_enabled && is_supporter === false) && ( 
                                        <Table>
                                            <TableBody>
                                                <Table>  
                                                    <TableBody>  
                                                        <TableRow key={9999995} className="fourth">
                                                            <TableCell rowSpan={1} colSpan={5} className="MobileHide"/>
                                                            <TableCell colSpan={2}>
                                                                <Alert severity="success" className="AlertSpedizione Success">
                                                                    {sconto_percentuale ? (
                                                                        <p>Vuoi uno sconto del {sconto_sostenitore}% su questa iscrizione e vuoi diventare sostenitore? <Link to="/supporter" target="_blank" rel="noopener noreferrer">Clicca qui</Link> e scopri tutti i dettagli</p>
                                                                    ) : (
                                                                        <p>Vuoi uno sconto di {sconto_sostenitore}€ su questa iscrizione e vuoi diventare sostenitore? <Link to="/supporter" target="_blank" rel="noopener noreferrer">Clicca qui</Link> e scopri tutti i dettagli</p>
                                                                    )}
                                                                </Alert>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table> 
                                            </TableBody>
                                        </Table>
                                        )}
                                    </TableContainer>
                                )}
                                { (classi.length > 0 && proceed === false)&& ( 
                                    <div className="Page-subtitle-top">Vuoi iscrivere un'altra classe con lo stesso metodo di pagamento?</div>
                                )}
                                <div className="Form-control Register">
                                    { classi.length > 0 ? ( 
                                        <div>
                                            { proceed === false && ( 
                                                <div>
                                                    <Button size="large" type="submit" variant="contained" color="primary" onClick={this.handleAddClassClick}>Iscrivi un'altra classe</Button>
                                                    <div className="separator"></div>
                                                    <Button size="large" type="submit" variant="contained" color="secondary" onClick={this.handleProceedClick}>No, prosegui con il checkout</Button>
                                                </div>
                                            )}
                                        </div>
                                    ) : (
                                        <Button size="large" type="submit" variant="contained" color="primary" onClick={this.handleAddClassClick}>Iscrivi una classe</Button>
                                    )}
                                </div>
                                {/* { (classi.length > 0 && proceed === true) && (         */}
                                { (classi.length > 0 && proceed === true && importo_totale > 0) && (               
                                <div className="Page-title Orange">2. Scegli un metodo di pagamento</div>
                                )}
                                {/* { (classi.length > 0 && proceed === true) && (  */}
                                { (classi.length > 0 && proceed === true && importo_totale > 0) && ( 
                                <div className="Page-subtitle-top">Seleziona il metodo di pagamento più adatto</div>
                                )}
                                {/* { (classi.length > 0 && proceed === true) && (  */}
                                { (classi.length > 0 && proceed === true && importo_totale > 0) && ( 
                                <Grid container spacing={1} className="Page-button-group">
                                    <Grid item xs={12}>
                                    <FormControl component="fieldset">
                                        <RadioGroup
                                            aria-label="payment"
                                            value={id_metodo_pagamento.toString()}
                                            name="radio-payment-group"
                                            onChange={this.handleChangeMetodoPagamento}
                                        >
                                            <FormControlLabel value="1" control={<Radio />} label="Scuola - Bonifico" />
                                            <FormControlLabel value="2" control={<Radio />} label="Docente - Bonifico" />
                                            { pagamento_carta_docente && (
                                                <FormControlLabel value="3" control={<Radio />} label="Docente - Carta del docente" />
                                            )}
                                            <FormControlLabel value="4" control={<Radio />} label="Altra persona fisica - Bonifico (es. genitore)" />
                                        </RadioGroup>
                                    </FormControl>
                                    </Grid>
                                </Grid> 
                                )}  
                                {(classi.length > 0 && id_metodo_pagamento > 0 && spedizione_attiva) && ( 
                                    <div className="Page-title Orange">3. Conferma le informazioni sulla <strong>Spedizione</strong></div>
                                )}  
                                {(classi.length > 0 && id_metodo_pagamento > 0 && spedizione_attiva) && ( 
                                <div>
                                    {(docente_referente === null) && (
                                        <div className="Page-subtitle-top" style={{width: "93%", padding: "10px 0"}}><strong>ATTENZIONE: Verifica la correttezza dell'indirizzo di spedizione</strong></div>
                                    )}
                                    <div className="Form-label Register" style={{width: "90%", padding: "0px 27px"}}>Codice Meccanografico Scuola</div>
                                    <div className="Form-control Register" style={{width: "90%", padding: "0px 27px"}}>
                                        <TextField id="sped_codice_scuola" inputProps={{maxLength : 255}} disabled placeholder="Codice Meccanografico" variant="outlined" value={ sped_codice_scuola }/>
                                    </div>
                                    <Grid container className="Form-control Register Half" style={{width: "100%", maxWidth: "600px", padding: "0px 27px"}}>
                                        <Grid item xs={6}>
                                            <div className="Form-label Register" style={{width: "100%", padding: "0"}}>Nome</div>    
                                            { (sped_nome_error === false && docente_referente === null) && (
                                                <TextField style={{ width : "95%", paddingRight : "19px" }} inputProps={{maxLength : 255}} id="sped_nome" required placeholder="Nome" variant="outlined" onChange={this.handleChangeSpedNome} value={ sped_nome }/>
                                            )}
                                            { (sped_nome_error && docente_referente === null) && (
                                                <TextField style={{ width : "95%", paddingRight : "19px" }} inputProps={{maxLength : 255}} id="sped_nome" required placeholder="Nome" error helperText="Valore non valido" variant="outlined" onChange={this.handleChangeSpedNome} value={ sped_nome }/>
                                            )}
                                            { (sped_nome_error === false && docente_referente) && (
                                                <TextField style={{ width : "95%", paddingRight : "19px" }} inputProps={{maxLength : 255}} id="sped_nome" disabled placeholder="Nome" variant="outlined" onChange={this.handleChangeSpedNome} value={ sped_nome }/>
                                            )}
                                        </Grid>
                                        <Grid item xs={6} style={{ textAlign: "right"}}>
                                            <div className="Form-label Register" style={{width: "100%", padding: "0", marginLeft: "11px"}}>Cognome</div>    
                                            { (sped_cognome_error === false && docente_referente === null) && (
                                                <TextField style={{ width : "95%"}} id="sped_cognome" inputProps={{maxLength : 255}} required placeholder="Cognome" variant="outlined" onChange={this.handleChangeSpedCognome} value={ sped_cognome }/>
                                            )}
                                            { (sped_cognome_error && docente_referente === null) && (
                                                <TextField style={{ width : "95%"}} id="sped_cognome" inputProps={{maxLength : 255}} required placeholder="Cognome" error helperText="Valore non valido" variant="outlined" onChange={this.handleChangeSpedCognome} value={ sped_cognome }/>
                                            )}
                                            {(sped_cognome_error === false && docente_referente) && (
                                                <TextField style={{ width : "95%"}} id="sped_cognome" inputProps={{maxLength : 255}} disabled placeholder="Cognome" variant="outlined" onChange={this.handleChangeSpedCognome} value={ sped_cognome }/>
                                            )}
                                        </Grid>
                                    </Grid>    
                                    <div className="Form-label Register" style={{width: "90%", padding: "0px 27px"}}>Nome Scuola</div>
                                    <div className="Form-control Register" style={{width: "90%", padding: "0px 27px"}}>
                                        { (sped_nome_scuola_error === false && docente_referente === null ) && (
                                            <TextField id="sped_nome_scuola" inputProps={{maxLength : 255}} required placeholder="Denominazione scuola" variant="outlined" onChange={this.handleChangeSpedNomeScuola} value={ sped_nome_scuola }/>
                                        )}
                                        {(sped_nome_scuola_error && docente_referente === null) && (
                                            <TextField id="sped_nome_scuola" inputProps={{maxLength : 255}} required placeholder="Denominazione scuola" error helperText="Valore non valido" variant="outlined" onChange={this.handleChangeSpedNomeScuola} value={ sped_nome_scuola }/>
                                        )}
                                        {(sped_nome_scuola_error === false && docente_referente) && (
                                            <TextField id="sped_nome_scuola" inputProps={{maxLength : 255}} disabled placeholder="Denominazione scuola" variant="outlined" onChange={this.handleChangeSpedNomeScuola} value={ sped_nome_scuola }/>
                                        )}
                                    </div>
                                    <div className="Form-label Register" style={{width: "90%", padding: "0px 27px"}}>Indirizzo</div>
                                    <div className="Form-control Register" style={{width: "90%", padding: "0px 27px"}}>
                                            { (sped_indirizzo_error && docente_referente === null) && (
                                                <TextField id="sped_indirizzo" inputProps={{maxLength : 255}} required placeholder="Indirizzo" error helperText="Valore non valido" variant="outlined" onChange={this.handleChangeSpedIndirizzo} value={ sped_indirizzo }/>
                                            )}
                                            { (sped_indirizzo_error === false && docente_referente === null) && (
                                                <TextField id="sped_indirizzo" inputProps={{maxLength : 255}} required placeholder="Indirizzo" variant="outlined" onChange={this.handleChangeSpedIndirizzo} value={ sped_indirizzo }/>
                                            )}
                                            {(sped_indirizzo_error === false && docente_referente) && (
                                                <TextField id="sped_indirizzo" inputProps={{maxLength : 255}} disabled placeholder="Indirizzo" variant="outlined" onChange={this.handleChangeSpedIndirizzo} value={ sped_indirizzo }/>
                                            )}
                                    </div>
                                    <div className="Form-label Register" style={{width: "90%", padding: "0px 27px"}}>Città</div>
                                    <div className="Form-control Register" style={{width: "90%", padding: "0px 27px"}}>
                                            { (sped_citta_error && docente_referente === null) && (
                                                <TextField id="sped_citta" inputProps={{maxLength : 255}} required placeholder="Città" error helperText="Valore non valido" variant="outlined" onChange={this.handleChangeSpedCitta} value={ sped_citta }/>
                                            )}
                                            { (sped_citta_error === false && docente_referente === null) && (
                                                <TextField id="sped_citta" inputProps={{maxLength : 255}} required placeholder="Città" variant="outlined" onChange={this.handleChangeSpedCitta} value={ sped_citta }/>
                                            )}
                                            {(sped_citta_error === false && docente_referente) && (
                                                <TextField id="sped_citta" inputProps={{maxLength : 255}} disabled placeholder="Città" variant="outlined" onChange={this.handleChangeSpedCitta} value={ sped_citta }/>
                                            )}
                                    </div>
                                    <div className="Form-label Register" style={{width: "90%", padding: "0px 27px"}}>Provincia</div>
                                    <div className="Form-control Register" style={{width: "90%", padding: "0px 27px"}}>
                                        { (sped_provincia_error && docente_referente === null ) && ( 
                                            <FormControl variant="outlined" className="Form-control" error>   
                                                <Select
                                                    native
                                                    value={sped_provincia}
                                                    onChange={this.handleChangeSpedProvincia}
                                                >
                                                    { Object.keys(this.getListaProvincie()).map((keyName, keyIndex) => (
                                                        <option key={keyName} value={keyName}>{this.getListaProvincie()[keyName]}</option>
                                                    ))}
                                                </Select>
                                                <FormHelperText>Valore non valido</FormHelperText>
                                            </FormControl>
                                        )}
                                        { (sped_provincia_error === false && docente_referente === null) && (
                                            <FormControl variant="outlined" className="Form-control">   
                                                <Select
                                                    native
                                                    value={sped_provincia}
                                                    onChange={this.handleChangeSpedProvincia}
                                                >
                                                    { Object.keys(this.getListaProvincie()).map((keyName, keyIndex) => (
                                                        <option key={keyName} value={keyName}>{this.getListaProvincie()[keyName]}</option>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        )}   
                                        {(sped_provincia_error === false && docente_referente) && (
                                            <FormControl variant="outlined" className="Form-control">   
                                                <Select
                                                    native
                                                    value={sped_provincia}
                                                    onChange={this.handleChangeSpedProvincia}
                                                    disabled
                                                >
                                                    { Object.keys(this.getListaProvincie()).map((keyName, keyIndex) => (
                                                        <option key={keyName} value={keyName}>{this.getListaProvincie()[keyName]}</option>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        )}
                                    </div>
                                    <div className="Form-label Register" style={{width: "90%", padding: "0px 27px"}}>Cap</div>
                                    <div className="Form-control Register" style={{width: "90%", padding: "0px 27px"}}>
                                            { (sped_cap_error && docente_referente === null ) && (
                                                <TextField id="sped_cap" inputProps={{maxLength : 5}} required placeholder="Cap" error helperText="Valore non valido" variant="outlined" onChange={this.handleChangeSpedCap} value={ sped_cap }/>
                                            )}
                                            { (sped_cap_error === false && docente_referente === null) && (
                                                <TextField id="sped_cap" inputProps={{maxLength : 5}} required placeholder="Cap" variant="outlined" onChange={this.handleChangeSpedCap} value={ sped_cap }/>
                                            )}
                                            {(sped_cap_error === false && docente_referente) && (
                                                <TextField id="sped_cap" inputProps={{maxLength : 5}} disabled placeholder="Cap" variant="outlined" onChange={this.handleChangeSpedCap} value={ sped_cap }/>
                                            )}
                                    </div>
                                    { docente_referente ? (
                                        <div className="Form-label Register Hidden" style={{width: "90%", padding: "0px 27px"}}>Cellulare</div>
                                    ): ( 
                                        <div className="Form-label Register" style={{width: "90%", padding: "0px 27px"}}>Cellulare</div>
                                    )}
                                    { docente_referente ? (
                                        <div className="Form-control Register Hidden" style={{width: "90%", padding: "0px 27px"}}>
                                            <TextField id="sped_cellulare" inputProps={{maxLength : 20}} disabled placeholder="Cellulare" variant="outlined" onChange={this.handleChangeSpedCellulare} value={ sped_cellulare }/>
                                        </div>
                                    ): (
                                    <div className="Form-control Register" style={{width: "90%", padding: "0px 27px"}}>
                                            { (sped_cellulare_error && docente_referente === null) && (
                                                <TextField id="sped_cellulare" inputProps={{maxLength : 20}} required placeholder="Cellulare" error helperText="Valore non valido" variant="outlined" onChange={this.handleChangeSpedCellulare} value={ sped_cellulare }/>
                                            )}
                                            { (sped_cellulare_error === false && docente_referente === null) && (
                                                <TextField id="sped_cellulare" inputProps={{maxLength : 20}} required placeholder="Cellulare" variant="outlined" onChange={this.handleChangeSpedCellulare} value={ sped_cellulare }/>
                                            )}
                                            {(sped_cellulare_error === false && docente_referente) && (
                                                <TextField id="sped_cellulare" inputProps={{maxLength : 20}} disabled placeholder="Cellulare" variant="outlined" onChange={this.handleChangeSpedCellulare} value={ sped_cellulare }/>
                                            )}
                                    </div>
                                    )}
                                    <div className="Form-label Register" style={{width: "90%", padding: "0px 27px"}}>Note consegna</div>
                                    <div className="Form-control Register" style={{width: "90%", padding: "0px 27px"}}>
                                            { (note_consegna_error && docente_referente === null) && (
                                                <TextField id="note_consegna" inputProps={{maxLength : 255}} required placeholder="Note per la consegna" error helperText="Valore non valido" variant="outlined" onChange={this.handleChangeNoteConsegna} value={ note_consegna }/>
                                            )}
                                            { (note_consegna_error === false && docente_referente === null) && (
                                                <TextField id="note_consegna" inputProps={{maxLength : 255}} required placeholder="Note per la consegna" variant="outlined" onChange={this.handleChangeNoteConsegna} value={ note_consegna }/>
                                            )}
                                            {(note_consegna_error === false && docente_referente) && (
                                                <TextField id="note_consegna" inputProps={{maxLength : 255}} required placeholder="Note per la consegna" variant="outlined" onChange={this.handleChangeNoteConsegna} value={ note_consegna }/>
                                            )}
                                    </div>
                                </div>
                                )}
                                { (docente_referente && classi.length > 0 && id_metodo_pagamento > 0 && spedizione_attiva) && (
                                    <Alert severity="warning" className="AlertSpedizione">
                                        <AlertTitle className="AlertTitle"><strong>ATTENZIONE</strong></AlertTitle>
                                        <p>Per questa scuola sono già state effettuate una o più iscrizioni in fase di spedizione per questo progetto: "{nome_progetto} - Edizione {anno_progetto}".</p>
                                        <p>L'indirizzo di spedizione può essere modificato solo dal docente "<strong>{nome_docente_referente} {cognome_docente_referente} - {grado_docente_referente}</strong>" nella sua homepage cliccando sul pulsante "Modifica Spedizione Scuola".</p>
                                        <p>Se non sei {nome_docente_referente} {cognome_docente_referente} contattalo, concordate il plesso in cui volete che effettuata la spedizione di tutte le iscrizioni associati a questo progetto e chiedi a lui di aggiornare i dati di spedizione.</p>
                                    </Alert>
                                )}
                                { (classi.length > 0 && (id_metodo_pagamento === 2 || id_metodo_pagamento === 3 )) && (
                                    <>  
                                        { spedizione_attiva ? (
                                            <div className="Page-title Orange">4. Conferma le informazioni sulla <strong>Fatturazione</strong></div>
                                        ) : (
                                            <div className="Page-title Orange">3. Conferma le informazioni sulla <strong>Fatturazione</strong></div>
                                        )}
                                    </>
                                )}
                                { (classi.length > 0 && (id_metodo_pagamento === 2 || id_metodo_pagamento === 3) ) && (
                                    <Grid container className="Form-control Register Half" style={{padding: "0px"}}>
                                        <Grid item xs={6}>
                                            <div className="Form-label Register" style={{width: "100%", padding: "0"}}>Nome</div>    
                                            { fatt_nome_error ? (
                                                <TextField id="fatt_nome" style={{ width : "95%", paddingRight : "19px" }} inputProps={{maxLength : 255}} required placeholder="Nome" error helperText="Valore non valido" variant="outlined" onChange={this.handleChangeFattNome} value={ fatt_nome }/>
                                            ) : (
                                                <TextField id="fatt_nome" style={{ width : "95%", paddingRight : "19px" }} inputProps={{maxLength : 255}} required placeholder="Nome" variant="outlined" onChange={this.handleChangeFattNome} value={ fatt_nome }/>
                                            )}
                                        </Grid>
                                        <Grid item xs={6} style={{ textAlign: "right"}}>
                                            <div className="Form-label Register" style={{width: "100%", padding: "0", paddingLeft: "10px"}}>Cognome</div>    
                                            { fatt_cognome_error ? (
                                                <TextField id="fatt_cognome" style={{ width : "95%" }} inputProps={{maxLength : 255}} required placeholder="Cognome" error helperText="Valore non valido" variant="outlined" onChange={this.handleChangeFattCognome} value={ fatt_cognome }/>
                                            ) : (
                                                <TextField id="fatt_cognome" style={{ width : "95%" }} inputProps={{maxLength : 255}} required placeholder="Cognome" variant="outlined" onChange={this.handleChangeFattCognome} value={ fatt_cognome }/>
                                            )}
                                        </Grid>
                                    </Grid>
                                )}
                                {/* { (classi.length > 0 && (id_metodo_pagamento === 2 || id_metodo_pagamento === 3) ) && (
                                    <div className="Form-control Register" style={{padding: "0"}}>
                                            <div className="Form-label Register" style={{width: "100%", padding: "0px"}}>Nome scuola</div>
                                            { fatt_nome_scuola_error ? (
                                                <TextField id="fatt_nome_scuola" inputProps={{maxLength : 16}} required placeholder="Denominazione scuola" error helperText="Valore non valido" variant="outlined" onChange={this.handleChangeFattNomeScuola} value={ fatt_nome_scuola }/>
                                            ) : (
                                                <TextField id="fatt_nome_scuola" inputProps={{maxLength : 16}} required placeholder="Denominazione scuola" variant="outlined" onChange={this.handleChangeFattNomeScuola} value={ fatt_nome_scuola }/>
                                            )}
                                    </div>
                                )} */}
                                { (classi.length > 0 && (id_metodo_pagamento === 2 || id_metodo_pagamento === 3) ) && (
                                    <div className="Form-control Register" style={{padding: "0"}}>
                                            <div className="Form-label Register" style={{width: "100%", padding: "0px"}}>Codice Fiscale</div>
                                            { fatt_cf_piva_error ? (
                                                <TextField id="sped_cf_piva" inputProps={{maxLength : 16}} required placeholder="Codice Fiscale" error helperText="Valore non valido" variant="outlined" onChange={this.handleChangeFattCfPiva} value={ fatt_cf_piva }/>
                                            ) : (
                                                <TextField id="sped_cf_piva" inputProps={{maxLength : 16}} required placeholder="Codice Fiscale" variant="outlined" onChange={this.handleChangeFattCfPiva} value={ fatt_cf_piva }/>
                                            )}
                                    </div>
                                )}
                                { (classi.length > 0 && (id_metodo_pagamento === 2 || id_metodo_pagamento === 3) ) && (
                                    <div className="Form-control Register" style={{padding: "0"}}>
                                            <div className="Form-label Register" style={{width: "100%", padding: "0px"}}>Indirizzo</div>
                                            { fatt_indirizzo_error ? (
                                                <TextField id="fatt_indirizzo" inputProps={{maxLength : 255}} required placeholder="Indirizzo" error helperText="Valore non valido" variant="outlined" onChange={this.handleChangeFattIndirizzo} value={ fatt_indirizzo }/>
                                            ) : (
                                                <TextField id="fatt_indirizzo" inputProps={{maxLength : 255}} required placeholder="Indirizzo" variant="outlined" onChange={this.handleChangeFattIndirizzo} value={ fatt_indirizzo }/>
                                            )}
                                    </div>
                                )}
                                { (classi.length > 0 && (id_metodo_pagamento === 2 || id_metodo_pagamento === 3) ) && (
                                    <div className="Form-control Register" style={{padding: "0"}}>
                                            <div className="Form-label Register" style={{width: "100%", padding: "0px"}}>Città</div>
                                            { fatt_citta_error ? (
                                                <TextField id="fatt_citta" inputProps={{maxLength : 255}} required placeholder="Città" error helperText="Valore non valido" variant="outlined" onChange={this.handleChangeFattCitta} value={ fatt_citta }/>
                                            ) : (
                                                <TextField id="fatt_citta" inputProps={{maxLength : 255}} required placeholder="Città" variant="outlined" onChange={this.handleChangeFattCitta} value={ fatt_citta }/>
                                            )}
                                    </div>
                                )}
                                { (classi.length > 0 && (id_metodo_pagamento === 2 || id_metodo_pagamento === 3) ) && (    
                                    <div className="Form-control Register" style={{padding: "0"}}>
                                        <div className="Form-label Register" style={{width: "100%", padding: "0px"}}>Provincia</div>
                                        { fatt_provincia_error ? ( 
                                            <FormControl variant="outlined" className="Form-control" error>    
                                                <Select
                                                    native
                                                    value={fatt_provincia}
                                                    onChange={this.handleChangeFattProvincia}
                                                >
                                                    { Object.keys(this.getListaProvincie()).map((keyName, keyIndex) => (
                                                        <option key={keyName} value={keyName}>{this.getListaProvincie()[keyName]}</option>
                                                    ))}
                                                </Select>
                                                <FormHelperText>Valore non valido</FormHelperText>
                                            </FormControl>
                                        ) : (
                                            <FormControl variant="outlined" className="Form-control">    
                                                <Select
                                                    native
                                                    value={fatt_provincia}
                                                    onChange={this.handleChangeFattProvincia}
                                                >
                                                    { Object.keys(this.getListaProvincie()).map((keyName, keyIndex) => (
                                                        <option key={keyName} value={keyName}>{this.getListaProvincie()[keyName]}</option>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        )}     
                                    </div>
                                )}
                                { (classi.length > 0 && (id_metodo_pagamento === 2 || id_metodo_pagamento === 3) ) && (    
                                    <div className="Form-control Register" style={{padding: "0"}}>
                                        <div className="Form-label Register" style={{width: "100%", padding: "0px"}}>Cap</div>
                                        { fatt_cap_error ? (
                                            <TextField id="fatt_cap" inputProps={{maxLength : 5}} required placeholder="Cap" error helperText="Valore non valido" variant="outlined" onChange={this.handleChangeFattCap} value={ fatt_cap }/>
                                        ) : (
                                            <TextField id="fatt_cap" inputProps={{maxLength : 5}} required placeholder="Cap" variant="outlined" onChange={this.handleChangeFattCap} value={ fatt_cap }/>
                                        )}
                                    </div>
                                )}
                                { isloading && (
                                    <CircularProgress className="Progress"/>
                                )}
                                { error && (
                                    <div className="Error-message">{ errormessage }</div>
                                )}
                                {/* {(classi.length > 0 && id_metodo_pagamento > 0) && (  */}
                                {(classi.length > 0 && (id_metodo_pagamento > 0 || (proceed == true && importo_totale == 0))) && ( 
                                <div className="Form-control Register">
                                    { orderenabled ? (
                                        <Button size="large" type="submit" variant="contained" color="primary" onClick={this.handleOrderClick}>INVIA LA DOMANDA D'ISCRIZIONE E RICEVI LE ISTRUZIONI PER IL PAGAMENTO</Button>
                                    ) : (
                                        <Button size="large" type="submit" disabled variant="contained" color="primary" onClick={this.handleOrderClick}>INVIA LA DOMANDA D'ISCRIZIONE E RICEVI LE ISTRUZIONI PER IL PAGAMENTO</Button>
                                    )}    
                                </div>
                                )}
                                <div className="separator"></div>
                                {classi.length === 0 && (
                                    <div className="adaptive"></div>
                                )}
                            </header>
                            <AppBar position="static" className="Footer">
                                <div>© Copyright {new Date().getFullYear()} – Associazione ToKalon - <Link to="/privacy">Privacy Policy</Link> - Realizzato da <a href="https://www.creab.it/" target="_blank" rel="noreferrer">Creabit.</a></div>
                            </AppBar>
                        </div>
                        ): (
                            <CircularProgress className="Progress"/>
                        )}
                    </div>
                )}
                { (autenticato && addclassenabled) && (
                    <div className="App">
                        {( isloading === false) ? (
                        <div>
                            <header className="App-header">
                                <AppBar position="static" className="WelcomeBar">
                                    <Toolbar>
                                        <table className="WelcomeMenu">
                                            <tbody>
                                                <tr>
                                                    <td className="LogoMenuColumn">
                                                        <Logo />
                                                    </td>
                                                    <td className="HomeMenuColumn">
                                                        <HomeButton />
                                                        |
                                                        <TokalonButton />
                                                    </td>
                                                    <td className="AccountMenuButton">
                                                        { (nome === "" || cognome === "") ? (
                                                            <>
                                                               {/*  <SocialButtonsInHeader /> */}
                                                                <Link to="/profile">
                                                                    <Button
                                                                        className="AccountName"
                                                                        size="large"
                                                                        id="user-menu-button"
                                                                        aria-label="account of current user"
                                                                        aria-controls="user-menu"
                                                                        //aria-haspopup="true"
                                                                        //aria-expanded={Boolean(anchorElUserMenu) ? 'true' : undefined}
                                                                        //onClick={this.handleUserMenuClick}
                                                                        color="inherit"
                                                                    >
                                                                        {/* <FontAwesomeIcon icon={faUser} /> */}
                                                                        <span className="WelcomeUser">{/* Bentornato */}</span>
                                                                    </Button>
                                                                </Link>
                                                                |
                                                                <Button
                                                                    className=""
                                                                    size="large"
                                                                    color="inherit"
                                                                    id="logout-button"
                                                                    onClick={this.handleLogoutClick}
                                                                >
                                                                    Esci
                                                                </Button>
                                                            </>
                                                        ): (
                                                            <>
                                                                {/* <SocialButtonsInHeader /> */}
                                                                <Link to="/profile">
                                                                    <Button
                                                                        className="AccountName"
                                                                        size="large"
                                                                        id="user-menu-button"
                                                                        aria-label="account of current user"
                                                                        aria-controls="user-menu"
                                                                        //aria-haspopup="true"
                                                                        //aria-expanded={Boolean(anchorElUserMenu) ? 'true' : undefined}
                                                                        //onClick={this.handleUserMenuClick}
                                                                        color="inherit" 
                                                                    >
                                                                        {/* <FontAwesomeIcon icon={faUser} /> */}
                                                                        <span className="WelcomeUser">{/* Bentornato */}</span> { nome + " " + cognome }
                                                                    </Button>
                                                                </Link>
                                                                |
                                                                <Button
                                                                    className=""
                                                                    size="large"
                                                                    color="inherit"
                                                                    id="logout-button"
                                                                    onClick={this.handleLogoutClick}
                                                                >
                                                                    Esci
                                                                </Button>
                                                            </>
                                                        )}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <Menu
                                            id="user-menu"
                                            anchorEl={anchorElUserMenu}
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            keepMounted
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            open={open}
                                            onClose={this.handleCloseUserMenu}
                                            MenuListProps={{
                                                'aria-labelledby': 'user-menu-button',
                                            }}
                                        >
                                            <MenuItem><Link to="/profile">Profilo</Link></MenuItem>
                                            <MenuItem onClick={this.handleLogoutClick}>Esci</MenuItem>
                                        </Menu>
                                    </Toolbar>
                                </AppBar>
                                {/* <Subheader></Subheader>   */}          
                                
                                <Box>
                                    {/* <div className="separator"></div> */}
                                    <div className="Page-title Orange">Aggiungi una classe a {nome_progetto} - Edizione {anno_progetto}</div>
                                    <div className="Page-subtitle-top">Inserisci una nuova classe</div>
                                    <div className="Page-title Orange">1.A Scegli la Classe</div>
                                    <Grid container className="Form-control" style={{width: "90%", maxWidth: "480px", margin: "0 auto"}}>
                                        <Grid item xs={8} style={{textAlign : "center", fontSize: "18px"}}>
                                            Classe
                                        </Grid>
                                        <Grid item xs={4} style={{textAlign : "center", fontSize: "18px"}}>
                                            Sezione
                                        </Grid>
                                    </Grid>
                                    <Grid container className="Form-control" style={{width: "90%", maxWidth: "480px", margin: "0 auto"}}>
                                        <Grid item xs={8} style={{textAlign : "center", fontSize: "18px"}}>
                                            { sub_classe_error ? (
                                                <FormControl variant="outlined" className="Form-control" error>   
                                                    <Select
                                                        native
                                                        value={sub_classe}
                                                        onChange={this.sub_handleChangeClasse}
                                                    >
                                                        { Object.keys(this.sub_getListaClassi()).map((keyName, keyIndex) => (
                                                            <option key={keyName} value={keyName}>{this.sub_getListaClassi()[keyName]}</option>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            ) : (
                                            <FormControl variant="outlined" className="Form-control">   
                                                <Select
                                                    native
                                                    value={sub_classe}
                                                    onChange={this.sub_handleChangeClasse}
                                                >
                                                    { Object.keys(this.sub_getListaClassi()).map((keyName, keyIndex) => (
                                                        <option key={keyName} value={keyName}>{this.sub_getListaClassi()[keyName]}</option>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            )}
                                        </Grid>
                                        <Grid item xs={4} style={{textAlign : "center", fontSize: "18px"}}>
                                        { sub_sezione_error ? (
                                             <FormControl variant="outlined" className="Form-control" error>   
                                                <Select
                                                    native
                                                    value={sub_sezione}
                                                    onChange={this.sub_handleChangeSezione}
                                                >
                                                    { Object.keys(this.sub_getListaSezioni()).map((keyName, keyIndex) => (
                                                        <option key={keyName} value={keyName}>{this.sub_getListaSezioni()[keyName]}</option>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        ) : (
                                            <FormControl variant="outlined" className="Form-control">   
                                                <Select
                                                    native
                                                    value={sub_sezione}
                                                    onChange={this.sub_handleChangeSezione}
                                                >
                                                    { Object.keys(this.sub_getListaSezioni()).map((keyName, keyIndex) => (
                                                        <option key={keyName} value={keyName}>{this.sub_getListaSezioni()[keyName]}</option>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        )}
                                        </Grid>
                                    </Grid>
                                    { (sub_classe_error && sub_sezione_error) && (
                                        <Grid container className="Form-control" style={{width: "90%", maxWidth: "480px", margin: "0 auto"}}>
                                            <div className="Form-control Register" style={{color: "red", textAlign: "center", fontSize: "18px"}}>Attenzione! Questa classe risulta già iscritta a questo progetto. Prosegui solo se hai la certezza che sia la scelta corretta (es: nelle scuole con più plessi ci sono sezioni che si ripetono).</div>
                                        </Grid>
                                    ) }
                                    {/* { (classi.length > 0 || (classi.length === 0 && ordini_precedenti > 0)) && ( */}
                                        <div className="Page-title Orange">1.B Inserisci i dati del docente di classe o di sostegno</div>
                                    {/* )} */}
                                    <Grid container spacing={1} style={{textAlign: "center"}}>
                                        <Grid item xs={12} className="Form-control Register" style={{margin: "0 auto"}}>
                                            {/* <div className="Form-control Register" style={{textAlign: "left", fontSize: "18px"}}><strong>DOCENTE REFERENTE</strong></div>
                                            <div className="Form-control Register" style={{textAlign: "left", fontSize: "18px"}}>Stai effettuando l'iscrizione per una tua classe o per quella di un tuo collega?</div> */}
                                            {/* <FormControl component="fieldset" className="Form-control Register" style={{width: "98%"}}>
                                                <RadioGroup
                                                    aria-label="signuptype"
                                                    value={sub_tipo_iscrizione}
                                                    name="radio-signuptype-group"
                                                    onChange={this.sub_handleChangeTipoIscrizione}
                                                >
                                                    <FormControlLabel value="docente" control={<Radio />} style={{width: "98%"}} label="E' una mia classe" />
                                                    <FormControlLabel value="collega" control={<Radio />} style={{width: "98%"}} label="E' la classe di un mio collega" />
                                                </RadioGroup>
                                            </FormControl> */}
                                            {/* { (classi.length > 0 || (classi.length === 0 && ordini_precedenti > 0)) && ( */}
                                                <div className="Form-control Register" style={{textAlign: "left", fontSize: "18px"}}>Se tu che stai facendo l’iscrizione sei il docente di questa classe e non ci sono altri docenti (es. sostegno) lascia in bianco. Altrimenti inserisci qui i dati del docente della classe oppure di un secondo docente della classe (ad es: sostegno).</div>
                                            {/* )} */}
                                            {/* { (classi.length > 0 || (classi.length === 0 && ordini_precedenti > 0)) && (   */}  
                                                <div className="Form-control Register">
                                                    { sub_nome_error ? (
                                                        <TextField id="nome_referente2" style={{padding: "0px"}} inputProps={{maxLength : 255}} placeholder="Nome" error helperText="Valore non valido" variant="outlined" onChange={this.sub_handleChangeNomeReferente2} value={ sub_nome_referente2 }/>
                                                    ) : (
                                                        <TextField id="nome_referente2" style={{padding: "0px"}} inputProps={{maxLength : 255}} placeholder="Nome" variant="outlined" onChange={this.sub_handleChangeNomeReferente2} value={ sub_nome_referente2 }/>
                                                    )}
                                                </div>
                                            {/* )} */}
                                            {/* { (classi.length > 0 || (classi.length === 0 && ordini_precedenti > 0)) && (    */} 
                                                <div className="Form-control Register">
                                                    { sub_cognome_error ? (
                                                        <TextField id="cognome_referente2" style={{padding: "0px"}} inputProps={{maxLength : 255}} placeholder="Cognome" error helperText="Valore non valido" variant="outlined" onChange={this.sub_handleChangeCognomeReferente2} value={ sub_cognome_referente2 }/>
                                                    ) : (
                                                        <TextField id="cognome_referente2" inputProps={{maxLength : 255}} placeholder="Cognome" variant="outlined" onChange={this.sub_handleChangeCognomeReferente2} value={ sub_cognome_referente2 }/>
                                                    )}
                                                </div>
                                            {/* )} */}
                                            {/* { (classi.length > 0 || (classi.length === 0 && ordini_precedenti > 0)) && (  */}   
                                                <div className="Form-control Register">
                                                    { (sub_email_error || sub_email_exist_error) ? (
                                                        <TextField id="email_referente2" style={{padding: "0px"}} inputProps={{maxLength : 255}} placeholder="Email" error helperText="Valore non valido" variant="outlined" onChange={this.sub_handleChangeEmailReferente2} value={ sub_email_referente2 }/>
                                                    ) : (
                                                        <TextField id="email_referente2" style={{padding: "0px"}} inputProps={{maxLength : 255}} placeholder="Email" variant="outlined" onChange={this.sub_handleChangeEmailReferente2} value={ sub_email_referente2 }/>
                                                    )}
                                                </div>
                                            {/* )} */}
                                            { sub_email_exist_error && (
                                                <div className="Form-control Register" style={{color: "red", textAlign: "center", fontSize: "18px"}}>Attenzione! Questo docente è già associato a una classe per questo progetto. Devi inserire un nuovo docente.</div>
                                            )}
                                        </Grid>
                                    </Grid>   
                                    <div className="Page-title Orange">2. Scegli la modalità di iscrizione</div>
                                    <Modal
                                        open={openimagemodal}
                                        onClose={this.handleCloseImageModal}
                                        aria-labelledby="modal-image-title"
                                        aria-describedby="modal-image-description"
                                    >
                                        <Box className="Modal"
                                            style={{width: "auto"}}
                                            onClick={this.sub_handleCloseImageModal}>
                                            { image_to_show !== "" && (
                                                <img
                                                    src={image_to_show}
                                                    alt="lightbox-product-img"
                                                    loading="lazy"
                                                    width={image_hd_width}
                                                />
                                            )}
                                        </Box>
                                    </Modal> 
                                    {/* Vecchio sistema */}
                                    {/* <Grid container spacing={1} style={{textAlign: "center"}}>
                                        <Grid item xs={12} className="Form-control Register" style={{margin: "0 auto"}}>
                                            <div className="Form-control Register" style={{textAlign: "left", fontSize: "18px"}}><strong>Scegli un prodotto principale (obbligatorio)</strong></div>
                                            <FormControl variant="outlined" className="Form-control Register">  
                                                <Select
                                                    native
                                                    value={sub_id_prodotto_prim_selezionato}
                                                    onChange={this.sub_handleChangeProdottoPrimario}
                                                >
                                                    { sub_prodotti_primari.map((keyName, keyIndex) => (
                                                        <option key={sub_prodotti_primari[keyIndex].id} value={sub_prodotti_primari[keyIndex].id} data-titolo={sub_prodotti_primari[keyIndex].titolo} data-importo={sub_prodotti_primari[keyIndex].importo}>1 {sub_prodotti_primari[keyIndex].titolo} - {sub_prodotti_primari[keyIndex].importo}€</option>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container className="Form-control" style={{width: "90%", maxWidth: "800px", margin: "0 auto"}}>
                                        <Grid item xs={0} sm={0} md={2} lg={2} xl={2} style={{textAlign : "center", fontSize: "14px", padding: "5px", marginTop: "4px"}}>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={5} lg={5} xl={5} style={{textAlign : "center", fontSize: "18px"}}>
                                            { sub_immagine_prodotto_prim_selezionato !== "" && (
                                                <img
                                                    src={sub_immagine_prodotto_prim_selezionato} 
                                                    alt="primary-product-img"
                                                    loading="lazy"
                                                    width="300px"
                                                    id={"img_"+ sub_id_prodotto_prim_selezionato}
                                                    onClick={this.sub_handleOpenImageModal}
                                                />
                                            )}
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={5} lg={5} xl={5} style={{paddingLeft: "10px", fontSize: "14px"}}>
                                            <div style={{maxWidth: "420px", margin: "0 auto"}} dangerouslySetInnerHTML={{__html:purify.sanitize(sub_descrizione_prodotto_prim_selezionato)}}></div>
                                            <div style={{maxWidth: "420px", margin: "0 auto", color: "green"}}><strong>Costo: {sub_importo_prodotto_prim_selezionato}€</strong></div>
                                        </Grid>
                                    </Grid> */}
                                    {/* Fine vecchio sistema  */}   
                                    {/* Nuovo sistema  */}   
                                    <Grid container spacing={1} style={{textAlign: "center"}}>
                                        <Grid item xs={12} className="Form-control Register" style={{margin: "0 auto"}}>
                                            <div className="Form-control Register" style={{textAlign: "center", fontSize: "18px"}}><strong>Scegli una delle seguenti modalità di iscrizione</strong></div>
                                        </Grid>
                                    </Grid>
                                    <div className="Form-control Register" style={{width: "90%", maxWidth: "800px", margin: "0px auto"}}>    
                                        <FormControl component="div">
                                            <RadioGroup
                                                aria-label="primary-product"
                                                value={sub_id_prodotto_prim_selezionato}
                                                name="radio-primaryproduct-group"
                                                onChange={this.sub_handleChangeProdottoPrimario}
                                            >
                                                { sub_prodotti_primari.map((keyName, keyIndex) => (        
                                                <Grid key={keyIndex} container style={{width: "90%", maxWidth: "800px", margin: "0 auto"}}>
                                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} style={{textAlign : "left", fontSize: "14px", padding: "5px", margin: "0px"}}>
                                                        <FormControlLabel
                                                            value={sub_prodotti_primari[keyIndex].id}
                                                            control={<Radio 
                                                                inputProps={{
                                                                    "data-titolo" : sub_prodotti_primari[keyIndex].titolo,
                                                                    "data-importo" : sub_prodotti_primari[keyIndex].importo
                                                                }}   
                                                            />}
                                                            style={{width: "98%"}} label={sub_prodotti_primari[keyIndex].titolo + " - "+ sub_prodotti_primari[keyIndex].importo + "€"}
                                                        />
                                                        <img
                                                            src={sub_prodotti_primari[keyIndex].immagine} 
                                                            alt="primary-product-img"
                                                            loading="lazy"
                                                            width="300px"
                                                            id={"img_"+ sub_prodotti_primari[keyIndex].id}
                                                            onClick={this.sub_handleOpenImageModal}
                                                            style={{marginTop: "10px"}}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}  style={{textAlign: "left", paddingLeft: "10px", fontSize: "14px", padding: "5px", margin: "0"}}>
                                                        <div style={{maxWidth: "420px", margin: "0 auto"}} dangerouslySetInnerHTML={{__html:purify.sanitize(sub_prodotti_primari[keyIndex].descrizione)}}></div>    
                                                    </Grid>
                                                </Grid>
                                                    ))}
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                
                                    {/* Fine nuovo sistema  */}      
                                    <Divider className="Divider"/>
                                    { sub_prodotti_secondari.length > 0 && (
                                    <Grid container spacing={1} style={{textAlign: "center"}}>
                                        <Grid item xs={12} className="Form-control Register" style={{margin: "0 auto"}}>
                                            <div className="Form-control Register" style={{textAlign: "left", fontSize: "18px"}}><strong>Aggiungi uno o più materiali EXTRA</strong></div>
                                        </Grid>
                                    </Grid>
                                    )}
                                    { sub_prodotti_secondari.map((keyName, keyIndex) => (        
                                        <Grid key={keyIndex} container style={{width: "90%", maxWidth: "800px", margin: "0 auto"}}>
                                            <Grid item xs={4} sm={3} md={2} lg={2} xl={2} style={{textAlign : "center", fontSize: "14px", padding: "5px", margin: "0 auto", maxWidth: "420px"}}>
                                                <div style={{display: "inline", float: "left"}}>
                                                <TextField 
                                                    className="qty-secondary-field" 
                                                    inputProps={{
                                                        "data-product" : keyIndex,
                                                        "min" : 0,
                                                        "max" : 999
                                                    }}                                
                                                    type="number"
                                                    required variant="outlined"
                                                    onChange={this.sub_handleChangeQuantitaProdottoSecondario}
                                                    value={ sub_prodotti_secondari[keyIndex].quantita || 0 }/>
                                                </div>
                                            </Grid>
                                            <Grid item xs={8} sm={9} md={5} lg={5} xl={5} style={{textAlign : "center", fontSize: "14px", padding: "5px", margin: "0 auto", maxWidth: "420px"}}>
                                                <div style={{display: "inline", float: "left", marginLeft: "20px", marginTop: "10px"}}>
                                                    {sub_prodotti_secondari[keyIndex].titolo || ""} - {sub_prodotti_secondari[keyIndex].importo || ""}€
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={5} lg={5} xl={5}  style={{textAlign: "left", paddingLeft: "10px", fontSize: "14px", padding: "5px", margin: "0"}}>
                                                <div style={{maxWidth: "420px", margin: "0 auto"}} dangerouslySetInnerHTML={{__html:purify.sanitize(sub_prodotti_secondari[keyIndex].descrizione)}}></div>
                                            </Grid>
                                            <Grid item /*xs={0} sm={0}*/ md={2} lg={2} xl={2} style={{textAlign : "center", fontSize: "14px", padding: "5px", marginTop: "4px"}}>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={5} lg={5} xl={5}  style={{textAlign: "center", paddingLeft: "10px", fontSize: "14px", padding: "5px", margin: "0"}}>
                                                { sub_prodotti_secondari[keyIndex].immagine !== "" && (
                                                    <img
                                                        src={sub_prodotti_secondari[keyIndex].immagine} 
                                                        alt="primary-product-img"
                                                        loading="lazy"
                                                        width="300px"
                                                        id={"img_"+ sub_prodotti_secondari[keyIndex].id}
                                                        onClick={this.sub_handleOpenImageModal}
                                                        style={{marginTop: "10px"}}
                                                    />
                                                )}
                                            </Grid>
                                            <Divider className="Divider"/>
                                        </Grid>
                                    ))}
                                    { sub_id_prodotto_prim_selezionato != 2 && (
                                    <Box>
                                    { sub_prodotti_regalo.length > 0 && (
                                    <Grid container spacing={1} style={{textAlign: "center"}}>
                                        <Grid item xs={12} className="Form-control Register" style={{margin: "0 auto"}}>
                                            <div className="Form-control Register" style={{textAlign: "left", fontSize: "18px"}}><strong>Prodotti in regalo</strong></div>
                                        </Grid>
                                    </Grid>
                                    )}
                                    { sub_prodotti_regalo.map((keyName, keyIndex) => (        
                                        <Grid key={keyIndex} container style={{width: "90%", maxWidth: "800px", margin: "0 auto"}}>
                                            <Grid item xs={4} sm={3} md={2} lg={2} xl={2} style={{textAlign : "center", fontSize: "14px", padding: "5px", margin: "0 auto", maxWidth: "420px"}}>
                                                <div style={{display: "inline", float: "left", marginLeft: "20px", marginTop: "10px"}}>
                                                { 1 }
                                                </div>
                                            </Grid>
                                            <Grid item xs={8} sm={9} md={5} lg={5} xl={5} style={{textAlign : "center", fontSize: "14px", padding: "5px", margin: "0 auto", maxWidth: "420px"}}>
                                                <div style={{display: "inline", float: "left", marginLeft: "20px", marginTop: "10px"}}>
                                                    {sub_prodotti_regalo[keyIndex].titolo || ""} - OMAGGIO
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={5} lg={5} xl={5}  style={{textAlign: "left", paddingLeft: "10px", fontSize: "14px", padding: "5px", margin: "0"}}>
                                                <div style={{maxWidth: "420px", margin: "0 auto"}} dangerouslySetInnerHTML={{__html:purify.sanitize(sub_prodotti_regalo[keyIndex].descrizione)}}></div>
                                            </Grid>
                                            <Grid item /*xs={0} sm={0}*/ md={2} lg={2} xl={2} style={{textAlign : "center", fontSize: "14px", padding: "5px", marginTop: "4px"}}>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={5} lg={5} xl={5}  style={{textAlign: "center", paddingLeft: "10px", fontSize: "14px", padding: "5px", margin: "0"}}>
                                                { sub_prodotti_regalo[keyIndex].immagine !== "" && (
                                                    <img
                                                        src={sub_prodotti_regalo[keyIndex].immagine} 
                                                        alt="primary-product-img"
                                                        loading="lazy"
                                                        width="300px"
                                                        id={"img_"+ sub_prodotti_regalo[keyIndex].id}
                                                        onClick={this.sub_handleOpenImageModal}
                                                        style={{marginTop: "10px"}}
                                                    />
                                                )}
                                            </Grid>
                                            <Divider className="Divider"/>
                                        </Grid>
                                    ))}
                                    </Box>
                                    )} 
                                    <Grid container spacing={1} style={{textAlign: "center"}}>
                                        <Grid item xs={12} className="Form-control Register" style={{margin: "0 auto"}}>
                                            <div className="Form-control Register" style={{textAlign: "left", fontSize: "18px"}}><strong>TOTALE</strong></div>
                                            <div className="Form-control Register" style={{textAlign: "left", fontSize: "18px", color: "red"}}><strong>{sub_importo_totale}€</strong></div>
                                        </Grid>
                                    </Grid>
                                </Box> 
                                <Grid container className="Form-control AddClass" columnSpacing={{xs: 2, sm: 2, md: 3}}>
                                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} style={{margin: "auto"}}>
                                        <Button className="Cancel-Button" size="large" type="submit" variant="contained" onClick={this.handleCancelClick}>Annulla e torna indietro</Button>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} style={{margin: "auto"}}>
                                        { sub_addclassenabled ? (
                                            <Button size="large" style={{height: "100%"}} type="submit" variant="contained" color="primary" onClick={this.sub_handleAddClassClick}>Iscrivi questa classe</Button>
                                        ) : (
                                            <Button size="large" style={{height: "100%"}} type="submit" disabled variant="contained" color="primary" onClick={this.sub_handleAddClassClick}>Iscrivi questa classe</Button>
                                        )}    
                                    </Grid>
                                </Grid>
                                <div className="separator"></div>
                            </header>
                            <AppBar position="static" className="Footer">
                                <div>© Copyright {new Date().getFullYear()} – Associazione ToKalon - <Link to="/privacy">Privacy Policy</Link> - Realizzato da <a href="https://www.creab.it/" target="_blank" rel="noreferrer">Creabit.</a></div>
                            </AppBar>
                        </div>
                        ): (
                            <CircularProgress className="Progress"/>
                        )}
                    </div>
                )}
                 { (autenticato && editclassenabled) && (
                    <div className="App">
                        {( isloading === false) ? (
                        <div>
                            <header className="App-header">
                                <AppBar position="static" className="WelcomeBar">
                                    <Toolbar>
                                        <table className="WelcomeMenu">
                                            <tbody>
                                                <tr>
                                                    <td className="LogoMenuColumn">
                                                        <Logo />
                                                    </td>
                                                    <td className="HomeMenuColumn">
                                                        <HomeButton />
                                                        |
                                                        <TokalonButton />
                                                    </td>
                                                    <td className="AccountMenuButton">
                                                        { (nome === "" || cognome === "") ? (
                                                            <>
                                                                {/* <SocialButtonsInHeader /> */}
                                                                <Link to="/profile">
                                                                    <Button
                                                                        className="AccountName"
                                                                        size="large"
                                                                        id="user-menu-button"
                                                                        aria-label="account of current user"
                                                                        aria-controls="user-menu"
                                                                        //aria-haspopup="true"
                                                                        //aria-expanded={Boolean(anchorElUserMenu) ? 'true' : undefined}
                                                                        //onClick={this.handleUserMenuClick}
                                                                        color="inherit"
                                                                    >
                                                                        {/* <FontAwesomeIcon icon={faUser} /> */}
                                                                        <span className="WelcomeUser">{/* Bentornato */}</span>
                                                                    </Button>
                                                                </Link>
                                                                |
                                                                <Button
                                                                    className=""
                                                                    size="large"
                                                                    color="inherit"
                                                                    id="logout-button"
                                                                    onClick={this.handleLogoutClick}
                                                                >
                                                                    Esci
                                                                </Button>
                                                            </>
                                                        ): (
                                                            <>
                                                                {/* <SocialButtonsInHeader /> */}
                                                                <Link to="/profile">
                                                                    <Button
                                                                        className=""
                                                                        size="large"
                                                                        id="user-menu-button"
                                                                        aria-label="account of current user"
                                                                        aria-controls="user-menu"
                                                                        //aria-haspopup="true"
                                                                        //aria-expanded={Boolean(anchorElUserMenu) ? 'true' : undefined}
                                                                        //onClick={this.handleUserMenuClick}
                                                                        color="inherit" 
                                                                    >
                                                                        {/* <FontAwesomeIcon icon={faUser} /> */}
                                                                        <span className="WelcomeUser">{/* Bentornato */}</span> { nome + " " + cognome }
                                                                    </Button>
                                                                </Link>
                                                                |
                                                                <Button
                                                                    className=""
                                                                    size="large"
                                                                    color="inherit"
                                                                    id="logout-button"
                                                                    onClick={this.handleLogoutClick}
                                                                >
                                                                    Esci
                                                                </Button>
                                                            </>
                                                        )}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <Menu
                                            id="user-menu"
                                            anchorEl={anchorElUserMenu}
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            keepMounted
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            open={open}
                                            onClose={this.handleCloseUserMenu}
                                            MenuListProps={{
                                                'aria-labelledby': 'user-menu-button',
                                            }}
                                        >
                                            <MenuItem><Link to="/profile">Profilo</Link></MenuItem>
                                            <MenuItem onClick={this.handleLogoutClick}>Esci</MenuItem>
                                        </Menu>
                                    </Toolbar>
                                </AppBar>
                                {/* <Subheader></Subheader>      */}       
                                
                                <Box>
                                    {/* <div className="separator"></div> */}
                                    <div className="Page-title Orange">Modifica una classe - {nome_progetto} - Edizione {anno_progetto}</div>
                                    <div className="Page-subtitle-top">Modifica la classe selezionata</div>
                                    <div className="Page-title Orange">1. Modifica la Classe e il Docente Referente</div>
                                    <Grid container className="Form-control" style={{width: "90%", maxWidth: "480px", margin: "0 auto"}}>
                                        <Grid item xs={8} style={{textAlign : "center", fontSize: "18px"}}>
                                            Classe
                                        </Grid>
                                        <Grid item xs={4} style={{textAlign : "center", fontSize: "18px"}}>
                                            Sezione
                                        </Grid>
                                    </Grid>
                                    <Grid container className="Form-control" style={{width: "90%", maxWidth: "480px", margin: "0 auto"}}>
                                        <Grid item xs={8} style={{textAlign : "center", fontSize: "18px"}}>
                                            { sub_classe_error ? (
                                                <FormControl variant="outlined" className="Form-control" error>   
                                                <Select
                                                    native
                                                    value={sub_classe}
                                                    onChange={this.sub_handleChangeClasse}
                                                >
                                                    { Object.keys(this.sub_getListaClassi()).map((keyName, keyIndex) => (
                                                        <option key={keyName} value={keyName}>{this.sub_getListaClassi()[keyName]}</option>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            ) : (    
                                                <FormControl variant="outlined" className="Form-control">   
                                                    <Select
                                                        native
                                                        value={sub_classe}
                                                        onChange={this.sub_handleChangeClasse}
                                                    >
                                                        { Object.keys(this.sub_getListaClassi()).map((keyName, keyIndex) => (
                                                            <option key={keyName} value={keyName}>{this.sub_getListaClassi()[keyName]}</option>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            )}
                                        </Grid>
                                        <Grid item xs={4} style={{textAlign : "center", fontSize: "18px"}}>
                                            { sub_sezione_error ? (
                                                <FormControl variant="outlined" className="Form-control" error>   
                                                    <Select
                                                        native
                                                        value={sub_sezione}
                                                        onChange={this.sub_handleChangeSezione}
                                                    >
                                                        { Object.keys(this.sub_getListaSezioni()).map((keyName, keyIndex) => (
                                                            <option key={keyName} value={keyName}>{this.sub_getListaSezioni()[keyName]}</option>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            ) : (
                                                <FormControl variant="outlined" className="Form-control">   
                                                    <Select
                                                        native
                                                        value={sub_sezione}
                                                        onChange={this.sub_handleChangeSezione}
                                                    >
                                                        { Object.keys(this.sub_getListaSezioni()).map((keyName, keyIndex) => (
                                                            <option key={keyName} value={keyName}>{this.sub_getListaSezioni()[keyName]}</option>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            )}
                                        </Grid>
                                    </Grid>
                                    { (sub_classe_error && sub_sezione_error) && (
                                        <Grid container className="Form-control" style={{width: "90%", maxWidth: "480px", margin: "0 auto"}}>
                                            <div className="Form-control Register" style={{color: "red", textAlign: "center", fontSize: "18px"}}>Attenzione! Questa classe risulta già iscritta a questo progetto. Prosegui solo se hai la certezza che sia la scelta corretta (es: nelle scuole con più plessi ci sono sezioni che si ripetono).</div>
                                        </Grid>
                                    ) }
                                    <Grid container spacing={1} style={{textAlign: "center"}}>
                                        <Grid item xs={12} className="Form-control Register" style={{margin: "0 auto"}}>
                                            {/* <div className="Form-control Register" style={{textAlign: "left", fontSize: "18px"}}><strong>DOCENTE REFERENTE</strong></div>
                                            <div className="Form-control Register" style={{textAlign: "left", fontSize: "18px"}}>Stai effettuando l'iscrizione per una tua classe o per quella di un tuo collega?</div> */}
                                            {/* <FormControl component="fieldset" className="Form-control Register" style={{width: "98%"}}>
                                                <RadioGroup
                                                    aria-label="signuptype"
                                                    value={sub_tipo_iscrizione}
                                                    name="radio-signuptype-group"
                                                    onChange={this.sub_handleChangeTipoIscrizione}
                                                >
                                                    <FormControlLabel value="docente" control={<Radio />} style={{width: "98%"}} label="E' una mia classe" />
                                                    <FormControlLabel value="collega" control={<Radio />} style={{width: "98%"}} label="E' la classe di un mio collega" />
                                                </RadioGroup>
                                            </FormControl> */}
                                            { (sub_id_docente_referente2 !== null && typeof sub_id_docente_referente2 !== 'undefined' ) && (
                                                <div className="Form-control Register" style={{textAlign: "left", fontSize: "18px"}}>Inserisci tutti i dati del tuo collega referente per questa classe:</div>
                                            )}
                                            { (sub_id_docente_referente2 !== null && typeof sub_id_docente_referente2 !== 'undefined' ) && (    
                                                <div className="Form-control Register">
                                                    { sub_nome_referente2_error ? (
                                                        <TextField id="nome_referente2" style={{padding: "0px"}} inputProps={{maxLength : 255}} required placeholder="Nome" error helperText="Valore non valido" variant="outlined" onChange={this.sub_handleChangeNomeReferente2} value={ sub_nome_referente2 }/>
                                                    ) : (
                                                        <TextField id="nome_referente2" style={{padding: "0px"}} inputProps={{maxLength : 255}} required placeholder="Nome" variant="outlined" onChange={this.sub_handleChangeNomeReferente2} value={ sub_nome_referente2 }/>
                                                    )}
                                                </div>
                                            )}
                                            { (sub_id_docente_referente2 !== null && typeof sub_id_docente_referente2 !== 'undefined' ) && (    
                                                <div className="Form-control Register">
                                                    { sub_cognome_referente2_error ? (
                                                        <TextField id="cognome_referente2" style={{padding: "0px"}} inputProps={{maxLength : 255}} required placeholder="Cognome" error helperText="Valore non valido" variant="outlined" onChange={this.sub_handleChangeCognomeReferente2} value={ sub_cognome_referente2 }/>
                                                    ) : (
                                                        <TextField id="cognome_referente2" inputProps={{maxLength : 255}} required placeholder="Cognome" variant="outlined" onChange={this.sub_handleChangeCognomeReferente2} value={ sub_cognome_referente2 }/>
                                                    )}
                                                </div>
                                            )}
                                            { (sub_id_docente_referente2 !== null && typeof sub_id_docente_referente2 !== 'undefined' ) && (    
                                                <div className="Form-control Register">
                                                    { (sub_email_error || sub_email_exist_error) ? (
                                                        <TextField id="email_referente2" style={{padding: "0px"}} inputProps={{maxLength : 255}} required placeholder="Email" error helperText="Valore non valido" variant="outlined" onChange={this.sub_handleChangeEmailReferente2} value={ sub_email_referente2 }/>
                                                    ) : (
                                                        <TextField id="email_referente2" style={{padding: "0px"}} inputProps={{maxLength : 255}} required placeholder="Email" variant="outlined" onChange={this.sub_handleChangeEmailReferente2} value={ sub_email_referente2 }/>
                                                    )}
                                                </div>
                                            )}
                                            { sub_email_exist_error && (
                                                <div className="Form-control Register" style={{color: "red", textAlign: "center", fontSize: "18px"}}>Attenzione! Questo docente è già associato a una classe per questo progetto. Devi inserire un nuovo docente.</div>
                                            )}
                                        </Grid>
                                    </Grid>   
                                    <div className="Page-title Orange">2. Modifica la modalità di iscrizione</div>
                                    <Modal
                                        open={openimagemodal}
                                        onClose={this.handleCloseImageModal}
                                        aria-labelledby="modal-image-title"
                                        aria-describedby="modal-image-description"
                                    >
                                        <Box className="Modal"
                                            style={{width: "auto"}}
                                            onClick={this.sub_handleCloseImageModal}>
                                            { image_to_show !== "" && (
                                                <img
                                                    src={image_to_show}
                                                    alt="lightbox-product-img"
                                                    loading="lazy"
                                                    width={image_hd_width}
                                                />
                                            )}
                                        </Box>
                                    </Modal> 
                                    <Grid container spacing={1} style={{textAlign: "center"}}>
                                        <Grid item xs={12} className="Form-control Register" style={{margin: "0 auto"}}>
                                            <div className="Form-control Register" style={{textAlign: "center", fontSize: "18px"}}><strong>Scegli la modalità di iscrizione</strong></div>
                                        </Grid>
                                    </Grid>
                                    <div className="Form-control Register" style={{width: "90%", maxWidth: "800px", margin: "0px auto"}}>    
                                        <FormControl component="div">
                                            <RadioGroup
                                                aria-label="primary-product"
                                                value={sub_id_prodotto_prim_selezionato}
                                                name="radio-primaryproduct-group"
                                                onChange={this.sub_handleChangeProdottoPrimario}
                                            >
                                                { sub_prodotti_primari.map((keyName, keyIndex) => (        
                                                <Grid key={keyIndex} container style={{width: "90%", maxWidth: "800px", margin: "0 auto"}}>
                                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} style={{textAlign : "left", fontSize: "14px", padding: "5px", margin: "0px"}}>
                                                        <FormControlLabel
                                                            value={sub_prodotti_primari[keyIndex].id}
                                                            control={<Radio 
                                                                inputProps={{
                                                                    "data-titolo" : sub_prodotti_primari[keyIndex].titolo,
                                                                    "data-importo" : sub_prodotti_primari[keyIndex].importo
                                                                }}   
                                                            />}
                                                            style={{width: "98%"}} label={sub_prodotti_primari[keyIndex].titolo + " - "+ sub_prodotti_primari[keyIndex].importo + "€"}
                                                        />
                                                        <img
                                                            src={sub_prodotti_primari[keyIndex].immagine} 
                                                            alt="primary-product-img"
                                                            loading="lazy"
                                                            width="300px"
                                                            id={"img_"+ sub_prodotti_primari[keyIndex].id}
                                                            onClick={this.sub_handleOpenImageModal}
                                                            style={{marginTop: "10px"}}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}  style={{textAlign: "left", paddingLeft: "10px", fontSize: "14px", padding: "5px", margin: "0"}}>
                                                        <div style={{maxWidth: "420px", margin: "0 auto"}} dangerouslySetInnerHTML={{__html:purify.sanitize(sub_prodotti_primari[keyIndex].descrizione)}}></div>    
                                                    </Grid>
                                                </Grid>
                                                    ))}
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                    <Divider className="Divider"/>
                                    { sub_prodotti_secondari.length > 0 && (
                                        <Grid container spacing={1} style={{textAlign: "center"}}>
                                            <Grid item xs={12} className="Form-control Register" style={{margin: "0 auto"}}>
                                                <div className="Form-control Register" style={{textAlign: "left", fontSize: "18px"}}><strong>Aggiungi uno o più materiali EXTRA</strong></div>
                                            </Grid>
                                        </Grid>
                                    )}
                                    { sub_prodotti_secondari.map((keyName, keyIndex) => (        
                                        <Grid key={keyIndex} container style={{width: "90%", maxWidth: "800px", margin: "0 auto"}}>
                                            <Grid item xs={4} sm={3} md={2} lg={2} xl={2} style={{textAlign : "center", fontSize: "14px", padding: "5px", margin: "0 auto", maxWidth: "420px"}}>
                                                <div style={{display: "inline", float: "left"}}>
                                                <TextField 
                                                    className="qty-secondary-field" 
                                                    inputProps={{
                                                        "data-product" : keyIndex,
                                                        "min" : 0,
                                                        "max" : 999
                                                    }}                                
                                                    type="number"
                                                    required variant="outlined"
                                                    onChange={this.sub_handleChangeQuantitaProdottoSecondario}
                                                    value={ sub_prodotti_secondari[keyIndex].quantita || 0 }/>
                                                </div>
                                            </Grid>
                                            <Grid item xs={8} sm={9} md={5} lg={5} xl={5} style={{textAlign : "center", fontSize: "14px", padding: "5px", margin: "0 auto", maxWidth: "420px"}}>
                                                <div style={{display: "inline", float: "left", marginLeft: "20px", marginTop: "10px"}}>
                                                    {sub_prodotti_secondari[keyIndex].titolo || ""} - {sub_prodotti_secondari[keyIndex].importo || ""}€
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={5} lg={5} xl={5}  style={{textAlign: "left", paddingLeft: "10px", fontSize: "14px", padding: "5px", margin: "0"}}>
                                                <div style={{maxWidth: "420px", margin: "0 auto"}} dangerouslySetInnerHTML={{__html:purify.sanitize(sub_prodotti_secondari[keyIndex].descrizione)}}></div>
                                            </Grid>
                                            {/* <Grid item xs={0} sm={0} md={2} lg={2} xl={2} style={{textAlign : "center", fontSize: "14px", padding: "5px", marginTop: "4px"}}>
                                            </Grid> */}
                                            <Grid item xs={12} sm={12} md={5} lg={5} xl={5}  style={{textAlign: "center", paddingLeft: "10px", fontSize: "14px", padding: "5px", margin: "0"}}>
                                                { sub_prodotti_secondari[keyIndex].immagine !== "" && (
                                                    <img
                                                        src={sub_prodotti_secondari[keyIndex].immagine} 
                                                        alt="primary-product-img"
                                                        loading="lazy"
                                                        width="300px"
                                                        id={"img_"+ sub_prodotti_secondari[keyIndex].id}
                                                        onClick={this.sub_handleOpenImageModal}
                                                        style={{marginTop: "10px"}}
                                                    />
                                                )}
                                            </Grid>
                                            <Divider className="Divider"/>
                                        </Grid>
                                    ))}
                                    <Grid container spacing={1} style={{textAlign: "center"}}>
                                        <Grid item xs={12} className="Form-control Register" style={{margin: "0 auto"}}>
                                            <div className="Form-control Register" style={{textAlign: "left", fontSize: "18px"}}><strong>TOTALE</strong></div>
                                            <div className="Form-control Register" style={{textAlign: "left", fontSize: "18px", color: "red"}}><strong>{sub_importo_totale}€</strong></div>
                                        </Grid>
                                    </Grid>
                                </Box> 



                                <Grid container className="Form-control AddClass" columnSpacing={{xs: 2, sm: 2, md: 3}}>
                                    <Grid item xs={5} sm={5} md={4} lg={4} xl={4} style={{margin: "auto"}}>
                                        <Button className="Cancel-Button" size="large" type="submit" variant="contained" onClick={this.handleCancelEditClick}>Annulla e torna indietro</Button>
                                    </Grid>
                                    <Grid item xs={5} sm={5} md={4} lg={4} xl={4} style={{margin: "auto"}}>
                                        { sub_editclassenabled ? (
                                            <Button size="large" style={{height: "100%"}} type="submit" variant="contained" color="primary" onClick={this.sub_handleEditClassClick}>Modifica questa classe</Button>
                                        ) : (
                                            <Button size="large" style={{height: "100%"}} type="submit" disabled variant="contained" color="primary" onClick={this.sub_handleEditClassClick}>Modifica questa classe</Button>
                                        )}    
                                    </Grid>
                                </Grid>
                                <div className="separator"></div>
                            </header>
                            <AppBar position="static" className="Footer">
                                <div>© Copyright {new Date().getFullYear()} – Associazione ToKalon - <Link to="/privacy">Privacy Policy</Link> - Realizzato da <a href="https://www.creab.it/" target="_blank" rel="noreferrer">Creabit.</a></div>
                            </AppBar>
                        </div>
                        ): (
                            <CircularProgress className="Progress"/>
                        )}
                    </div>
                )}
                { autenticato === false && (
                    <Redirect to="/login" />
                )}
            </div>
        );
    }
}

export default withRouter(SignupClass);