//import logo from './logo.svg';
import './App.css';
import Login from './Login';
import Home from './Home';
import Register from './Register';
import Verified from './Verified';
import Registered from './Registered';
import Supporter from './Supporter';
import SupporterOrdered from './SupporterOrdered';
import PasswordReset from './PasswordReset';
import PasswordResetLink from './PasswordResetLink';
import SignupClass from './SignupClass';
import Profile from './Profile';
import Privacy from './Privacy';
import AlreadyVerified from './AlreadyVerified';
import Verify from './Verify';
import Formation from './Formation';
import RegisterTeam from './RegisterTeam';
import ManageTeam from './ManageTeam';
import ConfirmParticipation from './ConfirmParticipation';
import LoadDocument from './LoadDocument';
import NewUser from './NewUser';
import PreviousEditions from './PreviousEditions';
import ShowTeam from './ShowTeam';
import InvalidUrl from './InvalidUrl';

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

function App() {
  return (
    <div>
      <Router>
        <div>
        {/*   <nav>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/about">About</Link>
              </li>
              <li>
                <Link to="/users">Users</Link>
              </li>
            </ul>
          </nav> */}

          {/* A <Switch> looks through its children <Route>s and
              renders the first one that matches the current URL. */}
          <Switch>
            <Route exact path="/register">
              <Register />
            </Route>
            <Route exact path="/login">
              <Login />
            </Route>
            <Route exact path="/verify/:id">
              <Verify />
            </Route>
            <Route exact path="/verified">
              <Verified />
            </Route>
            <Route exact path="/alreadyverified">
              <AlreadyVerified />
            </Route>
            <Route exact path="/invalidurl">
              <InvalidUrl />
            </Route>
            <Route exact path="/registered">
              <Registered />
            </Route>
            <Route exact path="/supporter/:id">
              <Supporter />
            </Route>
            <Route exact path="/supporterordered">
              <SupporterOrdered />
            </Route>
            <Route exact path="/passwordresetlink">
              <PasswordResetLink />
            </Route>
            <Route exact path="/passwordreset">
              <PasswordReset />
            </Route>
            <Route exact path="/signupclass/:id">
              <SignupClass />
            </Route>
            <Route exact path="/profile">
              <Profile />
            </Route>
            <Route exact path="/privacy">
              <Privacy />
            </Route>
            <Route exact path="/formation">
              <Formation />
            </Route>
            <Route exact path="/registerteam">
              <RegisterTeam />
            </Route>
            <Route exact path="/manageteam">
              <ManageTeam />
            </Route>
            <Route exact path="/showteam">
              <ShowTeam />
            </Route>
            <Route exact path="/confirmparticipation">
              <ConfirmParticipation />
            </Route>
            <Route exact path="/loaddocument">
              <LoadDocument />
            </Route>
            <Route exact path="/newuser">
              <NewUser />
            </Route>
            <Route exact path="/previouseditions">
              <PreviousEditions />
            </Route>
            <Route exact path="/">
              <Home />
            </Route>
          </Switch>
        </div>
      </Router>
    </div>
  );
}

export default App;