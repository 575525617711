import React, { Component } from 'react';
import logo1 from './logo-mxt.png';
//import logo2 from './logo-cmtk.png';
import logo2 from './logo-associazione-tokalon.png';
import logo3 from './logo-ixt.png';
import Cookies from 'js-cookie';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';
import Subheader from './Subheader';
import HomeButton from './HomeButton';
import SocialButtonsInHeader from './SocialButtonsInHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import {Helmet} from "react-helmet";
import Logo from './Logo';
import TokalonButton from './TokalonButton';

class Privacy extends Component {

    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            authorization: Cookies.get('authorization') || '',
            wp_user_id: Cookies.get('wp_user_id') || 0,
            user_email: Cookies.get('user_email') || '',
            nome: Cookies.get('nome') || '',
            cognome: Cookies.get('cognome') || '',
            anchorElUserMenu: null,

        };
        this.handleLogoutClick = this.handleLogoutClick.bind(this);
        this.handleUserMenuClick = this.handleUserMenuClick.bind(this);
        this.handleCloseUserMenu = this.handleCloseUserMenu.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleLogoutClick(event) {
        event.preventDefault();
        this.setState({ authorization: ''});
        Cookies.remove('authorization');
        Cookies.remove('user_email');
        Cookies.remove('nome');
        Cookies.remove('cognome');
        Cookies.remove('wp_user_id');
        Cookies.remove('newuser');
    }

    handleUserMenuClick(event){
        //console.log(event.currentTarget);
        this.setState({ anchorElUserMenu: event.currentTarget});
    }

    handleCloseUserMenu(event){
        this.setState({ anchorElUserMenu: null});
    }

    render() {

        const autenticato = this.state.authorization !== '';
        const nome = this.state.nome;
        const cognome = this.state.cognome;
        const anchorElUserMenu = this.state.anchorElUserMenu;
        const open = Boolean(anchorElUserMenu);

        return (
            <div className="App">
                <header className="App-header">
                { autenticato ? ( 
                    <>
                    <Helmet htmlAttributes={{lang: 'it-IT'}}></Helmet>
                    <AppBar position="static" className="WelcomeBar">
                        <Toolbar>
                            <table className="WelcomeMenu">
                            <tbody>
                                    <tr>
                                        <td className="LogoMenuColumn">
                                            <Logo />
                                        </td>
                                        <td className="HomeMenuColumn">
                                            <HomeButton />
                                            |
                                            <TokalonButton />
                                        </td>
                                        <td className="AccountMenuButton">
                                            { (nome === "" || cognome === "") ? (
                                                <>
                                                    {/* <SocialButtonsInHeader /> */}
                                                    <Link to="/profile">
                                                        <Button
                                                            className="AccountName"
                                                            size="large"
                                                            id="user-menu-button"
                                                            aria-label="account of current user"
                                                            aria-controls="user-menu"
                                                            //aria-haspopup="true"
                                                            //aria-expanded={Boolean(anchorElUserMenu) ? 'true' : undefined}
                                                            //onClick={this.handleUserMenuClick}
                                                            color="inherit"
                                                        >
                                                            {/* <FontAwesomeIcon icon={faUser} /> */}
                                                            <span className="WelcomeUser">{/* Bentornato */}</span>
                                                        </Button>
                                                    </Link>
                                                    |
                                                    <Button
                                                        className=""
                                                        size="large"
                                                        color="inherit"
                                                        id="logout-button"
                                                        onClick={this.handleLogoutClick}
                                                    >
                                                        Esci
                                                    </Button>
                                                </>
                                            ): (
                                                <>
                                                    {/* <SocialButtonsInHeader /> */}
                                                    <Link to="/profile">
                                                        <Button
                                                            className="AccountName"
                                                            size="large"
                                                            id="user-menu-button"
                                                            aria-label="account of current user"
                                                            aria-controls="user-menu"
                                                            //aria-haspopup="true"
                                                            //aria-expanded={Boolean(anchorElUserMenu) ? 'true' : undefined}
                                                            //onClick={this.handleUserMenuClick}
                                                            color="inherit" 
                                                        >
                                                            {/* <FontAwesomeIcon icon={faUser} /> */}
                                                            <span className="WelcomeUser">{/* Bentornato */}</span> { nome + " " + cognome }
                                                        </Button>
                                                    </Link>
                                                    |
                                                    <Button
                                                        className=""
                                                        size="large"
                                                        color="inherit"
                                                        id="logout-button"
                                                        onClick={this.handleLogoutClick}
                                                    >
                                                        Esci
                                                    </Button>
                                                </>
                                            )}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <Menu
                                id="user-menu"
                                anchorEl={anchorElUserMenu}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={open}
                                onClose={this.handleCloseUserMenu}
                                MenuListProps={{
                                    'aria-labelledby': 'user-menu-button',
                                }}
                            >
                                <MenuItem><Link to="/profile">Profilo</Link></MenuItem>
                                <MenuItem onClick={this.handleLogoutClick}>Esci</MenuItem>
                            </Menu>
                        </Toolbar>
                    </AppBar>
                    {/* <Subheader></Subheader> */}
                    </>
                ) : (
                    <>
                        <Helmet htmlAttributes={{lang: 'it-IT'}}></Helmet>
                        <AppBar position="static" className="WelcomeBar">
                            <Toolbar>
                                <table className="WelcomeMenu">
                                <tbody>
                                        <tr>
                                            <td className="LogoMenuColumn">
                                                <Logo />
                                            </td>
                                            <td className="HomeMenuColumn">
                                                <HomeButton />
                                                |
                                                <TokalonButton />
                                            </td>
                                            <td className="AccountMenuButton">
                                                <>
                                                    {/* <SocialButtonsInHeader /> */}
                                                </>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Toolbar>
                        </AppBar>
                        {/* <Subheader></Subheader> */}
                        {/* <div className="Logo-Bar">
                            <div className="Logo-Box"><img src={logo1} alt="logo1" height="60"></img></div>
                            <div className="Logo-Box"><img src={logo2} alt="logo2" height="60"></img></div>
                            <div className="Logo-Box"><img src={logo3} alt="logo3" height="85"></img></div>
                        </div> */}
                    </>
                )}           
                    <div className="separator"></div>         
                    <div className="Page-title Orange">Condizioni d'uso e Normativa della Privacy</div>
                    <div className="Full-text">
                        <h5>Titolare del trattamento</h5>
                        <p>Titolare del trattamento è L’Associazione ToKalOn, in persona del legale rappresentante <em>pro tempore</em>, con sede in Roma, alla Via dell’Assietta n. 8/A – 00141, e-mail <a href="mailto:presidente@associazionetokalon.com">presidente@associazionetokalon.com</a>, pec tokalonformazione@pec.it</p>
                        <h5>Finalità e base giuridica del trattamento</h5>
                        <p><strong>I Suoi dati verranno trattati dall’Associazione al fine di:</strong></p>
                        <p>a) consentire la Sua partecipazione al concorso, al corso di formazione, anche online, o ad ogni altro evento organizzato dall’Associazione, la quale tratterà perciò i Suoi dati anagrafici e i Suoi dati di contatto (es. nome, cognome, data e luogo di nascita, codice fiscale, indirizzo, recapiti telefonici e e-mail) il cui conferimento è necessario al fine della partecipazione e dello svolgimento del concorso, del corso di formazione o dell’evento;</p>
                        <p>b) inviare comunicazioni contenenti inviti a partecipare ai concorsi, alle attività di formazione e ad ogni altro evento organizzato dall’Associazione che siano analoghi a quelli da Lei usufruiti, ai recapiti da Lei indicati e comunicati;</p>
                        <p>c) previo il suo esplicito consenso, inviare comunicazioni su tutti gli altri eventi organizzati dall’Associazione (eventi, concorsi, corsi di formazione), ai recapiti da Lei indicati e comunicati;</p>
                        <p>d) con il Suo esplicito e libero consenso, scattare e/o registrare foto/video, nel corso degli eventi organizzati dall’Associazione e pubblicare le immagini e le videoriprese sul sito dell’Associazione e/o sui canali social (Facebook, Twitter, Youtube, Instagram, ecc.) della stessa;</p>
                        <p>e) adempiere agli obblighi scaturenti con le Pubbliche Amministrazioni;</p>
                        <p>f) adempiere agli obblighi previsti in ambito fiscale e contabile.</p>
                        <p><strong>La base giuridica del trattamento è costituita:</strong></p>
                        <p>1) dalla necessità di dare esecuzione alla Sua richiesta di partecipazione al concorso, corso di formazione o altro evento organizzato dall’Associazione per le finalità di cui al punto a) che precede. Qualora l’interessato dovesse decidere di non fornire i dati di cui al presente punto, l’Associazione sarà impossibilitata a fornire i servizi richiesti e a consentire la Sua partecipazione al concorso, al corso o all’evento;</p>
                        <p>2) dal legittimo interesse del Titolare del trattamento, per l’invio di comunicazioni di cui alla lettera b) del punto che precede. Sul punto, le comunicazioni terranno conto della Sua categoria professionale di appartenenza e quindi del Suo possibile interesse alle iniziative dell’Associazione (cd. <em>direct marketing</em>). In ogni caso, in calce ad ogni comunicazione sarà indicata la modalità di esercizio del diritto di opporsi all’invio di comunicazioni analoghe (cd. <em>opt-out</em>);</p>
                        <p>3) dal Suo esplicito e libero consenso, per i trattamenti aventi ad oggetto l’invio di comunicazioni contenenti inviti a partecipare agli altri concorsi, corsi, attività di formazione e ogni altro evento organizzato dall’Associazione (lett. c), ai recapiti da Lei indicati e comunicati. Anche in tale circostanza, in calce ad ogni comunicazione Le sarà comunque indicata la modalità di revocare il consenso e di opporsi all’invio di comunicazioni analoghe;</p>
                        <p>4) dal Suo esplicito e libero consenso, per i trattamenti di cui alla lettera d);</p>
                        <p>5) dall’adempimento di un obbligo di legge, per i trattamenti di cui alle lettere e) e f).</p>
                        <h5>Modalità di trattamento dei dati</h5>
                        <p>Il trattamento dei Suoi dati personali sarà effettuato con modalità elettroniche e/o analogiche e consiste in un complesso di operazioni relative alla raccolta, registrazione, organizzazione, strutturazione, conservazione, adattamento o modifica, estrazione, consultazione, uso, comunicazione mediante trasmissione, diffusione o qualsiasi altra forma di messa a disposizione, raffronto o interconnessione, limitazione, cancellazione o distruzione.</p>
                        <p>Il trattamento dei Suoi dati sarà improntato ai principi di liceità, correttezza, trasparenza, esattezza e avverrà in modo adeguato, pertinente e limitato a quanto necessario rispetto alle finalità per le quali sono trattati, nel rispetto del principio di minimizzazione del trattamento.</p>
                        <p><strong>Comunicazione e diffusione dei dati</strong></p>
                        <p>I Suoi dati personali potranno essere comunicati a dipendenti o associati in qualità di autorizzati al trattamento dei dati personali, i quali eseguiranno il trattamento secondo le istruzioni impartite.</p>
                        <p>I Suoi dati personali potranno altresì essere comunicati a terzi, ove la comunicazione si renda necessaria ai fini del corretto svolgimento dell’evento, quali ad esempio gli Istituti scolastici aderenti al concorso, ovvero per l’invio di materiale omaggio, laddove ci si avvalga di partnership con altre aziende (che tratteranno solo i dati necessari per provvedere all’invio), oppure per finalità di elaborazione gestionale e contabile, per la consulenza in ambito fiscale e finanziario e/o per obblighi di legge, ma sempre nel rispetto del principio di minimizzazione del trattamento e limitatamente alle finalità perseguite.</p>
                        <p>Tali soggetti, se non operanti in qualità di titolare di un autonomo trattamento, saranno nominati Responsabili del trattamento ai sensi dell’art. 28 del Regolamento UE 679/2016 e agiranno sotto il controllo dello scrivente titolare, garantendo le adeguate misure di sicurezza.</p>
                        <p>I Suoi dati Personali potranno essere inoltre resi accessibili, per le finalità sopra menzionate:</p>
                        <ul>
                        <li>agli Enti e agli Organismi pubblici che hanno per legge, regolamento o normativa comunitaria il diritto o l’obbligo di conoscerli;</li>
                        <li>liberi professionisti che collaborano con il Titolare;</li>
                        <li>società esterne titolari di un autonomo trattamento di dati personali;</li>
                        <li>società esterne nominate responsabili del trattamento.</li>
                        </ul>
                        <p>Solo con riferimento al materiale fotografico e video eventualmente raccolto dall’Associazione nel corso dell’evento, e previo Suo consenso, le immagini potranno essere pubblicate sui siti dell’Associazione e sui canali social (Facebook, Twitter, Youtube, Instagram, ecc.) della stessa, su carta stampata e/o su qualsiasi altro mezzo di diffusione per scopi istituzionali, informativi, promozionali o comunque relativi alle attività di ToKalOn.</p>
                        <p>In nessun caso i dati personali saranno comunicati, diffusi, ceduti o comunque trasferiti a terzi per scopi illeciti e, comunque, senza rendere idonea informativa agli interessati e acquisirne il consenso, ove richiesto dalla legge. Resta salva l’eventuale comunicazione dei dati su richiesta dell’Autorità Giudiziaria o di Pubblica Sicurezza, nei modi e nei casi previsti dalla legge.</p>
                        <p>I dati personali saranno trattati in Italia e conservati su server ubicati all’interno dell’Unione Europea o in Paesi che, secondo la Commissione Europea, garantiscono un livello adeguato di protezione.</p>
                        <h5>Periodo di conservazione dei dati personali</h5>
                        <p>Ai principi di liceità, correttezza, trasparenza, esattezza, adeguatezza, pertinenza e limitatezza è improntata anche la conservazione dei Suoi dati, che avverrà mediante strumenti idonei a garantirne la sicurezza e la riservatezza, con e senza l’ausilio di mezzi automatizzati atti a memorizzare, gestire e trasmettere i dati stessi.</p>
                        <p>Il trattamento dei Suoi dati per finalità di marketing e di legittimo interesse di marketing cesserà trascorsi 24 mesi successivi all’ultimo contatto con il Titolare (da intendersi anche quale partecipazione a qualsiasi evento organizzato dall’Associazione, nonché alla verifica periodica della correttezza dei dati e/o alla loro rettifica).</p>
                        <p>Negli altri casi, il periodo di conservazione non eccederà il decimo anno successivo alla conclusione dell’evento, al termine del quale gli stessi verranno cancellati e/o distrutti, nel rispetto delle misure di sicurezza volte a garantire la riservatezza.</p>
                        <p>La conservazione potrà protrarsi rispetto ai termini di cui sopra laddove sia resa necessaria da un’altra condizione che renda lecito il trattamento in assenza di consenso.</p>
                        <h5>Diritti dell’interessato</h5>
                        <p>Ai sensi degli artt. 15-21 del Regolamento UE 679/2016, in relazione ai dati personali comunicati, Lei ha il diritto di:</p>
                        <ul>
                        <li>accedere e chiederne copia;</li>
                        <li>richiedere la rettifica;</li>
                        <li>richiedere la cancellazione;</li>
                        <li>ottenere la limitazione del trattamento;</li>
                        <li>revocare il consenso;</li>
                        <li>opporsi al trattamento;</li>
                        <li>ricevere in un formato strutturato, di uso comune e leggibile da dispositivo automatico e di trasmettere senza impedimenti tali dati a un altro titolare del trattamento, ove tecnicamente fattibile.</li>
                        </ul>
                        <p>Tutte le sopra menzionate richieste dovranno essere comunicate tramite raccomandata a/r all’indirizzo sopra riportato o tramite e-mail con conferma di lettura, e allegando copia del documento sottoscritta all’indirizzo <a href="mailto:segreteria@associazionetokalon.com">segreteria@associazionetokalon.com</a> e avrà efficacia dal momento della conferma di ricevimento.</p>
                        <p>Le è riconosciuto inoltre il diritto di proporre reclamo all’Autorità Garante per la Protezione dei Dati Personali.</p>
                        <p><strong>LIBERATORIA PER IL TRATTAMENTO, L’UTILIZZO E PUBBLICAZIONE DELLE PROPRIE IMMAGINI FOTO/VIDEO</strong></p>
                        <p>Con riferimento alle immagini (foto e video) scattate e/o registrate da ToKalOn, ricevuta l’informativa sul trattamento dei dati personali ai sensi dell’art. 13 del Regolamento UE 679/2016,</p>
                        <p>a titolo gratuito, senza limiti di tempo, anche ai sensi del Codice in materia di protezione dei dati personali, recante disposizioni per l’adeguamento dell’ordinamento nazionale al Regolamento UE 679/2016 del Parlamento europeo e del Consiglio, del 27 aprile 2016, relativo alla protezione delle persone fisiche con riguardo al trattamento dei dati personali, nonché alla libera circolazione di tali dati e che abroga la direttiva 95/46/CE e dell’art. 10 c.c. e degli artt. 96 e 97 della l. 633/1941 e ss.mm.ii., con la presente presta il proprio consenso al trattamento dei propri dati personali e</p>
                        <p><strong>&nbsp;</strong><strong>AUTORIZZA</strong></p>
                        <p>l’Associazione Tokalon allo<strong> scatto </strong>e/o alla<strong> registrazione</strong> di foto e/o video che lo ritraggono nonché alla successiva<strong> pubblicazione</strong> delle fotografie e/o riprese video da parte dell’Associazione sui siti internet e/o sui canali social (Facebook, Instagram, Youtube, Twitter, ecc.) della stessa, su carta stampata e/o su qualsiasi altro mezzo di diffusione per scopi istituzionali, informativi, promozionali o comunque relativi alle attività di ToKalOn. Autorizza la conservazione delle foto e dei video stessi negli archivi informatici e/o cartacei della Associazione.</p>
                        <p>La presente liberatoria/autorizzazione potrà essere revocata in ogni tempo con comunicazione scritta da inviare via posta ordinaria, e-mail o PEC</p>
                        <p><strong>DICHIARA</strong></p>
                        <p>Di rinunciare a qualunque corrispettivo possa derivare dalla pubblicazione di dette immagini fotografiche e/o riprese video e dalla pubblicazione delle dichiarazioni e dei miei dati personali. Dichiaro altresì di non avere nulla a che pretendere dalla Associazione ToKalOn ad alcun titolo presente e futuro per le pubblicazioni di cui sopra.</p>
                        <p>&nbsp;</p>
					</div>
                </header>
                <AppBar position="static" className="Footer">
                    <div>© Copyright {new Date().getFullYear()} – Associazione ToKalon - <Link to="/privacy">Privacy Policy</Link> - Realizzato da <a href="https://www.creab.it/" target="_blank" rel="noreferrer">Creabit.</a></div>
                </AppBar>
            </div>
        );
    }

}

export default Privacy;