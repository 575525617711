import React, { Component, Fragment } from 'react';
import Cookies from 'js-cookie';
import { Redirect } from "react-router-dom";
import axios from 'axios';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Subheader from './Subheader';
import HomeButton from './HomeButton';
import SocialButtonsInHeader from './SocialButtonsInHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import {Helmet} from "react-helmet";
import Logo from './Logo';
import TokalonButton from './TokalonButton';
import Tooltip from '@mui/material/Tooltip';

class ShowTeam extends Component {

    _isMounted = false;

    constructor(props) {

        super(props);
        this.state = {
            authorization: Cookies.get('authorization') || '',
            wp_user_id: Cookies.get('wp_user_id') || 0,
            user_email: Cookies.get('user_email') || '',
            nome: Cookies.get('nome') || '',
            cognome: Cookies.get('cognome') || '',
            id_ordine_classe: parseInt(Cookies.get('id_ordine_classe')) || '',
            anchorElUserMenu: null,

            squadre: [],

            id_progetto: "",
            tipo_progetto: "",
            nome_progetto: "",
            nome_scuola: "",
            cod_scuola: "",
            classe: "",
            sezione: "",
            docente_referente1: "",
            docente_referente2: "",
            isloading: false,

            pulsante_carica_prove: false,

            confirmparticipationclicked: false,
            loaddocumentclicked: false
        }
        this.handleLogoutClick = this.handleLogoutClick.bind(this);
        this.handleUserMenuClick = this.handleUserMenuClick.bind(this);
        this.handleCloseUserMenu = this.handleCloseUserMenu.bind(this);
        
    }

    componentDidMount() {
        this._isMounted = true;
        if (this._isMounted) {
            this.setState({ isloading: true });
        }

        var config = require('./config.json');

        var pulsante_carica_prove = config.pulsante_carica_prove;
        
        var env_backend = config.env_backend;
        var api_url_backend = config.api_url_backend[env_backend];
        var authorization = config.api_auth_backend[env_backend];

        const settings = {
            headers: { Authorization: `Bearer ${authorization}` }
        };

        var params;

        const apiUrl = api_url_backend + 'teams/getmanage';

        const id_ordine_classe = this.state.id_ordine_classe;

        params = { 
            "id_ordine_classe": id_ordine_classe
        };

        axios.post(apiUrl, params, settings).then(res => {
            if (this._isMounted) {
                this.setState({ pulsante_carica_prove: pulsante_carica_prove});
                this.setState({ id_progetto: res.data.data.dati_classe.id_progetto || ""}); 
                this.setState({ tipo_progetto: res.data.data.dati_classe.tipo_progetto || ""}); 
                this.setState({ nome_progetto: res.data.data.dati_classe.progetto || ""}); 
                this.setState({ nome_scuola: res.data.data.dati_classe.scuola || ""}); 
                this.setState({ cod_scuola: res.data.data.dati_classe.cod_scuola || ""}); 
                this.setState({ classe: res.data.data.dati_classe.classe || ""}); 
                this.setState({ sezione: res.data.data.dati_classe.sezione || ""}); 
                this.setState({ docente_referente1: res.data.data.dati_classe.docente_referente1 || ""}); 
                this.setState({ docente_referente2: res.data.data.dati_classe.docente_referente2 || ""}); 
                this.setState({ squadre: res.data.data.dati_classe.squadre || []}); 
                this.setState({ lista_ambiti: res.data.data.lista_ambiti || []});
                this.setState({ isloading: false});
            }
        }).catch (error => {
            //console.log(error);
            this.setState({ isloading: false});
        }); 

    }

    componentWillUnmount() {
        this._isMounted = false;
        //Cookies.remove('id_ordine_classe');
    }

    handleLogoutClick(event) {
        event.preventDefault();
        this.setState({ authorization: ''});
        Cookies.remove('authorization');
        Cookies.remove('user_email');
        Cookies.remove('nome');
        Cookies.remove('cognome');
        Cookies.remove('wp_user_id');
        Cookies.remove('newuser');
    }

    handleUserMenuClick(event){
        this.setState({ anchorElUserMenu: event.currentTarget});
    }

    handleCloseUserMenu(){
        this.setState({ anchorElUserMenu: null});
    }

    render() {
        const autenticato = this.state.authorization !== '';

        const nome = this.state.nome;
        const cognome = this.state.cognome;

        const anchorElUserMenu = this.state.anchorElUserMenu;
        const open = Boolean(anchorElUserMenu);

        const id_ordine_classe = this.state.id_ordine_classe;
        const id_progetto = this.state.id_progetto;
        const tipo_progetto = this.state.tipo_progetto;
        const nome_progetto = this.state.nome_progetto;
        const nome_scuola = this.state.nome_scuola;
        const cod_scuola = this.state.cod_scuola;
        const classe = this.state.classe;
        const sezione = this.state.sezione;
        const docente_referente1 = this.state.docente_referente1;
        const docente_referente2 = this.state.docente_referente2;
        const squadre = this.state.squadre;
        const isloading = this.state.isloading;
        const user_email = this.state.user_email;
        const docente_accompagnatore_nomecognome = this.state.docente_accompagnatore_nomecognome;

        const pulsante_carica_prove = this.state.pulsante_carica_prove;
        const lista_ambiti = this.state.lista_ambiti;

        return (
            <div>
                { (autenticato && isloading === false) && ( 
                    <Fragment>
                        <Helmet htmlAttributes={{lang: 'it-IT'}}></Helmet>
                        <div className="App">
                            <header className="App-header-home">
                                <AppBar position="static" className="WelcomeBar">
                                    <Toolbar>
                                        <table className="WelcomeMenu">
                                            <tbody>
                                                <tr>
                                                    <td className="LogoMenuColumn">
                                                        <Logo />
                                                    </td>
                                                    <td className="HomeMenuColumn">
                                                        <HomeButton />
                                                        |
                                                        <TokalonButton />
                                                    </td>
                                                    <td className="AccountMenuButton">
                                                        { (nome === "" || cognome === "") ? (
                                                            <>
                                                                {/* <SocialButtonsInHeader /> */}
                                                                <Link to="/profile">
                                                                    <Button
                                                                        className=""
                                                                        size="large"
                                                                        id="user-menu-button"
                                                                        aria-label="account of current user"
                                                                        aria-controls="user-menu"
                                                                        //aria-haspopup="true"
                                                                        //aria-expanded={Boolean(anchorElUserMenu) ? 'true' : undefined}
                                                                        //onClick={this.handleUserMenuClick}
                                                                        color="inherit"
                                                                    >
                                                                        {/* <FontAwesomeIcon icon={faUser} /> */}
                                                                        <span className="WelcomeUser">{/* Bentornato */}</span>
                                                                    </Button>
                                                                </Link>
                                                                |
                                                                <Button
                                                                    className=""
                                                                    size="large"
                                                                    color="inherit"
                                                                    id="logout-button"
                                                                    onClick={this.handleLogoutClick}
                                                                >
                                                                    Esci
                                                                </Button>
                                                            </>
                                                        ): (
                                                            <>
                                                                {/* <SocialButtonsInHeader /> */}
                                                                <Link to="/profile">
                                                                    <Button
                                                                        className=""
                                                                        size="large"
                                                                        id="user-menu-button"
                                                                        aria-label="account of current user"
                                                                        aria-controls="user-menu"
                                                                        //aria-haspopup="true"
                                                                        //aria-expanded={Boolean(anchorElUserMenu) ? 'true' : undefined}
                                                                        //onClick={this.handleUserMenuClick}
                                                                        color="inherit" 
                                                                    >
                                                                        {/* <FontAwesomeIcon icon={faUser} /> */}
                                                                        <span className="WelcomeUser">{/* Bentornato */}</span> { nome + " " + cognome }
                                                                    </Button>
                                                                </Link>
                                                                |
                                                                <Button
                                                                    className=""
                                                                    size="large"
                                                                    color="inherit"
                                                                    id="logout-button"
                                                                    onClick={this.handleLogoutClick}
                                                                >
                                                                    Esci
                                                                </Button>
                                                            </>
                                                        )}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <Menu
                                            id="user-menu"
                                            anchorEl={anchorElUserMenu}
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            keepMounted
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            open={open}
                                            onClose={this.handleCloseUserMenu}
                                            MenuListProps={{
                                                'aria-labelledby': 'user-menu-button',
                                            }}
                                        >
                                            <MenuItem><Link to="/profile">Profilo</Link></MenuItem>
                                            <MenuItem onClick={this.handleLogoutClick}>Esci</MenuItem>
                                        </Menu>
                                    </Toolbar>
                                </AppBar>
                                {/* <Subheader></Subheader> */}
                            </header>
                            {/* <div className="separator"></div> */}
                            <div className="Page-title Orange">Visualizza Squadre</div>
                            <div className="Page-subtitle-top">Qui puoi visualizzare le squadre per la tua classe.</div>
                            <div className="separator"></div>
                            {id_ordine_classe !== "" && (
                                <Fragment>
                                <Box className="DatiClasse">
                                    <fieldset>
                                        <legend>Stai partecipando con questa classe</legend>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <label><strong>Progetto: </strong>{nome_progetto}</label><br/>
                                                <label><strong>Scuola: </strong>{nome_scuola}</label><br/>
                                                <label><strong>Classe: </strong>{classe}</label><br/>
                                                <label><strong>Sezione: </strong>{sezione}</label><br/>          
                                            </Grid>
                                            <Grid item xs={6} style={{textAlign: "right"}}>
                                                <label><strong>Docenti </strong></label><br/>
                                                <label>{docente_referente1}</label><br/>
                                                <label>{docente_referente2}</label><br/>
                                            </Grid>
                                        </Grid>
                                    </fieldset>
                                </Box>
                                <div className="separator"></div>
                                <TableContainer className="TableContainer" style={{margin: "0 auto"}}>
                                    <Table className="TabellaSquadre" sx={{ minWidth: 700 }} aria-label="customized table">
                                        <TableHead className="HeadTabellaSquadre">   
                                            <TableRow>
                                                <TableCell>
                                                    COMPETIZIONE - ID - NOME SQUADRA
                                                </TableCell>
                                                <TableCell>N° GIOCATORI</TableCell>
                                                <TableCell>NOME E COGNOME</TableCell>
                                                {/* <TableCell>ADULTO ACCOMPAGNATORE</TableCell> */}
                                                {/* <TableCell>PROVE</TableCell> */}
                                                <TableCell>RIS SEL</TableCell>
                                                <TableCell>POS SEL</TableCell>
                                                <TableCell>RIS SEM</TableCell>
                                                <TableCell>POS SEM</TableCell>
                                                <TableCell>RIS FIN</TableCell>
                                                <TableCell>POS FIN</TableCell>
                                                <TableCell>GARE FINALI</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody className="BodyTabellaSquadre">
                                            {squadre.map((squadra, indexsquadra) => (
                                                <TableRow key={"squadra"+indexsquadra}>
                                                    <TableCell align="center"
                                                        data-title="Competizione - Id - Nome squadra"
                                                    >
                                                        <div>{lista_ambiti[squadra.ambito]}</div>
                                                        <div>{squadra.id}</div>
                                                        <div>{squadra.nome_squadra}</div>
                                                    </TableCell>
                                                    <TableCell align="center"
                                                        data-title="N° Giocatori"
                                                    >
                                                        {squadra.giocatori.length}
                                                    </TableCell>
                                                    <TableCell align="center"
                                                        data-title="Nome e Cognome"
                                                    >
                                                        {squadra.giocatori.map((giocatore, indexgiocatore) => (
                                                            <div key={"giocatore_"+indexgiocatore} id={"giocatore_"+indexgiocatore}>{giocatore.nome + " " + giocatore.cognome}</div>
                                                        ))}
                                                        {squadra.docente_accompagnatore_nomecognome !== "" && (
                                                            <span style={{color: "red"}}>{squadra.docente_accompagnatore_nomecognome}</span>
                                                        )}
                                                    </TableCell>
                                                    {/* <TableCell align="center"
                                                        data-title="Nome e Cognome"
                                                    >
                                                        {squadra.docente_accompagnatore_nomecognome}
                                                    </TableCell> */}
                                                    {/* <TableCell align="center"
                                                        data-title="Prove"
                                                    >
                                                        {squadra.submitted === 1 ? (
                                                            >
                                                                <br></br>
                                                                Prove caricate
                                                            </>
                                                        ): (
                                                        <Fragment> */}
                                                            {/*(pulsante_carica_prove == true && id_progetto == 3) && (*/}
                                                            {/* {(pulsante_carica_prove == true) && (
                                                            <a href={loadtesturl.replace("[###idclasse###]", id_ordine_classe)
                                                                                .replace("[###idsquadra###]", squadra.id)
                                                                                .replace("[###competizione###]", squadra.ambito)
                                                                                .replace("[###nomesquadra###]", squadra.nome_squadra)
                                                                                .replace("[###giocatori###]", squadra.giocatori.length)
                                                                                .replace("[###codscuola###]", cod_scuola)
                                                                                .replace("[###docente###]", nome+" "+cognome)
                                                                                .replace("[###email###]", user_email)
                                                                                .replace("[###classe###]", classe)
                                                                                .replace("[###sezione###]", sezione)
                                                                                .replace("[###nomicognomi###]", squadra.giocatori.map(function(elemento, index){
                                                                                    return elemento.nome + " " + elemento.cognome;
                                                                                }).join(", "))
                                                                                } target="_blank">
                                                                <Button size="small" className="LoadTest" variant="contained" color="primary" onClick={this.handleLoadTestClick}>Carica prove</Button>
                                                            </a>
                                                            )} */}
                                                        {/* </Fragment>    
                                                        )}
                                                    </TableCell> */}
                                                    <TableCell align="center"
                                                        data-title="Risultato qualificazione"
                                                    >
                                                        {squadra.risultato ? (
                                                            <Fragment>
                                                                {squadra.risultato_note ? (
                                                                    <Tooltip title={squadra.risultato_note}>
                                                                        <div>{squadra.risultato}  <span style={{color: "red"}}>*</span></div>
                                                                    </Tooltip>
                                                                ) : (
                                                                    <Fragment>
                                                                        <div>{squadra.risultato}</div>
                                                                    </Fragment>
                                                                )}
                                                            </Fragment>
                                                        ):(
                                                            <Fragment>
                                                                {0}
                                                            </Fragment>
                                                        )}
                                                    </TableCell>
                                                    <TableCell align="center"
                                                        data-title="Posizione qualificazione"
                                                    >
                                                        {(squadra.risultato && squadra.submitted === 1) && (
                                                            <Fragment>{squadra.posizione_qualificazione}°</Fragment>
                                                        )}
                                                    </TableCell>
                                                    <TableCell align="center"
                                                        data-title="Risultato semifinale"
                                                    >
                                                        {squadra.risultato_semifinale && (
                                                            <Fragment>{squadra.risultato_semifinale}</Fragment>
                                                        )}
                                                    </TableCell>
                                                    <TableCell align="center"
                                                        data-title="Posizione semifinale"
                                                    >
                                                        {(squadra.risultato_semifinale > 0 && squadra.submitted === 1) && (
                                                            <Fragment>
                                                                {squadra.semifinali_note ? (
                                                                    <Tooltip title={squadra.semifinali_note}>
                                                                        <div>{squadra.posizione_semifinale}° <span style={{color: "red"}}>*</span></div>
                                                                    </Tooltip>
                                                                ): (
                                                                    <Fragment>
                                                                        <div>{squadra.posizione_semifinale}</div>
                                                                    </Fragment>
                                                                )}
                                                            </Fragment>
                                                        )}
                                                    </TableCell>
                                                    <TableCell align="center"
                                                        data-title="Risultato finale"
                                                    >
                                                        {squadra.risultato_finale && (
                                                            <Fragment>{squadra.risultato_finale}</Fragment>
                                                        )}
                                                    </TableCell>
                                                    <TableCell align="center"
                                                        data-title="Posizione finale"
                                                    >
                                                        {/*(squadra.risultato_finale > 0 &&*/ squadra.submitted === 1/*)*/ && (
                                                            <Fragment>
                                                                {squadra.posizione_finale && (
                                                                    <Fragment>{squadra.posizione_finale}°</Fragment>
                                                                )}
                                                            </Fragment>
                                                        )}
                                                    </TableCell>
                                                    <TableCell align="center"
                                                        data-title="Gare Finali"
                                                    >
                                                        {squadra.finale === 0 && (
                                                            <Fragment>Selezione locale in corso</Fragment>
                                                        )}
                                                        {squadra.finale === 1 && (
                                                            <Fragment>Non ammessa</Fragment>
                                                        )}
                                                        {squadra.finale === 2 && (
                                                            <Fragment>
                                                                Ammessa alle gare finali!
                                                                <br></br><br></br>
                                                                {/* <Button size="small" className="ConfirmParticipation" id={"cp_" + squadra.id} variant="contained" color="primary" onClick={this.handleConfirmParticipation}>Conferma partecipazione</Button> */}
                                                            </Fragment>
                                                        )}
                                                        {squadra.finale === 3 && (
                                                            <Fragment>
                                                                Partecipazione confermata. Carica i documenti.
                                                                <br></br><br></br>
                                                                {/* <Button size="small" className="LoadDocument" id={"ld_" + squadra.id} variant="contained" color="primary" onClick={this.handleLoadDocument}>Carica documenti</Button> */}
                                                            </Fragment>
                                                        )}
                                                        {squadra.finale === 4 && (
                                                            <Fragment>
                                                                {(squadra.risultato_finale !== null && squadra.posizione_finale !== null) ? (
                                                                    <Fragment>Complimenti per la finalissima!</Fragment>
                                                                ) : (
                                                                    <Fragment>
                                                                        {(squadra.risultato_semifinale !== null && squadra.posizione_semifinale !== null) ? (
                                                                            <Fragment>Grazie per aver partecipato alle gare finali!</Fragment>
                                                                        ) : (
                                                                            <Fragment>La partecipazione alle gare finali è confermata!</Fragment>
                                                                        )}
                                                                    </Fragment>
                                                                )}
                                                            </Fragment>
                                                        )}
                                                        {squadra.finale === 5 && (
                                                            <Fragment>Non ammessa alla finalissima</Fragment>
                                                        )}  
                                                        {squadra.finale === 6 && (
                                                            <Fragment>Ammessa alla finalissima</Fragment>
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            ))}        
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <div className="adaptive"></div>
                            </Fragment>
                            )}
                            <div className="separator"></div>
                            <AppBar position="static" className="Footer">
                                <div>© Copyright {new Date().getFullYear()} – Associazione ToKalon - <Link to="/privacy">Privacy Policy</Link> - Realizzato da <a href="https://www.creab.it/" target="_blank" rel="noreferrer">Creabit.</a></div>
                            </AppBar>  
                        </div>                        
                    </Fragment>
                )}
            </div>
        );
    }
}

export default ShowTeam;