import React, { Component, Fragment } from 'react';
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';

class HomeButton extends Component {

    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {}

    componentWillUnmount() {}

    render(){

        return(
            <Link to="/">
                <Button
                    className=""
                    size="large"
                    color="inherit"
                    id="home-button"
                >
                    Home
                </Button>
            </Link>
        );
    }
}

export default HomeButton;