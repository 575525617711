import React, { Component, Fragment } from 'react';
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import HomeButton from './HomeButton';
import SocialButtonsInHeader from './SocialButtonsInHeader';
import Subheader from './Subheader';
import {Helmet} from "react-helmet";
import Logo from './Logo';
import TokalonButton from './TokalonButton';

class PasswordReset extends Component {

    _isMounted = false;

    constructor(props) {
        super(props);
        
        this.state = {
            token: "",
            email : "",
            password : "",
            passwordconfirm: "",
            error: false,
            errorcode: '',
            errormessage: '',
            isloading: false,
            resetenabled: false,
            resetcompleted: false,
            emailerror: false,
            passworderror: false,
            passwordconfirmerror: false,
            emailenabled: true
        };
        this.handleChangeEmail = this.handleChangeEmail.bind(this);
        this.handleChangePassword = this.handleChangePassword.bind(this);
        this.handleChangePasswordConfirm = this.handleChangePasswordConfirm.bind(this);
        this.handleResetClick = this.handleResetClick.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;

        if (this._isMounted) {
            this.setState({ isloading: true });
            const params = new URLSearchParams(window.location.search);
            let token = params.get('token') || "";
            let email = params.get('email') || "";
            //console.log(token);
            //console.log(email);
            this.setState({ token: token });
            this.setState({ email: email }, function(){
                if (email !== null && email !== ""){
                    if (this.validateEmail(this.state.email) === false) {
                        this.setState({ emailerror: true });
                    }
                    this.setState({ emailenabled: false });
                }
            });
            this.setState({ isloading: false});
        }
        
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    requiredFieldsAreFilled() {

        var required_fields = [
            'email',
            'password',
            'passwordconfirm'
        ];

        let filled = 0;
        required_fields.forEach((element) => {
            if (this.state[element].length > 0) {
                filled = filled + 1;
            }
        });

        if (filled === required_fields.length){
            return true;
        } else {
            return false;
        }

    }

    fieldsAreValid() {

        if (this.validateEmail(this.state.email) === false) {
            //console.log("email invalid");
            return false;
        }
        if (this.validatePassword(this.state.password) === false) {
            //console.log("password invalid");
            return false;
        }
        if (this.validatePasswordConfirm(this.state.passwordconfirm) === false) {
            //console.log("password confirm invalid");
            return false;
        }
        return true;
    }

    validateEmail(value) {
        let email = value;
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    validatePassword(value) {
        let password = value;
        const re = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%§£^&°?\/\'\"\\~|<>,.%^*èéòçàùì()_+={}:;@\-\[\]])([a-zA-Z0-9!@#$£%§^&°?\/\'\"\\~|<>,.%^*èéòçàùì()_+={}:;@\-\[\]]{8,})$/;
        return re.test(password);
    }

    validatePasswordConfirm(value) {
        let password = this.state.password;
        let passwordconfirm = value;
        return password === passwordconfirm;
    }

    handleChangeEmail(event) {
        //console.log(event.target.value);
        this.setState({ email: event.target.value }, function(){
            let email_is_valid = this.validateEmail(this.state.email);
            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();
    
            if (email_is_valid) {
                this.setState({ emailerror: false });
            } else {
                this.setState({ emailerror: true });
            }
                    
            if (required_field_are_filled && fields_are_valid) {
                this.setState({ resetenabled: true });
            } else {
                this.setState({ resetenabled: false });
            }
        });     
    }

    handleChangePassword(event) {
        //console.log(event.target.value);
        this.setState({ password: event.target.value }, function(){
            this.setState({ passwordconfirm: "" }, function(){
                let passwordisvalid = this.validatePassword(event.target.value);
                let required_field_are_filled = this.requiredFieldsAreFilled();
                let fields_are_valid = this.fieldsAreValid();

                if (passwordisvalid) {
                    this.setState({ passworderror: false });
                } else {
                    this.setState({ passworderror: true });
                }
                    
                if (required_field_are_filled && fields_are_valid) {
                    this.setState({ resetenabled: true });
                } else {
                    this.setState({ resetenabled: false });
                }

                //console.log("====");
                //console.log(passwordisvalid);
                //console.log(required_field_are_filled);
                //console.log(fields_are_valid);
                //console.log("====");
            });
        });
    }

    handleChangePasswordConfirm(event) {
        this.setState({ passwordconfirm: event.target.value }, function(){
            let passwordconfirmisvalid = this.validatePasswordConfirm(event.target.value);
            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();

            if (passwordconfirmisvalid) {
                this.setState({ passwordconfirmerror: false });
            } else {
                this.setState({ passwordconfirmerror: true });
            }
            
            if (required_field_are_filled && fields_are_valid) {
                this.setState({ resetenabled: true });
            } else {
                this.setState({ resetenabled: false });
            }

            //console.log("====");
            //console.log(passwordconfirmisvalid);
            //console.log(required_field_are_filled);
            //console.log(fields_are_valid);
            //console.log("====");
            });
    }

    handleResetClick(event) {

        event.preventDefault();

        var config = require('./config.json');
        var env_backend = config.env_backend;
        var api_url_backend = config.api_url_backend[env_backend];
        var api_auth_backend = config.api_auth_backend[env_backend];

        this.setState({ error: false });
        this.setState({ isloading: true });

        const apiUrl = api_url_backend;
        let authorization = api_auth_backend;
        let token = this.state.token;
        let email = this.state.email;
        let password = this.state.password;

        const settings = {
            headers: { Authorization: `Bearer ${authorization}` }
        };

        axios.post(apiUrl+"password/reset", { 
            "token": token,
            "email": email,
            "password": password
        }, settings).then(res2 => {

            this.setState({ isloading: false }, function(){
                //console.log(res2.data.data); 
            });

            if (res2.data.success) {

                this.setState({ token: ''});
                this.setState({ email: ''});
                this.setState({ password: ''});
                this.setState({ passwordconfirm: ''});
                this.setState({ resetenabled: false});
                this.setState({ resetcompleted: true});
               
            } else {

                if (this._isMounted) {

                    this.setState({ error: true});
                    if (res2.data.data.code === "email_error"){
                        this.setState({ emailerror: true }); 
                    }
                    this.setState({ errorcode: res2.data.data.code });
                    this.setState({ errormessage: res2.data.data.message });
                    this.setState({ isloading: false });

                }
            }

        }).catch (error => {

            if (this._isMounted) {

                this.setState({ error: true});

                //console.log(error.response);

                if (error.response.data.data?.code === "email_error"){
                    this.setState({ emailerror: true }); 
                }
                this.setState({ errorcode: error.response.data.data?.code });
                this.setState({ errormessage: error.response.data.data?.message });
                this.setState({ isloading: false });
            }
        });


    }

    render() {

        const isloading = this.state.isloading;
        const error = this.state.error;
        const errormessage = this.state.errormessage;
        const emailerror = this.state.emailerror;
        const email = this.state.email;
        const password = this.state.password;
        const passwordconfirm = this.state.passwordconfirm;
        const passworderror = this.state.passworderror;
        const passwordconfirmerror = this.state.passwordconfirmerror;
        const resetenabled = this.state.resetenabled;
        const resetcompleted = this.state.resetcompleted;
        const emailenabled = this.state.emailenabled;

        return (
            <div>
                { resetcompleted ? (
                    <Fragment>
                    <Helmet htmlAttributes={{lang: 'it-IT'}}></Helmet>
                    <div className="App">
                        <header className="App-header">
                            <AppBar position="static" className="WelcomeBar">
                                <Toolbar>
                                    <table className="WelcomeMenu">
                                    <tbody>
                                            <tr>
                                                <td className="LogoMenuColumn">
                                                    <Logo />
                                                </td>
                                                <td className="HomeMenuColumn">
                                                    <HomeButton />
                                                    |
                                                    <TokalonButton />
                                                </td>
                                                <td className="AccountMenuButton">
                                                    <>
                                                        {/* <SocialButtonsInHeader /> */}
                                                    </>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Toolbar>
                            </AppBar>
                            {/* <Subheader></Subheader> */}
                            <div className="Home-description">
                                <p>Password ripristinata con successo!</p>
                            </div>
                            <div className="Form-control">
                                <Link to="/login">
                                    <Button size="large" className="submit" variant="contained" color="secondary">VAI AL LOGIN</Button>
                                </Link>
                            </div>
                        </header>
                    </div>
                    </Fragment>
                ) : (
                    <Fragment>
                    <Helmet htmlAttributes={{lang: 'it-IT'}}></Helmet>
                    <div className="App">
                        <header className="App-header">
                            <AppBar position="static" className="WelcomeBar">
                                    <Toolbar>
                                        <table className="WelcomeMenu">
                                        <tbody>
                                                <tr>
                                                    <td className="LogoMenuColumn">
                                                        <Logo />
                                                    </td>
                                                    <td className="HomeMenuColumn">
                                                        <HomeButton />
                                                        |
                                                        <TokalonButton />
                                                    </td>
                                                    <td className="AccountMenuButton">
                                                        <>
                                                            {/* <SocialButtonsInHeader /> */}
                                                        </>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Toolbar>
                                </AppBar>
                                {/* <Subheader></Subheader> */}
                            <div className="Page-title">Ripristina password</div>
                            { isloading && (
                                <CircularProgress className="Progress"/>
                            )}
                            { error && (
                                <div className="Error-message">{ errormessage }</div>
                            )}
                            <div className="Form-label">
                                <label>E-mail</label>
                            </div>
                            <div className="Form-control">
                                { (emailenabled === false && emailerror === false) && (
                                    <TextField id="email" type="email" disabled variant="outlined" value={ email }/>
                                )}
                                { (emailerror && emailenabled === false) &&(
                                    <TextField id="email" type="email" disabled error helperText="Valore non valido" required variant="outlined" onChange={this.handleChangeEmail} value={ email }/>
                                )}
                                { (emailerror && emailenabled === true) &&(
                                    <TextField id="email" type="email" error helperText="Valore non valido" required variant="outlined" onChange={this.handleChangeEmail} value={ email }/>
                                )}
                                { (emailerror === false && emailenabled === true) && (
                                    <TextField id="email" type="email" required variant="outlined" onChange={this.handleChangeEmail} value={ email }/>
                                )}
                            </div>
                            <div className="Form-label">
                                <label>Password</label>
                            </div>
                            <div className="Form-control">
                                { passworderror ? (
                                    <TextField id="password" type="password" required error helperText={<label>La password deve essere lunga<br />almeno 8 caratteri.<br />Inserisci almeno:<br />1 carattere maiuscolo,<br />1 carattere minuscolo,<br />1 numero<br />1 carattere speciale.</label>} variant="outlined" onChange={this.handleChangePassword} value={ password }/>
                                ) : (
                                    <TextField id="password" type="password" required variant="outlined" onChange={this.handleChangePassword} value={ password }/>
                                )}
                            </div>
                            <div className="Form-label">
                                <label>Conferma Password</label>
                            </div>
                            <div className="Form-control">
                            { passwordconfirmerror ? (
                                <TextField id="passwordconfirm" type="password" required error helperText="Valore non valido" variant="outlined" onChange={this.handleChangePasswordConfirm} value={ passwordconfirm }/>
                            ) : (
                                <TextField id="passwordconfirm" type="password" required variant="outlined" onChange={this.handleChangePasswordConfirm} value={ passwordconfirm }/>
                            )}
                            </div>
                            <div className="Form-control">
                                { resetenabled ? (
                                    <Button size="large" type="submit" variant="contained" color="primary" onClick={this.handleResetClick}>RIPRISTINA PASSWORD</Button>
                                ) : (
                                    <Button size="large" type="submit" disabled variant="contained" color="primary" onClick={this.handleResetClick}>RIPRISTINA PASSWORD</Button>
                                )}    
                            </div>
                        </header>
                    </div>
                    </Fragment>
                )}
            </div>
        );
    }
}

export default PasswordReset;