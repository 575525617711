import React, { Component, Fragment } from 'react';
import Cookies from 'js-cookie';
import { Redirect } from "react-router-dom";
import axios from 'axios';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import { FormGroup } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Subheader from './Subheader';
import HomeButton from './HomeButton';
import SocialButtonsInHeader from './SocialButtonsInHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import {Helmet} from "react-helmet";
import Logo from './Logo';
import TokalonButton from './TokalonButton';

class RegisterTeam extends Component {

    _isMounted = false;

    constructor(props) {

        super(props);
        this.state = {
            authorization: Cookies.get('authorization') || '',
            wp_user_id: Cookies.get('wp_user_id') || 0,
            user_email: Cookies.get('user_email') || '',
            nome: Cookies.get('nome') || '',
            cognome: Cookies.get('cognome') || '',
            id_ordine_classe: parseInt(Cookies.get('id_ordine_classe')) || '',
            anchorElUserMenu: null,

            nome_progetto: "",
            nome_scuola: "",
            classe: "",
            sezione: "",
            docente_referente1: "",
            docente_referente2: "",
            ambiti: [],
            ambitichecked: [],
            competition: [],
            competitionnames: [],
            squadre_gia_iscritte: [],
            squadre: [],

            registerenabled: false,
            registercompleted: false,
            isloading: false,
            
            error: false,
            errorcode: '',
            errormessage: '',
            nome_squadra_error: [false],
            nome_squadra_exist_error: [false],
            nome_giocatore_error: [[false,false,false]],
            cognome_giocatore_error: [[false,false,false]]
        }
        this.handleLogoutClick = this.handleLogoutClick.bind(this);
        this.handleUserMenuClick = this.handleUserMenuClick.bind(this);
        this.handleCloseUserMenu = this.handleCloseUserMenu.bind(this);
        this.handleSelectCompetition = this.handleSelectCompetition.bind(this);
        this.handleChangeTeams = this.handleChangeTeams.bind(this);
        this.handleChangePlayers = this.handleChangePlayers.bind(this);
        this.handleChangeTeamName = this.handleChangeTeamName.bind(this);
        this.handleChangePlayerFirstname = this.handleChangePlayerFirstname.bind(this);
        this.handleChangePlayerLastname = this.handleChangePlayerLastname.bind(this);
        this.handleRegisterClick = this.handleRegisterClick.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        if (this._isMounted) {
            this.setState({ isloading: true });
        }

        var config = require('./config.json');
        var env_backend = config.env_backend;
        var api_url_backend = config.api_url_backend[env_backend];
        const apiUrl = api_url_backend + 'teams/getregister';
        var authorization = config.api_auth_backend[env_backend];

        const settings = {
            headers: { Authorization: `Bearer ${authorization}` }
        };

        const id_ordine_classe = this.state.id_ordine_classe;
        var params;
        params = { 
            "id_ordine_classe": id_ordine_classe
        };

        axios.post(apiUrl, params, settings).then(res => {
            if (this._isMounted) {
                this.setState({ nome_progetto: res.data.data.dati_classe.progetto || ""}); 
                this.setState({ nome_scuola: res.data.data.dati_classe.scuola || ""}); 
                this.setState({ classe: res.data.data.dati_classe.classe || ""}); 
                this.setState({ sezione: res.data.data.dati_classe.sezione || ""}); 
                this.setState({ docente_referente1: res.data.data.dati_classe.docente_referente1 || ""}); 
                this.setState({ docente_referente2: res.data.data.dati_classe.docente_referente2 || ""}); 

                var ambiti = res.data.data.ambiti;

                this.setState({ ambiti: ambiti || []}, function(){
                    var ambiti = res.data.data.ambiti;
                  
                    var ambitichecked = [];
                    ambiti.forEach(function(value, index, array){
                        ambitichecked[index] = false;
                    });
                    //console.log(ambitichecked);
                    ambitichecked[0] = true;
                    this.setState({ ambitichecked: ambitichecked || []});
                });
                this.setState({ competition: [res.data.data.ambiti[0]] || []});
                this.setState({ competitionnames: res.data.data.nomi_ambiti || []});
                this.setState({ squadre_gia_iscritte: res.data.data.dati_squadre || []});
                this.setState({ squadre: [
                    {
                        "nome_squadra": "",
                        "giocatori": [
                            {
                                "nome": "",
                                "cognome": ""   
                            },
                            {
                                "nome": "",
                                "cognome": ""   
                            },
                            {
                                "nome": "",
                                "cognome": ""   
                            }
                        ]    
                    }
                ]});
                this.setState({ isloading: false });
            }
        }).catch (error => {
            //console.log(error);
            this.setState({ isloading: false});
        }); 

    }

    componentWillUnmount() {
        this._isMounted = false;
        Cookies.remove('id_ordine_classe');
    }

    handleLogoutClick(event) {
        event.preventDefault();
        this.setState({ authorization: ''});
        Cookies.remove('authorization');
        Cookies.remove('user_email');
        Cookies.remove('nome');
        Cookies.remove('cognome');
        Cookies.remove('wp_user_id');
        Cookies.remove('newuser');
    }

    handleUserMenuClick(event){
        this.setState({ anchorElUserMenu: event.currentTarget});
    }

    handleCloseUserMenu(){
        this.setState({ anchorElUserMenu: null});
    }

    handleSelectCompetition(event){
        var competition = this.state.competition;
        var competitionnames = this.state.competitionnames;
        var ambiticheckedold = this.state.ambitichecked;
        var ambiti = this.state.ambiti;
        let ambito = parseInt(event.target.value);
        let checked = event.target.checked;
        let id = event.target.id.split("_")[1];
        if (checked == true){
            competition.push(ambito);
            ambiticheckedold[id] = true;
        }
        if (checked == false){
            const index = competition.indexOf(ambito);
            if (index > -1) {
                competition.splice(index, 1);
                ambiticheckedold[id] = false;
            }
        }
        var listacompetition = [];
        competition.forEach(function(value, index){
            listacompetition.push(competitionnames[ambiti.indexOf(value)]);
        });
        listacompetition = listacompetition.join(", ");
        this.setState({ competition: competition });
        
    }

    handleChangeTeams(event){
        let teams = this.state.squadre;
        let nome_squadra_errors = this.state.nome_squadra_error;
        let nome_squadra_exist_errors = this.state.nome_squadra_exist_error;
        let nome_giocatore_errors = this.state.nome_giocatore_error;
        let cognome_giocatore_errors = this.state.cognome_giocatore_error;
        let oldnumteams = teams.length;
        let newnumteams = event.target.value;
        if (newnumteams == 0 || newnumteams.length > 1){

        } else {
            if (oldnumteams > newnumteams){
                let numtodelete = oldnumteams - newnumteams;
                for (let index = 0; index < numtodelete; index++) {
                    teams.pop();
                    nome_squadra_errors.pop();
                    nome_squadra_exist_errors.pop();
                    nome_giocatore_errors.pop();
                    cognome_giocatore_errors.pop();
                }
            }
            if (oldnumteams < newnumteams){
                let numtoadd = newnumteams - oldnumteams;
                for (let index = 0; index < numtoadd; index++) {
                    let newteam = {
                        "nome_squadra": "",
                        "giocatori": [
                            {
                                "nome": "",
                                "cognome": ""   
                            },
                            {
                                "nome": "",
                                "cognome": ""   
                            },
                            {
                                "nome": "",
                                "cognome": ""   
                            }
                        ]    
                    };
                    let newnameteamerror = false;
                    let newnameteamexisterror = false;
                    let newnameplayererror = [false,false,false];
                    let newlastnameplayererror = [false,false,false];
                    teams.push(newteam); 
                    nome_squadra_errors.push(newnameteamerror);
                    nome_squadra_exist_errors.push(newnameteamexisterror);
                    nome_giocatore_errors.push(newnameplayererror);
                    cognome_giocatore_errors.push(newlastnameplayererror);
                }
            }
            this.setState({ squadre: teams});
            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();
            if (required_field_are_filled && fields_are_valid) {
                this.setState({ registerenabled: true });
            } else {
                this.setState({ registerenabled: false });
            }
        }
    }

    handleChangePlayers(event){
        let teams = this.state.squadre;
        let players = teams[event.target.getAttribute("data-team")].giocatori;
        let nome_giocatore_errors = this.state.nome_giocatore_error[event.target.getAttribute("data-team")];
        let cognome_giocatore_errors = this.state.cognome_giocatore_error[event.target.getAttribute("data-team")];
        let oldnumplayers = players.length;
        let newnumplayers = event.target.value;
        if (newnumplayers < 2 || newnumplayers > 4 || newnumplayers.length > 1){

        } else {
            if (oldnumplayers > newnumplayers){
                let numtodelete = oldnumplayers - newnumplayers;
                for (let index = 0; index < numtodelete; index++) {
                    players.pop();
                    nome_giocatore_errors.pop();
                    cognome_giocatore_errors.pop();
                }
            }
            if (oldnumplayers < newnumplayers){
                let numtoadd = newnumplayers - oldnumplayers;
                for (let index = 0; index < numtoadd; index++) {
                    let newplayer = {
                        "nome": "",
                        "cognome": ""   
                    };
                    let newnameplayererror = false;
                    let newlastnameplayererror = false;
                    players.push(newplayer);   
                    nome_giocatore_errors.push(newnameplayererror);
                    cognome_giocatore_errors.push(newlastnameplayererror);
                }
            }
            teams[event.target.getAttribute("data-team")].giocatori = players;
            this.setState({ squadre: teams});
            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();
            if (required_field_are_filled && fields_are_valid) {
                this.setState({ registerenabled: true });
            } else {
                this.setState({ registerenabled: false });
            }
        }
    }

    handleChangeTeamName(event){
        let teams = this.state.squadre;
        teams[event.target.getAttribute("data-team")].nome_squadra = event.target.value;
        this.setState({ squadre: teams}, function(){
            if (this.nomeSquadraNotExist(event.target.value)){
                let nome_squadra_exist_errors = this.state.nome_squadra_exist_error;
                nome_squadra_exist_errors[event.target.getAttribute("data-team")] = false;
                this.setState({ nome_squadra_exist_error: nome_squadra_exist_errors });
            } else {
                let nome_squadra_exist_errors = this.state.nome_squadra_exist_error;
                nome_squadra_exist_errors[event.target.getAttribute("data-team")] = true;
                this.setState({ nome_squadra_exist_error: nome_squadra_exist_errors });
            }
            if (this.validateNomeSquadra(event.target.value)){
                let nome_squadra_errors = this.state.nome_squadra_error;
                nome_squadra_errors[event.target.getAttribute("data-team")] = false;
                this.setState({ nome_squadra_error: nome_squadra_errors });
            } else {
                let nome_squadra_errors = this.state.nome_squadra_error;
                nome_squadra_errors[event.target.getAttribute("data-team")] = true;
                this.setState({ nome_squadra_error: nome_squadra_errors });
            }
            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();
            if (required_field_are_filled && fields_are_valid) {
                this.setState({ registerenabled: true });
            } else {
                this.setState({ registerenabled: false });
            }
        });
    }

    handleChangePlayerFirstname(event){
        let teams = this.state.squadre;
        teams[event.target.getAttribute("data-team")].giocatori[event.target.getAttribute("data-player")].nome = event.target.value;
        this.setState({ squadre: teams }, function(){
            if (this.validateNomeGiocatore(event.target.value)){
                let nome_giocatore_errors = this.state.nome_giocatore_error;
                nome_giocatore_errors[event.target.getAttribute("data-team")][event.target.getAttribute("data-player")] = false;
                this.setState({ nome_giocatore_error: nome_giocatore_errors });
            } else {
                let nome_giocatore_errors = this.state.nome_giocatore_error;
                nome_giocatore_errors[event.target.getAttribute("data-team")][event.target.getAttribute("data-player")] = true;
                this.setState({ nome_giocatore_error: nome_giocatore_errors });
            }
            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();
            if (required_field_are_filled && fields_are_valid) {
                this.setState({ registerenabled: true });
            } else {
                this.setState({ registerenabled: false });
            }
        });
    }

    handleChangePlayerLastname(event){
        let teams = this.state.squadre;
        teams[event.target.getAttribute("data-team")].giocatori[event.target.getAttribute("data-player")].cognome = event.target.value;
        this.setState({ squadre: teams}, function(){
            if (this.validateCognomeGiocatore(event.target.value)){
                let cognome_giocatore_errors = this.state.cognome_giocatore_error;
                cognome_giocatore_errors[event.target.getAttribute("data-team")][event.target.getAttribute("data-player")] = false;
                this.setState({ cognome_giocatore_error: cognome_giocatore_errors });
            } else {
                let cognome_giocatore_errors = this.state.cognome_giocatore_error;
                cognome_giocatore_errors[event.target.getAttribute("data-team")][event.target.getAttribute("data-player")] = true;
                this.setState({ cognome_giocatore_error: cognome_giocatore_errors });
            }
            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();
            if (required_field_are_filled && fields_are_valid) {
                this.setState({ registerenabled: true });
            } else {
                this.setState({ registerenabled: false });
            }
        });        
    }

    handleRegisterClick(event){
        event.preventDefault();

        if (this._isMounted) {
            this.setState({ error: false });
            this.setState({ isloading: true });
        }

        var config = require('./config.json');
        var env_backend = config.env_backend;
        var api_url_backend = config.api_url_backend[env_backend];
        const apiUrl = api_url_backend + 'teams/register';
        var authorization = config.api_auth_backend[env_backend];

        let id_ordine_classe = this.state.id_ordine_classe;
        //let ambito = parseInt(this.state.competition);
        let ambiti = this.state.competition;
        let squadre = this.state.squadre;

        const settings = {
            headers: { Authorization: `Bearer ${authorization}` }
        };

        axios.post(apiUrl, { 

            "id_ordine_classe": id_ordine_classe,
            "ambiti": ambiti,
            "squadre": squadre

        }, settings).then(res => {
            
            this.setState({ isloading: false }, function(){
                //console.log(res.data.data); 
            });

            if (res.data.success){

                this.setState({ registercompleted: true });
                this.setState({ registerenabled: false });

            } else {  

                if (this._isMounted) {

                    this.setState({ error: true});
                    this.setState({ errorcode: res.data.data.code });
                    this.setState({ errormessage: res.data.data.message });
                    this.setState({ isloading: false });
                }

            }

        }).catch (error => {   

            if (this._isMounted) {
                
                this.setState({ isloading: false });
            }

        });
    }

    requiredFieldsAreFilled() {

        var fields_are_filled = true;
        var teams = this.state.squadre;
        teams.forEach(team => {
            if (team.nome_squadra == null || team.nome_squadra.length == 0){
                fields_are_filled = false;
            }
            team.giocatori.forEach(player => {
                if (player.nome == null || player.nome.length == 0){
                    fields_are_filled = false;
                }
                if (player.cognome == null || player.cognome.length == 0){
                    fields_are_filled = false;
                }
            });
        });
        return fields_are_filled;
    }

    fieldsAreValid() {

        var fields_are_valid = true;
        var teams = this.state.squadre;
        teams.forEach(team => {
            if (this.validateNomeSquadra(team.nome_squadra) === false){
                fields_are_valid = false;
            }
            if (this.nomeSquadraNotExist(team.nome_squadra) === false){
                fields_are_valid = false;
            }
            team.giocatori.forEach(player => {
                if (this.validateNomeGiocatore(player.nome) === false){
                    fields_are_valid = false;
                }
                if (this.validateCognomeGiocatore(player.cognome) === false){
                    fields_are_valid = false;
                }
            });
        });
        return fields_are_valid;

    }

    validateNomeSquadra(value){
        let nome = value;
        const re = /^[0-9a-zA-Zàòùèéì ;:,.!?@#$%§£^&°<>|()='-_]+$/i;
        
       /*  let squadre = this.state.squadre;
        let squadre_gia_iscritte = this.state.squadre_gia_iscritte;
        var squadre_stesso_nome = 0;
        squadre.forEach((squadra) => {
            if (squadra.nome_squadra === value){
                squadre_stesso_nome++;
            }
        })
        var ambiti_selezionati = [];
        var ambiti = this.state.ambiti;
        var ambiti_checked = this.state.ambitichecked;
        ambiti.forEach((ambito, index) => {
            if (ambiti_checked[index] == true){
                ambiti_selezionati.push(ambito);
            }
        });
        squadre_gia_iscritte.forEach((squadra) => {
            if (squadra.nome_squadra === value && ambiti_selezionati.indexOf(squadra.ambito) > -1){
                squadre_stesso_nome++;
            }
        }); */
        return (re.test(nome) || nome.length === 0);
    }

    nomeSquadraNotExist(value){
        let nome = value;
        //Verificare che il nome inserito sia diverso dalle altre squadre comprese quelle già iscritte
        let squadre = this.state.squadre;
        let squadre_gia_iscritte = this.state.squadre_gia_iscritte;
        var squadre_stesso_nome = 0;
        squadre.forEach((squadra) => {
            if (squadra.nome_squadra === value){
                squadre_stesso_nome++;
            }
        });
        var ambiti_selezionati = [];
        var ambiti = this.state.ambiti;
        var ambiti_checked = this.state.ambitichecked;
        ambiti.forEach((ambito, index) => {
            if (ambiti_checked[index] == true){
                ambiti_selezionati.push(ambito);
            }
        });
        squadre_gia_iscritte.forEach((squadra) => {
            if (squadra.nome_squadra === value && ambiti_selezionati.indexOf(squadra.ambito) > -1){
                squadre_stesso_nome++;
            }
        });
        return squadre_stesso_nome < 2;
    }

    validateNomeGiocatore(value){
        let nome = value;
        const re = /^[a-zàòùèéì ,.'-]+$/i;
        return re.test(nome) || nome.length === 0;
    }

    validateCognomeGiocatore(value){
        let nome = value;
        const re = /^[a-zàòùèéì ,.'-]+$/i;
        return re.test(nome) || nome.length === 0;
    }

    render() {

        const autenticato = this.state.authorization !== '';
        const error = this.state.error;
        const errormessage = this.state.errormessage;

        const nome = this.state.nome;
        const cognome = this.state.cognome;

        const anchorElUserMenu = this.state.anchorElUserMenu;
        const open = Boolean(anchorElUserMenu);

        const id_ordine_classe = this.state.id_ordine_classe;
        const nome_progetto = this.state.nome_progetto;
        const nome_scuola = this.state.nome_scuola;
        const classe = this.state.classe;
        const sezione = this.state.sezione;
        const docente_referente1 = this.state.docente_referente1;
        const docente_referente2 = this.state.docente_referente2;

        const ambiti = this.state.ambiti;
        const ambitichecked = this.state.ambitichecked;
        const competition = this.state.competition;
        const competitionnames = this.state.competitionnames;
        //const competitionnames = [
        //    "Geometria", "Aritmetica", "Scrittura Creativa", "Edipo Fotografico", "Giochi di Edipo"
        //];
        var listacompetition = [];
        competition.forEach(function(value, index){
            listacompetition.push(competitionnames[ambiti.indexOf(value)]);
        });
        listacompetition = listacompetition.join(", ");
        const squadre = this.state.squadre;

        const registerenabled = this.state.registerenabled;
        const registercompleted = this.state.registercompleted;

        const nome_squadra_error = this.state.nome_squadra_error;
        const nome_squadra_exist_error = this.state.nome_squadra_exist_error;
        const nome_giocatore_error = this.state.nome_giocatore_error;
        const cognome_giocatore_error = this.state.cognome_giocatore_error;

        const isloading = this.state.isloading;

        return (
            <div>
                {registercompleted && (
                    <Redirect to="/" /> 
                )}
                { (autenticato && isloading === false) && ( 
                    <Fragment>
                    <Helmet htmlAttributes={{lang: 'it-IT'}}></Helmet>
                    <div className="App">
                        <header className="App-header-home">
                            <AppBar position="static" className="WelcomeBar">
                                <Toolbar>
                                    <table className="WelcomeMenu">
                                        <tbody>
                                            <tr>
                                                <td className="LogoMenuColumn">
                                                    <Logo />
                                                </td>
                                                <td className="HomeMenuColumn">
                                                    <HomeButton />
                                                    |
                                                    <TokalonButton />
                                                </td>
                                                <td className="AccountMenuButton">
                                                    { (nome === "" || cognome === "") ? (
                                                        <>
                                                            {/* <SocialButtonsInHeader /> */}
                                                            <Link to="/profile">
                                                                <Button
                                                                    className="AccountName"
                                                                    size="large"
                                                                    id="user-menu-button"
                                                                    aria-label="account of current user"
                                                                    aria-controls="user-menu"
                                                                    //aria-haspopup="true"
                                                                    //aria-expanded={Boolean(anchorElUserMenu) ? 'true' : undefined}
                                                                    //onClick={this.handleUserMenuClick}
                                                                    color="inherit"
                                                                >
                                                                    {/* <FontAwesomeIcon icon={faUser} /> */}
                                                                    <span className="WelcomeUser">{/* Bentornato */}</span>
                                                                </Button>
                                                            </Link>
                                                            |
                                                            <Button
                                                                className=""
                                                                size="large"
                                                                color="inherit"
                                                                id="logout-button"
                                                                onClick={this.handleLogoutClick}
                                                            >
                                                                Esci
                                                            </Button>
                                                        </>
                                                    ): (
                                                        <>
                                                            {/* <SocialButtonsInHeader /> */}
                                                            <Link to="/profile">
                                                                <Button
                                                                    className=""
                                                                    size="large"
                                                                    id="user-menu-button"
                                                                    aria-label="account of current user"
                                                                    aria-controls="user-menu"
                                                                    //aria-haspopup="true"
                                                                    //aria-expanded={Boolean(anchorElUserMenu) ? 'true' : undefined}
                                                                    //onClick={this.handleUserMenuClick}
                                                                    color="inherit" 
                                                                >
                                                                    {/* <FontAwesomeIcon icon={faUser} /> */}
                                                                    <span className="WelcomeUser">{/* Bentornato */}</span> { nome + " " + cognome }
                                                                </Button>
                                                            </Link>
                                                            |
                                                            <Button
                                                                className=""
                                                                size="large"
                                                                color="inherit"
                                                                id="logout-button"
                                                                onClick={this.handleLogoutClick}
                                                            >
                                                                Esci
                                                            </Button>
                                                        </>
                                                    )}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <Menu
                                        id="user-menu"
                                        anchorEl={anchorElUserMenu}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        open={open}
                                        onClose={this.handleCloseUserMenu}
                                        MenuListProps={{
                                            'aria-labelledby': 'user-menu-button',
                                        }}
                                    >
                                        <MenuItem><Link to="/profile">Profilo</Link></MenuItem>
                                        <MenuItem onClick={this.handleLogoutClick}>Esci</MenuItem>
                                    </Menu>
                                </Toolbar>
                            </AppBar>
                            {/* <Subheader></Subheader> */}
                        </header>
                        {/* <div className="separator"></div> */}
                        <div className="Page-title Orange">Iscrivi Squadre</div>
                        <div className="Page-subtitle-top">Qui puoi iscrivere nuove squadre per la tua classe.</div>
                        <div className="separator"></div>
                        {id_ordine_classe !== "" && (
                            <Box className="DatiClasse">
                                <fieldset>
                                    <legend>Stai partecipando con questa classe</legend>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <label><strong>Progetto: </strong>{nome_progetto}</label><br/>
                                            <label><strong>Scuola: </strong>{nome_scuola}</label><br/>
                                            <label><strong>Classe: </strong>{classe}</label><br/>
                                            <label><strong>Sezione: </strong>{sezione}</label><br/>          
                                        </Grid>
                                        <Grid item xs={6} style={{textAlign: "right"}}>
                                            <label><strong>Docenti </strong></label><br/>
                                            <label>{docente_referente1}</label><br/>
                                            <label>{docente_referente2}</label><br/>
                                        </Grid>
                                    </Grid>
                                </fieldset>
                            </Box>
                        )}  
                        <div className="separator"></div> 
                        { registercompleted && (
                            <Grid container spacing={1} style={{textAlign: "center"}}> 
                                <Grid item xs={12} className="Form-control Register" style={{margin: "0 auto"}}>
                                    <Alert severity="success" className="AlertClassiIscritte Success">
                                        Le squadre sono state iscritte con successo!
                                    </Alert>
                                </Grid>
                            </Grid>
                        )}
                        <div className="Page-subtitle-top">Scegli le competizioni</div>
                        <Grid container spacing={1} style={{textAlign: "center"}}> 
                            <Grid item xs={12} className="Form-control Register" style={{margin: "0 auto"}}>
                                <FormGroup
                                    aria-label="position"
                                    row
                                    onChange={this.handleSelectCompetition}
                                    style={{display: "table", margin: "0 auto"}}
                                >
                                    {ambiti.map((keyName, keyIndex) => (
                                        <Fragment key={keyIndex}>
                                            { ((ambitichecked.filter(function(check){return check == true}).length > 1 && ambitichecked[keyIndex] == true) || (ambitichecked[keyIndex] == false)) ? (
                                                <FormControlLabel value={keyName} control={<Checkbox checked={ambitichecked[keyIndex]} id={"competition_"+keyIndex}/>} style={{width: "auto"}} labelPlacement="end" label={competitionnames[keyIndex]} />
                                            ) : (
                                                <FormControlLabel value={keyName} control={<Checkbox checked={ambitichecked[keyIndex]} id={"competition_"+keyIndex}/>} style={{width: "auto", pointerEvents: "none"}} labelPlacement="end" label={competitionnames[keyIndex]} />
                                            )} 
                                           {/*  { ambiti[keyIndex] == 1 && (
                                                <Fragment>
                                                    { ((ambitichecked.filter(function(check){return check == true}).length > 1 && ambitichecked[keyIndex] == true) || (ambitichecked[keyIndex] == false)) ? (
                                                        <FormControlLabel value="1" control={<Checkbox checked={ambitichecked[keyIndex]} id={"competition_"+keyIndex}/>} style={{width: "auto"}} labelPlacement="end" label="Geometria" />
                                                    ) : (
                                                        <FormControlLabel value="1" control={<Checkbox checked={ambitichecked[keyIndex]} id={"competition_"+keyIndex}/>} style={{width: "auto", pointerEvents: "none"}} labelPlacement="end" label="Geometria" />
                                                    )}
                                                </Fragment>
                                            )}
                                            { ambiti[keyIndex] == 2 && (
                                                <Fragment>
                                                    { ((ambitichecked.filter(function(check){return check == true}).length > 1 && ambitichecked[keyIndex] == true) || (ambitichecked[keyIndex] == false)) ? (
                                                        <FormControlLabel value="2" control={<Checkbox checked={ambitichecked[keyIndex]} id={"competition_"+keyIndex}/>} style={{width: "auto"}} labelPlacement="end" label="Aritmetica" />
                                                    ) : (
                                                        <FormControlLabel value="2" control={<Checkbox checked={ambitichecked[keyIndex]} id={"competition_"+keyIndex}/>} style={{width: "auto", pointerEvents: "none"}} labelPlacement="end" label="Aritmetica" />
                                                    )}  
                                                </Fragment>
                                            )}
                                            { ambiti[keyIndex] == 3 && (
                                                <Fragment>
                                                    { ((ambitichecked.filter(function(check){return check == true}).length > 1 && ambitichecked[keyIndex] == true) || (ambitichecked[keyIndex] == false)) ? (
                                                        <FormControlLabel value="3" control={<Checkbox checked={ambitichecked[keyIndex]} id={"competition_"+keyIndex}/>} style={{width: "auto"}} labelPlacement="end" label="Scrittura Creativa" />
                                                    ) : (
                                                        <FormControlLabel value="3" control={<Checkbox checked={ambitichecked[keyIndex]} id={"competition_"+keyIndex}/>} style={{width: "auto", pointerEvents: "none"}} labelPlacement="end" label="Scrittura Creativa" />
                                                    )}   
                                                </Fragment>
                                            )}
                                            { ambiti[keyIndex] == 4 && (
                                                <Fragment>
                                                    { ((ambitichecked.filter(function(check){return check == true}).length > 1 && ambitichecked[keyIndex] == true) || (ambitichecked[keyIndex] == false)) ? (
                                                        <FormControlLabel value="4" control={<Checkbox checked={ambitichecked[keyIndex]} id={"competition_"+keyIndex}/>} style={{width: "auto"}} labelPlacement="end" label="Edipo Fotografico" />
                                                    ) : (
                                                        <FormControlLabel value="4" control={<Checkbox checked={ambitichecked[keyIndex]} id={"competition_"+keyIndex}/>} style={{width: "auto", pointerEvents: "none"}} labelPlacement="end" label="Edipo Fotografico" />
                                                    )}        
                                                </Fragment>
                                            )}
                                            { ambiti[keyIndex] == 5 && (
                                                <Fragment>
                                                    { ((ambitichecked.filter(function(check){return check == true}).length > 1 && ambitichecked[keyIndex] == true) || (ambitichecked[keyIndex] == false)) ? (
                                                        <FormControlLabel value="5" control={<Checkbox checked={ambitichecked[keyIndex]} id={"competition_"+keyIndex}/>} style={{width: "auto"}} labelPlacement="end" label="Giochi di Edipo" />
                                                    ) : (
                                                        <FormControlLabel value="5" control={<Checkbox checked={ambitichecked[keyIndex]} id={"competition_"+keyIndex}/>} style={{width: "auto", pointerEvents: "none"}} labelPlacement="end" label="Giochi di Edipo" />
                                                    )}  
                                                </Fragment>
                                            )}     */}
                                        </Fragment>
                                    ))}
                                </FormGroup>
                            </Grid>  
                        </Grid>  
                        { competition.length == 1 && (
                            <div className="Page-subtitle-top">Scegli il numero di squadre che vuoi iscrivere per la competizione di {listacompetition}</div>
                        )}
                        { competition.length > 1 && (
                            <div className="Page-subtitle-top">Scegli il numero di squadre che vuoi iscrivere per le competizioni di {listacompetition}</div>
                        )}
                        <Grid container spacing={1} style={{textAlign: "center"}}> 
                            <Grid item xs={12} className="Form-control Register" style={{margin: "0 auto"}}>
                                <div className="Form-control Register" style={{width: "60px", padding: "0px 27px", margin: "0 auto"}}>
                                    <TextField type="number" inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 1, max: 9 }} value={squadre.length} onChange={this.handleChangeTeams}/>
                                </div>
                            </Grid>
                        </Grid>
                        {squadre.map((x, i) =>
                            <Grid key={"team_"+i} container spacing={1} style={{textAlign: "center"}}> 
                                <Grid item xs={12} className="Form-control Register" style={{margin: "0 auto"}}>
                                    <hr></hr>
                                    <div className="Form-label" style={{width: "90%", padding: "0px 27px"}}><strong>Squadra {i+1}</strong></div>
                                    <br></br>
                                    <div className="Form-label" style={{width: "90%", padding: "0px 27px"}}>Nome squadra</div>
                                    <div className="Form-control" style={{width: "90%", padding: "0px 27px"}}>
                                        {(nome_squadra_error[i] === false && nome_squadra_exist_error[i] === false) && (
                                            <TextField id={"nome_squadra_"+i} inputProps={{maxLength : 255, 'data-team': i}} required placeholder="Nome squadra" variant="outlined" value={ squadre[i].nome_squadra } onChange={this.handleChangeTeamName}/>
                                        )}
                                        {nome_squadra_error[i] && (
                                            <TextField id={"nome_squadra_"+i} inputProps={{maxLength : 255, 'data-team': i}} required placeholder="Nome squadra" error helperText="Valore non valido" variant="outlined" value={ squadre[i].nome_squadra } onChange={this.handleChangeTeamName}/>
                                        )}
                                        {nome_squadra_exist_error[i] && (
                                            <TextField id={"nome_squadra_"+i} inputProps={{maxLength : 255, 'data-team': i}} required placeholder="Nome squadra" error helperText="Esiste già una squadra con questo nome nella tua classe per la stessa competizione, devi sceglierne un altro" variant="outlined" value={ squadre[i].nome_squadra } onChange={this.handleChangeTeamName}/>
                                        )}
                                    </div>    
                                    <div className="Form-label" style={{width: "90%", padding: "0px 27px"}}>Numero giocatori</div>
                                    <div className="Form-control" style={{width: "90%", padding: "0px 27px"}}>
                                        <TextField id={"numero_giocatori_"+i} type="number" inputProps={{ inputMode: 'numeric', pattern: '[2-4]', min: 2, max: 4, maxLength: 1, 'data-team': i}} value={squadre[i].giocatori.length} onChange={this.handleChangePlayers}/>
                                    </div> 
                                    {squadre[i].giocatori.map((y, j) =>
                                        <Grid key={"player"+j} container className="Form-control Register Half" style={{width: "100%", maxWidth: "600px", padding: "0px 27px"}}>
                                            <div className="Form-label" style={{width: "100%", padding: "0"}}>Nome e Cognome Giocatore {j+1}</div>    
                                            <Grid item xs={6}>
                                                {nome_giocatore_error[i][j] === false && (
                                                    <TextField style={{ width : "95%", paddingRight : "19px" }} inputProps={{maxLength : 255, 'data-team': i, 'data-player': j}} required placeholder="Nome" variant="outlined" onChange={this.handleChangePlayerFirstname} value={ y.nome }/>
                                                )}
                                                {nome_giocatore_error[i][j] && (
                                                    <TextField style={{ width : "95%", paddingRight : "19px" }} inputProps={{maxLength : 255, 'data-team': i, 'data-player': j}} required placeholder="Nome" variant="outlined" error helperText="Valore non valido" onChange={this.handleChangePlayerFirstname} value={ y.nome }/>
                                                )}
                                            </Grid>
                                            <Grid item xs={6}>
                                                {cognome_giocatore_error[i][j] === false && (
                                                    <TextField style={{ width : "95%", paddingLeft : "15px" }} inputProps={{maxLength : 255, 'data-team': i, 'data-player': j}} required placeholder="Cognome" variant="outlined" onChange={this.handleChangePlayerLastname} value={ y.cognome }/>
                                                )}
                                                {cognome_giocatore_error[i][j] && (
                                                    <TextField style={{ width : "95%", paddingLeft : "15px" }} inputProps={{maxLength : 255, 'data-team': i, 'data-player': j}} required placeholder="Cognome" variant="outlined" error helperText="Valore non valido" onChange={this.handleChangePlayerLastname} value={ y.cognome }/>
                                                )}
                                            </Grid>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>  
                        )}
                        {registerenabled && (
                            <div className="Page-subtitle-top"><strong>ATTENZIONE! SE VUOI ISCRIVERE LE STESSE SQUADRE PER PIU' COMPETIZIONI SELEZIONA TUTTE LE COMPETIZIONI</strong></div>
                        )}
                        <Grid container spacing={1} style={{textAlign: "center"}}> 
                            <Grid item xs={12} className="Form-control Register" style={{margin: "0 auto"}}>
                                <div className="Form-control Register">
                                    {registerenabled ? (
                                            <Button size="large" type="submit" variant="contained" color="primary" onClick={this.handleRegisterClick}>Conferma e Iscrivi le squadre</Button>
                                    ) : (
                                        <Button size="large" type="submit" variant="contained" disabled color="primary" onClick={this.handleRegisterClick}>Conferma e Iscrivi le squadre</Button>
                                    )}
                                </div>
                            </Grid>
                        </Grid>
                        <div className="separator"></div>
                        <AppBar position="static" className="Footer">
                            <div>© Copyright {new Date().getFullYear()} – Associazione ToKalon - <Link to="/privacy">Privacy Policy</Link> - Realizzato da <a href="https://www.creab.it/" target="_blank" rel="noreferrer">Creabit.</a></div>
                        </AppBar>  
                    </div>
                </Fragment>  
                )}
            </div>
        );
    }

}

export default RegisterTeam;