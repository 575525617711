import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';
import { Box } from '@mui/material';

class BackButton extends Component {

    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {}

    componentWillUnmount() {}

    render(){

        return(
            <Box className="TopButton">
                <Link to="/">
                    <Button
                        className=""
                        size="small"
                        variant="contained"
                        color="primary"
                        id="back-button"
                    >
                        Indietro
                    </Button>
                </Link>
            </Box>
        );
    }
}

export default BackButton;