import React, { Component, Fragment } from 'react';
import { Redirect } from "react-router-dom";
import Cookies from 'js-cookie';
import axios from 'axios';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import CheckIcon from '@mui/icons-material/Check';
import CircularProgress from '@mui/material/CircularProgress';
import Subheader from './Subheader';
import HomeButton from './HomeButton';
import SocialButtonsInHeader from './SocialButtonsInHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import {Helmet} from "react-helmet";
import Logo from './Logo';
import TokalonButton from './TokalonButton';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

class LoadDocument extends Component {

    _isMounted = false;

    constructor(props) {

        super(props);
        this.state = {
            authorization: Cookies.get('authorization') || '',
            user_email: Cookies.get('user_email') || '',
            wp_user_id: Cookies.get('wp_user_id') || 0,
            nome: Cookies.get('nome') || '',
            cognome: Cookies.get('cognome') || '',
            id_squadra: parseInt(Cookies.get('id_squadra')) || '',
            anchorElUserMenu: null,

            giocatori: [],

            id_progetto: "",
            nome_progetto: "",
            nome_scuola: "",
            cod_scuola: "",
            classe: "",
            sezione: "",
            id_docente_referente1: 0,
            id_docente_referente1: 2,
            docente_referente1: "",
            docente_referente2: "",

            ambito: 0,
            nome_squadra: "",
            risultato: 0,
            risultato_note: "",
            submitted: false,
            final_admitted: false,
            finale: 0,

            url_teacher_privacy: "03._Informativa_Docenti_e_partecipanti_-_Con_liberatoria_immagini_FINALI.pdf",
            url_student_privacy: "12._Informativa_partecipanti_MINORI_-_Con_liberatoria_immagini_FINALI.pdf",

            nomefile_docente_caricato: "",
            file_docente_caricato: null,
            nomefile_studente_caricati: [],
            file_studente_caricati: [],

            documenti_giocatori_da_associare: [],
            documenti_docente_da_associare: [],
            nomefile_docente_associato: "",
            nomefile_studente_associati: [],
            openassociateteachermodal: false,
            openassociatestudentmodal: false,
            student_to_associate: 0,
            loadteacherdocenabled: true,
            associateteacherdocenabled: true,
            loadstudentsdocenabled: [],
            associatestudentsdocenabled: [],

            loaddocumentcompleted: false,
            loaddocumentenabled: false,
            isloading: false,            
        }
        this.handleLogoutClick = this.handleLogoutClick.bind(this);
        this.handleUserMenuClick = this.handleUserMenuClick.bind(this);
        this.handleCloseUserMenu = this.handleCloseUserMenu.bind(this);
        this.handleDownload = this.handleDownload.bind(this);
        this.handleLoadTeacherPrivacyClick = this.handleLoadTeacherPrivacyClick.bind(this);
        this.handleLoadSingleStudentDocClick = this.handleLoadSingleStudentDocClick.bind(this);
        this.handleLoadStudentsDocClick = this.handleLoadStudentsDocClick.bind(this);
        this.handleAssociateTeacherPrivacyClick = this.handleAssociateTeacherPrivacyClick.bind(this);
        this.handleAssociateSingleStudentDocClick = this.handleAssociateSingleStudentDocClick.bind(this);
        this.handleOpenAssociateTeacherModal = this.handleOpenAssociateTeacherModal.bind(this);
        this.handleOpenAssociateStudentModal = this.handleOpenAssociateStudentModal.bind(this);
        this.fileInputTeacher = React.createRef();
        this.fileInputStudent = React.createRef();
    }

    componentDidMount() {
        this._isMounted = true;
        if (this._isMounted) {
            this.setState({ isloading: true });
        }

        var config = require('./config.json');

        var env_backend = config.env_backend;
        var api_url_backend = config.api_url_backend[env_backend];
        var authorization = config.api_auth_backend[env_backend];

        const settings = {
            headers: { Authorization: `Bearer ${authorization}` }
        };

        const apiUrl = api_url_backend + 'teams/getconfirmparticipation';
        const id_squadra = this.state.id_squadra;
        
        var params = { 
            "id_squadra": id_squadra
        };

        axios.post(apiUrl, params, settings).then(res => {
            if (this._isMounted) {
                let nomefiles = [];
                let enabled = [];
                let files = [];
                let giocatori = res.data.data.dati_squadra.giocatori;
                giocatori.forEach((giocatore, index) => {
                    nomefiles.push("");
                    enabled.push(true);
                    files.push(null);
                });
                this.setState({ id_progetto: res.data.data.dati_squadra.ordine_classe.id_progetto || 0}); 
                this.setState({ nome_progetto: res.data.data.dati_squadra.ordine_classe.progetto || ""}); 
                this.setState({ nome_scuola: res.data.data.dati_squadra.ordine_classe.scuola || ""}); 
                this.setState({ cod_scuola: res.data.data.dati_squadra.ordine_classe.cod_scuola || ""}); 
                this.setState({ classe: res.data.data.dati_squadra.ordine_classe.classe || ""}); 
                this.setState({ sezione: res.data.data.dati_squadra.ordine_classe.sezione || ""}); 
                this.setState({ docente_referente1: res.data.data.dati_squadra.ordine_classe.docente_referente1 || ""}); 
                this.setState({ id_docente_referente1: res.data.data.dati_squadra.ordine_classe.id_docente_referente1 || ""}); 
                this.setState({ docente_referente2: res.data.data.dati_squadra.ordine_classe.docente_referente2 || ""}); 
                this.setState({ id_docente_referente2: res.data.data.dati_squadra.ordine_classe.id_docente_referente2 || ""}); 
                this.setState({ ambito: res.data.data.dati_squadra.squadra.ambito || 0}); 
                this.setState({ nome_squadra: res.data.data.dati_squadra.squadra.nome_squadra || ""}); 
                this.setState({ risultato: res.data.data.dati_squadra.squadra.risultato || 0}); 
                this.setState({ risultato_note: res.data.data.dati_squadra.squadra.risultato_note || ""}); 
                this.setState({ submitted: res.data.data.dati_squadra.squadra.submitted || 0}); 
                this.setState({ final_admitted: res.data.data.dati_squadra.squadra.final_admitted || 0}); 
                this.setState({ finale: res.data.data.dati_squadra.squadra.finale || 0}); 
                this.setState({ giocatori: res.data.data.dati_squadra.giocatori || []}); 
                this.setState({ file_studente_caricati: files || []}); 
                this.setState({ nomefile_studente_caricati: nomefiles || []}); 
                this.setState({ nomefile_studente_associati: nomefiles || []}); 
                this.setState({ loadstudentsdocenabled: enabled || []});
                this.setState({ associatestudentsdocenabled: enabled || []});
                this.setState({ documenti_giocatori_da_associare: res.data.data.dati_documenti.documenti_giocatori || []}); 
                this.setState({ documenti_docente_da_associare: res.data.data.dati_documenti.documenti_docenti || []}); 
                this.setState({ isloading: false});
            }
        }).catch (error => {
            //console.log(error);
            this.setState({ isloading: false});
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
        Cookies.remove('id_squadra');
    }

    handleLogoutClick(event) {
        event.preventDefault();
        this.setState({ authorization: ''});
        Cookies.remove('authorization');
        Cookies.remove('user_email');
        Cookies.remove('nome');
        Cookies.remove('cognome');
        Cookies.remove('wp_user_id');
        Cookies.remove('newuser');
    }

    handleUserMenuClick(event){
        this.setState({ anchorElUserMenu: event.currentTarget});
    }

    handleCloseUserMenu(){
        this.setState({ anchorElUserMenu: null});
    }

    handleDownload(event) {

        var doc_url = event.currentTarget.dataset.url;

        var config = require('./config.json');
        var env_backend = config.env_backend;
        var api_url_backend = config.api_url_backend[env_backend];
        const apiUrl = api_url_backend + 'teams/download';
        var authorization = config.api_auth_backend[env_backend];

        const settings = {
            headers: { Authorization: `Bearer ${authorization}` },
            responseType: 'blob'
        };

        axios.post(apiUrl, {
            "url" : doc_url
        }, settings).then(res =>  {

            if (this._isMounted) {
                this.downloadFile(res, doc_url);
                this.setState({ isloading: false });
            }

        }).catch (error => {
            //console.log(error);
            this.setState({ isloading: false});
        });      
    }

    handleLoadTeacherPrivacyClick(event) {
        let files = event.target.files;
        let fn = files[0].name;
        let file = files[0];
        this.setState({ nomefile_docente_caricato: fn}, function(){
            this.setState({ file_docente_caricato: file});
            this.setState({ associateteacherdocenabled: false});
            let required_field_are_filled = this.requiredFieldsAreFilled();
            if (required_field_are_filled) {
                this.setState({ loaddocumentenabled: true });
            } else {
                this.setState({ loaddocumentenabled: false });
            }
        });        
    }

    handleAssociateTeacherPrivacyClick(event){
        let doc_index = parseInt(event.target.dataset.doc);
        let doc_url = this.state.documenti_docente_da_associare[doc_index].privacy_doc_docente_finale;
        this.setState({ nomefile_docente_associato: doc_url}, function(){
            this.setState({ loadteacherdocenabled: false});
            this.handleCloseAssociateTeacherModal();
            let required_field_are_filled = this.requiredFieldsAreFilled();
            if (required_field_are_filled) {
                this.setState({ loaddocumentenabled: true });
            } else {
                this.setState({ loaddocumentenabled: false });
            }
        });     
    }

    handleOpenAssociateTeacherModal(event){
        event.preventDefault();
        this.setState({ openassociateteachermodal: true });
    }

    handleCloseAssociateTeacherModal(){
        this.setState({ openassociateteachermodal: false });
    }

    handleLoadSingleStudentDocClick(event) {
        event.preventDefault();
        let files = event.target.files;
        let fn = files[0].name;
        let file = files[0];
        let element = event.target.dataset.player;
        let nomefile_studente_caricati = [...this.state.nomefile_studente_caricati];
        nomefile_studente_caricati[element] = fn;
        let file_studente_caricati = this.state.file_studente_caricati;
        file_studente_caricati[element] = file;
        let associatestudentsdocenabled = [...this.state.associatestudentsdocenabled];
        associatestudentsdocenabled[element] = false;
        this.setState({ nomefile_studente_caricati: nomefile_studente_caricati}, function(){
            this.setState({ file_studente_caricati: file_studente_caricati});
            this.setState({ associatestudentsdocenabled: associatestudentsdocenabled});
            let required_field_are_filled = this.requiredFieldsAreFilled();
            if (required_field_are_filled) {
                this.setState({ loaddocumentenabled: true });
            } else {
                this.setState({ loaddocumentenabled: false });
            }
        });
    }

    handleOpenAssociateStudentModal(event){
        event.preventDefault();
        let student = parseInt(event.target.dataset.student);
        this.setState({ student_to_associate: student}, function(){
            this.setState({ openassociatestudentmodal: true });
        });
    }

    handleCloseAssociateStudentModal(){
        this.setState({ openassociatestudentmodal: false });
    }

    handleAssociateSingleStudentDocClick(event){
        event.preventDefault();
        let element = parseInt(event.target.dataset.doc);
        let nomefile_studente_associati = [...this.state.nomefile_studente_associati];
        let student_to_associate = this.state.student_to_associate;
        let url = [...this.state.documenti_giocatori_da_associare][element].privacy_doc;
        nomefile_studente_associati[student_to_associate] = url;
        let loadstudentsdocenabled = [...this.state.loadstudentsdocenabled];
        loadstudentsdocenabled[student_to_associate] = false;
        
        this.setState({ nomefile_studente_associati: nomefile_studente_associati}, function(){
            this.setState({ loadstudentsdocenabled: loadstudentsdocenabled});
            this.handleCloseAssociateStudentModal();
            let required_field_are_filled = this.requiredFieldsAreFilled();
            if (required_field_are_filled) {
                this.setState({ loaddocumentenabled: true });
            } else {
                this.setState({ loaddocumentenabled: false });
            }
        });
    }

    handleLoadStudentsDocClick() {
        if (this._isMounted) {
            this.setState({ isloading: true });
        }
        let path = "doc_privacy_teams_finali";
        let cod_scuola = this.state.cod_scuola;
        let id_classe = parseInt(Cookies.get('id_ordine_classe'));
        let id_squadra = this.state.id_squadra;
        let id_docente = this.state.id_docente_referente1;
        let id_giocatori = [];
        let giocatori = this.state.giocatori;
        giocatori.forEach((element, index) => {
            id_giocatori.push(element.id);
        });
        id_giocatori = id_giocatori.join(",");
        let teacherfile = this.state.file_docente_caricato;
        let teacherfilename = this.state.nomefile_docente_caricato;
        let studentfile = this.state.file_studente_caricati;
        let studentfilename = this.state.nomefile_studente_caricati;
        let teacherassociatefilename = this.state.nomefile_docente_associato;
        let studentassociatefilename = this.state.nomefile_studente_associati;
        let formData = new FormData();
        formData.append("path", path);
        formData.append("codice_scuola", cod_scuola);
        formData.append("id_classe", id_classe);
        formData.append("id_squadra", id_squadra);
        formData.append("id_docente", id_docente);
        formData.append("id_giocatori", id_giocatori);
        formData.append("teacherassociatefilename", teacherassociatefilename);
        formData.append("teacherfile", new File([teacherfile], teacherfilename));
        studentfile.forEach((element, index) => {
            if (element === null){
                formData.append("studentfile[]", new File(["empty"], "empty.pdf")); 
            } else {
                formData.append("studentfile[]", new File([studentfile[index]], studentfilename[index])); 
            }
        });   
        studentassociatefilename.forEach((element, index) => {
            formData.append("studentassociatefilename[]", studentassociatefilename[index]);    
        });   
        var config = require('./config.json');
        var env_backend = config.env_backend;
        var api_url_backend = config.api_url_backend[env_backend];
        const apiUrl = api_url_backend + 'teams/upload';
        var authorization = config.api_auth_backend[env_backend];

        const settings = {
            headers: {
                 Authorization: `Bearer ${authorization}`,
                 "Content-Type": 'multipart/form-data'
            },
            responseType: 'blob'
        };


        axios.post(apiUrl, formData, settings).then(res =>  {

            this.setState({ isloading: false }, function(){
                this.setState({ loaddocumentcompleted: true });
                this.setState({ loaddocumentenabled: false });
            });

        }).catch (error => {
            if (this._isMounted) {
                
                this.setState({ isloading: false });
            }
        });  
    }

    requiredFieldsAreFilled() {

        var giocatori = [...this.state.giocatori];
        var numgiocatori = giocatori.length;
        var required_files = 1 + numgiocatori;
        var nomefile_docente_caricato = this.state.nomefile_docente_caricato;
        var nomefile_docente_associato = this.state.nomefile_docente_associato;

        //console.log("required files > " + required_files);

        var loaded = 0;

        //console.log("nomefile_docente_caricato > " + nomefile_docente_caricato);
        //console.log("nomefile_docente_associato > " + nomefile_docente_associato);

        if (nomefile_docente_caricato || nomefile_docente_associato){
            loaded = loaded + 1;
        }

        giocatori.forEach((element, index) => {

            //console.log([...this.state.nomefile_studente_associati]);

            var nomefile_studente_caricato = [...this.state.nomefile_studente_caricati][index];
            var nomefile_studente_associato = [...this.state.nomefile_studente_associati][index];

            //console.log("nomefile_studente_caricato > " + nomefile_studente_caricato);
            //console.log("nomefile_studente_associato > " + nomefile_studente_associato);

            if (nomefile_studente_caricato || nomefile_studente_associato) {
                loaded = loaded + 1;
            }
        });

        //console.log("loaded > " + loaded);

        if (loaded === required_files){
            return true;
        } else {
            return false;
        }
    }

    downloadFile(response, filename) {

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(response.data);
          return;
        }
       
        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(response.data);
        var link = document.createElement('a');
        link.style.display = 'none';
        link.href = data;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data)
        }, 100); 
    }

    render() {

        const autenticato = this.state.authorization !== '';

        const nome = this.state.nome;
        const cognome = this.state.cognome;

        const anchorElUserMenu = this.state.anchorElUserMenu;
        const open = Boolean(anchorElUserMenu);

        const giocatori = this.state.giocatori;

        const url_teacher_privacy = this.state.url_teacher_privacy;
        const url_student_privacy = this.state.url_student_privacy;

        const loaddocumentcompleted = this.state.loaddocumentcompleted;
        const loaddocumentenabled = this.state.loaddocumentenabled;
        const isloading = this.state.isloading;

        
        var fileinputteacher = this.fileInputTeacher;
        var fileinputstudent = this.fileInputStudent;

        const nomefile_docente_caricato = this.state.nomefile_docente_caricato;
        const nomefile_studente_caricati = this.state.nomefile_studente_caricati;

        const nomefile_docente_associato = this.state.nomefile_docente_associato;
        const nomefile_studente_associati = this.state.nomefile_studente_associati;
        const openassociateteachermodal = this.state.openassociateteachermodal;
        const documenti_docente_da_associare = this.state.documenti_docente_da_associare;
        const openassociatestudentmodal = this.state.openassociatestudentmodal;
        const documenti_giocatori_da_associare = this.state.documenti_giocatori_da_associare;
        const loadteacherdocenabled = this.state.loadteacherdocenabled;
        const associateteacherdocenabled = this.state.associateteacherdocenabled;
        const loadstudentsdocenabled = this.state.loadstudentsdocenabled;
        const associatestudentsdocenabled = this.state.associatestudentsdocenabled;

        return (
            <div>
                { loaddocumentcompleted && (
                    <Redirect to="/manageteam" /> 
                )}
                { (autenticato /*&& isloading === false*/) && ( 
                    <Fragment>
                        <Helmet htmlAttributes={{lang: 'it-IT'}}></Helmet>
                        <div className="App">
                            <header className="App-header-home">
                                <AppBar position="static" className="WelcomeBar">
                                    <Toolbar>
                                        <table className="WelcomeMenu">
                                            <tbody>
                                                <tr>
                                                    <td className="LogoMenuColumn">
                                                       <Logo />
                                                    </td>
                                                    <td className="HomeMenuColumn"> 
                                                        <HomeButton />
                                                        |
                                                        <TokalonButton />
                                                    </td>
                                                    <td className="AccountMenuButton">
                                                        { (nome === "" || cognome === "") ? (
                                                            <>
                                                                {/* <SocialButtonsInHeader /> */}
                                                                <Link to="/profile">
                                                                    <Button
                                                                        className="AccountName"
                                                                        size="large"
                                                                        id="user-menu-button"
                                                                        aria-label="account of current user"
                                                                        aria-controls="user-menu"
                                                                        //aria-haspopup="true"
                                                                        //aria-expanded={Boolean(anchorElUserMenu) ? 'true' : undefined}
                                                                        //onClick={this.handleUserMenuClick}
                                                                        color="inherit"
                                                                    >
                                                                        {/* <FontAwesomeIcon icon={faUser} /> */}
                                                                        <span className="WelcomeUser">{/* Bentornato */}</span>
                                                                    </Button>
                                                                </Link>
                                                                |
                                                                <Button
                                                                    className=""
                                                                    size="large"
                                                                    color="inherit"
                                                                    id="logout-button"
                                                                    onClick={this.handleLogoutClick}
                                                                >
                                                                    Esci
                                                                </Button>
                                                            </>
                                                        ): (
                                                            <>
                                                                {/* <SocialButtonsInHeader /> */}
                                                                <Link to="/profile">
                                                                    <Button
                                                                        className="AccountName"
                                                                        size="large"
                                                                        id="user-menu-button"
                                                                        aria-label="account of current user"
                                                                        aria-controls="user-menu"
                                                                        //aria-haspopup="true"
                                                                        //aria-expanded={Boolean(anchorElUserMenu) ? 'true' : undefined}
                                                                        //onClick={this.handleUserMenuClick}
                                                                        color="inherit" 
                                                                    >
                                                                        {/* <FontAwesomeIcon icon={faUser} /> */}
                                                                        <span className="WelcomeUser">{/* Bentornato */}</span> { nome + " " + cognome }
                                                                    </Button>
                                                                </Link>
                                                                |
                                                                <Button
                                                                    className=""
                                                                    size="large"
                                                                    color="inherit"
                                                                    id="logout-button"
                                                                    onClick={this.handleLogoutClick}
                                                                >
                                                                    Esci
                                                                </Button>
                                                            </>
                                                        )}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <Menu
                                            id="user-menu"
                                            anchorEl={anchorElUserMenu}
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            keepMounted
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            open={open}
                                            onClose={this.handleCloseUserMenu}
                                            MenuListProps={{
                                                'aria-labelledby': 'user-menu-button',
                                            }}
                                        >
                                            <MenuItem><Link to="/profile">Profilo</Link></MenuItem>
                                            <MenuItem onClick={this.handleLogoutClick}>Esci</MenuItem>
                                        </Menu>
                                    </Toolbar>
                                </AppBar>
                                {/* <Subheader></Subheader> */}
                            </header>
                            <div className="separator"></div>
                            <div className="Page-title Orange">Carica Documenti</div>
                            <div className="Page-subtitle-top">
                                Qui puoi scaricare il documento per il consenso alla privacy e per la liberatoria
                                per l'utilizzo di immagini da compilare e caricare firmato a cura dell'adulto accompagnatore.
                            </div>
                            <div className="separator"></div>
                            <Grid container spacing={1}> 
                                <Grid item xs={12} className="Form-control Register" style={{margin: "0 auto", textAlign: "center"}}>
                                    <Button size="large" type="submit" data-url={url_teacher_privacy} variant="contained" color="primary" onClick={this.handleDownload}>Scarica modello liberatoria Docente</Button>
                                </Grid>
                            </Grid>
                            <div className="separator"></div>
                            <Grid container spacing={1}> 
                                <Grid item xs={12} className="Form-control Register" style={{margin: "0 auto", textAlign: "center"}}>
                                    { nomefile_docente_caricato ? (
                                        <Grid container spacing={1} style={{textAlign: "center"}}> 
                                            <Grid item xs={8} style={{margin: "0 auto", lineHeight: "48px"}}>
                                                Documento caricato
                                            </Grid>
                                            <Grid item xs={4} style={{margin: "0 auto", lineHeight: "48px"}}>
                                                <CheckIcon className='Green' style={{ marginTop: "12px"}}></CheckIcon>
                                            </Grid>
                                        </Grid>
                                    ) : (
                                        <Fragment>
                                            { loadteacherdocenabled ? (
                                                <label htmlFor="FileInputTeacher" className="FileInputTeacher">Carica documento adulto accompagnatore</label>    
                                            ) : (
                                                <label htmlFor="FileInputTeacher" className="FileInputTeacher disabled">Carica documento adulto accompagnatore</label>    
                                            )}
                                            <input
                                                id="FileInputTeacher"
                                                type="file"
                                                className="FileInputTeacher"
                                                ref={this.fileInputTeacher}
                                                onChange={this.handleLoadTeacherPrivacyClick}
                                            />                                            
                                        </Fragment>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid container spacing={1}> 
                                <Grid item xs={12} className="Form-control Register" style={{margin: "0 auto", textAlign: "center"}}>
                                    { nomefile_docente_associato ? (
                                        <Grid container spacing={1} style={{textAlign: "center"}}> 
                                            <Grid item xs={8} style={{margin: "0 auto", lineHeight: "48px"}}>
                                                Documento associato
                                            </Grid>
                                            <Grid item xs={4} style={{margin: "0 auto", lineHeight: "48px"}}>
                                                <CheckIcon className='Green' style={{ marginTop: "12px"}}></CheckIcon>
                                            </Grid>
                                        </Grid>
                                    ) : (
                                        <Fragment>
                                            { associateteacherdocenabled ? (
                                                <Button size="large" type="submit" variant="contained" color="primary" onClick={this.handleOpenAssociateTeacherModal}>Associa documento esistente</Button>
                                            ) : (
                                                <Button size="large" type="submit" variant="contained" color="primary" disabled>Associa documento esistente</Button>
                                            )}
                                        </Fragment>
                                    )}
                                </Grid>
                            </Grid>
                            <Modal
                                open={openassociateteachermodal}
                                //onClose={this.handleCloseAssociateTeacherModal}
                                aria-labelledby="modal-associateteacher-title"
                                aria-describedby="modal-associateteacher-description"
                            >
                                <Box className="Modal"
                                    style={{width: "auto", minWidth: "400px", maxHeight: "50vh", overflowY: "auto"}}
                                >
                                    <IconButton aria-label="close"
                                        onClick={() => this.setState({ openassociateteachermodal: false })}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                    <TableContainer className="TableContainer">
                                        <Table className="TabellaAssociaDocumento" sx={{ minWidth: 700 }} aria-label="customized table">
                                        <TableHead className="HeadTabellaAssociaDocumento">   
                                            <TableRow>
                                                <TableCell align="center">SQUADRA</TableCell>
                                                <TableCell align="center">ADULTO ACCOMPAGNATORE</TableCell>
                                                <TableCell align="center"></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody className="BodyTabellaAssociaDocumento">
                                            {documenti_docente_da_associare.map((doc_docente, doc_docente_index) => (
                                                <TableRow key={doc_docente_index}>
                                                    <TableCell align="center" 
                                                        data-title="Squadra"
                                                    >
                                                        {doc_docente.nome_squadra}
                                                    </TableCell>
                                                    <TableCell align="center" 
                                                        data-title="Nome"
                                                    >
                                                        {doc_docente.docente_accompagnatore_nomecognome}
                                                    </TableCell>
                                                    <TableCell align="center" 
                                                        data-title="Cognome"
                                                    >
                                                        <Button size="small" type="button" data-doc={doc_docente_index} variant="contained" color="primary" onClick={this.handleAssociateTeacherPrivacyClick}>Associa</Button>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            </Modal>
                            <div className="separator"></div>
                            <div className="Page-subtitle-top">
                                Qui puoi scaricare il documento per il consenso alla privacy e per la liberatoria
                                per l'utilizzo di immagini dei tuoi studenti, da compilare e caricare firmato dai
                                genitori.
                            </div>
                            <div className="separator"></div>
                            <Grid container spacing={1} style={{textAlign: "center"}}> 
                                <Grid item xs={12} className="Form-control Register" style={{margin: "0 auto"}}>
                                    <Button size="large" type="submit" data-url={url_student_privacy} variant="contained" color="primary" onClick={this.handleDownload}>Scarica modello liberatoria studenti</Button>
                                </Grid>
                            </Grid>
                            <div className="separator"></div>
                            <Grid container spacing={1} style={{textAlign: "center"}}> 
                                <Grid item xs={12} className="Form-control Register" style={{margin: "0 auto", maxWidth: "700px"}}>
                                    <table style={{ width: "100%"}}>
                                        <tbody>
                                            { giocatori.map((player, index) => 
                                                <tr key={"giocatore" + index}>
                                                    <td style={{ width: "40%"}}>{ player.nome } { player.cognome }</td>  
                                                    <td style={{ textAlign: "left", paddingRight: "10px"}}>
                                                        { nomefile_studente_caricati[index] ? (
                                                            <Grid container spacing={1} style={{textAlign: "center"}}> 
                                                                <Grid item xs={8} style={{margin: "0 auto", lineHeight: "48px"}}>
                                                                    Documento caricato
                                                                </Grid>
                                                                <Grid item xs={4} style={{margin: "0 auto", lineHeight: "48px"}}>
                                                                    <CheckIcon className='Green' style={{ marginTop: "12px"}}></CheckIcon>
                                                                </Grid>
                                                            </Grid>

                                                        ) : (
                                                            <Fragment>
                                                                { loadstudentsdocenabled[index] ? (
                                                                    <label htmlFor={"FileInputStudent"+index } className="FileInputStudent">Carica documento</label>
                                                                ) : (
                                                                    <label htmlFor={"FileInputStudent"+index } className="FileInputStudent disabled">Carica documento</label>
                                                                )}                                                                
                                                                <input id={"FileInputStudent"+index} type="file" className="FileInputStudent" data-player={index} ref={this.fileInputStudent} onChange={this.handleLoadSingleStudentDocClick}/>
                                                            </Fragment>
                                                        )}
                                                    </td>
                                                    <td style={{ textAlign: "left", paddingLeft: "10px"}}>
                                                        { nomefile_studente_associati[index] ? (
                                                            <Grid container spacing={1} style={{textAlign: "center"}}> 
                                                                <Grid item xs={8} style={{margin: "0 auto", lineHeight: "48px"}}>
                                                                    Documento associato
                                                                </Grid>
                                                                <Grid item xs={4} style={{margin: "0 auto", lineHeight: "48px"}}>
                                                                    <CheckIcon className='Green' style={{ marginTop: "12px"}}></CheckIcon>
                                                                </Grid>
                                                            </Grid>
                                                        ) : (
                                                            <Fragment>
                                                                { associatestudentsdocenabled[index] ? (
                                                                    <input type="button" data-student={index} className="AssociateStudent" onClick={this.handleOpenAssociateStudentModal} value="Associa documento"/>
                                                                ) : (
                                                                    <input type="button" data-student={index} className="AssociateStudent" disabled value="Associa documento"/>
                                                                )}
                                                                
                                                            </Fragment>
                                                        )}
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </Grid>
                            </Grid>
                            <Modal
                                open={openassociatestudentmodal}
                                //onClose={this.handleCloseAssociateStudentModal}
                                aria-labelledby="modal-associatestudent-title"
                                aria-describedby="modal-associatestudent-description"
                            >
                                <Box className="Modal"
                                    style={{width: "auto", minWidth: "400px", maxHeight: "50vh", overflowY: "auto"}}
                                >
                                    <IconButton aria-label="close"
                                        onClick={() => this.setState({ openassociatestudentmodal: false })}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                    <TableContainer className="TableContainer">
                                        <Table className="TabellaAssociaDocumento" sx={{ minWidth: 700 }} aria-label="customized table">
                                        <TableHead className="HeadTabellaAssociaDocumento">   
                                            <TableRow>
                                                <TableCell align="center">SQUADRA</TableCell>
                                                <TableCell align="center">NOME E COGNOME</TableCell>
                                                <TableCell align="center"></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody className="BodyTabellaAssociaDocumento">
                                            {documenti_giocatori_da_associare.map((doc_studente, doc_studente_index) => (
                                                <TableRow key={doc_studente_index}>
                                                    <TableCell align="center" 
                                                        data-title="Squadra"
                                                    >
                                                        {doc_studente.nome_squadra}
                                                    </TableCell>
                                                    <TableCell align="center" 
                                                        data-title="Nome"
                                                    >
                                                        {doc_studente.nome} {doc_studente.cognome}
                                                    </TableCell>
                                                    <TableCell align="center" 
                                                        data-title="Cognome"
                                                    >
                                                        <Button size="small" type="button" data-doc={doc_studente_index} variant="contained" color="primary" onClick={this.handleAssociateSingleStudentDocClick}>Associa</Button>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            </Modal>
                            <div className="separator"></div>
                            { isloading && (
                                <Fragment>
                                    <CircularProgress className="Progress"/>
                                    <div className="separator"></div>
                                </Fragment>
                            )}
                            <Grid container spacing={1} style={{textAlign: "center"}}> 
                                <Grid item xs={12} className="Form-control Register" style={{margin: "0 auto"}}>
                                    { loaddocumentenabled ? (
                                        <Button size="large" type="submit" variant="contained" color="primary" onClick={this.handleLoadStudentsDocClick}>Carica documenti</Button>
                                    ) : (
                                        <Button size="large" type="submit" variant="contained" disabled color="primary" onClick={this.handleLoadStudentsDocClick}>Carica documenti</Button>
                                    )}
                                </Grid>
                            </Grid>
                            <div className="separator"></div>
                        </div>
                    </Fragment>
                )}
            </div>
        )
    }
}

export default LoadDocument;