import React, { Component, Fragment } from 'react';
import Cookies from 'js-cookie';
import { Redirect } from "react-router-dom";
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Link } from "react-router-dom";
import axios from 'axios';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Modal from '@mui/material/Modal';
import Subheader from './Subheader';
import SocialButtonsInHeader from './SocialButtonsInHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import HomeButton from './HomeButton';
import {Helmet} from "react-helmet";
import Logo from './Logo';
import TokalonButton from './TokalonButton';
import BackButton from './BackButton';

class Formation extends Component {

    _isMounted = false;

    constructor(props) {

        super(props);
        this.state = {
            authorization: Cookies.get('authorization') || '',
            user_email: Cookies.get('user_email') || '',
            nome: Cookies.get('nome') || '',
            cognome: Cookies.get('cognome') || '',
            id_progetto: parseInt(Cookies.get('id_progetto')) || '',
            tipo_progetto: parseInt(Cookies.get('tipo_progetto')) || '',
            id_ordine_classe: parseInt(Cookies.get('id_ordine_classe')) || '',
            id_ordine: parseInt(Cookies.get('id_ordine')) || '',
            anchorElUserMenu: null,

            nome_progetto: "",
            edizione: "",
            edizione_attiva: "",
            nome_scuola: "",
            classe: "",
            sezione: "",
            docente_referente1: "",
            docente_referente2: "",
            categorie: [],
            isloading: false,
            path_immagini_copertina: "",

            openimagemodal: false,
            url_image_to_show: null,
            image_hd_width: 0
        }
        this.handleLogoutClick = this.handleLogoutClick.bind(this);
        this.handleUserMenuClick = this.handleUserMenuClick.bind(this);
        this.handleCloseUserMenu = this.handleCloseUserMenu.bind(this);
        this.handleOpen = this.handleOpen.bind(this);
        this.handleDownload = this.handleDownload.bind(this);
        this.handleVideo = this.handleVideo.bind(this);
        this.handleOpenImageModal = this.handleOpenImageModal.bind(this);
        this.handleCloseImageModal = this.handleCloseImageModal.bind(this);
        this.getImageHdWidth = this.getImageHdWidth.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        if (this._isMounted) {
            this.setState({ isloading: true });
        }

        var ImageHdWidth = this.getImageHdWidth();
        this.setState({ image_hd_width: ImageHdWidth });

        var config = require('./config.json');
        var env_backend = config.env_backend;
        var api_url_backend = config.api_url_backend[env_backend];
        const apiUrl = api_url_backend + 'trainings/getdocuments';
        var authorization = config.api_auth_backend[env_backend];

        const settings = {
            headers: { Authorization: `Bearer ${authorization}` }
        };

        const id_progetto = this.state.id_progetto;
        const tipo_progetto = this.state.tipo_progetto;
        var params;
        
        if (tipo_progetto == 1 || tipo_progetto == 2){
        //if (id_progetto == 2 || id_progetto == 3){
            const id_ordine_classe = this.state.id_ordine_classe;
            params = { 
                "id_progetto" : id_progetto,
                "id_ordine_classe": id_ordine_classe,
            };
        }
        if (tipo_progetto == 3) {
        //if (id_progetto == 1) {
            params = { 
                "id_progetto" : id_progetto
            };
        }
        if (tipo_progetto == 4) {
        //if (id_progetto == 4) {
            const id_ordine = this.state.id_ordine;
            params = { 
                "id_progetto" : id_progetto,
                "id_ordine": id_ordine,
            };
        }

        axios.post(apiUrl, params, settings).then(res => {
            //console.log(res);
            
            if (this._isMounted) {
                this.setState({ nome_progetto: res.data.data.dati_classe.progetto || ""}); 
                this.setState({ edizione: res.data.data.dati_classe.edizione || ""}); 
                this.setState({ edizione_attiva: res.data.data.edizione_attiva || ""}); 
                this.setState({ nome_scuola: res.data.data.dati_classe.scuola || ""}); 
                this.setState({ classe: res.data.data.dati_classe.classe || ""}); 
                this.setState({ sezione: res.data.data.dati_classe.sezione || ""}); 
                this.setState({ docente_referente1: res.data.data.dati_classe.docente_referente1 || ""}); 
                this.setState({ docente_referente2: res.data.data.dati_classe.docente_referente2 || ""}); 
                this.setState({ categorie: res.data.data.categorie || []}); 
                this.setState({ path_immagini_copertina: res.data.data.path_immagini_copertina});
                this.setState({ isloading: false });
            }

        }).catch (error => {
            //console.log(error);
            this.setState({ isloading: false});
        });    
    }

    componentWillUnmount() {
        this._isMounted = false;
        Cookies.remove('tipo_progetto');
        Cookies.remove('id_progetto');
        Cookies.remove('id_ordine_classe');
        Cookies.remove('id_ordine');
    }

    getImageHdWidth(){
        let screenWidth = window.innerWidth;
        let screenHeight = window.innerHeight;
        let imageWidth = Math.min(screenWidth, screenHeight);
        return imageWidth;
    }

    handleLogoutClick(event) {
        event.preventDefault();
        this.setState({ authorization: ''});
        Cookies.remove('authorization');
        Cookies.remove('user_email');
        Cookies.remove('nome');
        Cookies.remove('cognome');
        Cookies.remove('wp_user_id');
        Cookies.remove('newuser');
    }

    handleUserMenuClick(event){
        //console.log(event.currentTarget);
        this.setState({ anchorElUserMenu: event.currentTarget});
    }

    handleCloseUserMenu(){
        this.setState({ anchorElUserMenu: null});
    }

    handleOpen(event){
        //console.log(event.currentTarget.dataset.url);

        var doc_url = event.currentTarget.dataset.url;

        var config = require('./config.json');
        var env_backend = config.env_backend;
        var api_url_backend = config.api_url_backend[env_backend];
        const apiUrl = api_url_backend + 'trainings/open';
        var authorization = config.api_auth_backend[env_backend];

        const settings = {
            headers: { Authorization: `Bearer ${authorization}` },
            responseType: 'blob'
        };

        axios.post(apiUrl, {
            "url" : doc_url
        }, settings).then(res =>  {

            //console.log(res);

            
            if (this._isMounted) {

                this.openFile(res, doc_url);
                this.setState({ isloading: false });
            }

        }).catch (error => {
            //console.log(error);
            this.setState({ isloading: false});
        });    
    }

    handleDownload(event){
        //console.log(event.currentTarget.dataset.url);
        var doc_url = event.currentTarget.dataset.url;

        var config = require('./config.json');
        var env_backend = config.env_backend;
        var api_url_backend = config.api_url_backend[env_backend];
        const apiUrl = api_url_backend + 'trainings/download';
        var authorization = config.api_auth_backend[env_backend];

        const settings = {
            headers: { Authorization: `Bearer ${authorization}` },
            responseType: 'blob'
        };

        axios.post(apiUrl, {
            "url" : doc_url
        }, settings).then(res =>  {

            //console.log(res);

            
            if (this._isMounted) {

                this.downloadFile(res, doc_url);
                this.setState({ isloading: false });
            }

        }).catch (error => {
            //console.log(error);
            this.setState({ isloading: false});
        });    
    }

    handleVideo(event){
        var url = event.currentTarget.dataset.url;
        var is_valid_url = false;
        var is_secure_url = false;
        var is_embed_code = false;
        var urlobj;
        var htmlobj;
        var iframe;

        try {
            urlobj = new URL(url);
            is_valid_url = true;
            is_secure_url = urlobj.protocol === "https:";
        } catch (_) {
            is_valid_url = false;
        }

        if (is_valid_url === false){
            htmlobj = new DOMParser().parseFromString(url, "text/html");
            iframe = htmlobj.getElementsByTagName("iframe")[0];
            if ((iframe !== null) && (typeof iframe !== 'undefined')){
                is_embed_code = true;
            }
        }
        
        if (is_secure_url){
            window.open(url);
        }

        if (is_embed_code){
            var newwin = window.open();
            iframe.style.opacity = 0.99;
            newwin.document.body.append(iframe);
        }
    }

    handleOpenImageModal(event){
        event.preventDefault();
        let url_image_to_show = event.currentTarget.src;
        this.setState({ url_image_to_show: url_image_to_show}, function(){
            this.setState({ openimagemodal: true });
        });

    }

    handleCloseImageModal(event){
        event.preventDefault();
        this.setState({ openimagemodal: false });
    }

    downloadFile(response, filename) {

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(response.data);
          return;
        }
       
        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(response.data);
        var link = document.createElement('a');
        link.style.display = 'none';
        link.href = data;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data)
        }, 100); 
    }

    openFile(response, filename){
        const data = window.URL.createObjectURL(response.data);
        var link = document.createElement('a');
        link.style.display = 'none';
        link.href = data;
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data)
        }, 100); 
    }

    render() {

        const autenticato = this.state.authorization !== '';

        const nome = this.state.nome;
        const cognome = this.state.cognome;
        
        const anchorElUserMenu = this.state.anchorElUserMenu;
        const open = Boolean(anchorElUserMenu);

        const id_progetto = this.state.id_progetto;
        const tipo_progetto = this.state.tipo_progetto;
        const id_ordine_classe = this.state.id_ordine_classe;
        const nome_progetto = this.state.nome_progetto;
        const nome_scuola = this.state.nome_scuola;
        const classe = this.state.classe;
        const sezione = this.state.sezione;
        const docente_referente1 = this.state.docente_referente1;
        const docente_referente2 = this.state.docente_referente2;
        const isloading = this.state.isloading;
        const categorie = this.state.categorie;

        const path_immagini_copertina = this.state.path_immagini_copertina;

        const openimagemodal = this.state.openimagemodal;
        const url_image_to_show = this.state.url_image_to_show;
        const image_hd_width = this.state.image_hd_width;

        const edizione = this.state.edizione;
        const edizione_attiva = this.state.edizione_attiva;
        
        return (
            <div>
            {id_progetto == "" && (
                <Redirect to="/" /> 
            )}   
            {/* {((id_progetto == 2 || id_progetto == 3) && id_ordine_classe == "") && (
                <Redirect to="/" /> 
            )} */}
            { (autenticato && isloading === false) && ( 
            <Fragment>
            <Helmet htmlAttributes={{lang: 'it-IT'}}></Helmet>    
            <div className="App">
                <header className="App-header-home">
                    <AppBar position="static" className="WelcomeBar">
                        <Toolbar>
                            <table className="WelcomeMenu">
                                <tbody>
                                    <tr>
                                        <td className="LogoMenuColumn">
                                            <Logo />
                                        </td>
                                        <td className="HomeMenuColumn">
                                            <HomeButton />
                                            |
                                            <TokalonButton />
                                        </td>
                                        <td className="AccountMenuButton">
                                            { (nome === "" || cognome === "") ? (
                                                <>
                                                    {/* <SocialButtonsInHeader /> */}
                                                    <Link to="/profile">
                                                        <Button
                                                            className="AccountName"
                                                            size="large"
                                                            id="user-menu-button"
                                                            aria-label="account of current user"
                                                            aria-controls="user-menu"
                                                            //aria-haspopup="true"
                                                            //aria-expanded={Boolean(anchorElUserMenu) ? 'true' : undefined}
                                                            //onClick={this.handleUserMenuClick}
                                                            color="inherit"
                                                        >
                                                            {/* <FontAwesomeIcon icon={faUser} /> */}
                                                            <span className="WelcomeUser">{/* Bentornato */}</span>
                                                        </Button>
                                                    </Link>
                                                    |
                                                    <Button
                                                        className=""
                                                        size="large"
                                                        color="inherit"
                                                        id="logout-button"
                                                        onClick={this.handleLogoutClick}
                                                    >
                                                        Esci
                                                    </Button>
                                                </>
                                            ): (
                                                <>
                                                    {/* <SocialButtonsInHeader /> */}
                                                    <Link to="/profile">
                                                        <Button
                                                            className=""
                                                            size="large"
                                                            id="user-menu-button"
                                                            aria-label="account of current user"
                                                            aria-controls="user-menu"
                                                            //aria-haspopup="true"
                                                            //aria-expanded={Boolean(anchorElUserMenu) ? 'true' : undefined}
                                                            //onClick={this.handleUserMenuClick}
                                                            color="inherit" 
                                                        >
                                                            {/* <FontAwesomeIcon icon={faUser} /> */}
                                                            <span className="WelcomeUser">{/* Bentornato */}</span> { nome + " " + cognome }
                                                        </Button>
                                                    </Link>
                                                    |
                                                    <Button
                                                        className=""
                                                        size="large"
                                                        color="inherit"
                                                        id="logout-button"
                                                        onClick={this.handleLogoutClick}
                                                    >
                                                        Esci
                                                    </Button>
                                                </>
                                            )}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <Menu
                                id="user-menu"
                                anchorEl={anchorElUserMenu}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={open}
                                onClose={this.handleCloseUserMenu}
                                MenuListProps={{
                                    'aria-labelledby': 'user-menu-button',
                                }}
                            >
                                <MenuItem><Link to="/profile">Profilo</Link></MenuItem>
                                <MenuItem onClick={this.handleLogoutClick}>Esci</MenuItem>
                            </Menu>
                        </Toolbar>
                    </AppBar>
                    {/* <Subheader></Subheader> */}
                    <BackButton />
                </header>
                {/* <div className="Box-fullpage">
                    <div className="Home-description">
                        <p>In questa area troverai tutte le informazioni relative ai materiali formativi che hai acquistato per le tue classi. Ti invieremo una mail quando questa sezione sarà attiva. A presto!</p>
                        <div className="Form-control" style={{textAlign: "center"}}>
                            <Link to="/">
                                <Button size="large" className="submit" variant="contained" color="secondary">Torna alla home</Button>
                            </Link>
                        </div>
                    </div>
                </div>  */}
                <div className="separator"></div>
                <div className="Page-title Orange">Area Risorse</div>
                <div className="Page-subtitle-top">Qui puoi vedere l'elenco dei materiali a disposizione della tua classe.</div>
                <div className="separator"></div>
                {id_ordine_classe !== "" && (
                    <Box className="DatiClasse">
                        <fieldset>
                            <legend>Stai partecipando con questa classe</legend>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <label><strong>Progetto: </strong>{nome_progetto}</label><br/>
                                    <label><strong>Scuola: </strong>{nome_scuola}</label><br/>
                                    <label><strong>Classe: </strong>{classe}</label><br/>
                                    <label><strong>Sezione: </strong>{sezione}</label><br/>          
                                </Grid>
                                <Grid item xs={6} style={{textAlign: "right"}}>
                                    <label><strong>Docenti </strong></label><br/>
                                    <label>{docente_referente1}</label><br/>
                                    <label>{docente_referente2}</label><br/>
                                </Grid>
                            </Grid>
                        </fieldset>
                    </Box>
                )}
                <Modal
                    open={openimagemodal}
                    onClose={this.handleCloseImageModal}
                    aria-labelledby="modal-image-title"
                    aria-describedby="modal-image-description"
                >
                    <Box className="Modal"
                        style={{width: "auto"}}
                        onClick={this.sub_handleCloseImageModal}>
                        { url_image_to_show !== "" && (
                            <img
                                src={url_image_to_show}
                                alt="lightbox-product-img"
                                loading="lazy"
                                width={image_hd_width}
                            />
                        )}
                    </Box>
                </Modal> 
                <div className="separator"></div>
                { (edizione === edizione_attiva) ? (
                    <>
                    {Object.keys(categorie).length === 0 ? (
                        <div className="adaptive"></div>
                    ):(
                        <div>
                        {Object.values(categorie).map((categoria, index) => (
                                <Box key={index}>
                                    <div className="Page-title Orange">{categoria.titolo}</div>
                                    <div className="Page-subtitle-top">{categoria.sottotitolo}</div>
                                    <div className="separator"></div>
                                    <TableContainer className="TableContainer" style={{margin: "0 auto"}}>
                                        <Table className="TabellaClassi" sx={{ minWidth: 700 }} aria-label="customized table">
                                            <TableHead className="HeadTabellaClassi">   
                                                <TableRow>
                                                    {/* <TableCell>GRADO</TableCell> */}
                                                    <TableCell>MATERIALE</TableCell>
                                                    <TableCell>DESCRIZIONE</TableCell>
                                                    <TableCell>AZIONI</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody className="BodyTabellaClassi">
                                                {categoria.documenti.map((documento, indexdoc) => (
                                                    <TableRow key={indexdoc}>
                                                        {/* <TableCell align="center"
                                                            data-title="Grado"
                                                        >{documento.grado}</TableCell> */}
                                                        <TableCell align="center"
                                                            data-title="Materiale"
                                                        >
                                                            <div>
                                                                { documento.immagine_copertina !== "" && (
                                                                    <div>
                                                                        <img
                                                                        src={path_immagini_copertina + documento.immagine_copertina}
                                                                        alt="lightbox-product-img"
                                                                        loading="lazy"
                                                                        width={20}
                                                                        onClick={this.handleOpenImageModal}
                                                                        />
                                                                    </div>
                                                                )}
                                                                <div>{documento.titolo}</div>
                                                            </div>
                                                        </TableCell>
                                                        <TableCell align="center"
                                                            data-title="Descrizione"
                                                            dangerouslySetInnerHTML={{__html: documento.descrizione}}
                                                        ></TableCell>
                                                        <TableCell align="center"
                                                            data-title="Azioni"
                                                        >
                                                            {(documento.file_scaricabile && [".pdf", ".jpg", ".png", ".gif"].some(ext => documento.file_scaricabile.endsWith(ext))) && (
                                                                <Button className="ActionButton" size="small" data-url={documento.file_scaricabile} onClick={this.handleOpen}>APRI</Button>
                                                            )}
                                                            {documento.file_scaricabile && (    
                                                                <Button className="ActionButton" size="small" data-url={documento.file_scaricabile} onClick={this.handleDownload}>SCARICA</Button>
                                                            )}
                                                            {documento.video && (
                                                                <Button className="ActionButton" size="small" data-url={documento.video} onClick={this.handleVideo}>PLAY VIDEO</Button>
                                                            )}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                        ))}
                        </div>
                    )}
                    </>
                ) : (
                    <div className="Page-subtitle-top">Questa edizione è terminata, non puoi più accedere a queste risorse.</div>
                )}
                <div className="separator"></div>
                <AppBar position="static" className="Footer">
                    <div>© Copyright {new Date().getFullYear()} – Associazione ToKalon - <Link to="/privacy">Privacy Policy</Link> - Realizzato da <a href="https://www.creab.it/" target="_blank" rel="noreferrer">Creabit.</a></div>
                </AppBar>              
            </div>
            </Fragment>
            )}
            { autenticato === false && (
                <Redirect to="/login" />
            )}
            </div>
        );
    }

}

export default Formation;