import React, { Component } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';
import { Redirect } from "react-router-dom";
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormHelperText from '@mui/material/FormHelperText';
import purify from "dompurify";
import { Link } from "react-router-dom";
import {Helmet} from "react-helmet";
import Subheader from './Subheader';
import HomeButton from './HomeButton';
import SocialButtonsInHeader from './SocialButtonsInHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { withRouter } from "react-router";
import Logo from './Logo';
import TokalonButton from './TokalonButton';

class Supporter extends Component {

    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            authorization: Cookies.get('authorization') || '',
            wp_user_id: Cookies.get('wp_user_id') || 0,
            user_email: Cookies.get('user_email') || '',
            nome: Cookies.get('nome') || '',
            cognome: Cookies.get('cognome') || '',

            anchorElUserMenu: null,
            
            nome_progetto: "",
            anno_progetto: "",
            descrizione_progetto: "",
            descrizione_prodotto: "",
            importo_prodotto: 0,
            docente_sped_nome: "",
            docente_sped_cognome: "",
            docente_sped_indirizzo: "",
            docente_sped_citta: "",
            docente_sped_provincia: "XX",
            docente_sped_cap: "",
            docente_sped_cellulare: "",
            docente_sped_note_consegna: "",
            docente_fatt_nome: "",
            docente_fatt_cognome: "",
            docente_fatt_cf_piva: "",
            docente_fatt_indirizzo: "",
            docente_fatt_citta: "",
            docente_fatt_provincia: "XX",
            docente_fatt_cap: "",
            scuola_sped_docente_ref: null,
            scuola_sped_nome: "",
            scuola_sped_indirizzo:"",
            scuola_sped_citta: "",
            scuola_sped_provincia: "XX",
            scuola_sped_cap: "",
            scuola_sped_cellulare: "",
            scuola_sped_note_consegna: "",

            id_progetto: props.match.params.id,
            id_metodo_pagamento: 2,
            id_scuola: null,
            sped_nome: "",
            sped_cognome: "",
            sped_nome_scuola: "",
            sped_indirizzo: "",
            sped_citta: "",
            sped_provincia: "XX",
            sped_cap: "",
            sped_cellulare: "",
            note_consegna: "",
            fatt_nome: "",
            fatt_cognome: "",
            fatt_cf_piva: "",
            fatt_indirizzo: "",
            fatt_citta: "",
            fatt_provincia: "XX",
            fatt_cap: "",
            importo_netto: 0,
            sconto: 0,
            importo_totale: 0,

            error: false,
            errorcode: '',
            errormessage: '',
            isloading: false,
            orderenabled: false,

            sped_nome_error: false,
            sped_cognome_error: false,
            sped_nome_scuola_error: false,
            sped_indirizzo_error: false,
            sped_citta_error: false,
            sped_provincia_error: false,
            sped_cap_error: false,
            sped_cellulare_error: false,
            note_consegna_error: false,
            fatt_nome_error: false,
            fatt_cognome_error: false,
            fatt_cf_piva_error: false,
            fatt_indirizzo_error: false,
            fatt_citta_error: false,
            fatt_provincia_error: false,
            fatt_cap_error: false,
            ordercompleted: false
        };
        this.handleChangeSpedNome = this.handleChangeSpedNome.bind(this);
        this.handleChangeSpedCognome = this.handleChangeSpedCognome.bind(this);
        this.handleChangeSpedNomeScuola = this.handleChangeSpedNomeScuola.bind(this);
        this.handleChangeSpedIndirizzo = this.handleChangeSpedIndirizzo.bind(this);
        this.handleChangeSpedCitta = this.handleChangeSpedCitta.bind(this);
        this.handleChangeSpedProvincia = this.handleChangeSpedProvincia.bind(this);
        this.handleChangeSpedCap = this.handleChangeSpedCap.bind(this);
        this.handleChangeSpedCellulare = this.handleChangeSpedCellulare.bind(this);
        this.handleChangeNoteConsegna = this.handleChangeNoteConsegna.bind(this);
        this.handleChangeFattNome = this.handleChangeFattNome.bind(this);
        this.handleChangeFattCognome = this.handleChangeFattCognome.bind(this);
        this.handleChangeFattCfPiva = this.handleChangeFattCfPiva.bind(this);
        this.handleChangeFattIndirizzo = this.handleChangeFattIndirizzo.bind(this);
        this.handleChangeFattCitta = this.handleChangeFattCitta.bind(this);
        this.handleChangeFattProvincia = this.handleChangeFattProvincia.bind(this);
        this.handleChangeFattCap = this.handleChangeFattCap.bind(this);
        this.handleChangeMetodoPagamento = this.handleChangeMetodoPagamento.bind(this);

        this.handleLogoutClick = this.handleLogoutClick.bind(this);
        this.handleUserMenuClick = this.handleUserMenuClick.bind(this);
        this.handleCloseUserMenu = this.handleCloseUserMenu.bind(this);
        this.handleOrderClick = this.handleOrderClick.bind(this);

    }

    componentDidMount() {
        this._isMounted = true;
        if (this._isMounted) {
            this.setState({ isloading: true });
        }
        var config = require('./config.json');
        var env_backend = config.env_backend;
        var api_url_backend = config.api_url_backend[env_backend];
        const apiUrl = api_url_backend + 'orders/getsupporterdata';
        var authorization = config.api_auth_backend[env_backend];
        var email = this.state.user_email;

        const settings = {
            headers: { Authorization: `Bearer ${authorization}` }
        };

        axios.post(apiUrl, { 
            "id_progetto" : this.state.id_progetto,
            "email" : email
        }, settings).then(res => {
            //console.log(res);
                        
            if (this._isMounted) {
                this.setState({ id_progetto: res.data.data.dati_progetto.id });
                this.setState({ id_utente: res.data.data.dati_spedizione_docente.id_utente});
                this.setState({ id_scuola: res.data.data.dati_spedizione_docente.id_scuola});
                this.setState({ nome_progetto: res.data.data.dati_progetto.nome});
                this.setState({ anno_progetto: res.data.data.dati_progetto.anno});
                this.setState({ descrizione_progetto: res.data.data.dati_progetto.descrizione});
                this.setState({ descrizione_prodotto: res.data.data.dati_prodotto.descrizione});
                this.setState({ importo_prodotto: res.data.data.dati_prodotto.importo});
                this.setState({ docente_sped_nome: res.data.data.dati_spedizione_docente.nome});
                this.setState({ docente_sped_cognome: res.data.data.dati_spedizione_docente.cognome});
                this.setState({ docente_sped_indirizzo: res.data.data.dati_spedizione_docente.indirizzo});
                this.setState({ docente_sped_citta: res.data.data.dati_spedizione_docente.citta});
                this.setState({ docente_sped_provincia: res.data.data.dati_spedizione_docente.provincia});
                this.setState({ docente_sped_cap: res.data.data.dati_spedizione_docente.cap});
                this.setState({ docente_sped_cellulare: res.data.data.dati_spedizione_docente.cellulare});
                this.setState({ docente_sped_note_consegna: res.data.data.dati_spedizione_docente.note_consegna});
                this.setState({ docente_fatt_nome: res.data.data.dati_fatturazione_docente.nome});
                this.setState({ docente_fatt_cognome: res.data.data.dati_fatturazione_docente.cognome});
                this.setState({ docente_fatt_cf_piva: res.data.data.dati_fatturazione_docente.cf_piva});
                this.setState({ docente_fatt_indirizzo: res.data.data.dati_fatturazione_docente.indirizzo});
                this.setState({ docente_fatt_citta: res.data.data.dati_fatturazione_docente.citta});
                this.setState({ docente_fatt_provincia: res.data.data.dati_fatturazione_docente.provincia});
                this.setState({ docente_fatt_cap: res.data.data.dati_fatturazione_docente.cap});
                this.setState({ scuola_sped_docente_ref: res.data.data.dati_spedizione_scuola.docente_referente});
                this.setState({ scuola_sped_nome: res.data.data.dati_spedizione_scuola.nome_scuola});
                this.setState({ scuola_sped_indirizzo: res.data.data.dati_spedizione_scuola.indirizzo});
                this.setState({ scuola_sped_citta: res.data.data.dati_spedizione_scuola.citta});
                this.setState({ scuola_sped_provincia: res.data.data.dati_spedizione_scuola.provincia});
                this.setState({ scuola_sped_cap: res.data.data.dati_spedizione_scuola.cap});
                this.setState({ scuola_sped_cellulare: res.data.data.dati_spedizione_scuola.cellulare});
                this.setState({ scuola_sped_note_consegna: res.data.data.dati_spedizione_scuola.note_consegna});               

                this.setState({ sped_nome: res.data.data.dati_spedizione_docente.nome});
                this.setState({ sped_cognome: res.data.data.dati_spedizione_docente.cognome});
                this.setState({ sped_nome_scuola: res.data.data.dati_spedizione_docente.nome_scuola});
                this.setState({ sped_indirizzo: res.data.data.dati_spedizione_docente.indirizzo});
                this.setState({ sped_citta: res.data.data.dati_spedizione_docente.citta});
                this.setState({ sped_provincia: res.data.data.dati_spedizione_docente.provincia});
                this.setState({ sped_cap: res.data.data.dati_spedizione_docente.cap});
                this.setState({ sped_cellulare: res.data.data.dati_spedizione_docente.cellulare});
                this.setState({ note_consegna: res.data.data.dati_spedizione_docente.note_consegna});
                this.setState({ fatt_nome: res.data.data.dati_fatturazione_docente.nome});
                this.setState({ fatt_cognome: res.data.data.dati_fatturazione_docente.cognome});
                this.setState({ fatt_cf_piva: res.data.data.dati_fatturazione_docente.cf_piva});
                this.setState({ fatt_indirizzo: res.data.data.dati_fatturazione_docente.indirizzo});
                this.setState({ fatt_citta: res.data.data.dati_fatturazione_docente.citta});
                this.setState({ fatt_provincia: res.data.data.dati_fatturazione_docente.provincia});
                this.setState({ fatt_cap: res.data.data.dati_fatturazione_docente.cap});
                this.setState({ importo_netto: res.data.data.dati_prodotto.importo});
                this.setState({ importo_totale: res.data.data.dati_prodotto.importo});

                this.setState({ isloading: false }, function(){
                    let required_field_are_filled = this.requiredFieldsAreFilled();
                    let fields_are_valid = this.fieldsAreValid();
                    //console.log("required field are filled => " + required_field_are_filled);
                    //console.log("fields are valid => " + fields_are_valid);
                    if (required_field_are_filled && fields_are_valid) {
                        this.setState({ orderenabled: true });
                    } else {
                        this.setState({ orderenabled: false });
                    }
                });
            }
        }).catch (error => {
            //console.log(error);
            this.setState({ isloading: false});
        });

    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleLogoutClick(event) {
        event.preventDefault();
        this.setState({ authorization: ''});
        Cookies.remove('authorization');
        Cookies.remove('user_email');
        Cookies.remove('nome');
        Cookies.remove('cognome');
        Cookies.remove('wp_user_id');
        Cookies.remove('newuser');
    }

    handleUserMenuClick(event){
        //console.log(event.currentTarget);
        this.setState({ anchorElUserMenu: event.currentTarget});
    }

    handleCloseUserMenu(){
        this.setState({ anchorElUserMenu: null});
    }

    requiredFieldsAreFilled() {

        var required_fields = [
            //'sped_nome',
            //'sped_cognome',
            //'sped_nome_scuola',
            //'sped_indirizzo',
            //'sped_citta',
            //'sped_cap',
            //'sped_cellulare',
            'fatt_nome',
            'fatt_cognome',
            'fatt_cf_piva',
            'fatt_indirizzo',
            'fatt_citta',
            'fatt_cap'
        ];

        let filled = 0;
        required_fields.forEach((element) => {
            if (this.state[element] !== null) {
                if ( this.state[element].length > 0) {
                    filled = filled + 1;
                }
            }
        });

        if (filled === required_fields.length){
            return true;
        } else {
            return false;
        }
    }

    fieldsAreValid() {

        /* if (this.validateSpedNome(this.state.sped_nome) === false) {
            console.log("nome spedizione invalid");
            return false;
        }
        if (this.validateSpedCognome(this.state.sped_cognome) === false) {
            console.log("cognome spedizione invalid");
            return false;
        }
        if (this.validateSpedNomeScuola(this.state.sped_nome_scuola) === false) {
            console.log("nome scuola spedizione invalid");
            return false;
        }
        if (this.validateSpedIndirizzo(this.state.sped_indirizzo) === false) {
            console.log("indirizzo spedizione invalid");
            return false;
        }
        if (this.validateSpedProvincia(this.state.sped_provincia) === false) {
            console.log("provincia spedizione invalid");
            return false;
        }
        if (this.validateSpedCitta(this.state.sped_citta) === false) {
            console.log("citta spedizione invalid");
            return false;
        }
        if (this.validateSpedCap(this.state.sped_cap) === false) {
            console.log("cap spedizione invalid");
            return false;
        }
        if (this.validateSpedCellulare(this.state.sped_cellulare) === false) {
            console.log("cellulare spedizione invalid");
            return false;
        } */

        if (this.validateFattNome(this.state.fatt_nome) === false) {
            //console.log("nome fatturazione invalid");
            return false;
        }
        if (this.validateFattCognome(this.state.fatt_cognome) === false) {
            //console.log("cognome fatturazione invalid");
            return false;
        }
        if (this.validateFattCfPiva(this.state.fatt_cf_piva) === false) {
            //console.log("cf piva fatturazione invalid");
            return false;
        }
        if (this.validateFattIndirizzo(this.state.fatt_indirizzo) === false) {
            //console.log("indirizzo fatturazione invalid");
            return false;
        }
        if (this.validateFattCitta(this.state.fatt_citta) === false) {
            //console.log("citta fatturazione invalid");
            return false;
        }
        if (this.validateFattProvincia(this.state.fatt_provincia) === false) {
            //console.log("provincia fatturazione invalid");
            return false;
        }
       if (this.validateFattCap(this.state.fatt_cap) === false) {
            //console.log("cap fatturazione invalid");
            return false;
        }
        return true;

    }

    getListaProvincie() {
        let provincie = {
            XX : '', 
            AG : 'Agrigento',
            AL : 'Alessandria',
            AN : 'Ancona',
            AO : 'Aosta',
            AQ : 'L\'Aquila',
            AR : 'Arezzo',
            AP : 'Ascoli-Piceno',
            AT : 'Asti',
            AV : 'Avellino',
            BA : 'Bari',
            BT : 'Barletta-Andria-Trani',
            BL : 'Belluno',
            BN : 'Benevento',
            BG : 'Bergamo',
            BI : 'Biella',
            BO : 'Bologna',
            BZ : 'Bolzano',
            BS : 'Brescia',
            BR : 'Brindisi',
            CA : 'Cagliari',
            CL : 'Caltanissetta',
            CB : 'Campobasso',
            CI : 'Carbonia Iglesias',
            CE : 'Caserta',
            CT : 'Catania',
            CZ : 'Catanzaro',
            CH : 'Chieti',
            CO : 'Como',
            CS : 'Cosenza',
            CR : 'Cremona',
            KR : 'Crotone',
            CN : 'Cuneo',
            EN : 'Enna',
            FM : 'Fermo',
            FE : 'Ferrara',
            FI : 'Firenze',
            FG : 'Foggia',
            FC : 'Forli-Cesena',
            FR : 'Frosinone',
            GE : 'Genova',
            GO : 'Gorizia',
            GR : 'Grosseto',
            IM : 'Imperia',
            IS : 'Isernia',
            SP : 'La-Spezia',
            LT : 'Latina',
            LE : 'Lecce',
            LC : 'Lecco',
            LI : 'Livorno',
            LO : 'Lodi',
            LU : 'Lucca',
            MC : 'Macerata',
            MN : 'Mantova',
            MS : 'Massa-Carrara',
            MT : 'Matera',
            VS : 'Medio Campidano',
            ME : 'Messina',
            MI : 'Milano',
            MO : 'Modena',
            MB : 'Monza-Brianza',
            NA : 'Napoli',
            NO : 'Novara',
            NU : 'Nuoro',
            OG : 'Ogliastra',
            OT : 'Olbia Tempio',
            OR : 'Oristano',
            PD : 'Padova',
            PA : 'Palermo',
            PR : 'Parma',
            PV : 'Pavia',
            PG : 'Perugia',
            PU : 'Pesaro-Urbino',
            PE : 'Pescara',
            PC : 'Piacenza',
            PI : 'Pisa',
            PT : 'Pistoia',
            PN : 'Pordenone',
            PZ : 'Potenza',
            PO : 'Prato',
            RG : 'Ragusa',
            RA : 'Ravenna',
            RC : 'Reggio-Calabria',
            RE : 'Reggio-Emilia',
            RI : 'Rieti',
            RN : 'Rimini',
            RM : 'Roma',
            RO : 'Rovigo',
            SA : 'Salerno',
            SS : 'Sassari',
            SU : 'Sud Sardegna',
            SV : 'Savona',
            SI : 'Siena',
            SR : 'Siracusa',
            SO : 'Sondrio',
            TA : 'Taranto',
            TE : 'Teramo',
            TR : 'Terni',
            TO : 'Torino',
            TP : 'Trapani',
            TN : 'Trento',
            TV : 'Treviso',
            TS : 'Trieste',
            UD : 'Udine',
            VA : 'Varese',
            VE : 'Venezia',
            VB : 'Verbania',
            VC : 'Vercelli',
            VR : 'Verona',
            VV : 'Vibo-Valentia',
            VI : 'Vicenza',
            VT : 'Viterbo',
        };
        return provincie;
    }

    getListaMetodiPagamento() {
        let metodipagamento = {
          2: "Docente - Bonifico",
          /* 3: "Docente - Carta del docente", */
        };
        return metodipagamento;
    }

    validateSpedNome(value){
        let nome = value;
        const re = /^[a-zàòùèéì ,.'-]+$/i;
        return re.test(nome) || nome.length === 0;
    }

    validateSpedCognome(value){
        let cognome = value;
        const re = /^[a-zA-Zàòùèéì ,.'-]+$/i;
        return re.test(cognome) || cognome.length === 0;
    }

    validateSpedNomeScuola(value){
        let nomescuola = value;
        const re = /^[a-zA-Zàòùèéì() ,."'-]+$/i;
        return re.test(nomescuola) || nomescuola.length === 0;
    }

    validateSpedIndirizzo(value){
        let spedindirizzo = value;
        const re = /^[0-9a-zA-Z/òàùèéì'\s,.-]+$/;
        return re.test(spedindirizzo) || spedindirizzo.length === 0;
    }

    validateSpedCitta(value){
        let citta = value;
        const re = /^[0-9a-zA-Zàòùèéì'\s,-]+$/;
        return re.test(citta) || citta.length === 0;
    }

    validateSpedProvincia(value){
        let provincia = value;
        return provincia !== "XX" || provincia === 0;
    }

    validateSpedCap(value){
        let cap = value;
        const re = /^[0-9]{5}/;
        return re.test(cap) || cap.length === 0;
    }

    validateSpedCellulare(value){
        let cellulare = value;
        const re = /^[0-9\s]{8,20}$/;
        return re.test(cellulare) || cellulare.length === 0;
    }

    validateNoteConsegna(value){
        let note = value;
        const re = /^[0-9a-zA-Zàòùèéì'\s,-]+$/;
        return re.test(note) || note.length === 0; 
    }

    validateFattNome(value){
        let nome = value;
        const re = /^[a-zA-Zàòùèéì ,.'-]+$/i;
        return re.test(nome) || nome.length === 0;
    }

    validateFattCognome(value){
        let cognome = value;
        const re = /^[a-zA-Zàòùèéì ,.'-]+$/i;
        return re.test(cognome) || cognome.length === 0;
    }

    validateFattCfPiva(value){
        let cfpiva = value;
        const re = /^[A-Z0-9]{11,16}/;
        //console.log(re.test(cfpiva));
        return re.test(cfpiva);
    }

    validateFattIndirizzo(value){
        let fattindirizzo = value;
        const re = /^[0-9a-zA-Z/òàùèéì'\s,.-]+$/;
        return re.test(fattindirizzo) || fattindirizzo.length === 0;
    }

    validateFattCitta(value){
        let citta = value;
        const re = /^[0-9a-zA-Zàòùèéì'\s,-]+$/;
        return re.test(citta) || citta.length === 0;
    }

    validateFattProvincia(value){
        let provincia = value;
        return provincia !== "XX" || provincia === 0;
    }

    validateFattCap(value){
        let cap = value;
        const re = /^[0-9]{5}/;
        return re.test(cap) || cap.length === 0;
    }

    handleChangeMetodoPagamento(event){
        this.setState({ id_metodo_pagamento: parseInt(event.target.value) });
    }

    handleChangeSpedNome(event) {
        this.setState({ sped_nome: event.target.value }, function(){
            if (this.validateSpedNome(event.target.value)) {
                this.setState({ sped_nome_error: false });
            } else {
                this.setState({ sped_nome_error: true });
            }
            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();
            if (required_field_are_filled && fields_are_valid) {
                this.setState({ orderenabled: true });
            } else {
                this.setState({ orderenabled: false });
            }
        });
    }

    handleChangeSpedCognome(event) {
        this.setState({ sped_cognome: event.target.value }, function(){
            if (this.validateSpedCognome(event.target.value)) {
                this.setState({ sped_cognome_error: false });
            } else {
                this.setState({ sped_cognome_error: true });
            }
            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();
            if (required_field_are_filled && fields_are_valid) {
                this.setState({ orderenabled: true });
            } else {
                this.setState({ orderenabled: false });
            }
        });
    }

    handleChangeSpedNomeScuola(event){
        this.setState({ sped_nome_scuola: event.target.value }, function(){
            if (this.validateSpedNomeScuola(event.target.value)) {
                this.setState({ sped_nome_scuola_error: false });
            } else {
                this.setState({ sped_nome_scuola_error: true });
            }
            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();
            if (required_field_are_filled && fields_are_valid) {
                this.setState({ orderenabled: true });
            } else {
                this.setState({ orderenabled: false });
            }
        });
    }

    handleChangeSpedIndirizzo(event){
        this.setState({ sped_indirizzo: event.target.value }, function(){
            if (this.validateSpedIndirizzo(event.target.value)) {
                this.setState({ sped_indirizzo_error: false });
            } else {
                this.setState({ sped_indirizzo_error: true });
            }
            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();
            if (required_field_are_filled && fields_are_valid) {
                this.setState({ orderenabled: true });
            } else {
                this.setState({ orderenabled: false });
            }
        });
    }

    handleChangeSpedCitta(event){
        this.setState({ sped_citta: event.target.value }, function(){
            if (this.validateSpedCitta(event.target.value)) {
                this.setState({ sped_citta_error: false });
            } else {
                this.setState({ sped_citta_error: true });
            }
            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();
            if (required_field_are_filled && fields_are_valid) {
                this.setState({ orderenabled: true });
            } else {
                this.setState({ orderenabled: false });
            }
        });
    }

    handleChangeSpedProvincia(event){
        this.setState({ sped_provincia: event.target.value },function(){
            if (this.validateSpedProvincia(event.target.value)) {
                this.setState({ sped_provincia_error: false });
            } else {
                this.setState({ sped_provincia_error: true });
            }
            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();
            if (required_field_are_filled && fields_are_valid) {
                this.setState({ orderenabled: true });
            } else {
                this.setState({ orderenabled: false });
            }
        });
    }

    handleChangeSpedCap(event){
        this.setState({ sped_cap: event.target.value }, function(){
            if (this.validateSpedCap(event.target.value)) {
                this.setState({ sped_cap_error: false });
            } else {
                this.setState({ sped_cap_error: true });
            }
            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();
            if (required_field_are_filled && fields_are_valid) {
                this.setState({ orderenabled: true });
            } else {
                this.setState({ orderenabled: false });
            }
        });
    }

    handleChangeSpedCellulare(event){
        this.setState({ sped_cellulare: event.target.value }, function(){
            if (this.validateSpedCellulare(event.target.value)) {
                this.setState({ sped_cellulare_error: false });
            } else {
                this.setState({ sped_cellulare_error: true });
            }
            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();
            if (required_field_are_filled && fields_are_valid) {
                this.setState({ orderenabled: true });
            } else {
                this.setState({ orderenabled: false });
            }
        });
    }

    handleChangeNoteConsegna(event){
        this.setState({ note_consegna: event.target.value }, function(){
            if (this.validateNoteConsegna(event.target.value)) {
                this.setState({ note_consegna_error: false });
            } else {
                this.setState({ note_consegna_error: true });
            }
            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();
            if (required_field_are_filled && fields_are_valid) {
                this.setState({ orderenabled: true });
            } else {
                this.setState({ orderenabled: false });
            }
        });
    }

    handleChangeFattNome(event){
        this.setState({ fatt_nome: event.target.value }, function(){
            if (this.validateFattNome(event.target.value)) {
                this.setState({ fatt_nome_error: false });
            } else {
                this.setState({ fatt_nome_error: true });
            }
            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();
            if (required_field_are_filled && fields_are_valid) {
                this.setState({ orderenabled: true });
            } else {
                this.setState({ orderenabled: false });
            }
        });
    }

    handleChangeFattCognome(event){
        this.setState({ fatt_cognome: event.target.value }, function(){
            if (this.validateFattCognome(event.target.value)) {
                this.setState({ fatt_cognome_error: false });
            } else {
                this.setState({ fatt_cognome_error: true });
            }
            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();
            if (required_field_are_filled && fields_are_valid) {
                this.setState({ orderenabled: true });
            } else {
                this.setState({ orderenabled: false });
            }
        });
    }

    handleChangeFattCfPiva(event){
        this.setState({ fatt_cf_piva: event.target.value }, function(){
            if (this.validateFattCfPiva(event.target.value)) {
                this.setState({ fatt_cf_piva_error: false });
            } else {
                this.setState({ fatt_cf_piva_error: true });
            }
            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();
            if (required_field_are_filled && fields_are_valid) {
                this.setState({ orderenabled: true });
            } else {
                this.setState({ orderenabled: false });
            }
        });
    }

    handleChangeFattIndirizzo(event){
        this.setState({ fatt_indirizzo: event.target.value }, function(){
            if (this.validateFattIndirizzo(event.target.value)) {
                this.setState({ fatt_indirizzo_error: false });
            } else {
                this.setState({ fatt_indirizzo_error: true });
            }
            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();
            if (required_field_are_filled && fields_are_valid) {
                this.setState({ orderenabled: true });
            } else {
                this.setState({ orderenabled: false });
            }
        });
    }

    handleChangeFattCitta(event){
        this.setState({ fatt_citta: event.target.value }, function(){
            if (this.validateFattCitta(event.target.value)) {
                this.setState({ fatt_citta_error: false });
            } else {
                this.setState({ fatt_citta_error: true });
            }
            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();
            if (required_field_are_filled && fields_are_valid) {
                this.setState({ orderenabled: true });
            } else {
                this.setState({ orderenabled: false });
            }
        });
    }

    handleChangeFattProvincia(event){
        this.setState({ fatt_provincia: event.target.value },function(){
            if (this.validateFattProvincia(event.target.value)) {
                this.setState({ fatt_provincia_error: false });
            } else {
                this.setState({ fatt_provincia_error: true });
            }
            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();
            if (required_field_are_filled && fields_are_valid) {
                this.setState({ orderenabled: true });
            } else {
                this.setState({ orderenabled: false });
            }
        });
    }

    handleChangeFattCap(event){
        this.setState({ fatt_cap: event.target.value }, function(){
            if (this.validateFattCap(event.target.value)) {
                this.setState({ fatt_cap_error: false });
            } else {
                this.setState({ fatt_cap_error: true });
            }
            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();
            if (required_field_are_filled && fields_are_valid) {
                this.setState({ orderenabled: true });
            } else {
                this.setState({ orderenabled: false });
            }
        });
    }

    handleOrderClick(event) {
        event.preventDefault();

        var config = require('./config.json');
        var env_backend = config.env_backend;
        var api_url_backend = config.api_url_backend[env_backend];
        var api_auth_backend = config.api_auth_backend[env_backend];

        this.setState({ error: false });
        this.setState({ isloading: true });
        const apiUrl = api_url_backend;
        let authorization = api_auth_backend;

        let id_progetto = this.state.id_progetto;
        let id_utente = this.state.id_utente;
        let id_metodo_pagamento = this.state.id_metodo_pagamento;
        let id_scuola = this.state.id_scuola;
        let sped_nome = "-";
        let sped_cognome = "-";
        let sped_nome_scuola = "-";
        let sped_indirizzo = "-";
        let sped_citta = "-";
        let sped_provincia = "XX";
        let sped_cap = "00000";
        let sped_cellulare = "-";
        let note_consegna = "-";
        let fatt_nome = this.state.fatt_nome;
        let fatt_cognome = this.state.fatt_cognome;
        let fatt_cf_piva = this.state.fatt_cf_piva;
        let fatt_indirizzo = this.state.fatt_indirizzo;
        let fatt_citta = this.state.fatt_citta;
        let fatt_provincia = this.state.fatt_provincia;
        let fatt_cap = this.state.fatt_cap;
        let importo_netto = this.state.importo_netto;
        let sconto = this.state.sconto;
        let importo_totale = this.state.importo_totale;

        const settings = {
            headers: { Authorization: `Bearer ${authorization}` }
        };

        axios.post(apiUrl+"orders/sendsupporter", { 
            "id_progetto": id_progetto,
            "id_utente": id_utente,
            "id_metodo_pagamento": id_metodo_pagamento,
            "id_scuola": id_scuola,
            "sped_nome": sped_nome,
            "sped_cognome": sped_cognome,
            "sped_nome_scuola": sped_nome_scuola,
            "sped_indirizzo": sped_indirizzo,
            "sped_citta": sped_citta,
            "sped_provincia": sped_provincia,
            "sped_cap": sped_cap,
            "sped_cellulare": sped_cellulare,
            "note_consegna": note_consegna, 
            "fatt_nome": fatt_nome,
            "fatt_cognome": fatt_cognome,
            "fatt_cf_piva": fatt_cf_piva,
            "fatt_indirizzo": fatt_indirizzo,
            "fatt_citta": fatt_citta,
            "fatt_provincia": fatt_provincia,
            "fatt_cap": fatt_cap,
            "importo_netto": importo_netto,
            "sconto": sconto,
            "importo_totale": importo_totale
        }, settings).then(res2 => {

            this.setState({ isloading: false }, function(){
                //console.log(res2.data.data); 
            });

            if (res2.data.success) {

                this.setState({ id_progetto: null});
                this.setState({ id_metodo_pagamento: 2});
                this.setState({ id_scuola: null});
                this.setState({ sped_nome: ""});
                this.setState({ sped_cognome: ""});
                this.setState({ sped_nome_scuola: ""});
                this.setState({ sped_indirizzo: ""});
                this.setState({ sped_citta: ""});
                this.setState({ sped_provincia: "XX"});
                this.setState({ sped_cap: ""});
                this.setState({ sped_cellulare: ""});
                this.setState({ note_consegna: ""});
                this.setState({ fatt_nome: ""});
                this.setState({ fatt_cognome: ""});
                this.setState({ fatt_cf_piva: ""});
                this.setState({ fatt_indirizzo: ""});
                this.setState({ fatt_citta: ""});
                this.setState({ fatt_provincia: "XX"});
                this.setState({ fatt_cap: ""});
                this.setState({ importo_netto: 0});
                this.setState({ sconto: 0});
                this.setState({ importo_totale: 0});
                this.setState({ ordercompleted: true });



            } else {

                if (this._isMounted) {

                    this.setState({ error: true});
                    this.setState({ errorcode: res2.data.data.code });
                    this.setState({ errormessage: res2.data.data.message });
                    this.setState({ isloading: false });
                }
            }

        }).catch (error => {
            
            if (this._isMounted) {

                this.setState({ error: true});
                this.setState({ errorcode: error.response.data.data.code });
                this.setState({ errormessage: error.response.data.data.message });
                this.setState({ isloading: false });
            }
        });
    }

    render() {
        const autenticato = this.state.authorization !== '';
        const error = this.state.error;
        const errormessage = this.state.errormessage;
        const isloading = this.state.isloading;

        const nome = this.state.nome;
        const cognome = this.state.cognome;
        
        const anchorElUserMenu = this.state.anchorElUserMenu;
        const open = Boolean(anchorElUserMenu);
           
        const fatt_nome = this.state.fatt_nome;
        const fatt_cognome = this.state.fatt_cognome;
        const fatt_cf_piva = this.state.fatt_cf_piva;
        const fatt_indirizzo = this.state.fatt_indirizzo;
        const fatt_citta = this.state.fatt_citta;
        const fatt_provincia = this.state.fatt_provincia;
        const fatt_cap = this.state.fatt_cap;

        const fatt_nome_error = this.state.fatt_nome_error;
        const fatt_cognome_error = this.state.fatt_cognome_error;
        const fatt_cf_piva_error = this.state.fatt_cf_piva_error;
        const fatt_indirizzo_error = this.state.fatt_indirizzo_error;
        const fatt_citta_error = this.state.fatt_citta_error;
        const fatt_provincia_error = this.state.fatt_provincia_error;
        const fatt_cap_error = this.state.fatt_cap_error;

        const orderenabled = this.state.orderenabled;
        const ordercompleted = this.state.ordercompleted;
        const nome_progetto = this.state.nome_progetto;
        const anno_progetto = this.state.anno_progetto;
        const descrizione_progetto = this.state.descrizione_progetto;
        const importo_prodotto = this.state.importo_prodotto;
        const id_metodo_pagamento  = this.state.id_metodo_pagamento;

        return(
            <div>
                <Helmet htmlAttributes={{lang: 'it-IT'}}>
                    <title>Concorsi ToKalon - Diventa Sostenitore</title>
                    <meta name="description" content="Scopri come diventare un nostro sostenitore."  data-react-helmet="true"/>
                </Helmet> 
                { ordercompleted && (
                    <Redirect to="/supporterordered" />
                )}
                { autenticato ? ( 
                    <div className="App">
                        {( isloading === false) ? (
                        <div>
                            <header className="App-header">
                                <AppBar position="static" className="WelcomeBar">
                                    <Toolbar>
                                        <table className="WelcomeMenu">
                                            <tbody>
                                                <tr>
                                                    <td className="LogoMenuColumn">
                                                        <Logo />
                                                    </td>
                                                    <td className="HomeMenuColumn">
                                                        <HomeButton />
                                                        |
                                                        <TokalonButton />
                                                    </td>
                                                    <td className="AccountMenuButton">
                                                        { (nome === "" || cognome === "") ? (
                                                            <>
                                                                {/* <SocialButtonsInHeader /> */}
                                                                <Link to="/profile">
                                                                    <Button
                                                                        className="AccountName"
                                                                        size="large"
                                                                        id="user-menu-button"
                                                                        aria-label="account of current user"
                                                                        aria-controls="user-menu"
                                                                        //aria-haspopup="true"
                                                                        //aria-expanded={Boolean(anchorElUserMenu) ? 'true' : undefined}
                                                                        //onClick={this.handleUserMenuClick}
                                                                        color="inherit"
                                                                    >
                                                                        {/* <FontAwesomeIcon icon={faUser} /> */}
                                                                        <span className="WelcomeUser">{/* Bentornato */}</span>
                                                                    </Button>
                                                                </Link>
                                                                |
                                                                <Button
                                                                    className=""
                                                                    size="large"
                                                                    color="inherit"
                                                                    id="logout-button"
                                                                    onClick={this.handleLogoutClick}
                                                                >
                                                                    Esci
                                                                </Button>
                                                            </>
                                                        ): (
                                                            <>
                                                                {/* <SocialButtonsInHeader /> */}
                                                                <Link to="/profile">
                                                                    <Button
                                                                        className="AccountName"
                                                                        size="large"
                                                                        id="user-menu-button"
                                                                        aria-label="account of current user"
                                                                        aria-controls="user-menu"
                                                                        //aria-haspopup="true"
                                                                        //aria-expanded={Boolean(anchorElUserMenu) ? 'true' : undefined}
                                                                        //onClick={this.handleUserMenuClick}
                                                                        color="inherit" 
                                                                    >
                                                                        {/* <FontAwesomeIcon icon={faUser} /> */}
                                                                        <span className="WelcomeUser">{/* Bentornato */}</span> { nome + " " + cognome }
                                                                    </Button>
                                                                </Link>
                                                                |
                                                                <Button
                                                                    className=""
                                                                    size="large"
                                                                    color="inherit"
                                                                    id="logout-button"
                                                                    onClick={this.handleLogoutClick}
                                                                >
                                                                    Esci
                                                                </Button>
                                                            </>
                                                        )}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <Menu
                                            id="user-menu"
                                            anchorEl={anchorElUserMenu}
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            keepMounted
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            open={open}
                                            onClose={this.handleCloseUserMenu}
                                            MenuListProps={{
                                                'aria-labelledby': 'user-menu-button',
                                            }}
                                        >
                                            <MenuItem><Link to="/profile">Profilo</Link></MenuItem>
                                            <MenuItem onClick={this.handleLogoutClick}>Esci</MenuItem>
                                        </Menu>
                                    </Toolbar>
                                </AppBar>
                                {/* <Subheader></Subheader> */}
                            
                                {/* <div className="separator"></div> */}
                                <div className="Page-title Orange">{nome_progetto} - A.S. {anno_progetto}</div>
                                <div className="Page-subtitle-top" dangerouslySetInnerHTML={{__html:purify.sanitize(descrizione_progetto)}}></div>
                                <div className="Page-subtitle-top"><strong>Costo: {importo_prodotto}€</strong></div>
                                <div className="Page-title Orange">Metodo di pagamento</div>
                                {/* <div className="Page-subtitle-top">Seleziona il metodo di pagamento più adatto</div> */}
                                <Grid container spacing={1} className="Page-button-group">
                                    <Grid item xs={12}>
                                    <FormControl component="fieldset">
                                        <RadioGroup
                                            aria-label="payment"
                                            value={id_metodo_pagamento.toString()}
                                            name="radio-payment-group"
                                            onChange={this.handleChangeMetodoPagamento}
                                        >
                                            <FormControlLabel value="2" control={<Radio />} label="Docente - Bonifico" />
                                            {/* <FormControlLabel value="3" control={<Radio />} label="Docente - Carta del docente" /> */}
                                        </RadioGroup>
                                    </FormControl>
                                    </Grid>
                                </Grid>   
                                {/* <div className="Page-title Orange">2. Conferma le informazioni sulla <strong>Spedizione</strong></div>
                                <Grid container className="Form-control Register Half">
                                    <Grid item xs={6}>
                                        { sped_nome_error ? (
                                            <TextField style={{ width : "95%", paddingRight : "19px" }} inputProps={{maxLength : 255}} id="sped_nome" required placeholder="Nome" error helperText="Valore non valido" variant="outlined" onChange={this.handleChangeSpedNome} value={ sped_nome }/>
                                        ) : (
                                            <TextField style={{ width : "95%", paddingRight : "19px" }} inputProps={{maxLength : 255}} id="sped_nome" required placeholder="Nome" variant="outlined" onChange={this.handleChangeSpedNome} value={ sped_nome }/>
                                        )}
                                    </Grid>
                                    <Grid item xs={6} style={{ textAlign: "right"}}>
                                    { sped_cognome_error ? (
                                        <TextField style={{ width : "95%"}} id="sped_cognome" inputProps={{maxLength : 255}} required placeholder="Cognome" error helperText="Valore non valido" variant="outlined" onChange={this.handleChangeSpedCognome} value={ sped_cognome }/>
                                    ) : (
                                        <TextField style={{ width : "95%"}} id="sped_cognome" inputProps={{maxLength : 255}} required placeholder="Cognome" variant="outlined" onChange={this.handleChangeSpedCognome} value={ sped_cognome }/>
                                    )}
                                    </Grid>
                                </Grid>    
                                <div className="Form-control Register">
                                    { sped_nome_scuola_error ? (
                                        <TextField id="sped_nome_scuola" inputProps={{maxLength : 255}} required placeholder="Denominazione scuola" error helperText="Valore non valido" variant="outlined" onChange={this.handleChangeSpedNomeScuola} value={ sped_nome_scuola }/>
                                    ) : (
                                        <TextField id="sped_nome_scuola" inputProps={{maxLength : 255}} required placeholder="Denominazione scuola" variant="outlined" onChange={this.handleChangeSpedNomeScuola} value={ sped_nome_scuola }/>
                                    )}
                                </div>
                                <div className="Form-control Register">
                                        { sped_indirizzo_error ? (
                                            <TextField id="sped_indirizzo" inputProps={{maxLength : 255}} required placeholder="Indirizzo" error helperText="Valore non valido" variant="outlined" onChange={this.handleChangeSpedIndirizzo} value={ sped_indirizzo }/>
                                        ) : (
                                            <TextField id="sped_indirizzo" inputProps={{maxLength : 255}} required placeholder="Indirizzo" variant="outlined" onChange={this.handleChangeSpedIndirizzo} value={ sped_indirizzo }/>
                                        )}
                                </div>
                                <div className="Form-control Register">
                                        { sped_citta_error ? (
                                            <TextField id="sped_citta" inputProps={{maxLength : 255}} required placeholder="Città" error helperText="Valore non valido" variant="outlined" onChange={this.handleChangeSpedCitta} value={ sped_citta }/>
                                        ) : (
                                            <TextField id="sped_citta" inputProps={{maxLength : 255}} required placeholder="Città" variant="outlined" onChange={this.handleChangeSpedCitta} value={ sped_citta }/>
                                        )}
                                </div>
                                <div className="Form-control Register">
                                    { sped_provincia_error ? ( 
                                        <FormControl variant="outlined" className="Form-control" error>   
                                            <Select
                                                native
                                                value={sped_provincia}
                                                onChange={this.handleChangeSpedProvincia}
                                            >
                                                { Object.keys(this.getListaProvincie()).map((keyName, keyIndex) => (
                                                    <option key={keyName} value={keyName}>{this.getListaProvincie()[keyName]}</option>
                                                ))}
                                            </Select>
                                            <FormHelperText>Valore non valido</FormHelperText>
                                        </FormControl>
                                    ) : (
                                        <FormControl variant="outlined" className="Form-control">   
                                            <Select
                                                native
                                                value={sped_provincia}
                                                onChange={this.handleChangeSpedProvincia}
                                            >
                                                { Object.keys(this.getListaProvincie()).map((keyName, keyIndex) => (
                                                    <option key={keyName} value={keyName}>{this.getListaProvincie()[keyName]}</option>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    )}   
                                </div>
                                <div className="Form-control Register">
                                        { sped_cap_error ? (
                                            <TextField id="sped_cap" inputProps={{maxLength : 5}} required placeholder="Cap" error helperText="Valore non valido" variant="outlined" onChange={this.handleChangeSpedCap} value={ sped_cap }/>
                                        ) : (
                                            <TextField id="sped_cap" inputProps={{maxLength : 5}} required placeholder="Cap" variant="outlined" onChange={this.handleChangeSpedCap} value={ sped_cap }/>
                                        )}
                                </div>
                                <div className="Form-control Register">
                                        { sped_cellulare_error ? (
                                            <TextField id="sped_cellulare" inputProps={{maxLength : 20}} required placeholder="Cellulare" error helperText="Valore non valido" variant="outlined" onChange={this.handleChangeSpedCellulare} value={ sped_cellulare }/>
                                        ) : (
                                            <TextField id="sped_cellulare" inputProps={{maxLength : 20}} required placeholder="Cellulare" variant="outlined" onChange={this.handleChangeSpedCellulare} value={ sped_cellulare }/>
                                        )}
                                </div>
                                <div className="Form-control Register">
                                        { note_consegna_error ? (
                                            <TextField id="note_consegna" inputProps={{maxLength : 255}} required placeholder="Note per la consegna" error helperText="Valore non valido" variant="outlined" onChange={this.handleChangeNoteConsegna} value={ note_consengna }/>
                                        ) : (
                                            <TextField id="note_consegna" inputProps={{maxLength : 255}} required placeholder="Note per la consegna" variant="outlined" onChange={this.handleChangeNoteConsegna} value={ note_consengna }/>
                                        )}
                                </div> */}
                                <div className="Page-title Orange">1. Conferma le informazioni sulla <strong>Fatturazione</strong></div>
                                <Grid container className="Form-control Register Half" style={{padding: "0px"}}>
                                    <Grid item xs={6}>
                                    <div className="Form-label Register" style={{width: "100%", padding: "0"}}>Nome</div>    
                                        { fatt_nome_error ? (
                                            <TextField id="fatt_nome" style={{ width : "95%", paddingRight : "19px" }} inputProps={{maxLength : 255}} required placeholder="Nome" error helperText="Valore non valido" variant="outlined" onChange={this.handleChangeFattNome} value={ fatt_nome }/>
                                        ) : (
                                            <TextField id="fatt_nome" style={{ width : "95%", paddingRight : "19px" }} inputProps={{maxLength : 255}} required placeholder="Nome" variant="outlined" onChange={this.handleChangeFattNome} value={ fatt_nome }/>
                                        )}
                                    </Grid>
                                    <Grid item xs={6} style={{ textAlign: "right"}}>
                                        <div className="Form-label Register" style={{width: "100%", padding: "0", paddingLeft: "15px"}}>Cognome</div>    
                                        { fatt_cognome_error ? (
                                            <TextField id="fatt_cognome" style={{ width : "95%" }} inputProps={{maxLength : 255}} required placeholder="Cognome" error helperText="Valore non valido" variant="outlined" onChange={this.handleChangeFattCognome} value={ fatt_cognome }/>
                                        ) : (
                                            <TextField id="fatt_cognome" style={{ width : "95%" }} inputProps={{maxLength : 255}} required placeholder="Cognome" variant="outlined" onChange={this.handleChangeFattCognome} value={ fatt_cognome }/>
                                        )}
                                    </Grid>
                                </Grid>
                                <div className="Form-control Register" style={{padding: "0"}}>
                                <div className="Form-label Register" style={{width: "100%", padding: "0"}}>Codice Fiscale</div>    
                                        { fatt_cf_piva_error ? (
                                            <TextField id="sped_cf_piva" inputProps={{maxLength : 16}} required placeholder="Codice Fiscale" error helperText="Valore non valido" variant="outlined" onChange={this.handleChangeFattCfPiva} value={ fatt_cf_piva }/>
                                        ) : (
                                            <TextField id="sped_cf_piva" inputProps={{maxLength : 16}} required placeholder="Codice Fiscale" variant="outlined" onChange={this.handleChangeFattCfPiva} value={ fatt_cf_piva }/>
                                        )}
                                </div>
                                <div className="Form-control Register" style={{padding: "0"}}>
                                        <div className="Form-label Register" style={{width: "100%", padding: "0px"}}>Indirizzo</div>
                                        { fatt_indirizzo_error ? (
                                            <TextField id="fatt_indirizzo" inputProps={{maxLength : 255}} required placeholder="Indirizzo" error helperText="Valore non valido" variant="outlined" onChange={this.handleChangeFattIndirizzo} value={ fatt_indirizzo }/>
                                        ) : (
                                            <TextField id="fatt_indirizzo" inputProps={{maxLength : 255}} required placeholder="Indirizzo" variant="outlined" onChange={this.handleChangeFattIndirizzo} value={ fatt_indirizzo }/>
                                        )}
                                </div>
                                <div className="Form-control Register" style={{padding: "0"}}>
                                        <div className="Form-label Register" style={{width: "100%", padding: "0px"}}>Città</div>
                                        { fatt_citta_error ? (
                                            <TextField id="fatt_citta" inputProps={{maxLength : 255}} required placeholder="Città" error helperText="Valore non valido" variant="outlined" onChange={this.handleChangeFattCitta} value={ fatt_citta }/>
                                        ) : (
                                            <TextField id="fatt_citta" inputProps={{maxLength : 255}} required placeholder="Città" variant="outlined" onChange={this.handleChangeFattCitta} value={ fatt_citta }/>
                                        )}
                                </div>
                                <div className="Form-control Register" style={{padding: "0"}}>
                                    <div className="Form-label Register" style={{width: "100%", padding: "0px"}}>Provincia</div>
                                    { fatt_provincia_error ? ( 
                                        <FormControl variant="outlined" className="Form-control" error>    
                                            <Select
                                                native
                                                value={fatt_provincia}
                                                onChange={this.handleChangeFattProvincia}
                                            >
                                                { Object.keys(this.getListaProvincie()).map((keyName, keyIndex) => (
                                                    <option key={keyName} value={keyName}>{this.getListaProvincie()[keyName]}</option>
                                                ))}
                                            </Select>
                                            <FormHelperText>Valore non valido</FormHelperText>
                                        </FormControl>
                                    ) : (
                                        <FormControl variant="outlined" className="Form-control">    
                                            <Select
                                                native
                                                value={fatt_provincia}
                                                onChange={this.handleChangeFattProvincia}
                                            >
                                                { Object.keys(this.getListaProvincie()).map((keyName, keyIndex) => (
                                                    <option key={keyName} value={keyName}>{this.getListaProvincie()[keyName]}</option>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    )}     
                                </div>
                                <div className="Form-control Register" style={{padding: "0"}}>
                                    <div className="Form-label Register" style={{width: "100%", padding: "0px"}}>Cap</div>
                                    { fatt_cap_error ? (
                                        <TextField id="fatt_cap" inputProps={{maxLength : 5}} required placeholder="Cap" error helperText="Valore non valido" variant="outlined" onChange={this.handleChangeFattCap} value={ fatt_cap }/>
                                    ) : (
                                        <TextField id="fatt_cap" inputProps={{maxLength : 5}} required placeholder="Cap" variant="outlined" onChange={this.handleChangeFattCap} value={ fatt_cap }/>
                                    )}
                                </div>
                                { isloading && (
                                    <CircularProgress className="Progress"/>
                                )}
                                { error && (
                                    <div className="Error-message">{ errormessage }</div>
                                )}
                                <div className="Form-control Register">
                                    { orderenabled ? (
                                        <Button size="large" type="submit" variant="contained" color="primary" onClick={this.handleOrderClick}>Conferma l'iscrizione e Diventa Sostenitore</Button>
                                    ) : (
                                        <Button size="large" type="submit" disabled variant="contained" color="primary" onClick={this.handleOrderClick}>Conferma l'iscrizione e Diventa Sostenitore</Button>
                                    )}    
                                </div>
                                <div className="separator"></div>
                            </header>
                            <AppBar position="static" className="Footer">
                                <div>© Copyright {new Date().getFullYear()} – Associazione ToKalon - <Link to="/privacy">Privacy Policy</Link> - Realizzato da <a href="https://www.creab.it/" target="_blank" rel="noreferrer">Creabit.</a></div>
                            </AppBar>
                        </div>
                        ): (
                            <CircularProgress className="Progress"/>
                        )}
                    </div>
                ) : (
                    <Redirect to="/login" />
                )}
            </div>
        );
    }

}

export default withRouter(Supporter);