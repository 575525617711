import React, { Component } from 'react';
import axios from 'axios';
import { Redirect } from "react-router-dom";

class Verify extends Component {

    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            success: false,
            invalidurl: false,
            error: false,
            errorcode: null,
            errormessage: null,
            back_url: ""
        };
    }

    componentDidMount() {

        this._isMounted = true;

        //var front_url = new URL("https://devapp.creativamente.tokalon.it/verify/109?expires=1634026473&hash=194b2c1fa2141086d128e8ea3d14dbf41afd6ca1&signature=f04258301d88ce24714557c0b66dfb72395088bf417467b9bdbf08f2aa853ae3");
        var front_url = new URL(window.location.href);
        var back_path = front_url.pathname.replace("/verify", "email/verify");
          
        var config = require('./config.json');
        var env_wp = config.env_wp;
        var api_url_wp = config.api_url_wp[env_wp];
        var api_auth_wp = config.api_auth_wp[env_wp];
        var env_backend = config.env_backend;
        var api_url_backend = config.api_url_backend[env_backend];
        const back_url = api_url_backend + back_path + front_url.search;
        const apiUrl_wp = api_url_wp + 'verify';
        
        axios.get(back_url).then(res => {
            
            if (this._isMounted) {

                if (res.data.success === "true") {
                    
                    //Modificare ruolo WP in subscriber
                    var user_id = res.data.data.user_id_wp;
                    //console.log(res);
                    //console.log(user_id);
                    axios.post(apiUrl_wp, {
                        "authorization" : api_auth_wp,
                        "userid": user_id.toString()
                    })
                    .then(res_wp => {
                        //console.log(res_wp);
                        if (this._isMounted) {
                            this.setState({ success: true});
                            this.setState({ back_url: back_url});
                        }
                    }).catch (error_wp => {
                        //console.log(error_wp);
                        if (this._isMounted) {
                            this.setState({ success: false});
                            this.setState({ error: true});
                            this.setState({ errorcode: error_wp?.data?.data?.code });
                            this.setState({ errormessage: error_wp?.data?.data?.message });
                        }
                    });
                } else {
                    if (res.data.data.code === "email_already_verified"){
                        this.setState({ success: false});
                        this.setState({ alreadyverified: true});
                    }
                    if (res.data.data.code === "invalid url"){
                        this.setState({ success: false});
                        this.setState({ invalidurl: true});
                    }
                }
            }

        }).catch (error => {
            console.log(error);
            if (this._isMounted) {
                this.setState({ success: false});
                this.setState({ error: true});
                this.setState({ invalidurl: true});
                this.setState({ errorcode: error?.data?.data?.code });
                this.setState({ errormessage: error?.data?.data?.message });
            }
        });
    }

    render() {

        const back_url = this.state.back_url;
        const alreadyverified = this.state.alreadyverified;
        const invalidurl = this.state.invalidurl;
        const error = this.state.error;
        const errormessage = this.state.errormessage;
        
        return (
            <div>
                { back_url && (
                    <Redirect to="/verified" />
                )}
                { alreadyverified && (
                    <Redirect to="/alreadyverified" />
                )}
                { invalidurl && (
                    <Redirect to="/invalidurl" />
                )}
                { error && (
                    <div className="Error-message">{ errormessage }</div>
                )}
            </div>
        );
    }

}

export default Verify;