import React, { Component } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';
import { Redirect, Link } from "react-router-dom";
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import {Helmet} from "react-helmet";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import HomeButton from './HomeButton';
import SocialButtonsInHeader from './SocialButtonsInHeader';
import Subheader from './Subheader';
import Logo from './Logo';
import TokalonButton from './TokalonButton';

class Register extends Component {

    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            codice_scuola: "",
            id_scuola: "",
            nome_scuola: "",
            nome_istituto: "",
            tipologia_scuola: "",
            citta_scuola: "",
            provincia_scuola: "",
            indirizzo_scuola: "",
            cap_scuola: "",
            email : "",
            password : "",
            passwordconfirm: "",
            nome: "",
            cognome: "",
            cellulare: "",
            grado: "infanzia",
            ruolo: 1,
            altro_ruolo: "",
            privacy: false,
            newsletter: false,
            authorization: Cookies.get('authorization') || '',
            error: false,
            errorcode: '',
            errormessage: '',
            isloading: false,
            registerenabled: false,
            altro_ruolo_enabled: false,
            codice_scuola_error: false,
            emailerror: false,
            passworderror: false,
            passwordconfirmerror: false,
            nomeerror: false,
            cognomeerror: false,
            phoneerror: false,
            gradoerror: false,
            ruoloerror: false,
            altroruoloerror: false,
            formhidden: true,
            registercompleted: false
        };
        this.handleChangeEmail = this.handleChangeEmail.bind(this);
        this.handleChangePassword = this.handleChangePassword.bind(this);
        this.handleChangePasswordConfirm = this.handleChangePasswordConfirm.bind(this);
        this.handleChangeFirstname = this.handleChangeFirstname.bind(this);
        this.handleChangeLastname = this.handleChangeLastname.bind(this);
        this.handleChangePhone = this.handleChangePhone.bind(this);
        this.handleChangeGrado = this.handleChangeGrado.bind(this);
        this.handleChangeRole = this.handleChangeRole.bind(this);
        this.handleChangeOtherRole = this.handleChangeOtherRole.bind(this);
        this.handleChangePrivacy = this.handleChangePrivacy.bind(this);
        this.handleChangeNewsletter = this.handleChangeNewsletter.bind(this);
        this.handleRegisterClick = this.handleRegisterClick.bind(this);
        this.handleChangeSchoolCode = this.handleChangeSchoolCode.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    requiredFieldsAreFilled() {

        //console.log("Ruolo attuale => " + this.state.ruolo);

        var required_fields;

        if (this.state.ruolo == 1){

            required_fields = [
                'nome',
                'cognome',
                'cellulare',
                'email',
                'password',
                'passwordconfirm'
            ];

        } else {

            required_fields = [
                'altro_ruolo',
                'nome',
                'cognome',
                'cellulare',
                'email',
                'password',
                'passwordconfirm'
            ];

        }

        //console.log("Campi obbligatori => " + required_fields);
        
        let filled = 0;
        required_fields.forEach((element) => {
            if (this.state[element].length > 0) {
                filled = filled + 1;
            }
        });

        //console.log("Campi compilati => " + filled);

        if (filled === required_fields.length){
            return true;
        } else {
            return false;
        }
    }

    fieldsAreValid() {
      
        if (this.validateSchoolCode(this.state.codice_scuola) === false) {
            //console.log("school code invalid");
            return false;
        }
        if (this.validateEmail(this.state.email) === false) {
            //console.log("email invalid");
            return false;
        }
        if (this.validatePassword(this.state.password) === false) {
            //console.log("password invalid");
            return false;
        }
        if (this.validatePasswordConfirm(this.state.passwordconfirm) === false) {
            //console.log("password confirm invalid");
            return false;
        }
        if (this.validateFirstname(this.state.nome) === false) {
            //console.log("nome invalid");
            return false;
        }
        if (this.validateLastname(this.state.cognome) === false) {
            //console.log("cognome invalid");
            return false;
        }
        if (this.validatePhone(this.state.cellulare) === false) {
            //console.log("cellulare invalid");
            return false;
        }   
        if (this.validateOtherRole(this.state.altro_ruolo) === false) {
            //console.log("altro_ruolo invalid");
            return false;
        }
        return true;
    }

    getListaGradi() {
        let gradi = {
          infanzia: "infanzia",
          primaria: "primaria",
          secondaria: "secondaria di 1° grado",
          secondaria2: "secondaria di 2° grado",
        };
        return gradi;
    }

    getListaRuoli(){
        let ruoli = {
            1: "Sono un docente",
            3: "Non sono un docente (specificare ruolo)"
          };
          return ruoli;
    }

    validateSchoolCode(value){
        let schoolcode = value;
        const re = /^[A-Z0-9]{10}/;
        //console.log(re.test(schoolcode));
        return re.test(schoolcode);
    }

    validateEmail(value) {
        let email = value;
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    validatePassword(value) {
        let password = value;
        const re = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%§£^&°?\/\'\"\\~|<>,.%^*èéòçàùì()_+={}:;@\-\[\]])([a-zA-Z0-9!@#$£%§^&°?\/\'\"\\~|<>,.%^*èéòçàùì()_+={}:;@\-\[\]]{8,})$/;
        return re.test(password);
    }

    validatePasswordConfirm(value) {
        let password = this.state.password;
        let passwordconfirm = value;
        return password === passwordconfirm;
    }

    validateFirstname(value) {
        let nome = value;
        const re = /^[a-zàòùèéì ,.'-]+$/i;
        return re.test(nome) || nome.length === 0;
    }

    validateLastname(value) {
        let cognome = value;
        const re = /^[a-zàòùèéì ,.'-]+$/i;
        return re.test(cognome) || cognome.length === 0;
    }

    validatePhone(value) {
        let cellulare = value;
        const re = /^[0-9\s]{8,20}$/;
        return re.test(cellulare) || cellulare.length === 0;
    }

    validateOtherRole(value) {
        let nome = value;
        const re = /^[a-zàòùèéì ,.'-]+$/i;
        return re.test(nome) || nome.length === 0;
    }

    handleChangeEmail(event) {
        //console.log(event.target.value);
        this.setState({ email: event.target.value }, function(){
            let email_is_valid = this.validateEmail(this.state.email);
            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();
    
            if (email_is_valid) {
                this.setState({ emailerror: false });
            } else {
                this.setState({ emailerror: true });
            }
                    
            if (required_field_are_filled && fields_are_valid && this.state.privacy === true) {
                this.setState({ registerenabled: true });
            } else {
                this.setState({ registerenabled: false });
            }
        });     
    }

    handleChangePassword(event) {
        //console.log(event.target.value);
        this.setState({ password: event.target.value }, function(){
            this.setState({ passwordconfirm: "" }, function(){
                let passwordisvalid = this.validatePassword(event.target.value);
                let required_field_are_filled = this.requiredFieldsAreFilled();
                let fields_are_valid = this.fieldsAreValid();

                if (passwordisvalid) {
                    this.setState({ passworderror: false });
                } else {
                    this.setState({ passworderror: true });
                }
                    
                if (required_field_are_filled && fields_are_valid && this.state.privacy === true) {
                    this.setState({ registerenabled: true });
                } else {
                    this.setState({ registerenabled: false });
                }

                //console.log("====");
                //console.log(passwordisvalid);
                //console.log(required_field_are_filled);
                //console.log(fields_are_valid);
                //console.log("====");
            });
        });
    }

    handleChangePasswordConfirm(event) {
        this.setState({ passwordconfirm: event.target.value }, function(){
            let passwordconfirmisvalid = this.validatePasswordConfirm(event.target.value);
            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();

            if (passwordconfirmisvalid) {
                this.setState({ passwordconfirmerror: false });
            } else {
                this.setState({ passwordconfirmerror: true });
            }
            
            if (required_field_are_filled && fields_are_valid && this.state.privacy === true) {
                this.setState({ registerenabled: true });
            } else {
                this.setState({ registerenabled: false });
            }

            //console.log("====");
            //console.log(passwordconfirmisvalid);
            //console.log(required_field_are_filled);
            //console.log(fields_are_valid);
            //console.log("====");
            });
    }

    handleChangeFirstname(event) {
        this.setState({ nome: event.target.value }, function(){
            if (this.validateFirstname(event.target.value)) {
                this.setState({ nomeerror: false });
            } else {
                this.setState({ nomeerror: true });
            }
            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();
            if (required_field_are_filled && fields_are_valid && this.state.privacy === true) {
                this.setState({ registerenabled: true });
            } else {
                this.setState({ registerenabled: false });
            }
        });
    }

    handleChangeLastname(event) {
        this.setState({ cognome: event.target.value }, function(){
            if (this.validateLastname(event.target.value)) {
                this.setState({ cognomeerror: false });
            } else {
                this.setState({ cognomeerror: true });
            }
            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();
            if (required_field_are_filled && fields_are_valid && this.state.privacy === true) {
                this.setState({ registerenabled: true });
            } else {
                this.setState({ registerenabled: false });
            }
        });
    }

    handleChangePhone(event) {
        this.setState({ cellulare: event.target.value }, function(){
            if (this.validatePhone(event.target.value)) {
                this.setState({ phoneerror: false });
            } else {
                this.setState({ phoneerror: true });
            }
            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();
            if (required_field_are_filled && fields_are_valid && this.state.privacy === true) {
                this.setState({ registerenabled: true });
            } else {
                this.setState({ registerenabled: false });
            }
        });
    }

    handleChangeGrado(event) {
        this.setState({ grado: event.target.value },function(){
            if (this.requiredFieldsAreFilled()) {
                this.setState({ registerenabled: true });
            } else {
                this.setState({ registerenabled: false });
            }
        });
    }

    handleChangeRole(event) {
        this.setState({ ruolo: parseInt(event.target.value) },function(){
            //console.log(this.state.altro_ruolo_enabled);
            if (event.target.value == 1){
                this.setState({ altro_ruolo_enabled: false });
            }
            if (event.target.value == 3){
                this.setState({ altro_ruolo_enabled: true });
            }
            if (this.requiredFieldsAreFilled()) {
                this.setState({ registerenabled: true });
            } else {
                this.setState({ registerenabled: false });
            }
        });
    }

    handleChangeOtherRole(event) {
        this.setState({ altro_ruolo: event.target.value }, function(){
            if (this.validateOtherRole(event.target.value)) {
                this.setState({ altroruoloerror: false });
            } else {
                this.setState({ altroruoloerror: true });
            }
            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();
            if (required_field_are_filled && fields_are_valid && this.state.privacy === true) {
                this.setState({ registerenabled: true });
            } else {
                this.setState({ registerenabled: false });
            }
        });
    }

    handleChangeSchoolCode(event) {

        var config = require('./config.json');
        var env_backend = config.env_backend;
        var api_url_backend = config.api_url_backend[env_backend];
        var api_auth_backend = config.api_auth_backend[env_backend];

        this.setState({ codice_scuola: event.target.value.toUpperCase() }, function(){

            this.setState({ id_scuola: null});
            this.setState({ nome_scuola: ''});
            this.setState({ nome_istituto: ''});
            this.setState({ tipologia_scuola: ''});
            this.setState({ citta_scuola: ''});
            this.setState({ provincia_scuola: ''});
            this.setState({ indirizzo_scuola: ''});
            this.setState({ cap_scuola: ''});
            
            if (this.validateSchoolCode(event.target.value.toUpperCase())) {

                this.setState({ codice_scuola_error: false }, function(){
                    const apiUrl = api_url_backend;
                    let authorization = api_auth_backend;
                    let codice_scuola = event.target.value.toUpperCase();
    
                    const settings = {
                        headers: { Authorization: `Bearer ${authorization}` }
                    };
    
                    axios.post(apiUrl+"schools/getbycode", { 
                        "codice": codice_scuola
                    }, settings).then(res => {
    
                        if (res.data.success) {
    
                            let id_scuola = res.data.data.id_scuola;
                            let nome_scuola = res.data.data.nome_scuola; 
                            let nome_istituto = res.data.data.nome_istituto; 
                            let tipologia_scuola = res.data.data.tipologia; 
                            let citta_scuola = res.data.data.citta;
                            let provincia_scuola = res.data.data.provincia;
                            let indirizzo_scuola = res.data.data.indirizzo;
                            let cap_scuola = res.data.data.cap;
                            this.setState({ error: false});
                            this.setState({ id_scuola: id_scuola});
                            this.setState({ nome_scuola: nome_scuola});
                            this.setState({ nome_istituto: nome_istituto});
                            this.setState({ tipologia_scuola: tipologia_scuola});
                            this.setState({ citta_scuola: citta_scuola});
                            this.setState({ provincia_scuola: provincia_scuola});
                            this.setState({ indirizzo_scuola: indirizzo_scuola});
                            this.setState({ cap_scuola: cap_scuola});
                            this.setState({ formhidden: false});
                        } else {
    
                            if (this._isMounted) {
                                this.setState({ error: true});
                                this.setState({ id_scuola: null});
                                this.setState({ nome_scuola: ''});
                                this.setState({ nome_istituto: ''});
                                this.setState({ tipologia_scuola: ''});
                                this.setState({ citta_scuola: ''});
                                this.setState({ provincia_scuola: ''});
                                this.setState({ indirizzo_scuola: ''});
                                this.setState({ cap_scuola: ''});
                                this.setState({ errorcode: res.data.data.code });
                                this.setState({ errormessage: res.data.data.message });
                                this.setState({ formhidden: true});
                            }
    
                        }
    
                    }).catch (error => {
    
                        if (this._isMounted) {
                            this.setState({ error: true});
                            this.setState({ id_scuola: null});
                            this.setState({ nome_scuola: ''});
                            this.setState({ nome_istituto: ''});
                            this.setState({ tipologia_scuola: ''});
                            this.setState({ citta_scuola: ''});
                            this.setState({ provincia_scuola: ''});
                            this.setState({ indirizzo_scuola: ''});
                            this.setState({ cap_scuola: ''});
                            this.setState({ errorcode: error.response.data.data.code });
                            this.setState({ errormessage: error.response.data.data.message });
                            this.setState({ formhidden: true});
                        }
                    });          
                });
                

            } else {
                this.setState({ codice_scuola_error: true });
                this.setState({ formhidden: true});
            }
        });
    }

    handleChangePrivacy(event) {
        this.setState({ privacy: event.target.checked }, function(){
            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();
            if (required_field_are_filled && fields_are_valid && event.target.checked === true) {
                this.setState({ registerenabled: true });
            } else {
                this.setState({ registerenabled: false });
            }
        });
    }

    handleChangeNewsletter(event) {
        this.setState({ newsletter: event.target.checked }, function(){
            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();
            if (required_field_are_filled && fields_are_valid && this.state.privacy === true) {
                this.setState({ registerenabled: true });
            } else {
                this.setState({ registerenabled: false });
            }
        });
    }

    handleRegisterClick(event) {
        event.preventDefault();

        var config = require('./config.json');
        var env_wp = config.env_wp;
        var api_url_wp = config.api_url_wp[env_wp];
        var api_auth_wp = config.api_auth_wp[env_wp];
        var env_backend = config.env_backend;
        var api_url_backend = config.api_url_backend[env_backend];
        var api_auth_backend = config.api_auth_backend[env_backend];

        const apiUrl = api_url_wp + 'register';
        const apiUrl2 = api_url_backend;
        
        this.setState({ error: false });
        this.setState({ isloading: true });  
        
        let authorization = api_auth_backend;
        let email = this.state.email;
        let password = this.state.password;
        let nome = this.state.nome;
        let cognome = this.state.cognome;
        let username = this.state.nome+" "+this.state.cognome;
        let cellulare = this.state.cellulare;
        let grado = this.state.grado;
        let ruolo = this.state.ruolo;
        let altro_ruolo = this.state.altro_ruolo;
        let privacy = this.state.privacy;
        let newsletter = this.state.newsletter;
        let codice_scuola = this.state.codice_scuola;
        let nome_scuola = this.state.nome_scuola;
        let citta_scuola = this.state.citta_scuola;
        
        const settings = {
            headers: { Authorization: `Bearer ${authorization}` }
        };

        let id_scuola = this.state.id_scuola;

        //Registrare utente in WP
        axios.post(apiUrl, {
            "authorization" : api_auth_wp,
            "username" : email,
            "password" : password,
            "email" : email,
            "first_name": nome,
            "last_name": cognome,
            "cellulare": cellulare,
            "grado": grado,
            "codice_meccanografico_scuola": codice_scuola,
            "scuola": nome_scuola + " - " + citta_scuola
        }).then(res => {
            var user_id_wp = res.data.data.user_id;

            //Salvare i dati nell'utente Laravel                
            axios.post(apiUrl2+"users/register", { 
                "ruolo": ruolo,
                "altro_ruolo": altro_ruolo,
                "nome": nome,
                "cognome": cognome,
                "name": username,
                "cellulare": cellulare,
                "email": email,
                "grado": grado,
                "id_scuola": id_scuola,
                "password": password,
                "privacy": privacy,
                "newsletter": newsletter,
                "user_id_wp": user_id_wp
            }, settings).then(res2 => {

                this.setState({ isloading: false }, function(){
                    //console.log(res2.data.data); 
                });

                if (res2.data.success) {

                    this.setState({ codice_scuola: ''});
                    this.setState({ id_scuola: ''});
                    this.setState({ nome_scuola: ''});
                    this.setState({ nome_istituto: ''});
                    this.setState({ tipologia_scuola: ''});
                    this.setState({ citta_scuola: ''});
                    this.setState({ provincia_scuola: ''});
                    this.setState({ indirizzo_scuola: ''});
                    this.setState({ cap_scuola: ''});
                    this.setState({ password: ''});
                    this.setState({ passwordconfirm: ''});
                    this.setState({ nome: ''});
                    this.setState({ cognome: ''});
                    this.setState({ cellulare: ''});
                    this.setState({ email: ''});
                    this.setState({ grado: 'infanzia'});
                    this.setState({ ruolo: 1});
                    this.setState({ altro_ruolo: ''});
                    this.setState({ privacy: false});
                    this.setState({ newsletter: false});
                    this.setState({ registerenabled: false});
                    this.setState({ isloading: false });
                    this.setState({ registercompleted: true });

                } else {
                
                    if (this._isMounted) {
                        this.setState({ error: true});
                        /* this.setState({ codice_scuola: ''});
                        this.setState({ id_scuola: ''});
                        this.setState({ nome_scuola: ''});
                        this.setState({ nome_istituto: ''});
                        this.setState({ tipologia_scuola: ''});
                        this.setState({ citta_scuola: ''});
                        this.setState({ provincia_scuola: ''});
                        this.setState({ indirizzo_scuola: ''});
                        this.setState({ cap_scuola: ''});
                        this.setState({ password: ''});
                        this.setState({ passwordconfirm: ''});
                        this.setState({ nome: ''});
                        this.setState({ cognome: ''});
                        this.setState({ cellulare: ''});
                        this.setState({ email: ''});
                        this.setState({ grado: 'infanzia'});
                        this.setState({ ruolo: 1});
                        this.setState({ altro_ruolo: ''});
                        this.setState({ privacy: false});
                        this.setState({ newsletter: false});
                        this.setState({ registerenabled: false}); */
                        if (res2.data.data.code === "email_error"){
                            this.setState({ emailerror: true }); 
                        }
                        this.setState({ errorcode: res2.data.data.code });
                        this.setState({ errormessage: res2.data.data.message });
                        this.setState({ isloading: false });
                    }
                }
            
            }).catch (error2 => {

                if (this._isMounted) {
                    this.setState({ error: true});
                /*  this.setState({ codice_scuola: ''});
                    this.setState({ id_scuola: ''});
                    this.setState({ nome_scuola: ''});
                    this.setState({ nome_istituto: ''});
                    this.setState({ tipologia_scuola: ''});
                    this.setState({ citta_scuola: ''});
                    this.setState({ provincia_scuola: ''});
                    this.setState({ indirizzo_scuola: ''});
                    this.setState({ cap_scuola: ''});
                    this.setState({ password: ''});
                    this.setState({ passwordconfirm: ''});
                    this.setState({ nome: ''});
                    this.setState({ cognome: ''});
                    this.setState({ cellulare: ''});
                    this.setState({ email: ''});
                    this.setState({ grado: 'infanzia'});
                    this.setState({ ruolo: 1});
                    this.setState({ altro_ruolo: ''});
                    this.setState({ privacy: false});
                    this.setState({ newsletter: false});
                    this.setState({ registerenabled: false}); */
                    if (error2.response.data.data.code === "email_error"){
                        this.setState({ emailerror: true }); 
                    }
                    this.setState({ errorcode: error2.response.data.data.code });
                    this.setState({ errormessage: error2.response.data.data.message });
                    this.setState({ isloading: false });
                }
            });

        }).catch (error => {
            if (this._isMounted) {
                this.setState({ error: true});
               /*  this.setState({ codice_scuola: ''});
                this.setState({ id_scuola: ''});
                this.setState({ nome_scuola: ''});
                this.setState({ nome_istituto: ''});
                this.setState({ tipologia_scuola: ''});
                this.setState({ citta_scuola: ''});
                this.setState({ provincia_scuola: ''});
                this.setState({ indirizzo_scuola: ''});
                this.setState({ cap_scuola: ''});
                this.setState({ password: ''});
                this.setState({ passwordconfirm: ''});
                this.setState({ nome: ''});
                this.setState({ cognome: ''});
                this.setState({ cellulare: ''});
                this.setState({ email: ''});
                this.setState({ grado: 'infanzia'});
                this.setState({ ruolo: 1});
                this.setState({ altro_ruolo: ''});
                this.setState({ privacy: false});
                this.setState({ newsletter: false});
                this.setState({ registerenabled: false}); */
                if (error.response.data.data.code === "email_error"){
                    this.setState({ emailerror: true }); 
                }
                this.setState({ errorcode: error.response.data.data.code });
                this.setState({ errormessage: error.response.data.data.message });
                this.setState({ isloading: false });
            }
        });
        
    }

    render() {

        const autenticato = this.state.authorization !== '';
        const error = this.state.error;
        const errormessage = this.state.errormessage;
        const isloading = this.state.isloading;
        const codice_scuola = this.state.codice_scuola;
        const id_scuola = this.state.id_scuola;
        const nome_scuola = this.state.nome_scuola;
        const nome_istituto = this.state.nome_istituto;
        const tipologia_scuola = this.state.tipologia_scuola;
        const citta_scuola = this.state.citta_scuola;
        const provincia_scuola = this.state.provincia_scuola;
        const indirizzo_scuola = this.state.indirizzo_scuola;
        const cap_scuola = this.state.cap_scuola;
        const email = this.state.email;
        const password = this.state.password;
        const passwordconfirm = this.state.passwordconfirm;
        const nome = this.state.nome;
        const cognome = this.state.cognome;
        const cellulare = this.state.cellulare;
        const altro_ruolo = this.state.altro_ruolo;
        const codice_scuola_error = this.state.codice_scuola_error;
        const emailerror = this.state.emailerror;
        const passworderror = this.state.passworderror;
        const passwordconfirmerror = this.state.passwordconfirmerror;
        const nomeerror = this.state.nomeerror;
        const cognomeerror = this.state.cognomeerror;
        const phoneerror = this.state.phoneerror;
        const altroruoloerror = this.state.altroruoloerror;
        const registerenabled = this.state.registerenabled;
        const altroruoloenabled = this.state.altro_ruolo_enabled;
        const formhidden = this.state.formhidden;
        const registercompleted = this.state.registercompleted;

        return(
            <div>
                <Helmet htmlAttributes={{lang: 'it-IT'}}>
                    <title>Concorsi ToKalon - Registrati</title>
                    <meta name="description" content="Sei un docente? Registrati subito e iscriviti alle edizioni dei nostri progetti."  data-react-helmet="true"/>
               </Helmet> 
                { registercompleted && (
                    <Redirect to="/registered" />
                )}
                { autenticato ? ( 
                    <Redirect to="/" />
                ) : (
                    <div className="App">
                        <header className="App-header">
                        <AppBar position="static" className="WelcomeBar">
                                <Toolbar>
                                    <table className="WelcomeMenu">
                                    <tbody>
                                            <tr>
                                                <td className="LogoMenuColumn">
                                                    <Logo />
                                                </td>
                                                <td className="HomeMenuColumn">
                                                    <HomeButton />
                                                    |
                                                    <TokalonButton />
                                                </td>
                                                <td className="AccountMenuButton">
                                                    <>
                                                        {/* <SocialButtonsInHeader /> */}
                                                    </>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Toolbar>
                            </AppBar>
                            {/* <Subheader></Subheader> */}
                            {/* <div className="separator"></div> */}
                            <div className="Page-title Orange">Registrati all'area Con-corsi di ToKalon</div>
                            <div className="Page-subtitle-top">Compila questo modulo come Docente nella piattaforma ToKalon.</div>
                            <div className="Page-subtitle-top">Quando sarai registrato potrai partecipare alle nuove edizioni.</div>
                            { isloading && (
                                <CircularProgress className="Progress"/>
                            )}
                            <div className="Form-label Register"><strong>Inserisci il codice meccanografico della tua scuola<br></br><br></br>NB: in caso di più plessi, inserire il codice di istituto e non quello del singolo plesso.</strong></div>
                            <div className="Form-control Register SchoolData">
                                { codice_scuola_error ? (
                                    <TextField id="codice_scuola" inputProps={{ maxLength: 11 }} required error helperText="Valore non valido" variant="outlined" onChange={this.handleChangeSchoolCode} value={ codice_scuola }/>
                                ) : (
                                    <TextField id="codice_scuola" inputProps={{ maxLength: 11 }} required variant="outlined" onChange={this.handleChangeSchoolCode} value={ codice_scuola }/> 
                                )}
                                { id_scuola ? (
                                    <div>
                                        <div className="Form-label Register">Ho trovato:</div>
                                        <table className="School-Data" style={{paddingLeft: "7px"}}><tbody>
                                                <tr>
                                                    <td>Nome istituto:</td><td>{ nome_istituto }</td>
                                                </tr>
                                                <tr>
                                                    <td>Nome scuola:</td><td>{ nome_scuola }</td>
                                                </tr>
                                                <tr>
                                                    <td>Indirizzo:</td><td>{ indirizzo_scuola }</td>
                                                </tr>
                                                <tr>
                                                    <td></td><td>{ cap_scuola } { citta_scuola } { provincia_scuola }</td>
                                                </tr>
                                                <tr>
                                                    <td>Tipologia:</td><td>{ tipologia_scuola }</td>
                                                </tr>
                                            </tbody></table>
                                    </div>
                                ) : (
                                    <div></div>
                                )}
                            </div>
                            <Box className={formhidden ? "Form-control Register BoxHidden" : "Form-control Register"}>
                                <div className="Form-title-label Register"><strong>Inserisci i tuoi dati personali</strong></div>
                                <br/>
                                <div className="Form-label Register">Nome</div>
                                <div className="Form-control Register" style={{width: "100%", padding: "0"}}>
                                    { nomeerror ? (
                                        <TextField id="nome" required placeholder="Nome" error helperText="Valore non valido" variant="outlined" onChange={this.handleChangeFirstname} value={ nome }/>
                                    ) : (
                                        <TextField id="nome" required placeholder="Nome" variant="outlined" onChange={this.handleChangeFirstname} value={ nome }/>
                                    )}
                                </div>
                                <div className="Form-label Register">Cognome</div>
                                <div className="Form-control Register" style={{width: "100%", padding: "0"}}>
                                    { cognomeerror ? (
                                        <TextField id="cognome" placeholder="Cognome" required error helperText="Valore non valido" variant="outlined" onChange={this.handleChangeLastname} value={ cognome }/>
                                    ) : (
                                        <TextField id="cognome" placeholder="Cognome" required variant="outlined" onChange={this.handleChangeLastname} value={ cognome }/>
                                    )}
                                </div>
                                <div className="Form-label Register">Cellulare</div>
                                <div className="Form-control Register" style={{width: "100%", padding: "0"}}>
                                    { phoneerror ? (
                                        <TextField id="cellulare" placeholder="Cellulare" required variant="outlined" error helperText="Valore non valido" onChange={this.handleChangePhone} value={ cellulare }/>
                                    ) : (
                                        <TextField id="cellulare" placeholder="Cellulare" required variant="outlined" onChange={this.handleChangePhone} value={ cellulare }/>
                                    )}
                                </div>
                                <div className="Form-label Register">Grado:</div>
                                <div className="Form-control Register" style={{width: "100%", padding: "0"}}>
                                    <FormControl variant="outlined" className="Form-control">    
                                        <Select
                                            native
                                            value={this.state.grado}
                                            onChange={this.handleChangeGrado}
                                        >
                                            { Object.keys(this.getListaGradi()).map((keyName, keyIndex) => (
                                                <option key={keyName} value={keyName}>{this.getListaGradi()[keyName]}</option>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="Form-label Register">Ruolo:</div>
                                <div className="Form-control Register" style={{width: "100%", padding: "0"}}>
                                    <FormControl variant="outlined" className="Form-control">    
                                        <Select
                                            native
                                            value={this.state.ruolo}
                                            onChange={this.handleChangeRole}
                                        >
                                            { Object.keys(this.getListaRuoli()).map((keyName, keyIndex) => (
                                                <option key={keyName} value={keyName}>{this.getListaRuoli()[keyName]}</option>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="Form-label Register">Altro ruolo</div>
                                <div className="Form-control Register" style={{width: "100%", padding: "0"}}>
                                    { (altroruoloerror && altroruoloenabled === true) && (
                                        <TextField id="altro_ruolo" placeholder="Altro ruolo" required error helperText="Valore non valido" variant="outlined" onChange={this.handleChangeOtherRole} value={ altro_ruolo }/>
                                    )}
                                    { (altroruoloerror === false && altroruoloenabled === true) &&(
                                        <TextField id="altro_ruolo" placeholder="Altro ruolo" required variant="outlined" onChange={this.handleChangeOtherRole} value={ altro_ruolo }/>
                                    )}
                                    { altroruoloenabled === false && (
                                        <TextField id="altro_ruolo" placeholder="Altro ruolo" required variant="outlined" disabled value={ altro_ruolo }/>
                                    )}
                                </div>
                                <div className="Form-label Register">Email</div>
                                <div className="Form-control Register" style={{width: "100%", padding: "0"}}>
                                    { emailerror ? (
                                        <TextField id="email" placeholder="Email" type="email" required error helperText="Valore non valido" variant="outlined" onChange={this.handleChangeEmail} value={ email }/>
                                    ) : (
                                        <TextField id="email" placeholder="Email" type="email" required variant="outlined" onChange={this.handleChangeEmail} value={ email }/> 
                                    )}
                                </div>
                                <div className="Form-label Register">Password</div>
                                <div className="Form-control Register" style={{width: "100%", padding: "0"}}>
                                    { passworderror ? (
                                        <TextField id="password" placeholder="Password" type="password" required error helperText={<label>La password deve essere lunga<br />almeno 8 caratteri.<br />Inserisci almeno:<br />1 carattere maiuscolo,<br />1 carattere minuscolo,<br />1 numero<br />1 carattere speciale.</label>} variant="outlined" onChange={this.handleChangePassword} value={ password }/>
                                    ) : (
                                        <TextField id="password" placeholder="Password" type="password" required variant="outlined" onChange={this.handleChangePassword} value={ password }/>
                                    )}
                                </div>
                                <div className="Form-label Register">Conferma password</div>
                                <div className="Form-control Register" style={{width: "100%", padding: "0"}}>
                                    { passwordconfirmerror ? (
                                        <TextField id="passwordconfirm" placeholder="Conferma Password" type="password" required error helperText="Valore non valido" variant="outlined" onChange={this.handleChangePasswordConfirm} value={ passwordconfirm }/>
                                    ) : (
                                        <TextField id="passwordconfirm" placeholder="Conferma Password" type="password" required variant="outlined" onChange={this.handleChangePasswordConfirm} value={ passwordconfirm }/>
                                    )}
                                </div>
                                <div className="Form-control Register">
                                    <FormControlLabel className="Checkbox Register"
                                        control={
                                        <Checkbox
                                            checked={this.state.privacy}
                                            onChange={this.handleChangePrivacy}
                                            name="privacy"
                                            color="primary"
                                            style={{marginRight: "10px"}}
                                        />
                                        }
                                        label={<label className="label-desc">Cliccando su "Registrati a ToKalon", accetti le nostre <Link to="/privacy">Condizioni</Link> e la nostra <Link to="/privacy">normativa sulla Privacy</Link>.</label>}
                                        style={{padding: "0 30px", height: "auto"}}
                                    />  
                                </div> 
                                <div className="Form-control Register">
                                    <FormControlLabel className="Checkbox Register"
                                        control={
                                        <Checkbox
                                            checked={this.state.newsletter}
                                            onChange={this.handleChangeNewsletter}
                                            name="newsletter"
                                            color="primary"
                                            style={{marginRight: "10px", height: "auto"}}
                                        />
                                        }
                                        label={<label className="label-desc">Desidero rimanere informato sulle iniziative dell'associazione ToKalon</label>}
                                        style={{padding: "0 30px"}}
                                    />    
                                </div>
                                { error && (
                                    <div className="Error-message">{ errormessage }</div>
                                )}
                                <div className="Form-control Register" style={{width: "100%", padding: "0", marginTop:"20px"}}>
                                    { registerenabled ? (
                                        <Button size="large" type="submit" variant="contained" color="primary" onClick={this.handleRegisterClick}>Registrati all'area Con-corsi di ToKalon!</Button>
                                    ) : (
                                        <Button size="large" type="submit" disabled variant="contained" color="primary" onClick={this.handleRegisterClick}>Registrati all'area Con-corsi di ToKalon!</Button>
                                    )}    
                                </div>
                            </Box>
                            <div className="separator"></div>
                        </header>
                    </div>
                )}
            </div>
        );
    }
}

export default Register;