import React, { Component, Fragment } from 'react';
import Cookies from 'js-cookie';
import { Redirect } from "react-router-dom";
import axios from 'axios';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Subheader from './Subheader';
import HomeButton from './HomeButton';
import SocialButtonsInHeader from './SocialButtonsInHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import Modal from '@mui/material/Modal';
import {Helmet} from "react-helmet";
import Logo from './Logo';
import TokalonButton from './TokalonButton';
import Tooltip from '@mui/material/Tooltip';

class ManageTeam extends Component {

    _isMounted = false;

    constructor(props) {

        super(props);
        this.state = {
            authorization: Cookies.get('authorization') || '',
            wp_user_id: Cookies.get('wp_user_id') || 0,
            user_email: Cookies.get('user_email') || '',
            nome: Cookies.get('nome') || '',
            cognome: Cookies.get('cognome') || '',
            id_ordine_classe: parseInt(Cookies.get('id_ordine_classe')) || '',
            anchorElUserMenu: null,

            squadre: [],

            id_progetto: "",
            tipo_progetto: "",
            nome_progetto: "",
            nome_scuola: "",
            cod_scuola: "",
            classe: "",
            sezione: "",
            docente_referente1: "",
            docente_referente2: "",
            isloading: false,

            pulsante_carica_prove: false,
            pulsante_carica_documenti: false,
            pulsante_conferma_partecipazione: false,
            
            confirmparticipationclicked: false,
            loaddocumentclicked: false,
            registerteamclicked: false,
            deleteteamclicked: false,

            openregisterteammodal: false,
            opendeleteteammodal: false,
            ambito_target: 0,
            nome_ambito_target: "",
            squadra_origine: 0,
            nome_squadra_origine: "",
            squadra_da_eliminare: 0,
            nome_squadra_da_eliminare: ""
        }
        this.handleLogoutClick = this.handleLogoutClick.bind(this);
        this.handleUserMenuClick = this.handleUserMenuClick.bind(this);
        this.handleCloseUserMenu = this.handleCloseUserMenu.bind(this);
        this.handleConfirmParticipation = this.handleConfirmParticipation.bind(this);
        this.handleLoadDocument = this.handleLoadDocument.bind(this);
        this.handleOpenRegisterTeamModal = this.handleOpenRegisterTeamModal.bind(this);
        this.handleCloseRegisterTeamModal = this.handleCloseRegisterTeamModal.bind(this);
        this.handleClickRegisterTeam = this.handleClickRegisterTeam.bind(this);
        this.ambitoNotExist = this.ambitoNotExist.bind(this);
        this.handleOpenDeleteTeamModal = this.handleOpenDeleteTeamModal.bind(this);
        this.handleCloseDeleteTeamModal = this.handleCloseDeleteTeamModal.bind(this);
        this.handleClickDeleteTeam = this.handleClickDeleteTeam.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        if (this._isMounted) {
            this.setState({ isloading: true });
        }

        var config = require('./config.json');

        var pulsante_carica_prove = config.pulsante_carica_prove;
        var pulsante_carica_documenti = config.pulsante_carica_documenti;
        var pulsante_conferma_partecipazione = config.pulsante_conferma_partecipazione;
        
        var env_backend = config.env_backend;
        var api_url_backend = config.api_url_backend[env_backend];
        var authorization = config.api_auth_backend[env_backend];

        const settings = {
            headers: { Authorization: `Bearer ${authorization}` }
        };

        var params;

        var url = new URL(window.location.href);
        var id_squadra_submitted = url.searchParams.get("id-squadra");
        //console.log(url.href);
        //console.log(id_squadra_submitted);
        if (id_squadra_submitted){
            //console.log("submitted");
            //Richiamare API teams/submittest
            const apiUrl = api_url_backend + 'teams/submittest';
            params = { 
                "id_squadra": id_squadra_submitted
            };
            axios.patch(apiUrl, params, settings).then(res => {

            });
        } else {
            //console.log("not submitted");
        }
        
        const apiUrl = api_url_backend + 'teams/getmanage';
        
        const id_ordine_classe = this.state.id_ordine_classe;
        
        params = { 
            "id_ordine_classe": id_ordine_classe
        };

        axios.post(apiUrl, params, settings).then(res => {
            if (this._isMounted) {
                this.setState({ pulsante_carica_prove: pulsante_carica_prove});
                this.setState({ pulsante_carica_documenti: pulsante_carica_documenti});
                this.setState({ pulsante_conferma_partecipazione: pulsante_conferma_partecipazione});
                this.setState({ id_progetto: res.data.data.dati_classe.id_progetto || ""}); 
                this.setState({ tipo_progetto: res.data.data.dati_classe.tipo_progetto || ""}); 
                this.setState({ nome_progetto: res.data.data.dati_classe.progetto || ""}); 
                this.setState({ nome_scuola: res.data.data.dati_classe.scuola || ""}); 
                this.setState({ cod_scuola: res.data.data.dati_classe.cod_scuola || ""}); 
                this.setState({ classe: res.data.data.dati_classe.classe || ""}); 
                this.setState({ sezione: res.data.data.dati_classe.sezione || ""}); 
                this.setState({ docente_referente1: res.data.data.dati_classe.docente_referente1 || ""}); 
                this.setState({ docente_referente2: res.data.data.dati_classe.docente_referente2 || ""}); 
                this.setState({ squadre: res.data.data.dati_classe.squadre || []}); 
                this.setState({ lista_ambiti: res.data.data.lista_ambiti || []});
                this.setState({ ambiti_validi: res.data.data.ambiti_validi || []});
                this.setState({ isloading: false});
            }
        }).catch (error => {
            //console.log(error);
            this.setState({ isloading: false});
        }); 
    }

    componentWillUnmount() {
        this._isMounted = false;
        //Cookies.remove('id_ordine_classe');
    }

    handleLogoutClick(event) {
        event.preventDefault();
        this.setState({ authorization: ''});
        Cookies.remove('authorization');
        Cookies.remove('user_email');
        Cookies.remove('nome');
        Cookies.remove('cognome');
        Cookies.remove('wp_user_id');
        Cookies.remove('newuser');
    }

    handleUserMenuClick(event){
        this.setState({ anchorElUserMenu: event.currentTarget});
    }

    handleCloseUserMenu(){
        this.setState({ anchorElUserMenu: null});
    }

    handleConfirmParticipation(event) {
        event.preventDefault();
        let id_squadra;
        id_squadra = event.currentTarget.id.split("_")[1];
        Cookies.set('id_squadra', id_squadra);
        this.setState({ confirmparticipationclicked: true });
    }

    handleLoadDocument(event) {
        event.preventDefault();
        let id_squadra;
        id_squadra = event.currentTarget.id.split("_")[1];
        Cookies.set('id_squadra', id_squadra);
        this.setState({ loaddocumentclicked: true });
    }

    handleOpenRegisterTeamModal(event){
        event.preventDefault();
        let ambito_target = parseInt(event.currentTarget.id.split("_")[1]);
        let squadra_origine = parseInt(event.currentTarget.id.split("_")[2]);
        let nome_squadra_origine;
        let nome_ambito_target = this.state.lista_ambiti[ambito_target];
        this.state.squadre.forEach(function(squadra, indexsquadra){
            if (squadra.id === squadra_origine){
                nome_squadra_origine = squadra.nome_squadra;
            }
        });
        this.setState({ ambito_target: ambito_target}, function(){
            this.setState({ nome_ambito_target: nome_ambito_target });
            this.setState({ nome_squadra_origine: nome_squadra_origine });
            this.setState({ squadra_origine: squadra_origine });
            this.setState({ openregisterteammodal: true });
        });
    }

    handleCloseRegisterTeamModal(event){
        event.preventDefault();
        this.setState({ ambito_target: 0}, function(){
            this.setState({ nome_ambito_target: "" });
            this.setState({ nome_squadra_origine: "" });
            this.setState({ squadra_origine: 0 });
            this.setState({ openregisterteammodal: false });
        });
    }

    handleOpenDeleteTeamModal(event){
        event.preventDefault();
        let ambito_target = parseInt(event.currentTarget.id.split("_")[1]);
        let squadra_da_eliminare = parseInt(event.currentTarget.id.split("_")[2]);
        let nome_squadra_da_eliminare;
        let nome_ambito_target = this.state.lista_ambiti[ambito_target];
        this.state.squadre.forEach(function(squadra, indexsquadra){
            if (squadra.id === squadra_da_eliminare){
                nome_squadra_da_eliminare = squadra.nome_squadra;
            }
        });
        this.setState({ ambito_target: ambito_target}, function(){
            this.setState({ nome_ambito_target: nome_ambito_target });
            this.setState({ squadra_da_eliminare: squadra_da_eliminare});
            this.setState({ nome_squadra_da_eliminare: nome_squadra_da_eliminare });
            this.setState({ opendeleteteammodal: true });
        });
    }

    handleCloseDeleteTeamModal(event){
        event.preventDefault();
        this.setState({ ambito_target: 0}, function(){
            this.setState({ nome_ambito_target: "" });
            this.setState({ squadra_da_eliminare: 0});
            this.setState({ nome_squadra_da_eliminare: "" });
            this.setState({ opendeleteteammodal: false });
        });  
    }

    handleClickRegisterTeam(event){
        event.preventDefault();
        var ambito_target = this.state.ambito_target;
        var squadra_origine = this.state.squadra_origine;
        //Richiamare API per duplicare squadra
        var config = require('./config.json');

        var env_backend = config.env_backend;
        var api_url_backend = config.api_url_backend[env_backend];
        var authorization = config.api_auth_backend[env_backend];

        const settings = {
            headers: { Authorization: `Bearer ${authorization}` }
        };

        const apiUrl = api_url_backend + 'teams/duplicate';
        var params = { 
            "id_ordine_classe": this.state.id_ordine_classe,
            "ambito": ambito_target,
            "id_squadra": squadra_origine
        };

        axios.post(apiUrl, params, settings).then(res => {
            this.setState({ isloading: true});
            this.setState({ registerteamclicked: true});
            this.setState({ nome_ambito_target: "" });
            this.setState({ nome_squadra_origine: "" });
            this.setState({ squadra_origine: 0 });
            this.setState({ openregisterteammodal: false });
            window.location.reload(false);
            this.setState({ isloading: false});
        }).catch (error => {
            //console.log(error);
            this.setState({ isloading: false});
        });
    }

    handleClickDeleteTeam(event){
        event.preventDefault();
        var squadra_da_eliminare = this.state.squadra_da_eliminare;
        var config = require('./config.json');

        var env_backend = config.env_backend;
        var api_url_backend = config.api_url_backend[env_backend];
        var authorization = config.api_auth_backend[env_backend];

        const settings = {
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authorization}`
            }
        };

        const apiUrl = api_url_backend + 'teams/delete' + '?id_squadra=' + squadra_da_eliminare;
        
        axios.delete(apiUrl, settings).then(res => {
            this.setState({ isloading: true});
            this.setState({ deleteteamclicked: true});
            this.setState({ nome_ambito_target: "" });
            this.setState({ nome_squadra_da_eliminare: "" });
            this.setState({ squadra_da_eliminare: 0 });
            this.setState({ opendeleteteammodal: false });
            window.location.reload(false);
            this.setState({ isloading: false});
        }).catch (error => {
            //console.log(error);
            this.setState({ isloading: false});
        });
    }

    ambitoNotExist(nomesquadra, ambito){
        let squadre = this.state.squadre;
        let lista_ambiti = this.state.lista_ambiti;
        var ambitiEsistenti = [];
        squadre.forEach(function(squadra, indexsquadra){
            if (squadra.nome_squadra === nomesquadra){
                ambitiEsistenti.push(squadra.ambito);
            }
        });
        //console.log(ambitiEsistenti);
        //console.log(ambito);
        //console.log(ambitiEsistenti.indexOf(lista_ambiti[ambito]));
        if (ambitiEsistenti.indexOf(lista_ambiti[ambito]) > -1){
            return false;
        } else {
            return true;
        }
    }

    testButtonIsActive(idsquadra){
        let squadre = this.state.squadre;
        let result = false;
        squadre.forEach(function(squadra){
            if (squadra.id === idsquadra){
                let now = Date.now();
                let data_ora_inizio_carica_prove;
                let data_ora_termine_carica_prove;
                //console.log(squadra.data_ora_inizio_caricaprove);
                //console.log(squadra.data_ora_termine_caricaprove);
                if (squadra.data_ora_inizio_caricaprove !== null){
                    const [date, time] = squadra.data_ora_inizio_caricaprove.split(' ');
                    const [year, month, day] = date.split('-');
                    const [hours, minutes, seconds] = time.split(':');
                    data_ora_inizio_carica_prove = new Date(+year, +month - 1, +day, +hours, +minutes, +seconds);
                } else {
                    data_ora_inizio_carica_prove = null;
                }
                if (squadra.data_ora_termine_caricaprove !== null){
                    const [date, time] = squadra.data_ora_termine_caricaprove.split(' ');
                    const [year, month, day] = date.split('-');
                    const [hours, minutes, seconds] = time.split(':');
                    data_ora_termine_carica_prove = new Date(+year, +month - 1, +day, +hours, +minutes, +seconds);
                } else {
                    data_ora_termine_carica_prove = null;
                }          
                if (data_ora_inizio_carica_prove !== null && data_ora_termine_carica_prove !== null){      
                    if (now >= data_ora_inizio_carica_prove && now <= data_ora_termine_carica_prove){
                        result = true;
                    } else {
                        result = false;
                    }
                } else {
                    result = false;
                } 
                //console.log(data_ora_inizio_carica_prove);
                //console.log(data_ora_termine_carica_prove);
                //console.log("Il pulsante carica prove per " + squadra.ambito + " è > " + result)
            }
        });
        return result;
    }

    loadButtonIsActive(idsquadra){
        let squadre = this.state.squadre;
        let result = false;
        squadre.forEach(function(squadra){
            if (squadra.id === idsquadra){
                let now = Date.now();
                let data_ora_inizio_carica_documenti;
                let data_ora_termine_carica_documenti;
                if (squadra.data_ora_inizio_caricamentodocumenti !== null){
                    const [date, time] = squadra.data_ora_inizio_caricamentodocumenti.split(' ');
                    const [year, month, day] = date.split('-');
                    const [hours, minutes, seconds] = time.split(':');
                    data_ora_inizio_carica_documenti = new Date(+year, +month - 1, +day, +hours, +minutes, +seconds);
                } else {
                    data_ora_inizio_carica_documenti = null;
                }
                if (squadra.data_ora_termine_caricamentodocumenti !== null){
                    const [date, time] = squadra.data_ora_termine_caricamentodocumenti.split(' ');
                    const [year, month, day] = date.split('-');
                    const [hours, minutes, seconds] = time.split(':');
                    data_ora_termine_carica_documenti = new Date(+year, +month - 1, +day, +hours, +minutes, +seconds);
                } else {
                    data_ora_termine_carica_documenti = null;
                }
                if (data_ora_inizio_carica_documenti !== null && data_ora_termine_carica_documenti !== null){   
                    if (now >= data_ora_inizio_carica_documenti && now <= data_ora_termine_carica_documenti){
                        result = true;
                    } else {
                        result = false;
                    }
                } else {
                    result = false;
                }
            }
        });
        return result;
    }

    loadDocumentIsDue(idsquadra){
        let squadre = this.state.squadre;
        let result = false;
        squadre.forEach(function(squadra){
            if (squadra.id === idsquadra){
                let now = Date.now();
                let data_ora_termine_carica_documenti;
                if (squadra.data_ora_termine_caricamentodocumenti !== null){
                    const [date, time] = squadra.data_ora_termine_caricamentodocumenti.split(' ');
                    const [year, month, day] = date.split('-');
                    const [hours, minutes, seconds] = time.split(':');
                    data_ora_termine_carica_documenti = new Date(+year, +month - 1, +day, +hours, +minutes, +seconds);
                } else {
                    data_ora_termine_carica_documenti = null;
                }
                if (now >= data_ora_termine_carica_documenti){
                    result = true;
                } else {
                    result = false;
                }
            }
        });
        return result;
    }

    confirmButtonIsActive(idsquadra){
        let squadre = this.state.squadre;
        let result = false;
        squadre.forEach(function(squadra){
            if (squadra.id === idsquadra){
                let now = Date.now();
                let data_ora_inizio_conferma_partecipazione;
                let data_ora_termine_conferma_partecipazione;
                if (squadra.data_ora_inizio_confermapartecipazione !== null){
                    const [date, time] = squadra.data_ora_inizio_confermapartecipazione.split(' ');
                    const [year, month, day] = date.split('-');
                    const [hours, minutes, seconds] = time.split(':');
                    data_ora_inizio_conferma_partecipazione = new Date(+year, +month - 1, +day, +hours, +minutes, +seconds);
                } else {
                    data_ora_inizio_conferma_partecipazione = null;
                }
                if (squadra.data_ora_termine_confermapartecipazione !== null){
                    const [date, time] = squadra.data_ora_termine_confermapartecipazione.split(' ');
                    const [year, month, day] = date.split('-');
                    const [hours, minutes, seconds] = time.split(':');
                    data_ora_termine_conferma_partecipazione = new Date(+year, +month - 1, +day, +hours, +minutes, +seconds);
                } else {
                    data_ora_termine_conferma_partecipazione = null;
                }
                if (data_ora_inizio_conferma_partecipazione !== null && data_ora_termine_conferma_partecipazione !== null){  
                    if (now >= data_ora_inizio_conferma_partecipazione && now <= data_ora_termine_conferma_partecipazione){
                        result = true;
                    } else {
                        result = false;
                    }
                } else {
                    result = false;
                }
            }
        });
        return result;
    }

    confirmParticipationIsDue(idsquadra){
        let squadre = this.state.squadre;
        let result = false;
        squadre.forEach(function(squadra){
            if (squadra.id === idsquadra){
                let now = Date.now();
                let data_ora_termine_conferma_partecipazione;
                if (squadra.data_ora_termine_confermapartecipazione !== null){
                    const [date, time] = squadra.data_ora_termine_confermapartecipazione.split(' ');
                    const [year, month, day] = date.split('-');
                    const [hours, minutes, seconds] = time.split(':');
                    data_ora_termine_conferma_partecipazione = new Date(+year, +month - 1, +day, +hours, +minutes, +seconds);
                } else {
                    data_ora_termine_conferma_partecipazione = null;
                }
                if (now >= data_ora_termine_conferma_partecipazione){
                    result = true;
                } else {
                    result = false;
                }
            }
        });
        return result;
    }

    render() {

        const autenticato = this.state.authorization !== '';

        const nome = this.state.nome;
        const cognome = this.state.cognome;

        const anchorElUserMenu = this.state.anchorElUserMenu;
        const open = Boolean(anchorElUserMenu);

        const id_ordine_classe = this.state.id_ordine_classe;
        const id_progetto = this.state.id_progetto;
        const tipo_progetto = this.state.tipo_progetto;
        const nome_progetto = this.state.nome_progetto;
        const nome_scuola = this.state.nome_scuola;
        const cod_scuola = this.state.cod_scuola;
        const classe = this.state.classe;
        const sezione = this.state.sezione;
        const docente_referente1 = this.state.docente_referente1;
        const docente_referente2 = this.state.docente_referente2;
        const squadre = this.state.squadre;
        const isloading = this.state.isloading;
        const user_email = this.state.user_email;
        const docente_accompagnatore_nomecognome = this.state.docente_accompagnatore_nomecognome;

        const pulsante_carica_prove = this.state.pulsante_carica_prove;
        const pulsante_carica_documenti = this.state.pulsante_carica_documenti;
        const pulsante_conferma_partecipazione = this.state.pulsante_conferma_partecipazione;
        
        const confirmparticipationclicked = this.state.confirmparticipationclicked;
        const loaddocumentclicked = this.state.loaddocumentclicked;
        const registerteamclicked = this.state.registerteamclicked;
        const deleteteamclicked = this.state.deleteteamclicked;
        const lista_ambiti = this.state.lista_ambiti;
        const ambiti_validi = this.state.ambiti_validi;

        const openregisterteammodal = this.state.openregisterteammodal;
        const opendeleteteammodal = this.state.opendeleteteammodal;

        const squadra_origine = this.state.squadra_origine;
        const nome_squadra_origine = this.state.nome_squadra_origine;
        const ambito_target = this.state.ambito_target;
        const nome_ambito_target = this.state.nome_ambito_target;
        const squadra_da_eliminare = this.state.squadra_da_eliminare;
        const nome_squadra_da_eliminare = this.state.nome_squadra_da_eliminare;

        var loadtesturl;

        if (tipo_progetto === 1 || tipo_progetto === 2){
            if (tipo_progetto === 2) {
                loadtesturl = "https://www.italianopertutti.it/modulo-prove/";
            }
            if (tipo_progetto === 1) {
                loadtesturl = "https://www.matematicapertutti.it/modulo-prove/";
            }
            loadtesturl += "?idclasse=[###idclasse###]";
            loadtesturl += "&id-squadra=[###idsquadra###]";
            loadtesturl += "&competizione=[###competizione###]";
            loadtesturl += "&nome-squadra=[###nomesquadra###]";
            loadtesturl += "&giocatori=[###giocatori###]";
            loadtesturl += "&cod-scuola=[###codscuola###]";
            loadtesturl += "&docente=[###docente###]";
            loadtesturl += "&email=[###email###]";
            loadtesturl += "&classe=[###classe###]";
            loadtesturl += "&sezione=[###sezione###]";
            loadtesturl += "&nomi-cognomi=[###nomicognomi###]";
        } else {
            loadtesturl = "";
        }

        return (
            <div>
                {confirmparticipationclicked && (
                    <Redirect to="/confirmparticipation" /> 
                )}
                {loaddocumentclicked && (
                    <Redirect to="/loaddocument" /> 
                )}
                { (autenticato && isloading === false) && ( 
                    <Fragment>
                        <Helmet htmlAttributes={{lang: 'it-IT'}}></Helmet>
                        <div className="App">
                            <header className="App-header-home">
                                <AppBar position="static" className="WelcomeBar">
                                    <Toolbar>
                                        <table className="WelcomeMenu">
                                            <tbody>
                                                <tr>
                                                    <td className="LogoMenuColumn">
                                                        <Logo />
                                                    </td>
                                                    <td className="HomeMenuColumn">
                                                        <HomeButton />
                                                        |
                                                        <TokalonButton />
                                                    </td>
                                                    <td className="AccountMenuButton">
                                                        { (nome === "" || cognome === "") ? (
                                                            <>
                                                                {/* <SocialButtonsInHeader /> */}
                                                                <Link to="/profile">
                                                                    <Button
                                                                        className=""
                                                                        size="large"
                                                                        id="user-menu-button"
                                                                        aria-label="account of current user"
                                                                        aria-controls="user-menu"
                                                                        //aria-haspopup="true"
                                                                        //aria-expanded={Boolean(anchorElUserMenu) ? 'true' : undefined}
                                                                        //onClick={this.handleUserMenuClick}
                                                                        color="inherit"
                                                                    >
                                                                        {/* <FontAwesomeIcon icon={faUser} /> */}
                                                                        <span className="WelcomeUser">{/* Bentornato */}</span>
                                                                    </Button>
                                                                </Link>
                                                                |
                                                                <Button
                                                                    className=""
                                                                    size="large"
                                                                    color="inherit"
                                                                    id="logout-button"
                                                                    onClick={this.handleLogoutClick}
                                                                >
                                                                    Esci
                                                                </Button>
                                                            </>
                                                        ): (
                                                            <>
                                                                {/* <SocialButtonsInHeader /> */}
                                                                <Link to="/profile">
                                                                    <Button
                                                                        className=""
                                                                        size="large"
                                                                        id="user-menu-button"
                                                                        aria-label="account of current user"
                                                                        aria-controls="user-menu"
                                                                        //aria-haspopup="true"
                                                                        //aria-expanded={Boolean(anchorElUserMenu) ? 'true' : undefined}
                                                                        //onClick={this.handleUserMenuClick}
                                                                        color="inherit" 
                                                                    >
                                                                        {/* <FontAwesomeIcon icon={faUser} /> */}
                                                                        <span className="WelcomeUser">{/* Bentornato */}</span> { nome + " " + cognome }
                                                                    </Button>
                                                                </Link>
                                                                |
                                                                <Button
                                                                    className=""
                                                                    size="large"
                                                                    color="inherit"
                                                                    id="logout-button"
                                                                    onClick={this.handleLogoutClick}
                                                                >
                                                                    Esci
                                                                </Button>
                                                            </>
                                                        )}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <Menu
                                            id="user-menu"
                                            anchorEl={anchorElUserMenu}
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            keepMounted
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            open={open}
                                            onClose={this.handleCloseUserMenu}
                                            MenuListProps={{
                                                'aria-labelledby': 'user-menu-button',
                                            }}
                                        >
                                            <MenuItem><Link to="/profile">Profilo</Link></MenuItem>
                                            <MenuItem onClick={this.handleLogoutClick}>Esci</MenuItem>
                                        </Menu>
                                    </Toolbar>
                                </AppBar>
                                {/* <Subheader></Subheader> */}
                            </header>
                            <div className="separator"></div>
                            <div className="Page-title Orange">Gestisci Squadre</div>
                            <div className="Page-subtitle-top">Qui puoi gestire le squadre per la tua classe.</div>
                            <div className="separator"></div>
                            {id_ordine_classe !== "" && (
                                <Fragment>
                                <Box className="DatiClasse">
                                    <fieldset>
                                        <legend>Stai partecipando con questa classe</legend>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <label><strong>Progetto: </strong>{nome_progetto}</label><br/>
                                                <label><strong>Scuola: </strong>{nome_scuola}</label><br/>
                                                <label><strong>Classe: </strong>{classe}</label><br/>
                                                <label><strong>Sezione: </strong>{sezione}</label><br/>          
                                            </Grid>
                                            <Grid item xs={6} style={{textAlign: "right"}}>
                                                <label><strong>Docenti </strong></label><br/>
                                                <label>{docente_referente1}</label><br/>
                                                <label>{docente_referente2}</label><br/>
                                            </Grid>
                                        </Grid>
                                    </fieldset>
                                </Box>
                                <div className="separator"></div>
                                <TableContainer className="TableContainer" style={{margin: "0 auto"}}>
                                    <Table className="TabellaSquadre" sx={{ minWidth: 700 }} aria-label="customized table">
                                        <TableHead className="HeadTabellaSquadre">   
                                            <TableRow>
                                                <TableCell>
                                                    COMPETIZIONE - ID - NOME SQUADRA
                                                </TableCell>
                                                <TableCell>N° GIOCATORI</TableCell>
                                                <TableCell>NOME E COGNOME</TableCell>
                                                {/* <TableCell>ADULTO ACCOMPAGNATORE</TableCell> */}
                                                {/* <TableCell>PROVE</TableCell> */}
                                                <TableCell>RIS SEL</TableCell>
                                                <TableCell>POS SEL</TableCell>
                                                <TableCell>RIS SEM</TableCell>
                                                <TableCell>POS SEM</TableCell>
                                                <TableCell>RIS FIN</TableCell>
                                                <TableCell>POS FIN</TableCell>
                                                <TableCell>GARE FINALI</TableCell>
                                                <TableCell>AZIONI</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody className="BodyTabellaSquadre">
                                            {squadre.map((squadra, indexsquadra) => (
                                                <TableRow key={"squadra"+indexsquadra}>
                                                    <TableCell align="center"
                                                        data-title="Competizione - Id - Nome squadra"
                                                    >
                                                        <div>{/*lista_ambiti[*/squadra.ambito}</div>
                                                        <div>{squadra.id}</div>
                                                        <div>{squadra.nome_squadra}</div>
                                                    </TableCell>
                                                    <TableCell align="center"
                                                        data-title="N° Giocatori"
                                                    >
                                                        {squadra.giocatori.length}
                                                    </TableCell>
                                                    <TableCell align="center"
                                                        data-title="Nome e Cognome"
                                                    >
                                                        {squadra.giocatori.map((giocatore, indexgiocatore) => (
                                                            <div key={"giocatore_"+indexgiocatore} id={"giocatore_"+indexgiocatore}>{giocatore.nome + " " + giocatore.cognome}</div>
                                                        ))}
                                                        {squadra.docente_accompagnatore_nomecognome !== "" && (
                                                            <span style={{color: "red"}}>{squadra.docente_accompagnatore_nomecognome}</span>
                                                        )}
                                                    </TableCell>
                                                    {/* <TableCell align="center"
                                                        data-title="Nome e Cognome"
                                                    >
                                                        {squadra.docente_accompagnatore_nomecognome}
                                                    </TableCell> */}
                                                    {/* <TableCell align="center"
                                                        data-title="Prove"
                                                    >
                                                        {squadra.submitted === 1 ? (
                                                            "Prove caricate"
                                                        ): (
                                                        <Fragment> */}
                                                            {/*(pulsante_carica_prove == true && id_progetto == 3) && (*/}
                                                            {/* {(pulsante_carica_prove == true && (this.testButtonIsActive(squadra.id))) && (
                                                            <a href={loadtesturl.replace("[###idclasse###]", id_ordine_classe)
                                                                                .replace("[###idsquadra###]", squadra.id)
                                                                                .replace("[###competizione###]", squadra.id_ambito)
                                                                                .replace("[###nomesquadra###]", squadra.nome_squadra)
                                                                                .replace("[###giocatori###]", squadra.giocatori.length)
                                                                                .replace("[###codscuola###]", cod_scuola)
                                                                                .replace("[###docente###]", nome+" "+cognome)
                                                                                .replace("[###email###]", user_email)
                                                                                .replace("[###classe###]", classe)
                                                                                .replace("[###sezione###]", sezione)
                                                                                .replace("[###nomicognomi###]", squadra.giocatori.map(function(elemento, index){
                                                                                    return elemento.nome + " " + elemento.cognome;
                                                                                }).join(", "))
                                                                                } target="_blank">
                                                                <Button size="small" className="LoadTest" variant="contained" color="primary" onClick={this.handleLoadTestClick}>Carica prove</Button>
                                                            </a>
                                                            )} */}
                                                       {/*  </Fragment>    
                                                        )}
                                                    </TableCell> */}
                                                    <TableCell align="center"
                                                        data-title="Risultato qualificazione"
                                                    >
                                                        {squadra.risultato ? (
                                                            <Fragment>
                                                                {squadra.risultato_note ? (
                                                                    <Tooltip title={squadra.risultato_note}>
                                                                        <div>{squadra.risultato} <span style={{color: "red"}}>*</span></div>
                                                                    </Tooltip>
                                                                ) : (
                                                                    <Fragment>
                                                                        <div>{squadra.risultato}</div>
                                                                    </Fragment>
                                                                )}
                                                            </Fragment>
                                                        ):(
                                                            <Fragment>
                                                                {0}
                                                            </Fragment>
                                                        )}
                                                    </TableCell>
                                                    <TableCell align="center"
                                                        data-title="Posizione qualificazione"
                                                    >
                                                        {(squadra.risultato && squadra.submitted === 1) && (
                                                            <Fragment>{squadra.posizione_qualificazione}°</Fragment>
                                                        )}
                                                    </TableCell>
                                                    <TableCell align="center"
                                                        data-title="Risultato semifinale"
                                                    >
                                                        {squadra.risultato_semifinale && (
                                                            <Fragment>{squadra.risultato_semifinale}</Fragment>
                                                        )}
                                                    </TableCell>
                                                    <TableCell align="center"
                                                        data-title="Posizione semifinale"
                                                    >
                                                        {(squadra.risultato_semifinale > 0 && squadra.submitted === 1) && (
                                                            <Fragment>
                                                                {squadra.semifinali_note ? (
                                                                    <Tooltip title={squadra.semifinali_note}>
                                                                        <div>{squadra.posizione_semifinale}° <span style={{color: "red"}}>*</span></div>
                                                                    </Tooltip>
                                                                ) : (
                                                                    <Fragment>
                                                                        <div>{squadra.posizione_semifinale}°</div>
                                                                    </Fragment>
                                                                )}
                                                            </Fragment>
                                                        )}
                                                    </TableCell>
                                                    <TableCell align="center"
                                                        data-title="Risultato finale"
                                                    >
                                                        {squadra.risultato_finale && (
                                                            <Fragment>{squadra.risultato_finale}</Fragment>
                                                        )}
                                                    </TableCell>
                                                    <TableCell align="center"
                                                        data-title="Posizione finale"
                                                    >
                                                        {/*(squadra.risultato_finale > 0 &&*/ squadra.submitted === 1/*)*/ && (
                                                            <Fragment>
                                                                {squadra.posizione_finale &&(
                                                                    <Fragment>{squadra.posizione_finale}°</Fragment>
                                                                )}
                                                            </Fragment>
                                                        )}
                                                    </TableCell>
                                                    <TableCell align="center"
                                                        data-title="Gare finali"
                                                    >
                                                        {squadra.finale === 0 && (
                                                            <Fragment>Selezione locale in corso</Fragment>
                                                        )}
                                                        {squadra.finale === 1 && (
                                                            <Fragment>Non ammessa</Fragment>
                                                        )}
                                                        {squadra.finale === 2 && (
                                                            <Fragment>
                                                                Ammessa alle gare finali!
                                                                {(pulsante_conferma_partecipazione == true && (this.confirmButtonIsActive(squadra.id))) && (
                                                                    <>
                                                                        <br></br><br></br>
                                                                        <Button size="small" className="ConfirmParticipation" id={"cp_" + squadra.id} variant="contained" color="primary" onClick={this.handleConfirmParticipation}>Conferma partecipazione</Button>
                                                                    </>
                                                                )}
                                                                {this.confirmParticipationIsDue(squadra.id) && (
                                                                    <>
                                                                        <br></br>
                                                                        Partecipazione non confermata.
                                                                    </>
                                                                )}
                                                            </Fragment>
                                                        )}
                                                        {squadra.finale === 3 && (
                                                            <Fragment>
                                                                Partecipazione confermata. 
                                                                {(pulsante_carica_documenti == true && (this.loadButtonIsActive(squadra.id))) && (
                                                                    <>
                                                                        Carica i documenti.
                                                                        <br></br><br></br>
                                                                        <Button size="small" className="LoadDocument" id={"ld_" + squadra.id} variant="contained" color="primary" onClick={this.handleLoadDocument}>Carica documenti</Button>
                                                                    </>
                                                                )}
                                                                {this.loadDocumentIsDue(squadra.id) && (
                                                                    <>
                                                                        <br></br>
                                                                        Documenti non caricati. Contattare la segreteria.
                                                                    </>
                                                                )}
                                                            </Fragment>
                                                        )}
                                                        {squadra.finale === 4 && (
                                                            <Fragment>
                                                                {(squadra.risultato_finale !== null && squadra.posizione_finale !== null) ? (
                                                                    <Fragment>Complimenti per la finalissima!</Fragment>
                                                                ) : (
                                                                    <Fragment>
                                                                        {(squadra.risultato_semifinale !== null && squadra.posizione_semifinale !== null) ? (
                                                                            <Fragment>Grazie per aver partecipato alle gare finali!</Fragment>
                                                                        ) : (
                                                                            <Fragment>La partecipazione alle gare finali è confermata!</Fragment>
                                                                        )}
                                                                    </Fragment>
                                                                )}
                                                            </Fragment>
                                                        )}
                                                        {(squadra.submitted === 1 && squadra.finale === 0) ? (
                                                            <>
                                                                <br></br>
                                                                Prove caricate
                                                            </>
                                                        ): (
                                                        <Fragment>
                                                            {/*(pulsante_carica_prove == true && id_progetto == 3) && (*/}
                                                            {(pulsante_carica_prove == true && (this.testButtonIsActive(squadra.id))) && (
                                                            <a href={loadtesturl.replace("[###idclasse###]", id_ordine_classe)
                                                                                .replace("[###idsquadra###]", squadra.id)
                                                                                .replace("[###competizione###]", squadra.id_ambito)
                                                                                .replace("[###nomesquadra###]", squadra.nome_squadra)
                                                                                .replace("[###giocatori###]", squadra.giocatori.length)
                                                                                .replace("[###codscuola###]", cod_scuola)
                                                                                .replace("[###docente###]", nome+" "+cognome)
                                                                                .replace("[###email###]", user_email)
                                                                                .replace("[###classe###]", classe)
                                                                                .replace("[###sezione###]", sezione)
                                                                                .replace("[###nomicognomi###]", squadra.giocatori.map(function(elemento, index){
                                                                                    return elemento.nome + " " + elemento.cognome;
                                                                                }).join(", "))
                                                                                } target="_blank">
                                                                <Button size="small" className="LoadTest" variant="contained" color="primary" onClick={this.handleLoadTestClick}>Carica prove</Button>
                                                            </a>
                                                            )}
                                                        </Fragment>    
                                                        )}
                                                    </TableCell>
                                                    <TableCell align="center"
                                                        data-title="Azioni"
                                                    >
                                                        {ambiti_validi.map((ambito, ambitoindex) => (
                                                            <Fragment key={"a_"+ ambitoindex + "_" + squadra.id}>
                                                                {(this.ambitoNotExist(squadra.nome_squadra, ambito)) && 
                                                                    <Button key={"ab_" + ambitoindex + "_" + squadra.id} size="small" className="RegisterTeam" id={"rt_" + ambito + "_" + squadra.id} variant="contained" color="primary" onClick={this.handleOpenRegisterTeamModal}>Iscrivi a {lista_ambiti[ambito]}</Button>
                                                                }
                                                            </Fragment>
                                                        ))}
                                                        <Button key={"ad_" + squadra.id_ambito + "_" + squadra.id} size="small" className="DeleteTeam" id={"dt_" + squadra.id_ambito + "_" + squadra.id} variant="contained" color="secondary" onClick={this.handleOpenDeleteTeamModal}>Elimina</Button>
                                                    </TableCell>
                                                </TableRow>
                                            ))}        
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Modal
                                    open={openregisterteammodal}
                                    onClose={this.handleCloseRegisterTeamModal}    
                                    aria-labelledby="modal-detail-title"
                                    aria-describedby="modal-detail-description"
                                >
                                    <Box className="Modal"
                                        style={{width: "auto", minWidth: "400px"}}
                                    >
                                        <>
                                            Confermi di voler iscrivere la squadra <strong>{nome_squadra_origine}</strong> e tutti i suoi partecipanti anche alla competizione <strong>{nome_ambito_target}</strong>?
                                            <br></br><br></br>
                                            <Grid container className="Form-control RegisterTeamButtonContainer">
                                                <Grid item xs={6}>
                                                    <Button size="large" id="cancel-button" className="Button-90" variant="contained" onClick={this.handleCloseRegisterTeamModal}>Annulla</Button>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Button size="large" id="register-team-button" className="Button-90" variant="contained" color="primary" onClick={this.handleClickRegisterTeam}>Conferma</Button>
                                                </Grid>
                                            </Grid>
                                        </>
                                    </Box>
                                </Modal>
                                <Modal
                                    open={opendeleteteammodal}
                                    onClose={this.handleCloseDeleteTeamModal}    
                                    aria-labelledby="modal-detail-title"
                                    aria-describedby="modal-detail-description"
                                >
                                    <Box className="Modal"
                                        style={{width: "auto", minWidth: "400px"}}
                                    >
                                        <>
                                            Confermi di voler eliminare la squadra <strong>{nome_squadra_da_eliminare}</strong> e tutti i suoi partecipanti dalla competizione <strong>{nome_ambito_target}</strong>?
                                            <br></br><br></br>
                                            <Grid container className="Form-control DeleteTeamButtonContainer">
                                                <Grid item xs={6}>
                                                    <Button size="large" id="cancel-button" className="Button-90" variant="contained" onClick={this.handleCloseDeleteTeamModal}>Annulla</Button>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Button size="large" id="register-team-button" className="Button-90" variant="contained" color="primary" onClick={this.handleClickDeleteTeam}>Conferma</Button>
                                                </Grid>
                                            </Grid>
                                        </>
                                    </Box>
                                </Modal>
                                <div className="adaptive"></div>
                            </Fragment>
                            )}
                            <div className="separator"></div>
                            <AppBar position="static" className="Footer">
                                <div>© Copyright {new Date().getFullYear()} – Associazione ToKalon - <Link to="/privacy">Privacy Policy</Link> - Realizzato da <a href="https://www.creab.it/" target="_blank" rel="noreferrer">Creabit.</a></div>
                            </AppBar>  
                        </div>                        
                    </Fragment>
                )}
                { (autenticato === false) && ( 
                    <Redirect to="/login" />
                )}
            </div>
        );
    }

}

export default ManageTeam;