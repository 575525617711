import React, { Component } from 'react';
import Button from '@mui/material/Button';

class TokalonButton extends Component {

    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {}

    componentWillUnmount() {}

    render(){

        return(
            <a href="https://associazionetokalon.com/" target="_blank">
                <Button
                    size="large"
                    id="tokalon-website-button"
                    aria-label="tokalon website"
                    aria-controls="tokalon-website-menu"
                    color="inherit"
                >
                    <span className="TokalonWebsite">SITO TOKALON</span>
                </Button>
            </a>
        );
    }
}

export default TokalonButton;