import React, { Component, Fragment } from 'react';
import Grid from '@mui/material/Grid';

class Subheader extends Component {

    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {}

    componentWillUnmount() {}

    render(){

        return(
            <div className="App-subheader-home">
                <div className="App-subheader-container-home">
                    <Grid container spacing={2}>
                        <Grid item md={3} sm={12} xs={12}>
                            <div className="logo-unit">
                                <a href="https://associazionetokalon.com/">
                                    <img src="https://associazionetokalon.com/wp-content/uploads/2020/09/logo-1024x234-1-e1601319474201.png" style={{width: "253px"}} alt="Associazione Tokalon" />
                                </a>
                            </div>
                        </Grid>
                        <Grid item md={9} sm={9} xs={12}>
                            <div className="header-main-menu-wrapper">
                                <div className="pull-right">
                                    <ul className="header-menu"> 
                                        <li className="menu-item">
                                            <a href="https://associazionetokalon.com/chi-siamo/">CHI SIAMO</a>
                                        </li>
                                        <li className="menu-item">
                                            <a href="https://associazionetokalon.com/corsi/" aria-current="page">FORMAZIONE</a>
                                        </li>
                                        <li className="menu-item">
                                            <a href="https://associazionetokalon.com/con-corsi/">CON-CORSI</a>
                                        </li>
                                        <li className="menu-item">
                                            <a href="https://associazionetokalon.com/blog/">BLOG</a>
                                        </li>
                                        <li className="menu-item">
                                            <a href="https://associazionetokalon.com/pubblicazioni/">EBOOK</a>
                                        </li>
                                        <li className="menu-item">
                                            <a href="https://associazionetokalon.com/contatti/">CONTATTI</a>
                                        </li>
                                        <li className="menu-item">
                                            <a target="_blank" rel="noopener" href="https://a2c8i1.mailupclient.com/frontend/forms/Subscription.aspx?idList=1&amp;idForm=3&amp;guid=AAFA5375-BCF1-4E06-965A-E3A98B626156">NEWSLETTER</a>
                                        </li>
                                        <li className="menu-item active">
                                            <a href="#">CONCORSO</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        );
    }

}

export default Subheader;