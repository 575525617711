import React, { Component, Fragment } from 'react';
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';

class SocialButtonsInHeader extends Component {

    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {}

    componentWillUnmount() {}

    render(){

        return(
            <>
                <a target="_blank" href="https://www.facebook.com/associazionetokalon/">
                    <Button
                        className=""
                        size="large"
                        color="inherit"
                        id="facebook-button"
                    >
                        <FontAwesomeIcon icon={faFacebook} />
                    </Button>
                </a>
                <a target="_blank" href="https://www.instagram.com/associazionetokalon/">
                    <Button
                        className=""
                        size="large"
                        color="inherit"
                        id="instagram-button"
                    >    
                        <FontAwesomeIcon icon={faInstagram} />
                    </Button>
                </a>
                <a target="_blank" href="https://www.youtube.com/channel/UCvBdcVF1FtJeZObxW5zjulQ">
                    <Button
                        className=""
                        size="large"
                        color="inherit"
                        id="youtube-button"
                    >        
                        <FontAwesomeIcon icon={faYoutube} />
                    </Button>
                </a>
            </>
        );
    }

}

export default SocialButtonsInHeader;