import React, { Component } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';
import { Redirect } from "react-router-dom";
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Link } from "react-router-dom";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import FormHelperText from '@mui/material/FormHelperText';
import {Helmet} from "react-helmet";
import Subheader from './Subheader';
import HomeButton from './HomeButton';
import SocialButtonsInHeader from './SocialButtonsInHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import Logo from './Logo';
import TokalonButton from './TokalonButton';

class NewUser extends Component {

    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            authorization: Cookies.get('authorization') || '',
            wp_user_id: Cookies.get('wp_user_id') || 0,
            user_email: Cookies.get('user_email') || '',
            nome: Cookies.get('nome') || '',
            cognome: Cookies.get('cognome') || '',

            anchorElUserMenu: null,

            error: false,
            errorcode: '',
            errormessage: '',
            isloading: false,

            codice_scuola: "",
            id_scuola: "",
            nome_scuola: "",
            nome_istituto: "",
            tipologia_scuola: "",
            citta_scuola: "",
            provincia_scuola: "",
            indirizzo_scuola: "",
            cap_scuola: "",

            codice_scuola_error: false,
            nomeerror: false,
            cognomeerror: false,
            phoneerror: false,
            gradoerror: false,
            ruoloerror: false,
            altroruoloerror: false,
            formhidden: true,
            registercompleted: false,

            cellulare: "",
            grado: "infanzia",
            ruolo: 1,
            altro_ruolo: "",
            privacy: false,
            newsletter: false,

            nome_fatturazione: "",
            cognome_fatturazione: "",
            cf_piva: "",
            indirizzo_fatturazione: "",
            citta_fatturazione: "",
            provincia_fatturazione: "",
            cap_fatturazione: "",

            newuser: Cookies.get('newuser') || "false",
        };
        this.handleLogoutClick = this.handleLogoutClick.bind(this);
        this.handleUserMenuClick = this.handleUserMenuClick.bind(this);
        this.handleCloseUserMenu = this.handleCloseUserMenu.bind(this);
        this.handleChangeFirstname = this.handleChangeFirstname.bind(this);
        this.handleChangeLastname = this.handleChangeLastname.bind(this);
        this.handleChangePhone = this.handleChangePhone.bind(this);
        this.handleChangeGrado = this.handleChangeGrado.bind(this);
        this.handleChangeRole = this.handleChangeRole.bind(this);
        this.handleChangeOtherRole = this.handleChangeOtherRole.bind(this);
        this.handleChangePrivacy = this.handleChangePrivacy.bind(this);
        this.handleChangeNewsletter = this.handleChangeNewsletter.bind(this);
        this.handleRegisterClick = this.handleRegisterClick.bind(this);
        this.handleChangeSchoolCode = this.handleChangeSchoolCode.bind(this);

    }

    componentDidMount() {
        this._isMounted = true;
        if (this._isMounted) {
            this.setState({ isloading: true });
        }
        var config = require('./config.json');

        //Leggere i dati del profilo Wp dell'utente e se presenti li importa 
        var env_wp = config.env_wp;
        var api_url_wp = config.api_url_wp[env_wp];
        var api_auth_wp = config.api_auth_wp[env_wp];
        var email = this.state.user_email;

        const apiUrl2 = api_url_wp + 'getprofile';

        var env_backend = config.env_backend;
        var api_url_backend = config.api_url_backend[env_backend];
        var api_auth_backend = config.api_auth_backend[env_backend];

        const apiUrl = api_url_backend;
        let authorization = api_auth_backend;
                
        const settings = {
            headers: { Authorization: `Bearer ${authorization}` }
        };

        axios.post(apiUrl2, {
            "authorization" : api_auth_wp,
            "email" : email,
        }).then(res => {
            if (res.data.success == true){
                var codice_scuola = res.data.data.codice_meccanografico_scuola;
                var cellulare = res.data.data.telefono_fatturazione;
                var grado = res.data.data.grado;
                var nome_fatturazione = res.data.data.nome_fatturazione;
                var cognome_fatturazione = res.data.data.cognome_fatturazione;
                var codice_fiscale = res.data.data.codice_fiscale;
                var partita_iva = res.data.data.partita_iva;
                var indirizzo_fatturazione = res.data.data.indirizzo_fatturazione1;
                var citta_fatturazione = res.data.data.citta_fatturazione;
                var provincia_fatturazione = res.data.data.provincia_fatturazione;
                var cap_fatturazione = res.data.data.cap_fatturazione;
                if (codice_scuola != null && codice_scuola != ""){
                    this.setState({ codice_scuola: codice_scuola});
                }
                if (cellulare != null && cellulare != ""){
                    this.setState({ cellulare: cellulare});
                }
                if (grado != null && grado != ""){
                    let gradi_validi = ['infanzia', 'primaria', 'secondaria', 'secondaria2'];
                    if (gradi_validi.indexOf(grado)){
                        this.setState({ grado: grado});
                    }
                }
                if (nome_fatturazione != null && nome_fatturazione != ""){
                    this.setState({ nome_fatturazione: nome_fatturazione});
                }
                if (cognome_fatturazione != null && cognome_fatturazione != ""){
                    this.setState({ cognome_fatturazione: cognome_fatturazione});
                }
                if (partita_iva != null && partita_iva != ""){
                    this.setState({ cf_piva: partita_iva});
                } else {
                    if (partita_iva != null && partita_iva != ""){
                        this.setState({ cf_piva: codice_fiscale});
                    }
                }
                if (indirizzo_fatturazione != null && indirizzo_fatturazione != ""){
                    this.setState({ indirizzo_fatturazione: indirizzo_fatturazione});
                }
                if (citta_fatturazione != null && citta_fatturazione != ""){
                    this.setState({ citta_fatturazione: citta_fatturazione});
                }
                if (provincia_fatturazione != null && provincia_fatturazione != ""){
                    this.setState({ provincia_fatturazione: provincia_fatturazione});
                }
                if (cap_fatturazione != null && cap_fatturazione != ""){
                    this.setState({ cap_fatturazione: cap_fatturazione});
                }

                //Recupera l'id scuola
                if (codice_scuola != null && codice_scuola != ""){
                    axios.post(apiUrl+"schools/getbycode", { 
                        "codice": codice_scuola
                    }, settings).then(res => {

                        if (res.data.success) {

                            let id_scuola = res.data.data.id_scuola;
                            let nome_scuola = res.data.data.nome_scuola; 
                            let nome_istituto = res.data.data.nome_istituto; 
                            let tipologia_scuola = res.data.data.tipologia; 
                            let citta_scuola = res.data.data.citta;
                            let provincia_scuola = res.data.data.provincia;
                            let indirizzo_scuola = res.data.data.indirizzo;
                            let cap_scuola = res.data.data.cap;
                            this.setState({ error: false});
                            this.setState({ id_scuola: id_scuola});
                            this.setState({ nome_scuola: nome_scuola});
                            this.setState({ nome_istituto: nome_istituto});
                            this.setState({ tipologia_scuola: tipologia_scuola});
                            this.setState({ citta_scuola: citta_scuola});
                            this.setState({ provincia_scuola: provincia_scuola});
                            this.setState({ indirizzo_scuola: indirizzo_scuola});
                            this.setState({ cap_scuola: cap_scuola});
                            this.setState({ formhidden: false});
                        } else {

                            if (this._isMounted) {
                                this.setState({ error: true});
                                this.setState({ id_scuola: null});
                                this.setState({ nome_scuola: ''});
                                this.setState({ nome_istituto: ''});
                                this.setState({ tipologia_scuola: ''});
                                this.setState({ citta_scuola: ''});
                                this.setState({ provincia_scuola: ''});
                                this.setState({ indirizzo_scuola: ''});
                                this.setState({ cap_scuola: ''});
                                this.setState({ errorcode: res.data.data.code });
                                this.setState({ errormessage: res.data.data.message });
                                this.setState({ formhidden: true});
                            }

                        }

                    }).catch (error => {

                        if (this._isMounted) {
                            this.setState({ error: true});
                            this.setState({ id_scuola: null});
                            this.setState({ nome_scuola: ''});
                            this.setState({ nome_istituto: ''});
                            this.setState({ tipologia_scuola: ''});
                            this.setState({ citta_scuola: ''});
                            this.setState({ provincia_scuola: ''});
                            this.setState({ indirizzo_scuola: ''});
                            this.setState({ cap_scuola: ''});
                            this.setState({ errorcode: error.response.data.data.code });
                            this.setState({ errormessage: error.response.data.data.message });
                            this.setState({ formhidden: true});
                        }
                    });   
                }

                this.setState({ isloading: false });
            }
        }).catch (error => {  
            this.setState({ isloading: false });
        });

        /* var env_backend = config.env_backend;
        var api_url_backend = config.api_url_backend[env_backend];
        const apiUrl = api_url_backend + 'users/get';
        var authorization = config.api_auth_backend[env_backend];
        
        const settings = {
            headers: { Authorization: `Bearer ${authorization}` }
        }; */

        /* axios.post(apiUrl, { 
            "email" : email
        }, settings).then(res => {
            if (this._isMounted) {
                this.setState({ codice_scuola: res.data.data.dati_scuola.codice_meccanografico_scuola || ""});
                this.setState({ id_scuola: res.data.data.dati_scuola.id || ""});
                this.setState({ nome_scuola: res.data.data.dati_scuola.nome_scuola || ""});
                this.setState({ nome_istituto: res.data.data.dati_scuola.nome_istituto || ""});
                this.setState({ tipologia_scuola: res.data.data.dati_scuola.tipologia || ""});
                this.setState({ citta_scuola: res.data.data.dati_scuola.citta || ""});
                this.setState({ provincia_scuola: res.data.data.dati_scuola.provincia || "XX"});
                this.setState({ indirizzo_scuola: res.data.data.dati_scuola.indirizzo || ""});
                this.setState({ cap_scuola: res.data.data.dati_scuola.cap || ""});
                this.setState({ grado: res.data.data.dati_utente.grado || "infanzia"});
                this.setState({ formhidden: false});
                this.setState({ isloading: false });
            }
        }).catch (error => {
            //console.log(error);
            this.setState({ isloading: false});
        }); */
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    requiredFieldsAreFilled() {

        var required_fields;

        if (this.state.ruolo == 1){

            required_fields = [
                'nome',
                'cognome',
                'cellulare'
            ];

        } else {

            required_fields = [
                'altro_ruolo',
                'nome',
                'cognome',
                'cellulare'
            ];

        }

        let filled = 0;
        required_fields.forEach((element) => {
            if (this.state[element].length > 0) {
                filled = filled + 1;
            }
        });

        if (filled === required_fields.length){
            return true;
        } else {
            return false;
        }
    }

    fieldsAreValid() {
        if (this.validateSchoolCode(this.state.codice_scuola) === false) {
            //console.log("school code invalid");
            return false;
        }
        if (this.validateFirstname(this.state.nome) === false) {
            //console.log("nome invalid");
            return false;
        }
        if (this.validateLastname(this.state.cognome) === false) {
            //console.log("cognome invalid");
            return false;
        }
        if (this.validatePhone(this.state.cellulare) === false) {
            //console.log("cellulare invalid");
            return false;
        }   
        if (this.validateOtherRole(this.state.altro_ruolo) === false) {
            //console.log("altro_ruolo invalid");
            return false;
        }
        return true;
    }

    handleLogoutClick(event) {
        event.preventDefault();
        this.setState({ authorization: ''});
        Cookies.remove('authorization');
        Cookies.remove('user_email');
        Cookies.remove('nome');
        Cookies.remove('cognome');
        Cookies.remove('wp_user_id');
        Cookies.remove('newuser');
    }

    handleUserMenuClick(event){
        //console.log(event.currentTarget);
        this.setState({ anchorElUserMenu: event.currentTarget});
    }

    handleCloseUserMenu(){
        this.setState({ anchorElUserMenu: null});
    }

    validateSchoolCode(value){
        let schoolcode = value;
        const re = /^[A-Z0-9]{10}/;
        //console.log(re.test(schoolcode));
        return re.test(schoolcode);
    }

    validateFirstname(value) {
        let nome = value;
        const re = /^[a-zàòùèéì ,.'-]+$/i;
        return re.test(nome) || nome.length === 0;
    }

    validateLastname(value) {
        let cognome = value;
        const re = /^[a-zàòùèéì ,.'-]+$/i;
        return re.test(cognome) || cognome.length === 0;
    }

    validatePhone(value) {
        let cellulare = value;
        const re = /^[0-9\s]{8,20}$/;
        return re.test(cellulare) || cellulare.length === 0;
    }

    validateOtherRole(value) {
        let nome = value;
        const re = /^[a-zàòùèéì ,.'-]+$/i;
        return re.test(nome) || nome.length === 0;
    }

    getListaGradi() {
        let gradi = {
          infanzia: "infanzia",
          primaria: "primaria",
          secondaria: "secondaria di 1° grado",
          secondaria2: "secondaria di 2° grado",
        };
        return gradi;
    }

    getListaRuoli(){
        let ruoli = {
            1: "Sono un docente",
            3: "Non sono un docente (specificare ruolo)"
          };
          return ruoli;
    }

    handleChangeSchoolCode(event) {

        var config = require('./config.json');
        var env_backend = config.env_backend;
        var api_url_backend = config.api_url_backend[env_backend];
        var api_auth_backend = config.api_auth_backend[env_backend];

        this.setState({ codice_scuola: event.target.value.toUpperCase() }, function(){

            this.setState({ id_scuola: null});
            this.setState({ nome_scuola: ''});
            this.setState({ nome_istituto: ''});
            this.setState({ tipologia_scuola: ''});
            this.setState({ citta_scuola: ''});
            this.setState({ provincia_scuola: ''});
            this.setState({ indirizzo_scuola: ''});
            this.setState({ cap_scuola: ''});
            
            if (this.validateSchoolCode(event.target.value.toUpperCase())) {

                this.setState({ codice_scuola_error: false }, function(){
                    const apiUrl = api_url_backend;
                    let authorization = api_auth_backend;
                    let codice_scuola = event.target.value.toUpperCase();
    
                    const settings = {
                        headers: { Authorization: `Bearer ${authorization}` }
                    };
    
                    axios.post(apiUrl+"schools/getbycode", { 
                        "codice": codice_scuola
                    }, settings).then(res => {
    
                        if (res.data.success) {
    
                            let id_scuola = res.data.data.id_scuola;
                            let nome_scuola = res.data.data.nome_scuola; 
                            let nome_istituto = res.data.data.nome_istituto; 
                            let tipologia_scuola = res.data.data.tipologia; 
                            let citta_scuola = res.data.data.citta;
                            let provincia_scuola = res.data.data.provincia;
                            let indirizzo_scuola = res.data.data.indirizzo;
                            let cap_scuola = res.data.data.cap;
                            this.setState({ error: false});
                            this.setState({ id_scuola: id_scuola});
                            this.setState({ nome_scuola: nome_scuola});
                            this.setState({ nome_istituto: nome_istituto});
                            this.setState({ tipologia_scuola: tipologia_scuola});
                            this.setState({ citta_scuola: citta_scuola});
                            this.setState({ provincia_scuola: provincia_scuola});
                            this.setState({ indirizzo_scuola: indirizzo_scuola});
                            this.setState({ cap_scuola: cap_scuola});
                            this.setState({ formhidden: false});
                        } else {
    
                            if (this._isMounted) {
                                this.setState({ error: true});
                                this.setState({ id_scuola: null});
                                this.setState({ nome_scuola: ''});
                                this.setState({ nome_istituto: ''});
                                this.setState({ tipologia_scuola: ''});
                                this.setState({ citta_scuola: ''});
                                this.setState({ provincia_scuola: ''});
                                this.setState({ indirizzo_scuola: ''});
                                this.setState({ cap_scuola: ''});
                                this.setState({ errorcode: res.data.data.code });
                                this.setState({ errormessage: res.data.data.message });
                                this.setState({ formhidden: true});
                            }
    
                        }
    
                    }).catch (error => {
    
                        if (this._isMounted) {
                            this.setState({ error: true});
                            this.setState({ id_scuola: null});
                            this.setState({ nome_scuola: ''});
                            this.setState({ nome_istituto: ''});
                            this.setState({ tipologia_scuola: ''});
                            this.setState({ citta_scuola: ''});
                            this.setState({ provincia_scuola: ''});
                            this.setState({ indirizzo_scuola: ''});
                            this.setState({ cap_scuola: ''});
                            this.setState({ errorcode: error.response.data.data.code });
                            this.setState({ errormessage: error.response.data.data.message });
                            this.setState({ formhidden: true});
                        }
                    });          
                });
                

            } else {
                this.setState({ codice_scuola_error: true });
                this.setState({ formhidden: true});
            }
        });
    }

    handleChangeFirstname(event) {
        this.setState({ nome: event.target.value }, function(){
            if (this.validateFirstname(event.target.value)) {
                this.setState({ nomeerror: false });
            } else {
                this.setState({ nomeerror: true });
            }
            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();
            if (required_field_are_filled && fields_are_valid && this.state.privacy === true) {
                this.setState({ registerenabled: true });
            } else {
                this.setState({ registerenabled: false });
            }
        });
    }

    handleChangeLastname(event) {
        this.setState({ cognome: event.target.value }, function(){
            if (this.validateLastname(event.target.value)) {
                this.setState({ cognomeerror: false });
            } else {
                this.setState({ cognomeerror: true });
            }
            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();
            if (required_field_are_filled && fields_are_valid && this.state.privacy === true) {
                this.setState({ registerenabled: true });
            } else {
                this.setState({ registerenabled: false });
            }
        });
    }

    handleChangePhone(event) {
        this.setState({ cellulare: event.target.value }, function(){
            if (this.validatePhone(event.target.value)) {
                this.setState({ phoneerror: false });
            } else {
                this.setState({ phoneerror: true });
            }
            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();
            if (required_field_are_filled && fields_are_valid && this.state.privacy === true) {
                this.setState({ registerenabled: true });
            } else {
                this.setState({ registerenabled: false });
            }
        });
    }

    handleChangeGrado(event) {
        this.setState({ grado: event.target.value },function(){
            if (this.requiredFieldsAreFilled()) {
                this.setState({ registerenabled: true });
            } else {
                this.setState({ registerenabled: false });
            }
        });
    }

    handleChangeRole(event) {
        this.setState({ ruolo: parseInt(event.target.value) },function(){
            //console.log(this.state.altro_ruolo_enabled);
            if (event.target.value == 1){
                this.setState({ altro_ruolo_enabled: false });
            }
            if (event.target.value == 3){
                this.setState({ altro_ruolo_enabled: true });
            }
            if (this.requiredFieldsAreFilled()) {
                this.setState({ registerenabled: true });
            } else {
                this.setState({ registerenabled: false });
            }
        });
    }

    handleChangeOtherRole(event) {
        this.setState({ altro_ruolo: event.target.value }, function(){
            if (this.validateOtherRole(event.target.value)) {
                this.setState({ altroruoloerror: false });
            } else {
                this.setState({ altroruoloerror: true });
            }
            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();
            if (required_field_are_filled && fields_are_valid && this.state.privacy === true) {
                this.setState({ registerenabled: true });
            } else {
                this.setState({ registerenabled: false });
            }
        });
    }

    handleChangePrivacy(event) {
        this.setState({ privacy: event.target.checked }, function(){
            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();
            if (required_field_are_filled && fields_are_valid && event.target.checked === true) {
                this.setState({ registerenabled: true });
            } else {
                this.setState({ registerenabled: false });
            }
        });
    }

    handleChangeNewsletter(event) {
        this.setState({ newsletter: event.target.checked }, function(){
            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();
            if (required_field_are_filled && fields_are_valid && this.state.privacy === true) {
                this.setState({ registerenabled: true });
            } else {
                this.setState({ registerenabled: false });
            }
        });
    }

    handleRegisterClick(event) {
        event.preventDefault();

        var config = require('./config.json');
        var env_backend = config.env_backend;
        var api_url_backend = config.api_url_backend[env_backend];
        var api_auth_backend = config.api_auth_backend[env_backend];

        var env_wp = config.env_wp;
        var api_url_wp = config.api_url_wp[env_wp];
        var api_auth_wp = config.api_auth_wp[env_wp];

        const apiUrl2 = api_url_backend;

        const apiUrl3 = api_url_wp + 'profile';

        this.setState({ error: false });
        this.setState({ isloading: true });  

        let authorization = api_auth_backend;
        let nome = this.state.nome;
        let cognome = this.state.cognome;
        let username = this.state.nome+" "+this.state.cognome;
        let cellulare = this.state.cellulare;
        let grado = this.state.grado;
        let ruolo = this.state.ruolo;
        let altro_ruolo = this.state.altro_ruolo;
        let privacy = this.state.privacy;
        let newsletter = this.state.newsletter;
        let user_email = this.state.user_email;

        let nome_fatturazione = this.state.nome_fatturazione;
        let cognome_fatturazione = this.state.cognome_fatturazione;
        let cf_piva = this.state.cf_piva;
        let indirizzo_fatturazione = this.state.indirizzo_fatturazione;
        let citta_fatturazione = this.state.citta_fatturazione;
        let cap_fatturazione = this.state.cap_fatturazione;
        let codice_scuola = this.state.codice_scuola;
        let nome_scuola = this.state.nome_scuola;
        let citta_scuola = this.state.citta_scuola;
        
        const settings = {
            headers: { Authorization: `Bearer ${authorization}` }
        };

        let id_scuola = this.state.id_scuola;

        //Salvare i dati nell'utente Laravel                
        axios.post(apiUrl2+"users/completeregister", { 
            "user_email": user_email,
            "ruolo": ruolo,
            "altro_ruolo": altro_ruolo,
            "nome": nome,
            "cognome": cognome,
            "name": username,
            "cellulare": cellulare,
            "grado": grado,
            "id_scuola": id_scuola,
            "privacy": privacy,
            "newsletter": newsletter
        }, settings).then(res2 => {

            if (res2.data.success) {

                //Aggiornare i dati di fatturazione se presenti
                let dataToUpdate = {"user_email": user_email};
                if (nome_fatturazione != null && nome_fatturazione != ""){
                    dataToUpdate.nome_fatturazione = nome_fatturazione;
                }
                if (cognome_fatturazione != null && cognome_fatturazione != ""){
                    dataToUpdate.cognome_fatturazione = cognome_fatturazione;
                }
                if (cf_piva != null && cf_piva != ""){
                    dataToUpdate.cf_piva = cf_piva;
                }
                if (indirizzo_fatturazione != null && indirizzo_fatturazione != ""){
                    dataToUpdate.indirizzo_fatturazione = indirizzo_fatturazione;
                }
                if (citta_fatturazione != null && citta_fatturazione != ""){
                    dataToUpdate.citta_fatturazione = citta_fatturazione;
                }
                if (cap_fatturazione != null && cap_fatturazione != ""){
                    dataToUpdate.cap_fatturazione = cap_fatturazione;
                }
                axios.patch(apiUrl2+"users/updateinvoicingdata", dataToUpdate, settings
                ).then(res3 => {
                    if (res3.data.success) {

                    }
                }).catch (error3 => {

                });

                //Aggiorna profilo utente WP
                axios.post(apiUrl3, {
                    "authorization" : api_auth_wp,
                    "username" : user_email,
                    "nome" : nome,
                    "cognome" : cognome,
                    "telefono_fatturazione" : cellulare,
                    "codice_meccanografico_scuola" : codice_scuola,
                    "scuola": nome_scuola + " - " + citta_scuola,
                    "grado": grado
                }).then(res => {
                    
                }).catch (error => {  
                    
                });   

                Cookies.set('nome', res2.data.data.nome);
                Cookies.set('cognome', res2.data.data.cognome);
                Cookies.set('newuser', "false");
                if (this._isMounted) {
                    this.setState({ codice_scuola: ''});
                    this.setState({ id_scuola: ''});
                    this.setState({ nome_scuola: ''});
                    this.setState({ nome_istituto: ''});
                    this.setState({ tipologia_scuola: ''});
                    this.setState({ citta_scuola: ''});
                    this.setState({ provincia_scuola: ''});
                    this.setState({ indirizzo_scuola: ''});
                    this.setState({ cap_scuola: ''});
                    this.setState({ nome: ''});
                    this.setState({ cognome: ''});
                    this.setState({ cellulare: ''});
                    this.setState({ grado: 'infanzia'});
                    this.setState({ ruolo: 1});
                    this.setState({ altro_ruolo: ''});
                    this.setState({ privacy: false});
                    this.setState({ newsletter: false});
                    this.setState({ registerenabled: false});
                    this.setState({ isloading: false });
                    this.setState({ registercompleted: true });
                }
                
            } else {
            
                if (this._isMounted) {
                    this.setState({ error: true});
                    this.setState({ errorcode: res2.data.data.code });
                    this.setState({ errormessage: res2.data.data.message });
                    this.setState({ isloading: false });
                }
            }
        
        }).catch (error2 => {

            if (this._isMounted) {
                this.setState({ error: true});
                this.setState({ errorcode: error2.response.data.data.code });
                this.setState({ errormessage: error2.response.data.data.message });
                this.setState({ isloading: false });
            }
        });
    }

    render() {

        const autenticato = this.state.authorization !== '';
        const error = this.state.error;
        const errormessage = this.state.errormessage;
        const isloading = this.state.isloading;

        const anchorElUserMenu = this.state.anchorElUserMenu;
        const open = Boolean(anchorElUserMenu);

        const nome = this.state.nome;
        const cognome = this.state.cognome;

        const user_email = this.state.user_email;
        const codice_scuola = this.state.codice_scuola;
        const id_scuola = this.state.id_scuola;
        const nome_scuola = this.state.nome_scuola;
        const nome_istituto = this.state.nome_istituto;
        const tipologia_scuola = this.state.tipologia_scuola;
        const citta_scuola = this.state.citta_scuola;
        const provincia_scuola = this.state.provincia_scuola;
        const indirizzo_scuola = this.state.indirizzo_scuola;
        const cap_scuola = this.state.cap_scuola;
        const cellulare = this.state.cellulare;
        const altro_ruolo = this.state.altro_ruolo;

        const codice_scuola_error = this.state.codice_scuola_error;
        const nomeerror = this.state.nomeerror;
        const cognomeerror = this.state.cognomeerror;
        const phoneerror = this.state.phoneerror;
        const altroruoloerror = this.state.altroruoloerror;
        const registerenabled = this.state.registerenabled;
        const altroruoloenabled = this.state.altro_ruolo_enabled;
        const formhidden = this.state.formhidden;
        const registercompleted = this.state.registercompleted;

        const newuser = this.state.newuser;

        return(
            <div>
                <Helmet htmlAttributes={{lang: 'it-IT'}}>
                    <title>Concorsi ToKalon - Completa i dati di registrazione del tuo profilo</title>
                    <meta name="description" content="Completa i dati di registrazione del tuo profilo"  data-react-helmet="true"/>
               </Helmet> 
               { registercompleted && (
                    <Redirect to="/" />
               )}
               { (autenticato === false) && (
                    <Redirect to="/login" />
               )}
               { (newuser === "true" && autenticato) ? (
                    <div className="App">
                        {( isloading === false) ? (
                            <div>
                                <header className="App-header">
                                    <AppBar position="static" className="WelcomeBar">
                                        <Toolbar>
                                            <table className="WelcomeMenu">
                                                <tbody>
                                                    <tr>
                                                        <td className="LogoMenuColumn">
                                                            <Logo />
                                                        </td>
                                                        <td className="HomeMenuColumn">
                                                            <HomeButton />
                                                            |
                                                            <TokalonButton />
                                                        </td>
                                                        <td className="AccountMenuButton">
                                                            { (nome === "" || cognome === "") ? (
                                                                <>
                                                                    {/* <SocialButtonsInHeader /> */}
                                                                    <Link to="/profile">
                                                                        <Button
                                                                            className="AccountName"
                                                                            size="large"
                                                                            id="user-menu-button"
                                                                            aria-label="account of current user"
                                                                            aria-controls="user-menu"
                                                                            //aria-haspopup="true"
                                                                            //aria-expanded={Boolean(anchorElUserMenu) ? 'true' : undefined}
                                                                            //onClick={this.handleUserMenuClick}
                                                                            color="inherit"
                                                                        >
                                                                            {/* <FontAwesomeIcon icon={faUser} /> */}
                                                                            <span className="WelcomeUser">{/* Bentornato */}</span>
                                                                        </Button>
                                                                    </Link>
                                                                    |
                                                                    <Button
                                                                        className=""
                                                                        size="large"
                                                                        color="inherit"
                                                                        id="logout-button"
                                                                        onClick={this.handleLogoutClick}
                                                                    >
                                                                        Esci
                                                                    </Button>
                                                                </>
                                                            ): (
                                                                <>
                                                                    {/* <SocialButtonsInHeader /> */}
                                                                    <Link to="/profile">
                                                                        <Button
                                                                            className="AccountName"
                                                                            size="large"
                                                                            id="user-menu-button"
                                                                            aria-label="account of current user"
                                                                            aria-controls="user-menu"
                                                                            //aria-haspopup="true"
                                                                            //aria-expanded={Boolean(anchorElUserMenu) ? 'true' : undefined}
                                                                            //onClick={this.handleUserMenuClick}
                                                                            color="inherit" 
                                                                        >
                                                                            {/* <FontAwesomeIcon icon={faUser} /> */}
                                                                            <span className="WelcomeUser">{/* Bentornato */}</span> { nome + " " + cognome }
                                                                        </Button>
                                                                    </Link>
                                                                    |
                                                                    <Button
                                                                        className=""
                                                                        size="large"
                                                                        color="inherit"
                                                                        id="logout-button"
                                                                        onClick={this.handleLogoutClick}
                                                                    >
                                                                        Esci
                                                                    </Button>
                                                                </>
                                                            )}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <Menu
                                                id="user-menu"
                                                anchorEl={anchorElUserMenu}
                                                anchorOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                                keepMounted
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                                open={open}
                                                onClose={this.handleCloseUserMenu}
                                                MenuListProps={{
                                                    'aria-labelledby': 'user-menu-button',
                                                }}
                                            >
                                                {/* <MenuItem><Link to="/profile">Profilo</Link></MenuItem> */}
                                                <MenuItem onClick={this.handleLogoutClick}>Esci</MenuItem>
                                            </Menu>
                                        </Toolbar>
                                    </AppBar>
                                    {/* <Subheader></Subheader> */}
                                    <div className="separator"></div>
                                    <div className="Page-title Orange">Completa i tuoi dati di registrazione</div>
                                    <div className="Page-subtitle-top">Per iniziare a utilizzare l'App devi impostare alcuni dati associati al tuo profilo.</div>
                                    { isloading && (
                                        <CircularProgress className="Progress"/>
                                    )}
                                    <div className="Form-label Register"><strong>Inserisci il codice meccanografico della tua scuola</strong></div>
                                    <div className="Form-control Register SchoolData">
                                        { codice_scuola_error ? (
                                            <TextField id="codice_scuola" inputProps={{ maxLength: 11 }} required error helperText="Valore non valido" variant="outlined" onLoad={this.handleChangeSchoolCode} onChange={this.handleChangeSchoolCode} value={ codice_scuola }/>
                                        ) : (
                                            <TextField id="codice_scuola" inputProps={{ maxLength: 11 }} required variant="outlined" onChange={this.handleChangeSchoolCode} value={ codice_scuola }/> 
                                        )}
                                        { id_scuola ? (
                                            <div>
                                                <div className="Form-label Register">Ho trovato:</div>
                                                <table className="School-Data" style={{paddingLeft: "7px"}}><tbody>
                                                        <tr>
                                                            <td>Nome istituto:</td><td>{ nome_istituto }</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Nome scuola:</td><td>{ nome_scuola }</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Indirizzo:</td><td>{ indirizzo_scuola }</td>
                                                        </tr>
                                                        <tr>
                                                            <td></td><td>{ cap_scuola } { citta_scuola } { provincia_scuola }</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Tipologia:</td><td>{ tipologia_scuola }</td>
                                                        </tr>
                                                    </tbody></table>
                                            </div>
                                        ) : (
                                            <div></div>
                                        )}
                                    </div>
                                    <Box className={formhidden ? "Form-control Register BoxHidden" : "Form-control Register"}>
                                        <div className="Form-title-label Register"><strong>Inserisci i tuoi dati personali</strong></div>  
                                        <br/>
                                        <div className="Form-label Register">Nome</div>
                                        <div className="Form-control Register" style={{width: "100%", padding: "0"}}>
                                            { nomeerror ? (
                                                <TextField id="nome" required placeholder="Nome" error helperText="Valore non valido" variant="outlined" onChange={this.handleChangeFirstname} value={ nome }/>
                                            ) : (
                                                <TextField id="nome" required placeholder="Nome" variant="outlined" onChange={this.handleChangeFirstname} value={ nome }/>
                                            )}
                                        </div>
                                        <div className="Form-label Register">Cognome</div>
                                        <div className="Form-control Register" style={{width: "100%", padding: "0"}}>
                                            { cognomeerror ? (
                                                <TextField id="cognome" placeholder="Cognome" required error helperText="Valore non valido" variant="outlined" onChange={this.handleChangeLastname} value={ cognome }/>
                                            ) : (
                                                <TextField id="cognome" placeholder="Cognome" required variant="outlined" onChange={this.handleChangeLastname} value={ cognome }/>
                                            )}
                                        </div>
                                        <div className="Form-label Register">Cellulare</div>
                                        <div className="Form-control Register" style={{width: "100%", padding: "0"}}>
                                            { phoneerror ? (
                                                <TextField id="cellulare" placeholder="Cellulare" variant="outlined" error helperText="Valore non valido" onChange={this.handleChangePhone} value={ cellulare }/>
                                            ) : (
                                                <TextField id="cellulare" placeholder="Cellulare" variant="outlined" onChange={this.handleChangePhone} value={ cellulare }/>
                                            )}
                                        </div>
                                        <div className="Form-label Register">Grado:</div>
                                        <div className="Form-control Register" style={{width: "100%", padding: "0"}}>
                                            <FormControl variant="outlined" className="Form-control">    
                                                <Select
                                                    native
                                                    value={this.state.grado}
                                                    onChange={this.handleChangeGrado}
                                                >
                                                    { Object.keys(this.getListaGradi()).map((keyName, keyIndex) => (
                                                        <option key={keyName} value={keyName}>{this.getListaGradi()[keyName]}</option>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div className="Form-label Register">Ruolo:</div>
                                        <div className="Form-control Register" style={{width: "100%", padding: "0"}}>
                                            <FormControl variant="outlined" className="Form-control">    
                                                <Select
                                                    native
                                                    value={this.state.ruolo}
                                                    onChange={this.handleChangeRole}
                                                >
                                                    { Object.keys(this.getListaRuoli()).map((keyName, keyIndex) => (
                                                        <option key={keyName} value={keyName}>{this.getListaRuoli()[keyName]}</option>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div className="Form-label Register">Altro ruolo</div>
                                        <div className="Form-control Register" style={{width: "100%", padding: "0"}}>
                                            { (altroruoloerror && altroruoloenabled === true) && (
                                                <TextField id="altro_ruolo" placeholder="Altro ruolo" required error helperText="Valore non valido" variant="outlined" onChange={this.handleChangeOtherRole} value={ altro_ruolo }/>
                                            )}
                                            { (altroruoloerror === false && altroruoloenabled === true) &&(
                                                <TextField id="altro_ruolo" placeholder="Altro ruolo" required variant="outlined" onChange={this.handleChangeOtherRole} value={ altro_ruolo }/>
                                            )}
                                            { altroruoloenabled === false && (
                                                <TextField id="altro_ruolo" placeholder="Altro ruolo" required variant="outlined" disabled value={ altro_ruolo }/>
                                            )}
                                        </div>
                                        <div className="Form-control Register">
                                            <FormControlLabel className="Checkbox Register"
                                                control={
                                                <Checkbox
                                                    checked={this.state.privacy}
                                                    onChange={this.handleChangePrivacy}
                                                    name="privacy"
                                                    color="primary"
                                                    style={{marginRight: "10px"}}
                                                />
                                                }
                                                label={<label className="label-desc">Cliccando su "Registrati a ToKalon", accetti le nostre <Link to="/privacy">Condizioni</Link> e la nostra <Link to="/privacy">normativa sulla Privacy</Link>.</label>}
                                                style={{padding: "0 30px", height: "auto"}}
                                            />  
                                        </div> 
                                        <div className="Form-control Register">
                                            <FormControlLabel className="Checkbox Register"
                                                control={
                                                <Checkbox
                                                    checked={this.state.newsletter}
                                                    onChange={this.handleChangeNewsletter}
                                                    name="newsletter"
                                                    color="primary"
                                                    style={{marginRight: "10px", height: "auto"}}
                                                />
                                                }
                                                label={<label className="label-desc">Desidero rimanere informato sulle iniziative di ToKalon</label>}
                                                style={{padding: "0 30px"}}
                                            />    
                                        </div>
                                        { error && (
                                            <div className="Error-message">{ errormessage }</div>
                                        )}
                                        <div className="Form-control Register" style={{width: "100%", padding: "0", marginTop:"20px"}}>
                                            { registerenabled ? (
                                                <Button size="large" type="submit" variant="contained" color="primary" onClick={this.handleRegisterClick}>Completa la registrazione</Button>
                                            ) : (
                                                <Button size="large" type="submit" disabled variant="contained" color="primary" onClick={this.handleRegisterClick}>Completa la registrazione</Button>
                                            )}    
                                        </div>
                                    </Box>
                                    { isloading && (
                                        <CircularProgress className="Progress"/>
                                    )}
                                    { error && (
                                    <div className="Error-message">{ errormessage }</div>
                                    )}
                                    <div className="separator"></div>
                                </header>
                                <AppBar position="static" className="Footer">
                                    <div>© Copyright {new Date().getFullYear()} – Associazione ToKalon - <Link to="/privacy">Privacy Policy</Link> - Realizzato da <a href="https://www.creab.it/" target="_blank" rel="noreferrer">Creabit.</a></div>
                                </AppBar>
                            </div>
                        ) : (
                            <CircularProgress className="Progress"/>
                        )}
                    </div>
               ) : (
                    <Redirect to="/" />
               )}
            </div>
        );

    }
}

export default NewUser;