import React, { Component } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';
import { Redirect } from "react-router-dom";
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Link } from "react-router-dom";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import FormHelperText from '@mui/material/FormHelperText';
import {Helmet} from "react-helmet";
import Subheader from './Subheader';
import HomeButton from './HomeButton';
import SocialButtonsInHeader from './SocialButtonsInHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import Logo from './Logo';
import TokalonButton from './TokalonButton';

class Profile extends Component {

    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            authorization: Cookies.get('authorization') || '',
            wp_user_id: Cookies.get('wp_user_id') || 0,
            user_email: Cookies.get('user_email') || '',
            nome: Cookies.get('nome') || '',
            cognome: Cookies.get('cognome') || '',

            anchorElUserMenu: null,
            codice_scuola: "",
            id_scuola: "",
            nome_scuola: "",
            nome_istituto: "",
            tipologia_scuola: "",
            citta_scuola: "",
            provincia_scuola: "",
            indirizzo_scuola: "",
            cap_scuola: "",
            email : "",
            password : "",
            passwordconfirm: "",
            nuovo_nome: "",
            nuovo_cognome: "",
            cellulare: "",
            grado: "infanzia",
            ruolo: 1,
            altro_ruolo: "",
            newsletter: false,
            fatt_nome: "",
            fatt_cognome: "",
            fatt_cf_piva: "",
            fatt_indirizzo: "",
            fatt_citta: "",
            fatt_provincia: "XX",
            fatt_cap: "",
            error: false,
            errorcode: '',
            errormessage: '',
            isloading: false,
            updateprofileenabled: false,
            updateinvoicingenabled: false,
            altro_ruolo_enabled: false,
            codice_scuola_error: false,
            emailerror: false,
            passworderror: false,
            passwordconfirmerror: false,
            nomeerror: false,
            cognomeerror: false,
            phoneerror: false,
            gradoerror: false,
            ruoloerror: false,
            altroruoloerror: false,
            fatt_nome_error: false,
            fatt_cognome_error: false,
            fatt_cf_piva_error: false,
            fatt_indirizzo_error: false,
            fatt_citta_error: false,
            fatt_provincia_error: false,
            fatt_cap_error: false,
            updateprofilecompleted: false,
            updateinvoicingcompleted: false,
        }
        this.handleChangePassword = this.handleChangePassword.bind(this);
        this.handleChangePasswordConfirm = this.handleChangePasswordConfirm.bind(this);
        this.handleChangeSchoolCode = this.handleChangeSchoolCode.bind(this);
        this.handleChangeFirstname = this.handleChangeFirstname.bind(this);
        this.handleChangeLastname = this.handleChangeLastname.bind(this);
        this.handleChangePhone = this.handleChangePhone.bind(this);
        this.handleChangeGrado = this.handleChangeGrado.bind(this);
        this.handleChangeRole = this.handleChangeRole.bind(this);
        this.handleChangeOtherRole = this.handleChangeOtherRole.bind(this);
        this.handleChangeNewsletter = this.handleChangeNewsletter.bind(this);
        this.handleUpdateProfileClick = this.handleUpdateProfileClick.bind(this);
        this.handleUpdateInvoicingClick = this.handleUpdateInvoicingClick.bind(this);
        this.handleChangeFattNome = this.handleChangeFattNome.bind(this);
        this.handleChangeFattCognome = this.handleChangeFattCognome.bind(this);
        this.handleChangeFattCfPiva = this.handleChangeFattCfPiva.bind(this);
        this.handleChangeFattIndirizzo = this.handleChangeFattIndirizzo.bind(this);
        this.handleChangeFattCitta = this.handleChangeFattCitta.bind(this);
        this.handleChangeFattProvincia = this.handleChangeFattProvincia.bind(this);
        this.handleChangeFattCap = this.handleChangeFattCap.bind(this);

        this.handleLogoutClick = this.handleLogoutClick.bind(this);
        this.handleUserMenuClick = this.handleUserMenuClick.bind(this);
        this.handleCloseUserMenu = this.handleCloseUserMenu.bind(this);

    }

    componentDidMount() {
        this._isMounted = true;
        if (this._isMounted) {
            this.setState({ isloading: true });
        }
        var config = require('./config.json');
        var env_backend = config.env_backend;
        var api_url_backend = config.api_url_backend[env_backend];
        const apiUrl = api_url_backend + 'users/get';
        var authorization = config.api_auth_backend[env_backend];
        var email = this.state.user_email;

        const settings = {
            headers: { Authorization: `Bearer ${authorization}` }
        };

        axios.post(apiUrl, { 
            "email" : email
        }, settings).then(res => {
            //console.log(res);

            if (this._isMounted) {

                this.setState({ codice_scuola: res.data.data.dati_scuola.codice_meccanografico_scuola || ""});
                this.setState({ id_scuola: res.data.data.dati_scuola.id || ""});
                this.setState({ nome_scuola: res.data.data.dati_scuola.nome_scuola || ""});
                this.setState({ nome_istituto: res.data.data.dati_scuola.nome_istituto || ""});
                this.setState({ tipologia_scuola: res.data.data.dati_scuola.tipologia || ""});
                this.setState({ citta_scuola: res.data.data.dati_scuola.citta || ""});
                this.setState({ provincia_scuola: res.data.data.dati_scuola.provincia || "XX"});
                this.setState({ indirizzo_scuola: res.data.data.dati_scuola.indirizzo || ""});
                this.setState({ cap_scuola: res.data.data.dati_scuola.cap || ""});
                this.setState({ nuovo_nome: res.data.data.dati_utente.nome || ""});
                this.setState({ nuovo_cognome: res.data.data.dati_utente.cognome || ""});
                this.setState({ email: res.data.data.dati_utente.email || ""});
                this.setState({ cellulare: res.data.data.dati_utente.cellulare || ""});
                this.setState({ grado: res.data.data.dati_utente.grado || "infanzia"});
                this.setState({ ruolo: res.data.data.dati_utente.ruolo}, function(){
                    if  (res.data.data.dati_utente.ruolo === 3) {
                        this.setState({ altro_ruolo_enabled: true});
                    } 
                });
                this.setState({ altro_ruolo: res.data.data.dati_utente.altro_ruolo || ""});
                this.setState({ newsletter: Boolean(res.data.data.dati_utente.newsletter || false)});
                this.setState({ fatt_nome: res.data.data.dati_utente.fatt_nome || ""});
                this.setState({ fatt_cognome: res.data.data.dati_utente.fatt_cognome || ""});
                this.setState({ fatt_cf_piva: res.data.data.dati_utente.fatt_cf_piva || ""});
                this.setState({ fatt_indirizzo: res.data.data.dati_utente.fatt_indirizzo || ""});
                this.setState({ fatt_citta: res.data.data.dati_utente.fatt_citta || ""});
                this.setState({ fatt_provincia: res.data.data.dati_utente.fatt_provincia || "XX"});
                this.setState({ fatt_cap: res.data.data.dati_utente.fatt_cap || ""});
         
                this.setState({ isloading: false }, function(){
                    let required_field_are_filled = this.requiredFieldsAreFilled();
                    let fields_are_valid = this.fieldsAreValid();
                    //console.log("required field are filled => " + required_field_are_filled);
                    //console.log("fields are valid => " + fields_are_valid);
                    if (required_field_are_filled && fields_are_valid) {
                        this.setState({ updateprofileenabled: true });
                    } else {
                        this.setState({ updateprofileenabled: false });
                    }

                    let required_field_are_filled_fatt = this.requiredFieldsAreFilled_Fatt();
                    let fields_are_valid_fatt = this.fieldsAreValid_Fatt();
                    if (required_field_are_filled_fatt && fields_are_valid_fatt) {
                        this.setState({ updateinvoicingenabled: true });
                    } else {
                        this.setState({ updateinvoicingenabled: false });
                    }

                });
            }
        }).catch (error => {
            //console.log(error);
            this.setState({ isloading: false});
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleLogoutClick(event) {
        event.preventDefault();
        this.setState({ authorization: ''});
        Cookies.remove('authorization');
        Cookies.remove('user_email');
        Cookies.remove('nome');
        Cookies.remove('cognome');
        Cookies.remove('wp_user_id');
        Cookies.remove('newuser');
    }

    handleUserMenuClick(event){
        //console.log(event.currentTarget);
        this.setState({ anchorElUserMenu: event.currentTarget});
    }

    handleCloseUserMenu(){
        this.setState({ anchorElUserMenu: null});
    }

    requiredFieldsAreFilled() {

        var required_fields;

        if (this.state.ruolo === 0){

            required_fields = [
                'nome',
                'cognome',
                'cellulare',
                'codice_scuola'
            ];

        }

        if (this.state.ruolo === 1){

            required_fields = [
                'nome',
                'cognome',
                'cellulare',
                'codice_scuola',
                /* 'fatt_nome',
                'fatt_cognome',
                'fatt_cf_piva',
                'fatt_indirizzo',
                'fatt_citta',
                'fatt_cap' */
            ];

        }
        
        if (this.state.ruolo === 3) {

            required_fields = [
                'altro_ruolo',
                'nome',
                'cognome',
                'cellulare',
                'codice_scuola',
                /* 'fatt_nome',
                'fatt_cognome',
                'fatt_cf_piva',
                'fatt_indirizzo',
                'fatt_citta',
                'fatt_cap' */
            ];

        }

        let filled = 0;
        required_fields.forEach((element) => {
            if (this.state[element].length > 0) {
                filled = filled + 1;
            }
        });

        if (filled === required_fields.length){
            return true;
        } else {
            return false;
        }
    }

    requiredFieldsAreFilled_Fatt() {

        var required_fields;

        if (this.state.ruolo === 0){

            required_fields = [];

        }

        if (this.state.ruolo === 1){

            required_fields = [
                'fatt_nome',
                'fatt_cognome',
                'fatt_cf_piva',
                'fatt_indirizzo',
                'fatt_citta',
                'fatt_cap'
            ];

        }
        
        if (this.state.ruolo === 3) {

            required_fields = [
                'fatt_nome',
                'fatt_cognome',
                'fatt_cf_piva',
                'fatt_indirizzo',
                'fatt_citta',
                'fatt_cap'
            ];

        }

        let filled = 0;
        required_fields.forEach((element) => {
            if (this.state[element].length > 0) {
                filled = filled + 1;
            }
        });

        if (filled === required_fields.length){
            return true;
        } else {
            return false;
        }

    }

    fieldsAreValid() {

        if (this.validateSchoolCode(this.state.codice_scuola) === false) {
            //console.log("school code invalid");
            return false;
        }
        if (this.validatePassword(this.state.password) === false) {
            //console.log("password invalid");
            return false;
        }
        if (this.validatePasswordConfirm(this.state.passwordconfirm) === false) {
            //console.log("password confirm invalid");
            return false;
        }
        if (this.validateFirstname(this.state.nome) === false) {
            //console.log("nome invalid");
            return false;
        }
        if (this.validateLastname(this.state.cognome) === false) {
            //console.log("cognome invalid");
            return false;
        }
        if (this.validatePhone(this.state.cellulare) === false) {
            //console.log("cellulare invalid");
            return false;
        }   
        if (this.validateOtherRole(this.state.altro_ruolo) === false) {
            //console.log("altro_ruolo invalid");
            return false;
        }
        return true;

    }

    fieldsAreValid_Fatt() {

        if (this.validateFattNome(this.state.fatt_nome) === false) {
            //console.log("nome fatturazione invalid");
            return false;
        }
        if (this.validateFattCognome(this.state.fatt_cognome) === false) {
            //console.log("cognome fatturazione invalid");
            return false;
        }
        if (this.validateFattCfPiva(this.state.fatt_cf_piva) === false) {
            //console.log("cf piva fatturazione invalid");
            return false;
        }
        if (this.validateFattIndirizzo(this.state.fatt_indirizzo) === false) {
            //console.log("indirizzo fatturazione invalid");
            return false;
        }
        if (this.validateFattCitta(this.state.fatt_citta) === false) {
            //console.log("citta fatturazione invalid");
            return false;
        }
        if (this.validateFattProvincia(this.state.fatt_provincia) === false) {
            //console.log("provincia fatturazione invalid");
            return false;
        }
        if (this.validateFattCap(this.state.fatt_cap) === false) {
            //console.log("cap fatturazione invalid");
            return false;
        }
        return true;
    }

    getListaGradi() {
        let gradi = {
          infanzia: "infanzia",
          primaria: "primaria",
          secondaria: "secondaria di 1° grado",
          secondaria2: "secondaria di 2° grado",
        };
        return gradi;
    }

    getListaRuoli(){
        let ruoli = {
            1: "Sono un docente",
            3: "Non sono un docente (specificare ruolo)"
          };
          return ruoli;
    }

    getListaProvincie() {
        let provincie = {
            XX : '', 
            AG : 'Agrigento',
            AL : 'Alessandria',
            AN : 'Ancona',
            AO : 'Aosta',
            AQ : 'L\'Aquila',
            AR : 'Arezzo',
            AP : 'Ascoli-Piceno',
            AT : 'Asti',
            AV : 'Avellino',
            BA : 'Bari',
            BT : 'Barletta-Andria-Trani',
            BL : 'Belluno',
            BN : 'Benevento',
            BG : 'Bergamo',
            BI : 'Biella',
            BO : 'Bologna',
            BZ : 'Bolzano',
            BS : 'Brescia',
            BR : 'Brindisi',
            CA : 'Cagliari',
            CL : 'Caltanissetta',
            CB : 'Campobasso',
            CI : 'Carbonia Iglesias',
            CE : 'Caserta',
            CT : 'Catania',
            CZ : 'Catanzaro',
            CH : 'Chieti',
            CO : 'Como',
            CS : 'Cosenza',
            CR : 'Cremona',
            KR : 'Crotone',
            CN : 'Cuneo',
            EN : 'Enna',
            FM : 'Fermo',
            FE : 'Ferrara',
            FI : 'Firenze',
            FG : 'Foggia',
            FC : 'Forli-Cesena',
            FR : 'Frosinone',
            GE : 'Genova',
            GO : 'Gorizia',
            GR : 'Grosseto',
            IM : 'Imperia',
            IS : 'Isernia',
            SP : 'La-Spezia',
            LT : 'Latina',
            LE : 'Lecce',
            LC : 'Lecco',
            LI : 'Livorno',
            LO : 'Lodi',
            LU : 'Lucca',
            MC : 'Macerata',
            MN : 'Mantova',
            MS : 'Massa-Carrara',
            MT : 'Matera',
            VS : 'Medio Campidano',
            ME : 'Messina',
            MI : 'Milano',
            MO : 'Modena',
            MB : 'Monza-Brianza',
            NA : 'Napoli',
            NO : 'Novara',
            NU : 'Nuoro',
            OG : 'Ogliastra',
            OT : 'Olbia Tempio',
            OR : 'Oristano',
            PD : 'Padova',
            PA : 'Palermo',
            PR : 'Parma',
            PV : 'Pavia',
            PG : 'Perugia',
            PU : 'Pesaro-Urbino',
            PE : 'Pescara',
            PC : 'Piacenza',
            PI : 'Pisa',
            PT : 'Pistoia',
            PN : 'Pordenone',
            PZ : 'Potenza',
            PO : 'Prato',
            RG : 'Ragusa',
            RA : 'Ravenna',
            RC : 'Reggio-Calabria',
            RE : 'Reggio-Emilia',
            RI : 'Rieti',
            RN : 'Rimini',
            RM : 'Roma',
            RO : 'Rovigo',
            SA : 'Salerno',
            SS : 'Sassari',
            SU : 'Sud Sardegna',
            SV : 'Savona',
            SI : 'Siena',
            SR : 'Siracusa',
            SO : 'Sondrio',
            TA : 'Taranto',
            TE : 'Teramo',
            TR : 'Terni',
            TO : 'Torino',
            TP : 'Trapani',
            TN : 'Trento',
            TV : 'Treviso',
            TS : 'Trieste',
            UD : 'Udine',
            VA : 'Varese',
            VE : 'Venezia',
            VB : 'Verbania',
            VC : 'Vercelli',
            VR : 'Verona',
            VV : 'Vibo-Valentia',
            VI : 'Vicenza',
            VT : 'Viterbo',
        };
        return provincie;
    }

    validateSchoolCode(value){
        let schoolcode = value;
        const re = /^[A-Z0-9]{10}/;
        //console.log(re.test(schoolcode));
        return re.test(schoolcode);
    }

    validatePassword(value) {
        let password = value;
        const re = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%§£^&°?\/\'\"\\~|<>,.%^*èéòçàùì()_+={}:;@\-\[\]])([a-zA-Z0-9!@#$£%§^&°?\/\'\"\\~|<>,.%^*èéòçàùì()_+={}:;@\-\[\]]{8,})$/;
        return re.test(password) || password.length === 0;
    }

    validatePasswordConfirm(value) {
        let password = this.state.password;
        let passwordconfirm = value;
        return password === passwordconfirm;
    }

    validateFirstname(value) {
        let nome = value;
        const re = /^[a-zàòùèéì ,.'-]+$/i;
        return re.test(nome) || nome.length === 0;
    }

    validateLastname(value) {
        let cognome = value;
        const re = /^[a-zàòùèéì ,.'-]+$/i;
        return re.test(cognome) || cognome.length === 0;
    }

    validatePhone(value) {
        if (value !== null) {
            let cellulare = value;
            const re = /^[0-9\s]{8,20}$/;
            return re.test(cellulare) || cellulare.length === 0;
        } else {
            return true;
        }
    }

    validateOtherRole(value) {
        let nome = value;
        const re = /^[a-zàòùèéì ,.'-]+$/i;
        return re.test(nome) || nome.length === 0;
    }

    validateFattNome(value){
        let nome = value;
        const re = /^[a-zA-Zàòùèéì ,.'-]+$/i;
        return re.test(nome) || nome.length === 0;
    }

    validateFattCognome(value){
        let cognome = value;
        const re = /^[a-zA-Zàòùèéì ,.'-]+$/i;
        return re.test(cognome) || cognome.length === 0;
    }

    validateFattCfPiva(value){
        let cfpiva = value;
        const re = /^[A-Z0-9]{11,16}/;
        //console.log(re.test(cfpiva));
        return re.test(cfpiva) || cfpiva.length === 0;
    }

    validateFattIndirizzo(value){
        let fattindirizzo = value;
        const re = /^[0-9a-zA-Z/òàùèéì'\s,.-]+$/;
        return re.test(fattindirizzo) || fattindirizzo.length === 0;
    }

    validateFattCitta(value){
        let citta = value;
        const re = /^[0-9a-zA-Zàòùèéì'\s,-]+$/;
        return re.test(citta) || citta.length === 0;
    }

    validateFattProvincia(value){
        if (this.state.ruolo === 0) {
            return true;
        } else {
            let provincia = value;
            return provincia !== "XX" || provincia === 0;
        }
    }

    validateFattCap(value){
        let cap = value;
        const re = /^[0-9]{5}/;
        return re.test(cap) || cap.length === 0;
    }

    handleChangeSchoolCode(event) {

        var config = require('./config.json');
        var env_backend = config.env_backend;
        var api_url_backend = config.api_url_backend[env_backend];
        var api_auth_backend = config.api_auth_backend[env_backend];

        this.setState({ codice_scuola: event.target.value.toUpperCase() }, function(){

            this.setState({ id_scuola: null});
            this.setState({ nome_scuola: ''});
            this.setState({ nome_istituto: ''});
            this.setState({ tipologia_scuola: ''});
            this.setState({ citta_scuola: ''});
            this.setState({ provincia_scuola: ''});
            this.setState({ indirizzo_scuola: ''});
            this.setState({ cap_scuola: ''});
            
            if (this.validateSchoolCode(event.target.value.toUpperCase())) {

                this.setState({ codice_scuola_error: false }, function(){
                    const apiUrl = api_url_backend;
                    let authorization = api_auth_backend;
                    let codice_scuola = event.target.value.toUpperCase();
    
                    const settings = {
                        headers: { Authorization: `Bearer ${authorization}` }
                    };
    
                    axios.post(apiUrl+"schools/getbycode", { 
                        "codice": codice_scuola
                    }, settings).then(res => {
    
                        if (res.data.success) {
    
                            let id_scuola = res.data.data.id_scuola;
                            let nome_scuola = res.data.data.nome_scuola; 
                            let nome_istituto = res.data.data.nome_istituto; 
                            let tipologia_scuola = res.data.data.tipologia; 
                            let citta_scuola = res.data.data.citta;
                            let provincia_scuola = res.data.data.provincia;
                            let indirizzo_scuola = res.data.data.indirizzo;
                            let cap_scuola = res.data.data.cap;
                            this.setState({ id_scuola: id_scuola});
                            this.setState({ nome_scuola: nome_scuola});
                            this.setState({ nome_istituto: nome_istituto});
                            this.setState({ tipologia_scuola: tipologia_scuola});
                            this.setState({ citta_scuola: citta_scuola});
                            this.setState({ provincia_scuola: provincia_scuola});
                            this.setState({ indirizzo_scuola: indirizzo_scuola});
                            this.setState({ cap_scuola: cap_scuola});
                           
                        } else {
    
                            if (this._isMounted) {
                                this.setState({ error: true});
                                this.setState({ id_scuola: null});
                                this.setState({ nome_scuola: ''});
                                this.setState({ nome_istituto: ''});
                                this.setState({ tipologia_scuola: ''});
                                this.setState({ citta_scuola: ''});
                                this.setState({ provincia_scuola: ''});
                                this.setState({ indirizzo_scuola: ''});
                                this.setState({ cap_scuola: ''});
                                this.setState({ errorcode: res.data.data.code });
                                this.setState({ errormessage: res.data.data.message });
                               
                            }
    
                        }
    
                    }).catch (error => {
    
                        if (this._isMounted) {
                            this.setState({ error: true});
                            this.setState({ id_scuola: null});
                            this.setState({ nome_scuola: ''});
                            this.setState({ nome_istituto: ''});
                            this.setState({ tipologia_scuola: ''});
                            this.setState({ citta_scuola: ''});
                            this.setState({ provincia_scuola: ''});
                            this.setState({ indirizzo_scuola: ''});
                            this.setState({ cap_scuola: ''});
                            this.setState({ errorcode: error.response.data.data.code });
                            this.setState({ errormessage: error.response.data.data.message });
                            
                        }
                    });          
                });
                

            } else {
                this.setState({ codice_scuola_error: true });
                
            }
            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();
            if (required_field_are_filled && fields_are_valid) {
                this.setState({ updateprofileenabled: true });
            } else {
                this.setState({ updateprofileenabled: false });
            }
        });
    }

    handleChangePassword(event) {
        //console.log(event.target.value);
        this.setState({ password: event.target.value }, function(){
            this.setState({ passwordconfirm: "" }, function(){
                let passwordisvalid = this.validatePassword(event.target.value);
                let required_field_are_filled = this.requiredFieldsAreFilled();
                let fields_are_valid = this.fieldsAreValid();

                if (passwordisvalid) {
                    this.setState({ passworderror: false });
                } else {
                    this.setState({ passworderror: true });
                }
                    
                if (required_field_are_filled && fields_are_valid) {
                    this.setState({ updateprofileenabled: true });
                } else {
                    this.setState({ updateprofileenabled: false });
                }

                //console.log("====");
                //console.log(passwordisvalid);
                //console.log(required_field_are_filled);
                //console.log(fields_are_valid);
                //console.log("====");
            });
        });
    }

    handleChangePasswordConfirm(event) {
        this.setState({ passwordconfirm: event.target.value }, function(){
            let passwordconfirmisvalid = this.validatePasswordConfirm(event.target.value);
            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();

            if (passwordconfirmisvalid) {
                this.setState({ passwordconfirmerror: false });
            } else {
                this.setState({ passwordconfirmerror: true });
            }
            
            if (required_field_are_filled && fields_are_valid) {
                this.setState({ updateprofileenabled: true });
            } else {
                this.setState({ updateprofileenabled: false });
            }

            //console.log("====");
            //console.log(passwordconfirmisvalid);
            //console.log(required_field_are_filled);
            //console.log(fields_are_valid);
            //console.log("====");
            });
    }

    handleChangeFirstname(event) {
        this.setState({ nome: event.target.value }, function(){
            if (this.validateFirstname(event.target.value)) {
                this.setState({ nomeerror: false });
            } else {
                this.setState({ nomeerror: true });
            }
            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();
            if (required_field_are_filled && fields_are_valid) {
                this.setState({ updateprofileenabled: true });
            } else {
                this.setState({ updateprofileenabled: false });
            }
        });
    }

    handleChangeLastname(event) {
        this.setState({ cognome: event.target.value }, function(){
            if (this.validateLastname(event.target.value)) {
                this.setState({ cognomeerror: false });
            } else {
                this.setState({ cognomeerror: true });
            }
            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();
            if (required_field_are_filled && fields_are_valid) {
                this.setState({ updateprofileenabled: true });
            } else {
                this.setState({ updateprofileenabled: false });
            }
        });
    }

    handleChangePhone(event) {
        this.setState({ cellulare: event.target.value }, function(){
            if (this.validatePhone(event.target.value)) {
                this.setState({ phoneerror: false });
            } else {
                this.setState({ phoneerror: true });
            }
            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();
            if (required_field_are_filled && fields_are_valid) {
                this.setState({ updateprofileenabled: true });
            } else {
                this.setState({ updateprofileenabled: false });
            }
        });
    }

    handleChangeGrado(event) {
        this.setState({ grado: event.target.value },function(){
            if (this.requiredFieldsAreFilled()) {
                this.setState({ updateprofileenabled: true });
            } else {
                this.setState({ updateprofileenabled: false });
            }
        });
    }

    handleChangeRole(event) {
        this.setState({ ruolo: parseInt(event.target.value) },function(){
            
            if (event.target.value == 1){
                this.setState({ altro_ruolo_enabled: false });
            }
            if (event.target.value == 3){
                this.setState({ altro_ruolo_enabled: true });
            }
            if (this.requiredFieldsAreFilled()) {
                this.setState({ updateprofileenabled: true });
            } else {
                this.setState({ updateprofileenabled: false });
            }
        });
    }

    handleChangeOtherRole(event) {
        this.setState({ altro_ruolo: event.target.value }, function(){
            if (this.validateOtherRole(event.target.value)) {
                this.setState({ altroruoloerror: false });
            } else {
                this.setState({ altroruoloerror: true });
            }
            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();
            if (required_field_are_filled && fields_are_valid) {
                this.setState({ updateprofileenabled: true });
            } else {
                this.setState({ updateprofileenabled: false });
            }
        });
    }

    handleChangeNewsletter(event) {
        this.setState({ newsletter: event.target.checked }, function(){
            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();
            if (required_field_are_filled && fields_are_valid) {
                this.setState({ updateprofileenabled: true });
            } else {
                this.setState({ updateprofileenabled: false });
            }
        });
    }

    handleChangeFattNome(event){
        this.setState({ fatt_nome: event.target.value }, function(){
            if (this.validateFattNome(event.target.value)) {
                this.setState({ fatt_nome_error: false });
            } else {
                this.setState({ fatt_nome_error: true });
            }
            let required_field_are_filled = this.requiredFieldsAreFilled_Fatt();
            let fields_are_valid = this.fieldsAreValid_Fatt();
            if (required_field_are_filled && fields_are_valid) {
                this.setState({ updateinvoicingenabled: true });
            } else {
                this.setState({ updateinvoicingenabled: false });
            }
        });
    }

    handleChangeFattCognome(event){
        this.setState({ fatt_cognome: event.target.value }, function(){
            if (this.validateFattCognome(event.target.value)) {
                this.setState({ fatt_cognome_error: false });
            } else {
                this.setState({ fatt_cognome_error: true });
            }
            let required_field_are_filled = this.requiredFieldsAreFilled_Fatt();
            let fields_are_valid = this.fieldsAreValid_Fatt();
            if (required_field_are_filled && fields_are_valid) {
                this.setState({ updateinvoicingenabled: true });
            } else {
                this.setState({ updateinvoicingenabled: false });
            }
        });
    }

    handleChangeFattCfPiva(event){
        this.setState({ fatt_cf_piva: event.target.value.toUpperCase() }, function(){
            if (this.validateFattCfPiva(event.target.value.toUpperCase())) {
                this.setState({ fatt_cf_piva_error: false });
            } else {
                this.setState({ fatt_cf_piva_error: true });
            }
            let required_field_are_filled = this.requiredFieldsAreFilled_Fatt();
            let fields_are_valid = this.fieldsAreValid_Fatt();
            if (required_field_are_filled && fields_are_valid) {
                this.setState({ updateinvoicingenabled: true });
            } else {
                this.setState({ updateinvoicingenabled: false });
            }
        });
    }

    handleChangeFattIndirizzo(event){
        this.setState({ fatt_indirizzo: event.target.value }, function(){
            if (this.validateFattIndirizzo(event.target.value)) {
                this.setState({ fatt_indirizzo_error: false });
            } else {
                this.setState({ fatt_indirizzo_error: true });
            }
            let required_field_are_filled = this.requiredFieldsAreFilled_Fatt();
            let fields_are_valid = this.fieldsAreValid_Fatt();
            if (required_field_are_filled && fields_are_valid) {
                this.setState({ updateinvoicingenabled: true });
            } else {
                this.setState({ updateinvoicingenabled: false });
            }
        });
    }

    handleChangeFattCitta(event){
        this.setState({ fatt_citta: event.target.value }, function(){
            if (this.validateFattCitta(event.target.value)) {
                this.setState({ fatt_citta_error: false });
            } else {
                this.setState({ fatt_citta_error: true });
            }
            let required_field_are_filled = this.requiredFieldsAreFilled_Fatt();
            let fields_are_valid = this.fieldsAreValid_Fatt();
            if (required_field_are_filled && fields_are_valid) {
                this.setState({ updateinvoicingenabled: true });
            } else {
                this.setState({ updateinvoicingenabled: false });
            }
        });
    }

    handleChangeFattProvincia(event){
        this.setState({ fatt_provincia: event.target.value },function(){
            if (this.validateFattProvincia(event.target.value)) {
                this.setState({ fatt_provincia_error: false });
            } else {
                this.setState({ fatt_provincia_error: true });
            }
            let required_field_are_filled = this.requiredFieldsAreFilled_Fatt();
            let fields_are_valid = this.fieldsAreValid_Fatt();
            if (required_field_are_filled && fields_are_valid) {
                this.setState({ updateinvoicingenabled: true });
            } else {
                this.setState({ updateinvoicingenabled: false });
            }
        });
    }

    handleChangeFattCap(event){
        this.setState({ fatt_cap: event.target.value }, function(){
            if (this.validateFattCap(event.target.value)) {
                this.setState({ fatt_cap_error: false });
            } else {
                this.setState({ fatt_cap_error: true });
            }
            let required_field_are_filled = this.requiredFieldsAreFilled_Fatt();
            let fields_are_valid = this.fieldsAreValid_Fatt();
            if (required_field_are_filled && fields_are_valid) {
                this.setState({ updateinvoicingenabled: true });
            } else {
                this.setState({ updateinvoicingenabled: false });
            }
        });
    }

    handleUpdateProfileClick(event) {
        event.preventDefault();

        var config = require('./config.json');
        var env_wp = config.env_wp;
        var api_url_wp = config.api_url_wp[env_wp];
        var api_auth_wp = config.api_auth_wp[env_wp];
        var env_backend = config.env_backend;
        var api_url_backend = config.api_url_backend[env_backend];
        var api_auth_backend = config.api_auth_backend[env_backend];

        this.setState({ error: false });
        this.setState({ isloading: true });  
        this.setState({ updateprofilecompleted: false });  
        this.setState({ updateinvoicingcompleted: false });  
        this.setState({ isloading: true });  
        
        const apiUrl = api_url_backend;
        const apiUrl2 = api_url_wp + 'profile';

        let authorization = api_auth_backend;
        let email = this.state.email;
        let password = this.state.password;
        let nome = this.state.nome;
        let cognome = this.state.cognome;
        let username = this.state.nome+" "+this.state.cognome;
        let cellulare = this.state.cellulare;
        let grado = this.state.grado;
        let ruolo = this.state.ruolo;
        let altro_ruolo = this.state.altro_ruolo;
        let newsletter = this.state.newsletter;
        let scuola = this.state.id_scuola;
        let codice_scuola = this.state.codice_scuola;
        let nome_scuola = this.state.nome_scuola;
        let citta_scuola = this.state.citta_scuola;
       
        const settings = {
            headers: { Authorization: `Bearer ${authorization}` }
        };

        let user_email = this.state.user_email;
                
        axios.patch(apiUrl+"users/update", { 
            "scuola": scuola,
            "user_email": user_email,
            "ruolo": ruolo,
            "altro_ruolo": altro_ruolo,
            "nome": nome,
            "cognome": cognome,
            "name": username,
            "cellulare": cellulare,
            "grado": grado,
            "password": password,
            "newsletter": newsletter,

        }, settings).then(res2 => {

            this.setState({ isloading: false }, function(){
                //console.log(res2.data.data); 
            });

            if (res2.data.success) {

                //Aggiornare il profilo utente in WordPress
                axios.post(apiUrl2, {
                    "authorization" : api_auth_wp,
                    "username" : user_email,
                    "codice_meccanografico_scuola": codice_scuola,
                    "scuola": nome_scuola + " - " + citta_scuola,
                    "nome" : nome,
                    "cognome" : cognome,
                    "telefono_fatturazione": cellulare,
                    "grado": grado,
                    "password": password
                }).then(res2 => {
                    //console.log(res2);
                }).catch (error => {
                    //console.log(error);
                });   

                this.setState({ password: ''});
                this.setState({ passwordconfirm: ''});
                this.setState({ user_email: email});
                
                Cookies.set('user_email', email);
                Cookies.set('nome', nome);
                Cookies.set('cognome', cognome);
                                
                this.setState({ updateprofileenabled: false});
                this.setState({ isloading: false });
                this.setState({ updateprofilecompleted: true });

                let required_field_are_filled = this.requiredFieldsAreFilled();
                let fields_are_valid = this.fieldsAreValid();
                //console.log("required field are filled => " + required_field_are_filled);
                //console.log("fields are valid => " + fields_are_valid);
                if (required_field_are_filled && fields_are_valid) {
                    this.setState({ updateprofileenabled: true });
                } else {
                    this.setState({ updateprofileenabled: false });
                }

                let required_field_are_filled_fatt = this.requiredFieldsAreFilled_Fatt();
                let fields_are_valid_fatt = this.fieldsAreValid_Fatt();
                if (required_field_are_filled_fatt && fields_are_valid_fatt) {
                    this.setState({ updateinvoicingenabled: true });
                } else {
                    this.setState({ updateinvoicingenabled: false });
                }

            } else {
            
                if (this._isMounted) {
                    this.setState({ error: true});
                   
                    if (res2.data.data.code === "email_error"){
                        this.setState({ emailerror: true }); 
                    }
                    this.setState({ errorcode: res2.data.data.code });
                    this.setState({ errormessage: res2.data.data.message });
                    this.setState({ isloading: false });
                }
            }
        
        }).catch (error => {

            if (this._isMounted) {
                this.setState({ error: true});
           
                if (error.response.data.data.code === "email_error"){
                    this.setState({ emailerror: true }); 
                }
                this.setState({ errorcode: error.response.data.data.code });
                this.setState({ errormessage: error.response.data.data.message });
                this.setState({ isloading: false });
            }
        });
        
    }

    handleUpdateInvoicingClick(event){

        event.preventDefault();
        var config = require('./config.json');
        var env_wp = config.env_wp;
        var api_url_wp = config.api_url_wp[env_wp];
        var api_auth_wp = config.api_auth_wp[env_wp];
        var env_backend = config.env_backend;
        var api_url_backend = config.api_url_backend[env_backend];
        var api_auth_backend = config.api_auth_backend[env_backend];

        this.setState({ error_invoicing: false });
        this.setState({ isloading: true });  
        this.setState({ updateprofilecompleted: false });  
        this.setState({ updateinvoicingcompleted: false });  

        const apiUrl = api_url_backend;
        const apiUrl2 = api_url_wp + 'profile';

        let authorization = api_auth_backend;
        let fatt_nome = this.state.fatt_nome;
        let fatt_cognome = this.state.fatt_cognome;
        let fatt_cf_piva = this.state.fatt_cf_piva;
        let fatt_indirizzo = this.state.fatt_indirizzo;
        let fatt_citta = this.state.fatt_citta;
        let fatt_provincia = this.state.fatt_provincia;
        let fatt_paese = "IT";
        let fatt_cap = this.state.fatt_cap;

        const settings = {
            headers: { Authorization: `Bearer ${authorization}` }
        };

        let user_email = this.state.user_email;

        axios.patch(apiUrl+"users/updateinvoicingdata", { 
            "user_email": user_email,
            "fatt_nome" : fatt_nome,
            "fatt_cognome" : fatt_cognome,
            "fatt_cf_piva" : fatt_cf_piva,
            "fatt_indirizzo" : fatt_indirizzo,
            "fatt_citta" : fatt_citta,
            "fatt_provincia": fatt_provincia,
            "fatt_cap" : fatt_cap
        }, settings).then(res2 => {

            this.setState({ isloading: false }, function(){
                //console.log(res2.data.data); 
            });

            if (res2.data.success) {
                if (fatt_cf_piva.length == 16){
                    var codice_fiscale = fatt_cf_piva;
                    var partita_iva = "";
                } else {
                    var codice_fiscale = fatt_cf_piva;
                    var partita_iva = fatt_cf_piva;
                }

                //Aggiornare il profilo utente in WordPress
                axios.post(apiUrl2, {
                    "authorization" : api_auth_wp,
                    "username" : user_email,
                    "nome_fatturazione": fatt_nome,
                    "cognome_fatturazione": fatt_cognome,
                    "societa_fatturazione": codice_fiscale,
                    "partita_iva" : partita_iva,
                    "indirizzo_fatturazione1": fatt_indirizzo,
                    "citta_fatturazione": fatt_citta,
                    "paese_fatturazione": fatt_paese,
                    "provincia_fatturazione": fatt_provincia,
                    "cap_fatturazione": fatt_cap
                }).then(res2 => {
                    console.log(res2);
                }).catch (error => {
                    console.log(error);
                });   

                this.setState({ updateinvoicingenabled: false});
                this.setState({ isloading: false });
                this.setState({ updateinvoicingcompleted: true });

                let required_field_are_filled = this.requiredFieldsAreFilled();
                let fields_are_valid = this.fieldsAreValid();
                //console.log("required field are filled => " + required_field_are_filled);
                //console.log("fields are valid => " + fields_are_valid);
                if (required_field_are_filled && fields_are_valid) {
                    this.setState({ updateprofileenabled: true });
                } else {
                    this.setState({ updateprofileenabled: false });
                }

                let required_field_are_filled_fatt = this.requiredFieldsAreFilled_Fatt();
                let fields_are_valid_fatt = this.fieldsAreValid_Fatt();
                if (required_field_are_filled_fatt && fields_are_valid_fatt) {
                    this.setState({ updateinvoicingenabled: true });
                } else {
                    this.setState({ updateinvoicingenabled: false });
                }

            } else {

                if (this._isMounted) {
                    this.setState({ error_invoicing: true});
                    this.setState({ errorcode: res2.data.data.code });
                    this.setState({ errormessage: res2.data.data.message });
                    this.setState({ isloading: false });
                }

            }
        }).catch (error => {
            
            if (this._isMounted) {
                this.setState({ error_invoicing: true});
           
                this.setState({ errorcode: error.response.data.data.code });
                this.setState({ errormessage: error.response.data.data.message });
                this.setState({ isloading: false });
            }

        });   
    }

    render() {

        const autenticato = this.state.authorization !== '';
        const error = this.state.error;
        const errormessage = this.state.errormessage;
        const isloading = this.state.isloading;

        const anchorElUserMenu = this.state.anchorElUserMenu;
        const open = Boolean(anchorElUserMenu);

        const codice_scuola = this.state.codice_scuola;
        const nome_scuola = this.state.nome_scuola;
        const nome_istituto = this.state.nome_istituto;
        const tipologia_scuola = this.state.tipologia_scuola;
        const citta_scuola = this.state.citta_scuola;
        const provincia_scuola = this.state.provincia_scuola;
        const indirizzo_scuola = this.state.indirizzo_scuola;
        const cap_scuola = this.state.cap_scuola;
        const email = this.state.email;
        const password = this.state.password;
        const passwordconfirm = this.state.passwordconfirm;
        const nome = this.state.nome;
        const cognome = this.state.cognome;
        const cellulare = this.state.cellulare;
        const ruolo = this.state.ruolo;
        const altro_ruolo = this.state.altro_ruolo;
        const fatt_nome = this.state.fatt_nome;
        const fatt_cognome = this.state.fatt_cognome;
        const fatt_cf_piva = this.state.fatt_cf_piva;
        const fatt_indirizzo = this.state.fatt_indirizzo;
        const fatt_citta = this.state.fatt_citta;
        const fatt_provincia = this.state.fatt_provincia;
        const fatt_cap = this.state.fatt_cap;
        const codice_scuola_error = this.state.codice_scuola_error;
        const passworderror = this.state.passworderror;
        const passwordconfirmerror = this.state.passwordconfirmerror;
        const nomeerror = this.state.nomeerror;
        const cognomeerror = this.state.cognomeerror;
        const phoneerror = this.state.phoneerror;
        const altroruoloerror = this.state.altroruoloerror;
        const fatt_nome_error = this.state.fatt_nome_error;
        const fatt_cognome_error = this.state.fatt_cognome_error;
        const fatt_cf_piva_error = this.state.fatt_cf_piva_error;
        const fatt_indirizzo_error = this.state.fatt_indirizzo_error;
        const fatt_citta_error = this.state.fatt_citta_error;
        const fatt_provincia_error = this.state.fatt_provincia_error;
        const fatt_cap_error = this.state.fatt_cap_error;
        const updateprofileenabled = this.state.updateprofileenabled;
        const updateinvoicingenabled = this.state.updateinvoicingenabled;
        const altroruoloenabled = this.state.altro_ruolo_enabled;
        const updateprofilecompleted = this.state.updateprofilecompleted;
        const updateinvoicingcompleted = this.state.updateinvoicingcompleted;

        return(

            <div>
               <Helmet htmlAttributes={{lang: 'it-IT'}}>
                    <title>Concorsi ToKalon - Modifica il tuo profilo</title>
                    <meta name="description" content="Aggiorna i tuoi dati personali, di spedizione e di fatturazione."  data-react-helmet="true"/>
               </Helmet> 
               { autenticato ? ( 
                    <div className="App">
                        {( isloading === false) ? (
                        <div>    
                            <header className="App-header">
                            <AppBar position="static" className="WelcomeBar">
                                    <Toolbar>
                                        <table className="WelcomeMenu">
                                            <tbody>
                                                <tr>
                                                    <td className="LogoMenuColumn">
                                                        <Logo />
                                                    </td>
                                                    <td className="HomeMenuColumn">
                                                        <HomeButton />
                                                        |
                                                        <TokalonButton />
                                                    </td>
                                                    <td className="AccountMenuButton">
                                                        { (nome === "" || cognome === "") ? (
                                                            <>
                                                                {/* <SocialButtonsInHeader /> */}
                                                                <Link to="/profile">
                                                                    <Button
                                                                        className="AccountName"
                                                                        size="large"
                                                                        id="user-menu-button"
                                                                        aria-label="account of current user"
                                                                        aria-controls="user-menu"
                                                                        //aria-haspopup="true"
                                                                        //aria-expanded={Boolean(anchorElUserMenu) ? 'true' : undefined}
                                                                        //onClick={this.handleUserMenuClick}
                                                                        color="inherit"
                                                                    >
                                                                        {/* <FontAwesomeIcon icon={faUser} /> */}
                                                                        <span className="WelcomeUser">{/* Bentornato */}</span>
                                                                    </Button>
                                                                </Link>
                                                                |
                                                                <Button
                                                                    className=""
                                                                    size="large"
                                                                    color="inherit"
                                                                    id="logout-button"
                                                                    onClick={this.handleLogoutClick}
                                                                >
                                                                    Esci
                                                                </Button>
                                                            </>
                                                        ): (
                                                            <>
                                                                {/* <SocialButtonsInHeader /> */}
                                                                <Link to="/profile">
                                                                    <Button
                                                                        className="AccountName"
                                                                        size="large"
                                                                        id="user-menu-button"
                                                                        aria-label="account of current user"
                                                                        aria-controls="user-menu"
                                                                        //aria-haspopup="true"
                                                                        //aria-expanded={Boolean(anchorElUserMenu) ? 'true' : undefined}
                                                                        //onClick={this.handleUserMenuClick}
                                                                        color="inherit" 
                                                                    >
                                                                        {/* <FontAwesomeIcon icon={faUser} /> */}
                                                                        <span className="WelcomeUser">{/* Bentornato */}</span> { nome + " " + cognome }
                                                                    </Button>
                                                                </Link>
                                                                |
                                                                <Button
                                                                    className=""
                                                                    size="large"
                                                                    color="inherit"
                                                                    id="logout-button"
                                                                    onClick={this.handleLogoutClick}
                                                                >
                                                                    Esci
                                                                </Button>
                                                            </>
                                                        )}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <Menu
                                            id="user-menu"
                                            anchorEl={anchorElUserMenu}
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            keepMounted
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            open={open}
                                            onClose={this.handleCloseUserMenu}
                                            MenuListProps={{
                                                'aria-labelledby': 'user-menu-button',
                                            }}
                                        >
                                            <MenuItem><Link to="/profile">Profilo</Link></MenuItem>
                                            <MenuItem onClick={this.handleLogoutClick}>Esci</MenuItem>
                                        </Menu>
                                    </Toolbar>
                                </AppBar>
                                {/* <Subheader></Subheader> */}
                                {/* <div className="separator"></div> */}
                                <div className="Page-title Orange">Profilo Personale <span style={{color: "red"}}>*</span></div>
                                { isloading && (
                                    <CircularProgress className="Progress"/>
                                )}
                                { ruolo !== 0 && (
                                    <div className="Form-title-label Register"><strong>Sei associato alla scuola:</strong></div>
                                )}
                                { (ruolo !== 0) && (
                                    <div className="Form-control Register SchoolData">
                                        { codice_scuola_error ? (
                                            <TextField id="codice_scuola" inputProps={{ maxLength: 11 }} required error helperText="Valore non valido" variant="outlined" onChange={this.handleChangeSchoolCode} value={ codice_scuola }/>
                                        ) : (
                                            <TextField id="codice_scuola" inputProps={{ maxLength: 11 }} required variant="outlined" onChange={this.handleChangeSchoolCode} value={ codice_scuola }/> 
                                        )}
                                            <div>
                                                <table className="School-Data"><tbody>
                                                    <tr>
                                                        <td>Nome istituto:</td><td>{ nome_istituto }</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Nome scuola:</td><td>{ nome_scuola }</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Indirizzo:</td><td>{ indirizzo_scuola }</td>
                                                    </tr>
                                                    <tr>
                                                        <td></td><td>{ cap_scuola } { citta_scuola } { provincia_scuola }</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Tipologia:</td><td>{ tipologia_scuola }</td>
                                                    </tr>
                                                </tbody></table>
                                            </div>
                                    </div>
                                )}
                                <Box className="Form-control Register">
                                    <div className="Form-title-label Register"><strong>Modifica i tuoi dati personali</strong></div>
                                    <br/>
                                    <div className="Form-label Register">Nome</div>
                                    <div className="Form-control Register" style={{width: "100%", padding: "0"}}>
                                        { nomeerror ? (
                                            <TextField id="nome" required placeholder="Nome" error helperText="Valore non valido" variant="outlined" onChange={this.handleChangeFirstname} value={ nome }/>
                                        ) : (
                                            <TextField id="nome" required placeholder="Nome" variant="outlined" onChange={this.handleChangeFirstname} value={ nome }/>
                                        )}
                                    </div>
                                    <div className="Form-label Register">Cognome</div>
                                    <div className="Form-control Register" style={{width: "100%", padding: "0"}}>
                                        { cognomeerror ? (
                                            <TextField id="cognome" placeholder="Cognome" required error helperText="Valore non valido" variant="outlined" onChange={this.handleChangeLastname} value={ cognome }/>
                                        ) : (
                                            <TextField id="cognome" placeholder="Cognome" required variant="outlined" onChange={this.handleChangeLastname} value={ cognome }/>
                                        )}
                                    </div>
                                    <div className="Form-label Register">Cellulare</div>
                                    <div className="Form-control Register" style={{width: "100%", padding: "0"}}>
                                        { phoneerror ? (
                                            <TextField id="cellulare" placeholder="Cellulare" required variant="outlined" error helperText="Valore non valido" onChange={this.handleChangePhone} value={ cellulare }/>
                                        ) : (
                                            <TextField id="cellulare" placeholder="Cellulare" required variant="outlined" onChange={this.handleChangePhone} value={ cellulare }/>
                                        )}
                                    </div>
                                    <div className={ruolo === 0 ? "Form-label Register Hidden" : "Form-label Register"}>Grado:</div>
                                    <div className="Form-control Register" style={{width: "100%", padding: "0"}}>
                                        <FormControl variant="outlined" className={ruolo === 0 ? "Form-control Hidden" : "Form-control"}>    
                                            <Select
                                                native
                                                value={this.state.grado}
                                                onChange={this.handleChangeGrado}
                                            >
                                                { Object.keys(this.getListaGradi()).map((keyName, keyIndex) => (
                                                    <option key={keyName} value={keyName}>{this.getListaGradi()[keyName]}</option>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className={ruolo === 0 ? "Form-label Register Hidden" : "Form-label Register"}>Ruolo:</div>
                                    <div className={ruolo === 0 ? "Form-control Register Hidden" : "Form-control Register"} style={{width: "100%", padding: "0"}}>
                                        <FormControl variant="outlined" className="Form-control">    
                                            <Select
                                                native
                                                value={this.state.ruolo}
                                                onChange={this.handleChangeRole}
                                            >
                                                { Object.keys(this.getListaRuoli()).map((keyName, keyIndex) => (
                                                    <option key={keyName} value={keyName}>{this.getListaRuoli()[keyName]}</option>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className={ruolo === 0 ? "Form-label Register Hidden" : "Form-label Register"}>Altro ruolo:</div>
                                    <div className={ ruolo === 0 ? "Form-control Register Hidden" : "Form-control Register"} style={{width: "100%", padding: "0"}}>
                                        { (altroruoloerror && altroruoloenabled === true) && (
                                            <TextField id="altro_ruolo" placeholder="Altro ruolo" required error helperText="Valore non valido" variant="outlined" onChange={this.handleChangeOtherRole} value={ altro_ruolo }/>
                                        )}
                                        { (altroruoloerror === false && altroruoloenabled === true) &&(
                                            <TextField id="altro_ruolo" placeholder="Altro ruolo" required variant="outlined" onChange={this.handleChangeOtherRole} value={ altro_ruolo }/>
                                        )}
                                        { altroruoloenabled === false && (
                                            <TextField id="altro_ruolo" placeholder="Altro ruolo" required variant="outlined" disabled value={ altro_ruolo }/>
                                        )}
                                    </div>
                                    <div className="Form-label Register">Email</div>
                                    <div className="Form-control Register" style={{width: "100%", padding: "0"}}>
                                        <TextField id="email" placeholder="Email" type="email" disabled variant="outlined" value={ email }/> 
                                    </div>
                                    <div className="Form-label Register">Password</div>
                                    <div className="Form-control Register" style={{width: "100%", padding: "0"}}>
                                        { passworderror ? (
                                            <TextField id="password" placeholder="Password" type="password" error helperText={<label>La password deve essere lunga<br />almeno 8 caratteri.<br />Inserisci almeno:<br />1 carattere maiuscolo,<br />1 carattere minuscolo,<br />1 numero<br />1 carattere speciale.</label>} variant="outlined" onChange={this.handleChangePassword} value={ password }/>
                                        ) : (
                                            <TextField id="password" placeholder="Password" type="password" variant="outlined" onChange={this.handleChangePassword} value={ password }/>
                                        )}
                                    </div>
                                    <div className="Form-label Register">Conferma password</div>
                                    <div className="Form-control Register" style={{width: "100%", padding: "0"}}>
                                        { passwordconfirmerror ? (
                                            <TextField id="passwordconfirm" placeholder="Conferma Password" type="password" error helperText="Valore non valido" variant="outlined" onChange={this.handleChangePasswordConfirm} value={ passwordconfirm }/>
                                        ) : (
                                            <TextField id="passwordconfirm" placeholder="Conferma Password" type="password" variant="outlined" onChange={this.handleChangePasswordConfirm} value={ passwordconfirm }/>
                                        )}
                                    </div>
                                    <div className={ruolo === 0 ? "Form-control Register Hidden" : "Form-control Register"} style={{width: "100%", padding: "10px 0"}}>
                                        <FormControlLabel className="Checkbox Register"
                                            control={
                                            <Checkbox
                                                checked={this.state.newsletter}
                                                onChange={this.handleChangeNewsletter}
                                                name="newsletter"
                                                color="primary"
                                            />
                                            }
                                            label={<label>Voglio ricevere la newsletter e rimanere informato su...</label>}
                                        />    
                                    </div>
                                    { isloading && (
                                        <CircularProgress className="Progress"/>
                                    )}
                                    { error && (
                                        <div className="Error-message">{ errormessage }</div>
                                    )}
                                    { updateprofilecompleted && (
                                        <div className="Success-message" style={{textAlign: "center"}}>Dati del profilo aggiornati!</div>
                                    )}
                                    <div className="Form-control Register" style={{width: "100%", padding: "10px 0"}}>
                                            { updateprofileenabled ? (
                                                <Button size="large" type="submit" variant="contained" color="primary" onClick={this.handleUpdateProfileClick}>Aggiorna Dati Personali</Button>
                                            ) : (
                                                <Button size="large" type="submit" disabled variant="contained" color="primary" onClick={this.handleUpdateProfileClick}>Aggiorna Dati Personali</Button>
                                            )}    
                                    </div>
                                </Box>
                                <div className="separator"></div>
                                <div className={ruolo === 0 ? "Page-title Orange Hidden" : "Page-title Orange"}>Dati di fatturazione personali</div>
                                <Grid container className={ruolo === 0 ? "Form-control Register Half Hidden" : "Form-control Register Half"} style={{width: "90%", padding: "0"}}>
                                    <Grid item xs={6}>
                                        <div className="Form-label Register">Nome</div>
                                        { fatt_nome_error ? (
                                            <TextField id="fatt_nome" style={{ width : "95%", paddingRight : "19px" }} inputProps={{maxLength : 255}} required placeholder="Nome" error helperText="Valore non valido" variant="outlined" onChange={this.handleChangeFattNome} value={ fatt_nome }/>
                                        ) : (
                                            <TextField id="fatt_nome" style={{ width : "95%", paddingRight : "19px" }} inputProps={{maxLength : 255}} required placeholder="Nome" variant="outlined" onChange={this.handleChangeFattNome} value={ fatt_nome }/>
                                        )}
                                    </Grid>
                                    <Grid item xs={6} style={{ textAlign: "right"}}>
                                        <div className="Form-label Register" style={{paddingLeft: "20px"}}>Cognome</div>
                                        { fatt_cognome_error ? (
                                            <TextField id="fatt_cognome" style={{ width : "95%" }} inputProps={{maxLength : 255}} required placeholder="Cognome" error helperText="Valore non valido" variant="outlined" onChange={this.handleChangeFattCognome} value={ fatt_cognome }/>
                                        ) : (
                                            <TextField id="fatt_cognome" style={{ width : "95%" }} inputProps={{maxLength : 255}} required placeholder="Cognome" variant="outlined" onChange={this.handleChangeFattCognome} value={ fatt_cognome }/>
                                        )}
                                    </Grid>
                                </Grid>
                                <div className={ruolo === 0 ? "Form-label Register Hidden" : "Form-label Register"}>Codice Fiscale / Partita Iva</div>
                                <div className={ruolo === 0 ? "Form-control Register Hidden" : "Form-control Register"} style={{width: "90%", padding: "0"}}>
                                        { fatt_cf_piva_error ? (
                                            <TextField id="sped_cf_piva" inputProps={{maxLength : 16}} required placeholder="Codice Fiscale / Partita Iva" error helperText="Valore non valido" variant="outlined" onChange={this.handleChangeFattCfPiva} value={ fatt_cf_piva }/>
                                        ) : (
                                            <TextField id="sped_cf_piva" inputProps={{maxLength : 16}} required placeholder="Codice Fiscale / Partita Iva" variant="outlined" onChange={this.handleChangeFattCfPiva} value={ fatt_cf_piva }/>
                                        )}
                                </div>
                                <div className={ruolo === 0 ? "Form-label Register Hidden" : "Form-label Register"}>Indirizzo</div>
                                <div className={ruolo === 0 ? "Form-control Register Hidden" : "Form-control Register"} style={{width: "90%", padding: "0"}}>
                                        { fatt_indirizzo_error ? (
                                            <TextField id="fatt_indirizzo" inputProps={{maxLength : 255}} required placeholder="Indirizzo" error helperText="Valore non valido" variant="outlined" onChange={this.handleChangeFattIndirizzo} value={ fatt_indirizzo }/>
                                        ) : (
                                            <TextField id="fatt_indirizzo" inputProps={{maxLength : 255}} required placeholder="Indirizzo" variant="outlined" onChange={this.handleChangeFattIndirizzo} value={ fatt_indirizzo }/>
                                        )}
                                </div>
                                <div className={ruolo === 0 ? "Form-label Register Hidden" : "Form-label Register"}>Città</div>
                                <div className={ruolo === 0 ? "Form-control Register Hidden" : "Form-control Register"} style={{width: "92%", padding: "0"}}>
                                        { fatt_citta_error ? (
                                            <TextField id="fatt_citta" inputProps={{maxLength : 255}} required placeholder="Città" error helperText="Valore non valido" variant="outlined" onChange={this.handleChangeFattCitta} value={ fatt_citta }/>
                                        ) : (
                                            <TextField id="fatt_citta" inputProps={{maxLength : 255}} required placeholder="Città" variant="outlined" onChange={this.handleChangeFattCitta} value={ fatt_citta }/>
                                        )}
                                </div>
                                <div className={ruolo === 0 ? "Form-label Register Hidden" : "Form-label Register"}>Provincia</div>
                                <div className={ruolo === 0 ? "Form-control Register Hidden" : "Form-control Register"} style={{width: "90%", padding: "0"}}>
                                    { fatt_provincia_error ? ( 
                                        <FormControl variant="outlined" className="Form-control" error>    
                                            <Select
                                                native
                                                value={fatt_provincia}
                                                onChange={this.handleChangeFattProvincia}
                                            >
                                                { Object.keys(this.getListaProvincie()).map((keyName, keyIndex) => (
                                                    <option key={keyName} value={keyName}>{this.getListaProvincie()[keyName]}</option>
                                                ))}
                                            </Select>
                                            <FormHelperText>Valore non valido</FormHelperText>
                                        </FormControl>
                                    ) : (
                                        <FormControl variant="outlined" className="Form-control">    
                                            <Select
                                                native
                                                value={fatt_provincia}
                                                onChange={this.handleChangeFattProvincia}
                                            >
                                                { Object.keys(this.getListaProvincie()).map((keyName, keyIndex) => (
                                                    <option key={keyName} value={keyName}>{this.getListaProvincie()[keyName]}</option>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    )}     
                                </div>
                                <div className={ruolo === 0 ? "Form-label Register Hidden" : "Form-label Register"}>Cap</div>
                                <div className={ruolo === 0 ? "Form-control Register Hidden" : "Form-control Register"} style={{width: "90%", padding: "0"}}>
                                    { fatt_cap_error ? (
                                        <TextField id="fatt_cap" inputProps={{maxLength : 5}} required placeholder="Cap" error helperText="Valore non valido" variant="outlined" onChange={this.handleChangeFattCap} value={ fatt_cap }/>
                                    ) : (
                                        <TextField id="fatt_cap" inputProps={{maxLength : 5}} required placeholder="Cap" variant="outlined" onChange={this.handleChangeFattCap} value={ fatt_cap }/>
                                    )}
                                </div>
                                { isloading && (
                                    <CircularProgress className="Progress"/>
                                )}
                                { error && (
                                    <div className="Error-message">{ errormessage }</div>
                                )}
                                { updateinvoicingcompleted && (
                                    <div className="Success-message">Dati di fatturazione aggiornati!</div>
                                )}
                                <div className={ruolo === 0 ? "Form-control Register Hidden" : "Form-control Register"}>
                                        { updateinvoicingenabled ? (
                                            <Button size="large" type="submit" variant="contained" color="primary" onClick={this.handleUpdateInvoicingClick}>Aggiorna Dati di Fatturazione</Button>
                                        ) : (
                                            <Button size="large" type="submit" disabled variant="contained" color="primary" onClick={this.handleUpdateInvoicingClick}>Aggiorna Dati di Fatturazione</Button>
                                        )}    
                                </div>
                                <div className="Form-title-label Register">
                                    <span style={{color: "red"}}>N.B.: Se vuoi cambiare mail registrati con un nuovo profilo, scrivi a segreteria@associazionetokalon.com indicandoci la mail vecchia e la mail nuova e noi provvederemo a unificare i profili, in modo che tu possa conservare lo storico delle iscrizioni passate.</span>
                                </div>
                                <div className="separator"></div>
                            </header>
                            <AppBar position="static" className="Footer">
                                <div>© Copyright {new Date().getFullYear()} – Associazione ToKalon - <Link to="/privacy">Privacy Policy</Link> - Realizzato da <a href="https://www.creab.it/" target="_blank" rel="noreferrer">Creabit.</a></div>
                            </AppBar>
                        </div>
                        ): (
                            <CircularProgress className="Progress"/>
                        )}
                    </div>
                )  : (
                    <Redirect to="/login" />
                )}
            </div>
        );
    }
}

export default Profile;