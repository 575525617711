import React, { Component, Fragment } from 'react';
import { Redirect } from "react-router-dom";
import Cookies from 'js-cookie';
import axios from 'axios';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Subheader from './Subheader';
import SocialButtonsInHeader from './SocialButtonsInHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import HomeButton from './HomeButton';
import {Helmet} from "react-helmet";
import Logo from './Logo';
import TokalonButton from './TokalonButton';

class ConfirmParticipation extends Component {

    _isMounted = false;

    constructor(props) {

        super(props);
        this.state = {
            authorization: Cookies.get('authorization') || '',
            user_email: Cookies.get('user_email') || '',
            nome: Cookies.get('nome') || '',
            cognome: Cookies.get('cognome') || '',
            id_squadra: parseInt(Cookies.get('id_squadra')) || '',
            anchorElUserMenu: null,

            giocatori: [],

            id_progetto: "",
            nome_progetto: "",
            nome_scuola: "",
            cod_scuola: "",
            classe: "",
            sezione: "",
            docente_referente1: "",
            docente_referente2: "",

            ambito: 0,
            competizione: "",
            nome_squadra: "",
            risultato: 0,
            risultato_note: "",
            submitted: false,
            final_admitted: false,
            finale: 0,

            nome_giocatore_error: [false,false,false,false],
            cognome_giocatore_error: [false,false,false,false],

            nomedocente: '',
            emaildocente: '',
            cellularedocente: '',

            privacy: false,

            nomedocenteerror: false,
            emaildocenteerror: false,
            cellularedocenteerror: false,
            
            confirmparticipationenabled: false,
            confirmparticipationcompleted: false,

            isloading: false
        }
        this.handleLogoutClick = this.handleLogoutClick.bind(this);
        this.handleUserMenuClick = this.handleUserMenuClick.bind(this);
        this.handleCloseUserMenu = this.handleCloseUserMenu.bind(this);
        this.handleChangeTeacherName = this.handleChangeTeacherName.bind(this);
        this.handleChangeTeacherEmail = this.handleChangeTeacherEmail.bind(this);
        this.handleChangeTeacherPhone = this.handleChangeTeacherPhone.bind(this);
        this.handleChangePlayerFirstname = this.handleChangePlayerFirstname.bind(this);
        this.handleChangePlayerLastname = this.handleChangePlayerLastname.bind(this);
        this.handleChangePrivacy = this.handleChangePrivacy.bind(this);
        this.handleConfirmParticipationClick = this.handleConfirmParticipationClick.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        if (this._isMounted) {
            this.setState({ isloading: true });
        }

        var config = require('./config.json');

        var env_backend = config.env_backend;
        var api_url_backend = config.api_url_backend[env_backend];
        var authorization = config.api_auth_backend[env_backend];

        const settings = {
            headers: { Authorization: `Bearer ${authorization}` }
        };

        const apiUrl = api_url_backend + 'teams/getconfirmparticipation';
        const id_squadra = this.state.id_squadra;
        
        var params = { 
            "id_squadra": id_squadra
        };

        axios.post(apiUrl, params, settings).then(res => {
            if (this._isMounted) {
                this.setState({ id_progetto: res.data.data.dati_squadra.ordine_classe.id_progetto || 0}); 
                this.setState({ nome_progetto: res.data.data.dati_squadra.ordine_classe.progetto || ""}); 
                this.setState({ nome_scuola: res.data.data.dati_squadra.ordine_classe.scuola || ""}); 
                this.setState({ cod_scuola: res.data.data.dati_squadra.ordine_classe.cod_scuola || ""}); 
                this.setState({ classe: res.data.data.dati_squadra.ordine_classe.classe || ""}); 
                this.setState({ sezione: res.data.data.dati_squadra.ordine_classe.sezione || ""}); 
                this.setState({ docente_referente1: res.data.data.dati_squadra.ordine_classe.docente_referente1 || ""}); 
                this.setState({ docente_referente2: res.data.data.dati_squadra.ordine_classe.docente_referente2 || ""}); 
                this.setState({ ambito: res.data.data.dati_squadra.squadra.ambito || 0}); 
                this.setState({ competizione: res.data.data.dati_squadra.ordine_classe.competizione || ""}); 
                this.setState({ nome_squadra: res.data.data.dati_squadra.squadra.nome_squadra || ""}); 
                this.setState({ risultato: res.data.data.dati_squadra.squadra.risultato || 0}); 
                this.setState({ risultato_note: res.data.data.dati_squadra.squadra.risultato_note || ""}); 
                this.setState({ submitted: res.data.data.dati_squadra.squadra.submitted || 0}); 
                this.setState({ final_admitted: res.data.data.dati_squadra.squadra.final_admitted || 0}); 
                this.setState({ finale: res.data.data.dati_squadra.squadra.finale || 0}); 
                var giocatori = res.data.data.dati_squadra.giocatori;
                if (giocatori.length === 2){
                    giocatori.push({
                        id: 0,
                        id_squadra: id_squadra,
                        nome: '',
                        cognome: '',
                        privacy_doc: null,
                        created_at: null,
                        updated_at: null
                    });
                    giocatori.push({
                        id: 0,
                        id_squadra: id_squadra,
                        nome: '',
                        cognome: '',
                        privacy_doc: null,
                        created_at: null,
                        updated_at: null
                    });
                }
                if (giocatori.length === 3){
                    giocatori.push({
                        id: 0,
                        id_squadra: id_squadra,
                        nome: '',
                        cognome: '',
                        privacy_doc: null,
                        created_at: null,
                        updated_at: null
                    });
                }
                this.setState({ giocatori: giocatori || []}); 
                this.setState({ isloading: false});
            }
        }).catch (error => {
            //console.log(error);
            this.setState({ isloading: false});
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
        Cookies.remove('id_squadra');
    }

    handleLogoutClick(event) {
        event.preventDefault();
        this.setState({ authorization: ''});
        Cookies.remove('authorization');
        Cookies.remove('user_email');
        Cookies.remove('nome');
        Cookies.remove('cognome');
        Cookies.remove('wp_user_id');
        Cookies.remove('newuser');
    }

    handleUserMenuClick(event){
        this.setState({ anchorElUserMenu: event.currentTarget});
    }

    handleCloseUserMenu(){
        this.setState({ anchorElUserMenu: null});
    }

    handleChangeTeacherName(event){
        this.setState({ nomedocente: event.target.value }, function(){
            if (this.validateNomeDocente(event.target.value)) {
                this.setState({ nomedocenteerror: false });
            } else {
                this.setState({ nomedocenteerror: true });
            }
            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();
            if (required_field_are_filled && fields_are_valid && this.state.privacy === true) {
                this.setState({ confirmparticipationenabled: true });
            } else {
                this.setState({ confirmparticipationenabled: false });
            }
        });
    }

    handleChangeTeacherEmail(event){
        this.setState({ emaildocente: event.target.value }, function(){
            let email_is_valid = this.validateEmailDocente(this.state.emaildocente);
            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();
    
            if (email_is_valid) {
                this.setState({ emaildocenteerror: false });
            } else {
                this.setState({ emaildocenteerror: true });
            }
                    
            if (required_field_are_filled && fields_are_valid && this.state.privacy === true) {
                this.setState({ confirmparticipationenabled: true });
            } else {
                this.setState({ confirmparticipationenabled: false });
            }
        }); 
    }

    handleChangeTeacherPhone(event){
        this.setState({ cellularedocente: event.target.value }, function(){
            if (this.validateCellulareDocente(event.target.value)) {
                this.setState({ cellularedocenteerror: false });
            } else {
                this.setState({ cellularedocenteerror: true });
            }
            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();
            if (required_field_are_filled && fields_are_valid && this.state.privacy === true) {
                this.setState({ confirmparticipationenabled: true });
            } else {
                this.setState({ confirmparticipationenabled: false });
            }
        });
    }

    handleChangePlayerFirstname(event){

        let giocatori = this.state.giocatori;
        let id_player = event.target.getAttribute("data-player");
        giocatori[id_player].nome = event.target.value;
        this.setState({ giocatori: giocatori }, function(){
            if (id_player == 2 || id_player == 3) {
                if (this.validateNomeGiocatore4(event.target.value)) {
                    let nome_giocatore_errors = this.state.nome_giocatore_error;
                    nome_giocatore_errors[event.target.getAttribute("data-player")] = false;
                    this.setState({ nome_giocatore_error: nome_giocatore_errors });
                } else {
                    let nome_giocatore_errors = this.state.nome_giocatore_error;
                    nome_giocatore_errors[event.target.getAttribute("data-player")] = true;
                    this.setState({ nome_giocatore_error: nome_giocatore_errors });
                }
            } else {
                if (this.validateNomeGiocatore(event.target.value)) {
                    let nome_giocatore_errors = this.state.nome_giocatore_error;
                    nome_giocatore_errors[event.target.getAttribute("data-player")] = false;
                    this.setState({ nome_giocatore_error: nome_giocatore_errors });
                } else {
                    let nome_giocatore_errors = this.state.nome_giocatore_error;
                    nome_giocatore_errors[event.target.getAttribute("data-player")] = true;
                    this.setState({ nome_giocatore_error: nome_giocatore_errors });
                }
            }
            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();
            if (required_field_are_filled && fields_are_valid && this.state.privacy === true) {
                this.setState({ confirmparticipationenabled: true });
            } else {
                this.setState({ confirmparticipationenabled: false });
            }
        });  
        event.target.focus();
    }

    handleChangePlayerLastname(event){
        let giocatori = this.state.giocatori;
        let id_player = event.target.getAttribute("data-player");
        giocatori[id_player].cognome = event.target.value;
        this.setState({ giocatori: giocatori }, function(){
            if (id_player == 2 || id_player == 3) {
                if (this.validateCognomeGiocatore4(event.target.value)) {
                    let cognome_giocatore_errors = this.state.cognome_giocatore_error;
                    cognome_giocatore_errors[event.target.getAttribute("data-player")] = false;
                    this.setState({ cognome_giocatore_error: cognome_giocatore_errors });
                } else {
                    let cognome_giocatore_errors = this.state.cognome_giocatore_error;
                    cognome_giocatore_errors[event.target.getAttribute("data-player")] = true;
                    this.setState({ cognome_giocatore_error: cognome_giocatore_errors });
                }
            } else {
                if (this.validateCognomeGiocatore(event.target.value)) {
                    let cognome_giocatore_errors = this.state.cognome_giocatore_error;
                    cognome_giocatore_errors[event.target.getAttribute("data-player")] = false;
                    this.setState({ cognome_giocatore_error: cognome_giocatore_errors });
                } else {
                    let cognome_giocatore_errors = this.state.cognome_giocatore_error;
                    cognome_giocatore_errors[event.target.getAttribute("data-player")] = true;
                    this.setState({ cognome_giocatore_error: cognome_giocatore_errors });
                }
            }
            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();
            //console.log("required fields are filled > " + required_field_are_filled);
            //console.log("fields_are_valid > " + fields_are_valid);
            if (required_field_are_filled && fields_are_valid && this.state.privacy === true) {
                this.setState({ confirmparticipationenabled: true });
            } else {
                this.setState({ confirmparticipationenabled: false });
            }
        });  
    }

    handleChangePrivacy(event) {
        this.setState({ privacy: event.target.checked }, function(){
            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();
            if (required_field_are_filled && fields_are_valid && event.target.checked === true) {
                this.setState({ confirmparticipationenabled: true });
            } else {
                this.setState({ confirmparticipationenabled: false });
            }
        });
    }

    requiredFieldsAreFilled() {

        var required_fields;

        required_fields = [
            'nomedocente',
            'emaildocente',
            'cellularedocente'
        ];

        let filled = 0;
        required_fields.forEach((element) => {
            //console.log(element);
            if (this.state[element].length > 0) {
                filled = filled + 1;
            }
        });

        if (filled === required_fields.length){
            
            var fields_are_filled = true;
            var giocatori = this.state.giocatori;
            giocatori.forEach((player, index ) => {
                if (index == 2 || index == 3) {

                } else {
                    if (player.nome == null || player.nome.length == 0){
                        fields_are_filled = false;
                    }
                    if (player.cognome == null || player.cognome.length == 0){
                        fields_are_filled = false;
                    }
                }
            });
            return fields_are_filled;
        } else {
            return false;
        }
    }

    fieldsAreValid() {
        if (this.validateNomeDocente(this.state.nomedocente) === false) {
            //console.log("teacher name invalid");
            return false;
        }
        if (this.validateEmailDocente(this.state.emaildocente) === false) {
            //console.log("teacher email invalid");
            return false;
        }
        if (this.validateCellulareDocente(this.state.cellularedocente) === false) {
            //console.log("theacer phone invalid");
            return false;
        }
        var fields_are_valid = true;
        var giocatori = this.state.giocatori;
        giocatori.forEach((player, index) => {
            if (index == 2 || index == 3) {
                if (this.validateNomeGiocatore4(player.nome) === false){
                    fields_are_valid = false;
                }
                if (this.validateCognomeGiocatore4(player.cognome) === false){
                    fields_are_valid = false;
                }
            } else {
                if (this.validateNomeGiocatore(player.nome) === false){
                    fields_are_valid = false;
                }
                if (this.validateCognomeGiocatore(player.cognome) === false){
                    fields_are_valid = false;
                }
            }
        });
        return fields_are_valid;
    }
    
    validateNomeDocente(value){
        let nome = value;
        const re = /^[a-zàòùèéì ,.'-]+$/i;
        return re.test(nome) || nome.length === 0;
    }

    validateEmailDocente(value){
        let email = value;
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    validateCellulareDocente(value){
        let cellulare = value;
        const re = /^[0-9\s]{8,20}$/;
        return re.test(cellulare) || cellulare.length === 0;
    }

    validateNomeGiocatore(value){
        let nome = value;
        const re = /^[a-zàòùèéì ,.'-]+$/i;
        return re.test(nome);
    }

    validateNomeGiocatore4(value){
        let nome = value;
        const re = /^[a-zàòùèéì ,.'-]+$/i;
        return re.test(nome) || nome.length === 0;
    }

    validateCognomeGiocatore(value){
        let cognome = value;
        const re = /^[a-zàòùèéì ,.'-]+$/i;
        return re.test(cognome);
    }

    validateCognomeGiocatore4(value){
        let cognome = value;
        const re = /^[a-zàòùèéì ,.'-]+$/i;
        return re.test(cognome) || cognome.length === 0;
    }

    handleConfirmParticipationClick(event){
        event.preventDefault();

        if (this._isMounted) {
            this.setState({ error: false });
            this.setState({ isloading: true });
        }

        var config = require('./config.json');
        var env_backend = config.env_backend;
        var api_url_backend = config.api_url_backend[env_backend];
        const apiUrl = api_url_backend + 'teams/confirmparticipation';
        var authorization = config.api_auth_backend[env_backend];

        let id_squadra = this.state.id_squadra;
        let nome_docente = this.state.nomedocente;
        let email_docente = this.state.emaildocente;
        let cellulare_docente = this.state.cellularedocente;
        let giocatori = this.state.giocatori;
        let dati_giocatori = [];
        
        giocatori.forEach((player, index) => {
            if (index == 2 && (player.nome.length == 0 || player.cognome.length == 0)){

            } else {
                if (index == 3 && (player.nome.length == 0 || player.cognome.length == 0)){

                } else {
                    dati_giocatori.push({
                        nome: player.nome,
                        cognome: player.cognome
                    });
                }
            }
        });

        const settings = {
            headers: { Authorization: `Bearer ${authorization}` }
        };

        //console.log(dati_giocatori);

        const params = { 
            "id_squadra": id_squadra,
            "nome_docente": nome_docente,
            "email_docente": email_docente,
            "cellulare_docente": cellulare_docente,
            "giocatori": dati_giocatori
        };

        axios.patch(apiUrl, params, settings).then(res => {

            this.setState({ isloading: false }, function(){
                //console.log(res.data.data); 
            });

            if (res.data.success){

                this.setState({ confirmparticipationcompleted: true });
                this.setState({ confirmparticipationenabled: false });

            } else {

                if (this._isMounted) {

                    this.setState({ error: true});
                    this.setState({ errorcode: res.data.data.code });
                    this.setState({ errormessage: res.data.data.message });
                    this.setState({ isloading: false });
                }

            }

        }).catch (error => {
            
            if (this._isMounted) {
                
                this.setState({ isloading: false });
            }

        }); 
        

    }

    render() {

        const autenticato = this.state.authorization !== '';

        const nome = this.state.nome;
        const cognome = this.state.cognome;

        const anchorElUserMenu = this.state.anchorElUserMenu;
        const open = Boolean(anchorElUserMenu);

        const nome_squadra = this.state.nome_squadra;
        const classe = this.state.classe;
        const sezione = this.state.sezione;
        const nome_scuola = this.state.nome_scuola;
        const nome_progetto = this.state.nome_progetto;
        const competizione = this.state.competizione;

        const giocatori = this.state.giocatori;
        const nome_giocatore_error = this.state.nome_giocatore_error;
        const cognome_giocatore_error = this.state.cognome_giocatore_error;
        const nomedocenteerror = this.state.nomedocenteerror;
        const emaildocenteerror = this.state.emaildocenteerror;
        const cellularedocenteerror = this.state.cellularedocenteerror;

        const privacy = this.state.privacy;
        const nomedocente = this.state.nomedocente;
        const emaildocente = this.state.emaildocente;
        const cellularedocente = this.state.cellularedocente;

        const confirmparticipationenabled = this.state.confirmparticipationenabled;
        const confirmparticipationcompleted = this.state.confirmparticipationcompleted;
        const isloading = this.state.isloading;

        return (
            <div>
                { confirmparticipationcompleted && (
                    <Redirect to="/manageteam" /> 
                )}
                { (autenticato && isloading === false) && ( 
                    <Fragment>
                        <Helmet htmlAttributes={{lang: 'it-IT'}}></Helmet>
                        <div className="App">
                            <header className="App-header-home">
                                <AppBar position="static" className="WelcomeBar">
                                    <Toolbar>
                                        <table className="WelcomeMenu">
                                            <tbody>
                                                <tr>
                                                    <td className="LogoMenuColumn">
                                                        <Logo />
                                                    </td>
                                                    <td className="HomeMenuColumn">
                                                        <HomeButton />
                                                        |
                                                        <TokalonButton />
                                                    </td>
                                                    <td className="AccountMenuButton">
                                                        { (nome === "" || cognome === "") ? (
                                                            <>
                                                                {/* <SocialButtonsInHeader /> */}
                                                                <Link to="/profile">
                                                                    <Button
                                                                        className="AccountName"
                                                                        size="large"
                                                                        id="user-menu-button"
                                                                        aria-label="account of current user"
                                                                        aria-controls="user-menu"
                                                                        //aria-haspopup="true"
                                                                        //aria-expanded={Boolean(anchorElUserMenu) ? 'true' : undefined}
                                                                        //onClick={this.handleUserMenuClick}
                                                                        color="inherit"
                                                                    >
                                                                        {/* <FontAwesomeIcon icon={faUser} /> */}
                                                                        <span className="WelcomeUser">{/* Bentornato */}</span>
                                                                    </Button>
                                                                </Link>
                                                                |
                                                                <Button
                                                                    className=""
                                                                    size="large"
                                                                    color="inherit"
                                                                    id="logout-button"
                                                                    onClick={this.handleLogoutClick}
                                                                >
                                                                    Esci
                                                                </Button>
                                                            </>
                                                        ): (
                                                            <>
                                                                {/* <SocialButtonsInHeader /> */}
                                                                <Link to="/profile">
                                                                    <Button
                                                                        className=""
                                                                        size="large"
                                                                        id="user-menu-button"
                                                                        aria-label="account of current user"
                                                                        aria-controls="user-menu"
                                                                        //aria-haspopup="true"
                                                                        //aria-expanded={Boolean(anchorElUserMenu) ? 'true' : undefined}
                                                                        //onClick={this.handleUserMenuClick}
                                                                        color="inherit" 
                                                                    >
                                                                        {/* <FontAwesomeIcon icon={faUser} /> */}
                                                                        <span className="WelcomeUser">{/* Bentornato */}</span> { nome + " " + cognome }
                                                                    </Button>
                                                                </Link>
                                                                |
                                                                <Button
                                                                    className=""
                                                                    size="large"
                                                                    color="inherit"
                                                                    id="logout-button"
                                                                    onClick={this.handleLogoutClick}
                                                                >
                                                                    Esci
                                                                </Button>
                                                            </>
                                                        )}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <Menu
                                            id="user-menu"
                                            anchorEl={anchorElUserMenu}
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            keepMounted
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            open={open}
                                            onClose={this.handleCloseUserMenu}
                                            MenuListProps={{
                                                'aria-labelledby': 'user-menu-button',
                                            }}
                                        >
                                            <MenuItem><Link to="/profile">Profilo</Link></MenuItem>
                                            <MenuItem onClick={this.handleLogoutClick}>Esci</MenuItem>
                                        </Menu>
                                    </Toolbar>
                                </AppBar>
                                {/* <Subheader></Subheader> */}
                            </header>
                            <div className="separator"></div>
                            <div className="Page-title Orange">Conferma Partecipazione</div>
                            <div className="Page-subtitle-top">Complimenti! La Squadra "{nome_squadra}" della
                             classe {classe} {sezione} - {nome_scuola} è stata ammessa alla fase finale della competizione 
                               {" " + competizione}. Inserisci i dati dell'adulto accompagnatore, verifica i dati della squadra
                                e conferma la partecipazione alla fase finale che si svolgerà al parco
                              Cinecittà World, a Roma, nei giorni indicati.
                            </div>
                            <div className="separator"></div>
                            <Grid container spacing={1} style={{textAlign: "center"}}> 
                                <Grid item xs={12} className="Form-control Register" style={{margin: "0 auto"}}>
                                    <div className="Form-label" style={{width: "90%", padding: "0px 27px"}}>Adulto Accompagnatore - Nome e Cognome</div>
                                    <div className="Form-control Register" style={{width: "90%", padding: "0px 27px", margin: "0 auto"}}>
                                        { nomedocenteerror ? (
                                            <TextField id={"nomedocente"} inputProps={{maxLength : 255}} autoComplete='nope' required placeholder="Nome e cognome" error helperText="Valore non valido" variant="outlined" value={ nomedocente } onChange={this.handleChangeTeacherName}/>
                                        ) : (
                                            <TextField id={"nomedocente"} inputProps={{maxLength : 255}} autoComplete='nope' required placeholder="Nome e cognome" variant="outlined" value={ nomedocente } onChange={this.handleChangeTeacherName}/>
                                        )}
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid container spacing={1} style={{textAlign: "center"}}> 
                                <Grid item xs={12} className="Form-control Register" style={{margin: "0 auto"}}>
                                    <div className="Form-label" style={{width: "90%", padding: "0px 27px"}}>Adulto Accompagnatore - Email</div>
                                    <div className="Form-control Register" style={{width: "90%", padding: "0px 27px", margin: "0 auto"}}>
                                        { emaildocenteerror ? (
                                            <TextField id={"emaildocente"} inputProps={{maxLength : 255}} autoComplete='nope' required placeholder="Email" error helperText="Valore non valido" variant="outlined" value={ emaildocente } onChange={this.handleChangeTeacherEmail}/>
                                        ) : (
                                            <TextField id={"emaildocente"} inputProps={{maxLength : 255}} autoComplete='nope' required placeholder="Email" variant="outlined" value={ emaildocente } onChange={this.handleChangeTeacherEmail}/>
                                        )}
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid container spacing={1} style={{textAlign: "center"}}> 
                                <Grid item xs={12} className="Form-control Register" style={{margin: "0 auto"}}>
                                    <div className="Form-label" style={{width: "90%", padding: "0px 27px"}}>Adulto Accompagnatore - Telefono cellulare</div>
                                    <div className="Form-control Register" style={{width: "90%", padding: "0px 27px", margin: "0 auto"}}>
                                        { cellularedocenteerror ? (
                                            <TextField id={"cellularedocente"} inputProps={{maxLength : 255}} autoComplete='nope' required placeholder="Telefono cellulare" error helperText="Valore non valido" variant="outlined" value={ cellularedocente } onChange={this.handleChangeTeacherPhone}/>
                                        ) : (
                                            <TextField id={"cellularedocente"} inputProps={{maxLength : 255}} autoComplete='nope' required placeholder="Telefono cellulare" variant="outlined" value={ cellularedocente } onChange={this.handleChangeTeacherPhone}/>
                                        )}
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid container spacing={1} style={{textAlign: "center"}}> 
                                <Grid item xs={12} className="Form-control Register" style={{margin: "0 auto"}}>
                                    {[...Array(4)].map((y, j) =>
                                        <Grid key={"player"+j} container className="Form-control Register Half" style={{width: "100%", maxWidth: "600px", padding: "0px 27px"}}>
                                            {giocatori[j] !== undefined && (
                                                <Fragment>
                                                    {(giocatori[j].nome !== '' || giocatori[j].cognome !== '') && (
                                                        <div className="Form-label" style={{width: "100%", padding: "0"}}>Nome e Cognome Giocatore {j+1}</div>    
                                                    )}
                                                </Fragment>
                                            )}
                                            <Grid item xs={6}>
                                                {giocatori[j] !== undefined ? (
                                                    <Fragment>
                                                        {(nome_giocatore_error[j] === false && giocatori[j].nome !== '') && (
                                                            <TextField id={"nomegiocatore" + (j+1)} style={{ width : "95%", paddingRight : "19px" }} inputProps={{maxLength : 255, 'data-player': j}} autoComplete='nope' required placeholder="Nome" variant="outlined" onChange={this.handleChangePlayerFirstname} value={ giocatori[j].nome }/>
                                                        )}
                                                    </Fragment>
                                                ) : (
                                                    <TextField id={"nomegiocatore" + (j+1)} style={{ width : "95%", paddingRight : "19px" }} inputProps={{maxLength : 255, 'data-player': j}} autoComplete='nope' required placeholder="Nome" variant="outlined" onChange={this.handleChangePlayerFirstname} value={ "" }/>
                                                )}
                                                {nome_giocatore_error[j] && (
                                                    <TextField id={"nomegiocatore" + (j+1)} style={{ width : "95%", paddingRight : "19px" }} inputProps={{maxLength : 255, 'data-player': j}} autoComplete='nope' required placeholder="Nome" variant="outlined" error helperText="Valore non valido" onChange={this.handleChangePlayerFirstname} value={ giocatori[j].nome }/>
                                                )}
                                            </Grid>
                                            <Grid item xs={6}>
                                                {giocatori[j] !== undefined ? (
                                                    <Fragment>
                                                        {(cognome_giocatore_error[j] === false && giocatori[j].cognome !== '') && (
                                                            <TextField id={"cognomegiocatore" + (j+1)} style={{ width : "95%", paddingLeft : "15px" }} inputProps={{maxLength : 255, 'data-player': j}} autoComplete='nope' required placeholder="Cognome" variant="outlined" onChange={this.handleChangePlayerLastname} value={ giocatori[j].cognome }/>
                                                        )}
                                                    </Fragment>
                                                ) : (
                                                    <TextField id={"nomegiocatore" + (j+1)} style={{ width : "95%", paddingLeft : "15px" }} inputProps={{maxLength : 255, 'data-player': j}} autoComplete='nope' required placeholder="Cognome" variant="outlined" onChange={this.handleChangePlayerLastname} value={ "" }/>
                                                )}
                                                {cognome_giocatore_error[j] && (
                                                    <TextField id={"nomegiocatore" + (j+1)} style={{ width : "95%", paddingLeft : "15px" }} inputProps={{maxLength : 255, 'data-player': j}} autoComplete='nope' required placeholder="Cognome" variant="outlined" error helperText="Valore non valido" onChange={this.handleChangePlayerLastname} value={ giocatori[j].cognome }/>
                                                )}
                                            </Grid>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid container spacing={1} style={{textAlign: "center"}}> 
                                <Grid item xs={12} className="Form-control Register" style={{margin: "0 auto"}}>
                                    <FormControlLabel className="Checkbox ConfirmParticipation"
                                        control={
                                        <Checkbox
                                            checked={this.state.privacy}
                                            onChange={this.handleChangePrivacy}
                                            name="privacy"
                                            color="primary"
                                            style={{marginRight: "10px"}}
                                        />
                                        }
                                        label={<label className="label-desc">Cliccando su "Conferma partecipazione", accetti le nostre <Link to="/privacy" target="_blank">Condizioni</Link> e la nostra <Link to="/privacy" target="_blank">normativa sulla Privacy</Link>.</label>}
                                        style={{padding: "0 30px", height: "auto"}}
                                    />  
                                </Grid>
                            </Grid>
                            <Grid container spacing={1} style={{textAlign: "center"}}> 
                                <Grid item xs={12} className="Form-control Register" style={{margin: "0 auto"}}>
                                    <div className="Form-control Register">
                                        {confirmparticipationenabled ? (
                                            <Button size="large" type="submit" variant="contained" color="primary" onClick={this.handleConfirmParticipationClick}>Conferma partecipazione</Button>
                                        ) : (
                                            <Button size="large" type="submit" variant="contained" disabled color="primary" onClick={this.handleConfirmParticipationClick}>Conferma partecipazione</Button>
                                        )}
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </Fragment>
                )}
            </div>
        )
    }
}

export default ConfirmParticipation;